import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import Filters from 'components/ui/Filters/Filters';
import FiltersPanel from 'components/ui/FiltersPanel/FiltersPanel';
import FilterItemContainer from 'components/ui/FilterItemContainer/FilterItemContainer';
import CRTableWidget from 'components/widgets/CRTableWidget';
import FiltersContext from 'context/filters.context';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'framework/constants';
import { useFilters } from 'hooks/useFilters';
import { clientRequestsFind } from 'store/client-request/client-request.actions';
import { installerDataGet } from 'store/installer/installer.actions';
import {
  clientRequestsFindStateSelector,
  foundClientRequestsSelector,
  foundClientRequestsTotalSelector,
} from 'store/client-request/client-request.selectors';
import { RequestState } from 'store/common.types';

import { tenderStates } from 'types/installer-common.types';
import styles from './DashboardPage.module.sass';

export interface IClientRequestsConfig {
  limit: number;
  offset: number;
  radius?: string | undefined | null;
  zip?: string;
}

const defaultFiltersValue = {
  query: '',
  radius: null,
  zip: '',
};

const defaultMetaValue = {
  limit: DEFAULT_PAGINATION_PAGE_SIZE,
  offset: 0,
};

const TendersTab = () => {
  const { filters, handleFilterChange } = useFilters(defaultFiltersValue);
  const [meta, setMeta] = useState(defaultMetaValue);

  const dispatch = useDispatch();
  const { t } = useTranslation(['clientRequest']);

  const findState = useSelector(clientRequestsFindStateSelector);
  const foundRequests = useSelector(foundClientRequestsSelector);
  const foundRequestsTotal = useSelector(foundClientRequestsTotalSelector);

  useEffect(() => {
    const config = {
      ...filters,
      offset: 0,
    };

    dispatch(clientRequestsFind(config));
    dispatch(installerDataGet());
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(clientRequestsFind({ ...filters, ...meta }));
  }, [filters]); // eslint-disable-line

  const onPageChange = (page: number, pageSize: number) => {
    const newMeta = {
      offset: pageSize * (page - 1),
      limit: pageSize,
    };

    setMeta(newMeta);
    dispatch(clientRequestsFind({ ...filters, ...newMeta }));
  };

  const renderFilters = () => {
    const stateOptions = tenderStates.map((state) => ({
      value: state,
      label: t(`clientRequest:state:${state}`),
    }));

    const radiusOptions = [
      { value: null, label: t('installerFlow:preferences:unlimited') },
      { value: '30', label: '30 km' },
      { value: '60', label: '60 km' },
      { value: '120', label: '120 km' },
      { value: '240', label: '240 km' },
      { value: '580', label: '580 km' },
      { value: '1000', label: '1000 km' },
    ];

    return (
      <FiltersPanel
        query={filters.query}
        onSearchChange={(newValue) => handleFilterChange({ query: newValue })}
      >
        <FilterItemContainer label={t('intermediate:myProjects:filters:tenderStatus')}>
          <Filters.MultiSelect
            name="state"
            label={t('intermediate:myProjects:filters:searchStatusLabel')}
            options={stateOptions}
            // [TODO]: Use correct naming
            placeholder={t('intermediate:myProjects:filters:searchStatus')}
          />
        </FilterItemContainer>
        <FilterItemContainer label={t('intermediate:myProjects:filters:zip')}>
          <Tooltip title={t('installerFlow:preferences:tooltip:zip')}>
            <Filters.TextField
              className={styles.searchField}
              name="zip"
              value={filters.zip}
              placeholder={t('installerFlow:dashboard:zip')}
            />
          </Tooltip>
        </FilterItemContainer>
        <FilterItemContainer label={t('intermediate:myProjects:filters:radius')}>
          <Tooltip title={t('installerFlow:preferences:tooltip:searchInRadius')}>
            <Filters.Select
              name="radius"
              value={filters.radius}
              placeholder={t('installerFlow:preferences:searchRadius')}
              width={200}
              // @ts-ignore
              options={radiusOptions}
            />
          </Tooltip>
        </FilterItemContainer>
      </FiltersPanel>
    );
  };

  const isLoading = !(findState === RequestState.Success || findState === RequestState.Error);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ filters, setFilters: handleFilterChange }), [filters]);

  return (
    <>
      <FiltersContext.Provider value={contextValue}>{renderFilters()}</FiltersContext.Provider>
      <CRTableWidget
        isLoading={isLoading}
        dataSource={foundRequests}
        pageSize={meta.limit}
        offset={meta.offset}
        total={foundRequestsTotal}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default TendersTab;
