import { produce } from 'immer';
import {
  CUSTOM_DOCUMENTS_GET_SUCCESS,
  CUSTOM_DOCUMENTS_IMAGE_UPLOAD_SUCCESS,
  CUSTOM_DOCUMENTS_IMAGES_GET_SUCCESS,
  CUSTOM_DOCUMENTS_MEDIA_LINK_GET_SUCCESS,
  CUSTOM_DOCUMENTS_UPDATE_SUCCESS,
  DELETE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  GET_CURRENCIES_BY_ORGANIZATION,
  GET_CURRENCIES_BY_ORGANIZATION_ERROR,
  GET_CURRENCIES_BY_ORGANIZATION_SUCCESS,
  GET_LEGAL_DOCUMENTS,
  GET_LEGAL_DOCUMENTS_ERROR,
  GET_LEGAL_DOCUMENTS_SUCCESS,
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_CONFIGS,
  GET_QUESTIONNAIRE_CONFIGS_ERROR,
  GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_SUCCESS,
  GET_QUESTIONNAIRE_CONFIGS_SUCCESS,
  GET_QUESTIONNAIRE_ERROR,
  GET_QUESTIONNAIRE_SUCCESS,
  IIntermediateState,
  INTERMEDIATE_ARCHIVE_SUCCESS,
  INVITE_ORGANIZATION_ERROR,
  INVITE_ORGANIZATION_SUCCESS,
  INVITE_TOKEN_UPDATE,
  INVITE_TOKEN_UPDATE_ERROR,
  INVITE_TOKEN_UPDATE_SUCCESS,
  LANDING_PAGE_GET,
  LANDING_PAGE_GET_ERROR,
  LANDING_PAGE_GET_SUCCESS,
  ORDER_INSTALLER_DIRECTLY_ERROR,
  ORDER_INSTALLER_DIRECTLY_SUCCESS,
  ORGANIZATIONS_FIND_SUCCESS,
  ORGANIZATIONS_INVITE_DELETE_SUCCESS,
  ORGANIZATIONS_INVITES_GET_SUCCESS,
  POOL_CLIENT_REQUESTS_GET,
  POOL_CLIENT_REQUESTS_GET_ERROR,
  POOL_CLIENT_REQUESTS_GET_SUCCESS,
  POOL_CREATE,
  POOL_CREATE_ERROR,
  POOL_CREATE_SUCCESS,
  POOL_FILE_UPLOAD_SUCCESS,
  POOL_FILES_GET,
  POOL_FILES_GET_ERROR,
  POOL_FILES_GET_SUCCESS,
  POOL_GET_BY_ID,
  POOL_GET_BY_ID_ERROR,
  POOL_GET_BY_ID_SUCCESS,
  POOL_GET_REQUESTS_BY_ID,
  POOL_GET_REQUESTS_BY_ID_ERROR,
  POOL_GET_REQUESTS_BY_ID_SUCCESS,
  POOL_INVITE_TOKENS_GET,
  POOL_INVITE_TOKENS_GET_ERROR,
  POOL_INVITE_TOKENS_GET_SUCCESS,
  POOL_OFFERS_GET,
  POOL_OFFERS_GET_ERROR,
  POOL_OFFERS_GET_SUCCESS,
  POOLS_GET,
  POOL_UPDATE,
  POOL_UPDATE_ERROR,
  POOL_UPDATE_SUCCESS,
  POOLS_GET_SUCCESS,
  PoolsActionTypes,
  SAVE_LEGAL_DOCUMENTS,
  SAVE_LEGAL_DOCUMENTS_ERROR,
  SAVE_LEGAL_DOCUMENTS_SUCCESS,
  STATS_GET,
  STATS_GET_ERROR,
  STATS_GET_SUCCESS,
  UNAPPROVED_CLIENT_REQUESTS_GET_SUCCESS,
  UNAPPROVED_OFFERS_GET_SUCCESS,
  UPDATE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  CREATE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  CUSTOM_DOCUMENTS_CREATE_SUCCESS,
  CUSTOM_DOCUMENTS_GET,
  SERVICE_FEE_DESCRIPTION_GET_ERROR,
  SERVICE_FEE_DESCRIPTION_GET_SUCCESS,
  SERVICE_FEE_DESCRIPTION_GET,
  RESET_POOL_FILES,
  GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS,
  GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_SUCCESS,
  GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_ERROR,
  RESET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS,
  CUSTOM_DOCUMENTS_DELETE_SUCCESS,
  GET_POOL_TAGS,
  GET_POOL_TAGS_SUCCESS,
  GET_POOL_TAGS_ERROR,
  CLIENT_REQUESTS_GET,
  CLIENT_REQUESTS_GET_SUCCESS,
  CLIENT_REQUESTS_GET_ERROR,
} from './intermediate.types';
import { RequestState } from '../common.types';
import { MediaCategoryType } from '../client-request/client-request.types';

const initialState: IIntermediateState = {
  pools: [],
  totalPools: 0,
  poolsRequestState: null,
  poolCreateState: null,
  poolCreateError: null,

  poolUpdateSuccess: null,
  poolUpdateError: null,

  currentPool: null,
  currentPoolState: null,
  currentPoolError: null,

  currentPoolRequests: {
    items: [],
    total: 0,
  },
  currentPoolRequestsState: null,
  currentPoolRequestsError: null,

  unapprovedClientRequests: [],

  clientRequests: {
    state: RequestState.None,
    data: {
      items: [],
      total: 0,
    },
  },

  unapprovedOffers: [],

  findOrganizations: [],

  stats: null,
  statsState: null,

  poolFiles: [],
  poolFilesState: null,

  getCustomDocumentsState: null,
  customDocument: [],
  customerQuestionnaireConfigs: [],

  questionnaireConfigs: [],
  questionnaireConfigsState: null,

  questionnaire: null,
  questionnaireState: null,

  landingPage: null,
  landingPageState: null,
  servicesFeeDescription: null,
  servicesFeeDescriptionRequestState: null,
  inviteTokenUpdateState: null,

  customDocumentImages: [],
  customDocumentMediaLink: null,
  organizationsInvites: [],

  poolClientRequests: [],
  poolClientRequestsState: null,
  poolClientRequestsError: null,

  poolOffers: {
    items: [],
    total: 0,
  },
  poolOffersState: null,
  poolOffersError: null,

  inviteTokens: [],
  inviteTokensState: null,
  inviteTokensError: null,
  orderInstallerDirectly: null,
  orderInstallerDirectlyState: null,

  currencies: [],
  currenciesState: null,
  currenciesError: null,

  legalDocuments: [],
  legalDocumentsState: null,
  legalDocumentsError: null,

  saveLegalDocumentsState: null,
  saveLegalDocumentsError: null,

  installationWizardCustomImageUrls: {
    wallboxOnly: '',
    wallboxAndInstallation: '',
    state: RequestState.None,
  },

  poolTags: {
    state: RequestState.None,
    tags: [],
  },
};

const IntermediateReducer = (state = initialState, action: PoolsActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case POOL_INVITE_TOKENS_GET:
        draft.inviteTokensState = RequestState.Loading;
        break;
      case INTERMEDIATE_ARCHIVE_SUCCESS:
        draft.currentPoolRequests.items = state.currentPoolRequests.items.map((item) =>
          item.id === action.payload.id ? { ...item, archived: !item.archived } : item,
        );
        break;
      case POOL_INVITE_TOKENS_GET_SUCCESS:
        draft.inviteTokensState = RequestState.Success;
        draft.inviteTokens = action.payload;
        draft.inviteTokensError = null;
        break;
      case POOL_INVITE_TOKENS_GET_ERROR:
        draft.inviteTokensState = RequestState.Error;
        draft.inviteTokensError = action.payload;
        break;

      case INVITE_ORGANIZATION_SUCCESS:
        draft.inviteTokensState = RequestState.Success;
        draft.inviteTokensError = null;
        break;
      case INVITE_ORGANIZATION_ERROR:
        draft.inviteTokensState = RequestState.Error;
        draft.inviteTokensError = action.payload;
        break;

      case ORGANIZATIONS_INVITE_DELETE_SUCCESS:
        draft.organizationsInvites = state.organizationsInvites.filter(
          (i: any) => i.id !== action.payload,
        );
        break;

      case ORGANIZATIONS_INVITES_GET_SUCCESS:
        draft.organizationsInvites = action.payload;
        break;

      case CUSTOM_DOCUMENTS_MEDIA_LINK_GET_SUCCESS:
        draft.customDocumentMediaLink = action.payload;
        break;

      case CUSTOM_DOCUMENTS_IMAGES_GET_SUCCESS:
        draft.customDocumentImages = action.payload;
        break;

      case CUSTOM_DOCUMENTS_IMAGE_UPLOAD_SUCCESS:
        draft.customDocumentImages = [action.payload];
        break;

      case INVITE_TOKEN_UPDATE:
        draft.inviteTokenUpdateState = RequestState.Loading;
        break;

      case INVITE_TOKEN_UPDATE_ERROR:
        draft.inviteTokenUpdateState = RequestState.Error;
        break;

      case INVITE_TOKEN_UPDATE_SUCCESS:
        draft.inviteTokenUpdateState = RequestState.Success;
        break;
      case SERVICE_FEE_DESCRIPTION_GET:
        draft.servicesFeeDescriptionRequestState = RequestState.Loading;
        break;
      case SERVICE_FEE_DESCRIPTION_GET_SUCCESS:
        draft.landingPageState = RequestState.Success;
        draft.servicesFeeDescription = action.payload;
        break;
      case SERVICE_FEE_DESCRIPTION_GET_ERROR:
        draft.servicesFeeDescriptionRequestState = RequestState.Error;
        break;
      case LANDING_PAGE_GET:
        draft.landingPageState = RequestState.Loading;
        break;

      case LANDING_PAGE_GET_ERROR:
        draft.landingPageState = RequestState.Error;
        draft.landingPage = null;
        break;

      case LANDING_PAGE_GET_SUCCESS:
        draft.landingPageState = RequestState.Success;
        draft.landingPage = action.payload;
        break;

      case GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_SUCCESS:
        draft.customerQuestionnaireConfigs = action.payload;
        break;

      case DELETE_QUESTIONNAIRE_CONFIGS_SUCCESS:
        draft.questionnaireConfigs = [
          ...state.questionnaireConfigs.filter((i) => i.id !== action.payload),
        ];
        break;

      case UPDATE_QUESTIONNAIRE_CONFIGS_SUCCESS:
        draft.questionnaireConfigs = [
          ...state.questionnaireConfigs.map((i) =>
            i.id === action.payload.id ? action.payload : i,
          ),
        ];
        break;

      case CREATE_QUESTIONNAIRE_CONFIGS_SUCCESS:
        draft.questionnaireConfigs = [...state.questionnaireConfigs, action.payload];
        break;

      case GET_QUESTIONNAIRE_CONFIGS:
        draft.questionnaireConfigsState = RequestState.Loading;
        break;

      case GET_QUESTIONNAIRE_CONFIGS_ERROR:
        draft.questionnaireConfigsState = RequestState.Error;
        break;

      case GET_QUESTIONNAIRE_CONFIGS_SUCCESS:
        draft.questionnaireConfigsState = RequestState.Success;
        draft.questionnaireConfigs = action.payload;
        break;

      case GET_QUESTIONNAIRE:
        draft.questionnaireState = RequestState.Loading;
        break;

      case GET_QUESTIONNAIRE_ERROR:
        draft.questionnaireState = RequestState.Error;
        break;

      case GET_QUESTIONNAIRE_SUCCESS:
        draft.questionnaireState = RequestState.Success;
        draft.questionnaire = action.payload;
        break;

      case POOL_FILES_GET:
        draft.poolFilesState = RequestState.Loading;
        break;

      case POOL_FILES_GET_ERROR:
        draft.poolFilesState = RequestState.Error;
        break;

      case POOL_FILES_GET_SUCCESS:
        draft.poolFilesState = RequestState.Success;
        if (action.payload.length > 0) {
          const categoriesToCheck = [
            MediaCategoryType.PoolAvatar,
            MediaCategoryType.RequestWallboxOnly,
            MediaCategoryType.RequestWallboxAndInstallation,
          ];
          const hasRelevantCategory = action.payload.some((file) =>
            categoriesToCheck.includes(file.category),
          );

          if (!hasRelevantCategory) {
            draft.poolFiles = draft.poolFiles.concat(action.payload);
            break;
          }

          const foundIndex = draft.poolFiles.findIndex(
            (file) => file.category === action.payload[0].category,
          );

          if (foundIndex >= 0) {
            draft.poolFiles[foundIndex] = action.payload[0];
            break;
          }

          draft.poolFiles = draft.poolFiles.concat(action.payload);
        }
        break;

      case RESET_POOL_FILES:
        draft.poolFilesState = null;
        draft.poolFiles = [];
        break;

      case GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS:
        draft.installationWizardCustomImageUrls = {
          state: RequestState.Loading,
          wallboxAndInstallation: '',
          wallboxOnly: '',
        };
        break;

      case GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_SUCCESS:
        draft.installationWizardCustomImageUrls = {
          state: RequestState.Success,
          wallboxAndInstallation: action.payload.wallboxAndInstallationUrl,
          wallboxOnly: action.payload.wallboxOnlyUrl,
        };
        break;

      case GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_ERROR:
        draft.installationWizardCustomImageUrls = {
          state: RequestState.Error,
          wallboxAndInstallation: '',
          wallboxOnly: '',
        };
        break;

      case RESET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS:
        draft.installationWizardCustomImageUrls = {
          state: RequestState.None,
          wallboxAndInstallation: '',
          wallboxOnly: '',
        };
        break;

      // [TODO]: Remove this case if it doesn't break
      case POOL_FILE_UPLOAD_SUCCESS:
        break;

      case CUSTOM_DOCUMENTS_UPDATE_SUCCESS:
        draft.customDocument = [
          ...state.customDocument.map((d) => (d.id === action.payload.id ? action.payload : d)),
        ];
        break;

      case CUSTOM_DOCUMENTS_CREATE_SUCCESS:
        draft.customDocument = [...state.customDocument, action.payload];
        break;
      case CUSTOM_DOCUMENTS_DELETE_SUCCESS:
        draft.customDocument = state.customDocument.filter(
          (_customDocument) => _customDocument.id !== action.payload.documentId,
        );
        break;
      case CUSTOM_DOCUMENTS_GET:
        draft.getCustomDocumentsState = RequestState.Loading;
        break;

      case CUSTOM_DOCUMENTS_GET_SUCCESS:
        draft.getCustomDocumentsState = RequestState.Success;
        draft.customDocument = action.payload;
        break;

      case STATS_GET:
        draft.statsState = RequestState.Loading;
        break;

      case STATS_GET_ERROR:
        draft.statsState = RequestState.Error;
        break;

      case STATS_GET_SUCCESS:
        draft.statsState = RequestState.Success;
        draft.stats = action.payload;
        break;
      case POOLS_GET:
        draft.poolsRequestState = RequestState.Loading;
        break;
      case POOLS_GET_SUCCESS:
        draft.pools = action.payload.pools;
        draft.totalPools = action.payload.totalPools;
        draft.poolsRequestState = RequestState.Success;
        break;

      case POOL_CREATE:
        draft.poolCreateState = RequestState.Loading;
        break;

      case POOL_CREATE_ERROR:
        draft.poolCreateState = RequestState.Error;
        draft.poolCreateError = action.payload;
        break;

      case POOL_CREATE_SUCCESS:
        draft.poolCreateState = RequestState.Success;
        draft.poolCreateError = null;
        draft.pools = [...state.pools, action.payload];
        break;

      case POOL_GET_BY_ID:
        draft.currentPoolState = RequestState.Loading;
        break;

      case POOL_GET_BY_ID_ERROR:
        draft.currentPoolState = RequestState.Error;
        draft.currentPoolError = action.payload;
        break;

      case POOL_GET_BY_ID_SUCCESS:
        draft.currentPoolState = RequestState.Success;
        draft.currentPoolError = null;
        draft.currentPool = action.payload;
        break;

      case POOL_GET_REQUESTS_BY_ID:
        draft.currentPoolRequestsState = RequestState.Loading;
        break;

      case POOL_GET_REQUESTS_BY_ID_ERROR:
        draft.currentPoolRequestsState = RequestState.Error;
        draft.currentPoolRequestsError = action.payload;
        break;

      case POOL_GET_REQUESTS_BY_ID_SUCCESS:
        draft.currentPoolRequestsState = RequestState.Success;
        draft.currentPoolRequestsError = null;
        draft.currentPoolRequests = action.payload;
        break;

      case POOL_UPDATE:
        draft.poolUpdateError = null;
        draft.poolUpdateSuccess = null;
        draft.poolOffersError = null;
        break;

      case POOL_UPDATE_SUCCESS:
        draft.pools = state.pools.map((i) => (i.id === action.payload.id ? action.payload : i));
        draft.currentPool = action.payload;
        draft.poolUpdateSuccess = true;
        break;

      case POOL_UPDATE_ERROR:
        draft.poolUpdateError = action.payload;
        break;

      case UNAPPROVED_CLIENT_REQUESTS_GET_SUCCESS:
        draft.unapprovedClientRequests = action.payload;
        break;

      case CLIENT_REQUESTS_GET:
        draft.clientRequests = {
          state: RequestState.Loading,
          data: {
            items: [],
            total: 0,
          },
        };
        break;

      case CLIENT_REQUESTS_GET_SUCCESS:
        draft.clientRequests = {
          state: RequestState.Success,
          data: {
            items: action.payload.items,
            total: action.payload.total,
          },
        };
        break;

      case CLIENT_REQUESTS_GET_ERROR:
        draft.clientRequests = {
          state: RequestState.Error,
          data: {
            items: [],
            total: 0,
          },
        };
        break;

      case UNAPPROVED_OFFERS_GET_SUCCESS:
        draft.unapprovedOffers = action.payload;
        break;

      case ORGANIZATIONS_FIND_SUCCESS:
        draft.findOrganizations = action.payload;
        break;

      case POOL_CLIENT_REQUESTS_GET:
        draft.poolClientRequestsState = RequestState.Loading;
        break;

      case POOL_CLIENT_REQUESTS_GET_ERROR:
        draft.poolClientRequestsState = RequestState.Error;
        draft.poolClientRequestsError = action.payload;
        break;

      case POOL_CLIENT_REQUESTS_GET_SUCCESS:
        draft.poolClientRequestsState = RequestState.Success;
        draft.poolClientRequestsError = null;
        draft.poolClientRequests = action.payload;
        break;

      case POOL_OFFERS_GET:
        draft.poolOffersState = RequestState.Loading;
        break;

      case POOL_OFFERS_GET_ERROR:
        draft.poolOffersState = RequestState.Error;
        draft.poolOffersError = action.payload;
        break;

      case POOL_OFFERS_GET_SUCCESS:
        draft.poolOffersState = RequestState.Success;
        draft.poolOffersError = null;
        draft.poolOffers = action.payload;
        break;

      case ORDER_INSTALLER_DIRECTLY_SUCCESS:
        draft.orderInstallerDirectlyState = RequestState.Success;
        draft.orderInstallerDirectly = action.payload;
        break;
      case ORDER_INSTALLER_DIRECTLY_ERROR:
        draft.orderInstallerDirectlyState = RequestState.Error;
        break;
      case GET_CURRENCIES_BY_ORGANIZATION:
        draft.currenciesState = RequestState.Loading;
        break;
      case GET_CURRENCIES_BY_ORGANIZATION_ERROR:
        draft.currenciesState = RequestState.Error;
        draft.currenciesError = action.payload;
        break;
      case GET_CURRENCIES_BY_ORGANIZATION_SUCCESS:
        draft.currenciesState = RequestState.Success;
        draft.currenciesError = null;
        draft.currencies = action.payload;
        break;
      case GET_LEGAL_DOCUMENTS:
        draft.legalDocumentsState = RequestState.Loading;
        break;
      case GET_LEGAL_DOCUMENTS_SUCCESS:
        draft.legalDocumentsState = RequestState.Success;
        draft.legalDocumentsError = null;
        draft.legalDocuments = action.payload;
        break;
      case GET_LEGAL_DOCUMENTS_ERROR:
        draft.legalDocumentsState = RequestState.Error;
        draft.legalDocumentsError = action.payload;
        break;
      case SAVE_LEGAL_DOCUMENTS:
        draft.saveLegalDocumentsState = RequestState.Loading;
        break;
      case SAVE_LEGAL_DOCUMENTS_SUCCESS:
        draft.saveLegalDocumentsState = RequestState.Success;
        draft.saveLegalDocumentsError = null;
        draft.legalDocuments = action.payload;
        break;
      case SAVE_LEGAL_DOCUMENTS_ERROR:
        draft.saveLegalDocumentsState = RequestState.Error;
        draft.saveLegalDocumentsError = action.payload;
        break;

      case GET_POOL_TAGS:
        draft.poolTags = {
          state: RequestState.Loading,
          tags: [],
        };
        break;

      case GET_POOL_TAGS_SUCCESS:
        draft.poolTags = {
          state: RequestState.Success,
          tags: action.payload,
        };
        break;

      case GET_POOL_TAGS_ERROR:
        draft.poolTags = {
          state: RequestState.Error,
          tags: [],
        };
        break;
    }
  });
export default IntermediateReducer;
