import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'Kunden-Nutzungsbedingungen für tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: "Conditions d'utilisation du client Tendergy",
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german:
      'Die Schneider Electric GmbH („Schneider Electric“) betreibt eine Vermittlungs-Plattform für die Installation (und ggfs. Kauf) einer sog. Wallbox („tendergy“). Auf der Plattform haben Nutzer die Möglichkeit deren Installation durch einen Installateur zu buchen. Die Angebote werden von verschiedenen Anbietern („Anbieter“) online gestellt, mit denen auch der jeweilige Vertrag über die Leistung zustande kommt („Leistungsvertrag“). Zu den Leistungen der Anbieter können über Tendergy Informationen abgerufen und Bestellungen veranlasst werden.',
    targetLanguage:
      "Schneider Electric GmbH (« Schneider Electric ») exploite une plateforme de courtage pour l'installation (et, le cas échéant, l'achat) d'une wallbox (« tendergy »). La plateforme offre aux utilisateurs la possibilité de réserver leurs installations par l'intermédiaire d'un installateur. Tendergy peut être utilisé pour obtenir des informations sur les services des fournisseurs et pour passer des commandes.",
  },
  {
    german:
      'Tendergy richtet sich gleichermaßen an Verbraucher und Unternehmer. Für die Zwecke dieser Nutzungsbedingungen gilt Folgendes:',
    targetLanguage:
      "Tendergy s'adresse aussi bien aux consommateurs qu'aux professionnels. Les dispositions suivantes s'appliquent aux fins des présentes conditions d'utilisation :",
  },
  {
    german:
      '(a) Ein „Verbraucher“ ist jede natürliche Person, die den Vertrag zu Zwecken abschließt, die überwiegend keiner gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 Bürgerliches Gesetzbuch – „BGB“).',
    targetLanguage:
      '(a) Un « consommateur » est toute personne physique qui conclut le contrat à des fins qui ne sont principalement pas imputables à une activité professionnelle commerciale ou indépendante (article 13 du Code civil allemand).',
    indented: true,
  },
  {
    german:
      '(b) Ein „Unternehmer“ ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss des Vertrages in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt (§ 14 Abs. 1 BGB).',
    targetLanguage:
      "(b) Un « entrepreneur » est une personne physique ou morale ou une société de personnes dotée de la capacité juridique qui, au moment de la conclusion du contrat, agit dans l'exercice de son activité professionnelle commerciale ou indépendante (article 14 (1) du Code civil allemand).",
    indented: true,
  },
  {
    german:
      '„Nutzer“ bezeichnet eine natürliche Person, es sei denn, sie ist ausdrücklich als juristische Person bei tendergy registriert. Eine Nutzung von Handlungen und Unterlassungen unter der Registrierung einer juristischen Person werden der natürlichen Person zugerechnet, außer im Rahmen ihrer Vollmacht für die juristische Person.',
    targetLanguage:
      "Le terme « utilisateur » désigne une personne physique, à moins qu'elle ne soit expressément enregistrée en tant que personne morale auprès de tendergy. L'utilisation d'actes et d'omissions dans le cadre de l'enregistrement d'une personne morale est attribuée à la personne physique, sauf dans le cadre de sa procuration au nom de la personne morale.",
  },
  {
    german: '1. Gegenstand',
    targetLanguage: '1. Objet',
    subSection: true,
  },
  {
    german:
      'Diese Nutzungsbedingungen gelten für die Nutzung von tendergy. Das Vertragsverhältnis zwischen dem Nutzer und tendergy umfasst nur die ordnungsgemäße Vermittlung der Nutzer an den Anbieter. Die Vermittlung zwischen Nutzern und Anbietern findet in verschiedenen Gruppen und/oder Projekte statt – sog. Pools („Pools“). tendergy tritt nicht selbst als Installateur in Bezug auf den Leistungsvertrag mit dem Nutzer auf. Schneider Electric bietet selbst keine Installationsleistungen an und wird nicht Vertragspartner der Verträge, die ausschließlich zwischen den Nutzern und Anbietern geschlossen werden. Für die Vermittlung der Nutzer an den Anbieter gelten deshalb diese Nutzungsbedingungen von tendergy. Für den jeweiligen Leistungsvertrag zwischen Nutzer und Anbieter gelten die jeweiligen Bedingungen der Anbieter.',
    targetLanguage:
      "Les présentes conditions d'utilisation s'appliquent à l'utilisation de tendergy. La relation contractuelle entre l'utilisateur et tendergy comprend uniquement l'orientation appropriée des utilisateurs vers le fournisseur. La négociation entre les utilisateurs et les fournisseurs se déroule dans différents groupes et/ou projets - appelés pools (« pools »). tendergy n'agira pas elle-même en tant qu'installateur en ce qui concerne le contrat de service avec l'utilisateur. Schneider Electric elle-même n'offre aucun service d'installation et ne deviendra pas un partenaire contractuel des contrats, qui seront conclus exclusivement entre les utilisateurs et les fournisseurs. Par conséquent, les conditions d'utilisation de tendergy s'appliquent à l'orientation des utilisateurs vers le fournisseur. Les conditions générales du fournisseur respectives s'appliquent au contrat de service respectif entre l'utilisateur et le fournisseur.",
  },
  {
    german: '2. Registrierung',
    targetLanguage: '2. Inscription',
    subSection: true,
  },
  {
    german:
      'Die Nutzung von tendergy und das Veranlassen von Buchungen, erfordern eine Registrierung. Bei der Registrierung sendet der Nutzer ein elektronisches Registrierungsformular ab und willigt in die Nutzungsbedingungen ein. Anschließend wird die Registrierung durch Schneider Electric bestätigt. Natürliche Personen müssen das 18. Lebensjahr vollendet haben.',
    targetLanguage:
      "L'inscription est obligatoire pour l'utilisation de tendergy et l'organisation des réservations. L'utilisateur soumettra un formulaire d'inscription électronique lors de son inscription et acceptera les conditions générales d'utilisation. Schneider Electric confirmera ensuite l'inscription. Les personnes physiques doivent avoir atteint l'âge de 18 ans.",
  },
  {
    german: '3. Leistungen von Schneider Electric/Vertragsschluss',
    targetLanguage: '3. Services Schneider Electric/Conclusion du contrat',
    subSection: true,
  },
  {
    german: '3.1',
    targetLanguage: '3.1',
    bold: true,
  },
  {
    german:
      'Für die Vermittlung der Installationsleistungen auf tendergy gibt es einen öffentlichen Pool, dem alle Nutzer und Anbieter betreten können und in dem eine freie Vermittlung stattfindet.',
    targetLanguage:
      "Un pool public est disponible pour le courtage de services d'installation sur tendergy, auquel tous les utilisateurs et fournisseurs peuvent adhérer et dans lequel le courtage gratuit a lieu.",
  },
  {
    german: '3.2',
    targetLanguage: '3.2',
    bold: true,
  },
  {
    german:
      'Darüber hinaus können sich Anbieter zu sog. Pool-Betreibern freischalten lassen. Pool-Betreiber können zusätzlich zum öffentlichen Pool auch private Pools zur Vermittlung („Privater Vermittlungs-Pool“) von Installationsleistungen durch andere Anbieter oder zum Abschluss der Installationsleistung mit sich selber („Privater Betreiber-Pool“) erstellen. Nutzer können diesen privaten Pools nur auf Einladung der entsprechenden Pool-Betreiber beitreten.',
    targetLanguage:
      "Les fournisseurs peuvent également être autorisés à devenir des « opérateurs de pool ». En plus du pool public, les opérateurs de pool peuvent également créer des pools privés pour le courtage (« pool de courtage privé ») de services d'installation par d'autres fournisseurs ou pour la conclusion de contrats de services d'installation avec eux-mêmes (« pool d'opérateurs privés »). Les utilisateurs ne peuvent rejoindre ces pools privés que sur invitation des opérateurs de pool respectifs.",
  },
  {
    german: '3.3',
    targetLanguage: '3.3',
    bold: true,
  },
  {
    german:
      'Nachdem der Nutzer auf tendergy in einem öffentlichen Pool seine Leistungswünsche geäußert hat und diese die grundsätzlichen Anforderungen an die Vermittlung eines Leistungsvertrags erfüllen, übermittelt Schneider Electric diese Informationen den Anbietern („Leistungsinformationen“). In einem Privaten Vermittlungs-Pool werden die Leistungsinformationen nur an die Anbieter in diesem Pool übermittelt. In einem Privaten Betreiber-Pool erhält neben den Anbietern in diesem Pool auch der Pool-Betreiber die entsprechenden Leistungsinformationen. Auf Grundlage dieser Informationen können Anbieter bzw. Pool-Betreiber ein Vertragsangebot zur Weiterleitung an den Nutzer abgeben. Dieses Angebot kann durch Klicken auf die Schaltfläche „kostenpflichtig bestellen“ angenommen werden. Im Anschluss erhält der Nutzer eine Bestätigung über den Vertragsschluss vom entsprechenden Anbieter bzw. Pool-Betreiber dessen Angebot vom Nutzer angenommen wurde.',
    targetLanguage:
      "Lorsque l'utilisateur soumet des demandes de service dans un pool public sur tendergy, qui répondent aux exigences de base pour la négociation d'un contrat de service, Schneider Electric transmet ces informations aux fournisseurs (« Informations sur le service »). Dans un pool de courtage privé, les informations sur le service ne sont transmises qu'aux fournisseurs de ce pool. Dans un pool d'opérateurs privés, l'opérateur du pool, en plus des fournisseurs du pool, reçoit également les informations sur le service pertinentes. Les fournisseurs ou les opérateurs de pool peuvent soumettre une offre de contrat à l'utilisateur sur la base de ces informations. Cette offre peut être acceptée en cliquant sur le bouton « commande payante ». L'utilisateur recevra alors une confirmation de la conclusion du contrat de la part du fournisseur ou de l'opérateur de pool correspondant dont l'offre a été acceptée par l'utilisateur.",
  },
  {
    german: '3.4',
    targetLanguage: '3.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric stellt dem Nutzer die Geschäftsbedingungen der Anbieter für deren Verhältnis zum Nutzer und für deren Leistungen zur Verfügung, soweit diese der Schneider Electric vorliegen.',
    targetLanguage:
      "Dans la mesure où Schneider Electric en dispose, Schneider Electric fournira à l'Utilisateur les Conditions Générales des Fournisseurs pour leur relation avec l'Utilisateur et pour leurs services.",
  },
  {
    german: '3.5',
    targetLanguage: '3.5',
    bold: true,
  },
  {
    german:
      'Die Kosten für den technischen Zugang zu tendergy (z.B. Internetzugang) trägt der Nutzer selbst.',
    targetLanguage:
      "L'Utilisateur prend en charge les frais d'accès technique à tendergy (par exemple l'accès à Internet).",
  },
  {
    german: '4. Bezahlen bei Tendergy',
    targetLanguage: '4. Paiement chez Tendergy',
    subSection: true,
  },
  {
    german:
      'Die Zahlungsabwicklung erfolgt allein über die Anbieter. Schneider Electric ist in Zahlungsabwicklungen nicht involviert.',
    targetLanguage:
      "Le traitement des paiements est effectué exclusivement par les fournisseurs. Schneider Electric n'est pas impliqué dans le traitement des paiements.",
  },
  {
    german: '5. Die Preisgestaltung von Tendergy',
    targetLanguage: '5. Tarification Tendergy',
    subSection: true,
  },
  {
    german: '5.1',
    targetLanguage: '5.1',
    bold: true,
  },
  {
    german:
      'Alle Preise auf Tendergy werden pro Person und einschließlich Umsatzsteuer und aller anderen Steuern angezeigt.',
    targetLanguage:
      'Tous les prix sur tendergy sont indiqués par personne et incluent la TVA et toutes les autres taxes.',
  },
  {
    german: '5.2',
    targetLanguage: '5.2',
    bold: true,
  },
  {
    german:
      'Diese von den Anbietern und/oder Pool-Betreibern festgelegten Preise unterliegen gegebenenfalls besonderen Bestimmungen, zum Beispiel in Bezug auf Stornierungen und die Erstattung von geleisteten Zahlungen. Bitte überprüfen Sie vor der Buchung daher genau, ob der jeweilige Leistungsvertrag gesonderten Bestimmungen unterliegt.',
    targetLanguage:
      'Ces tarifs fixés par les prestataires et/ou les exploitants de pools peuvent être soumis à des dispositions particulières, relatives par exemple aux annulations et aux remboursements des paiements effectués. Veuillez donc vérifier si le contrat de service concerné est soumis à des dispositions distinctes avant de réserver.',
  },
  {
    german: '6. Pflichten und Obliegenheiten des Nutzers',
    targetLanguage: "6. Obligations et devoirs de l'utilisateur",
    subSection: true,
  },
  {
    german: '6.1',
    targetLanguage: '6.1',
    bold: true,
  },
  {
    german:
      'Der Nutzer wird Registrierungsdaten (Nutzerkennung und Passwort) geheim halten und Dritten keinen Zugang zu tendergy mit seinen Registrierungsdaten ermöglichen. Eine Nutzung von tendergy unter der Registrierung des Nutzers wird diesem zugerechnet.',
    targetLanguage:
      "L'Utilisateur doit garder secrètes les données d'enregistrement (identifiant et mot de passe) et ne doit pas permettre à des tiers d'accéder à tendergy avec ces données d'enregistrement. Toute utilisation de tendergy sous l'enregistrement de l'Utilisateur sera attribuée à l'Utilisateur.",
  },
  {
    german: '6.2',
    targetLanguage: '6.2',
    bold: true,
  },
  {
    german:
      'Der Nutzer kann nach Erhalt von Leistungsinformationen etwaige Bestellungen an tendergy zur Weiterleitung an den Anbieter und/oder Pool-Betreiber senden.',
    targetLanguage:
      "Dès réception des Informations sur le Service, l'Utilisateur peut adresser toute commande à tendergy pour transmission au Fournisseur et/ou à l'Opérateur du Pool.",
  },
  {
    german: '6.3',
    targetLanguage: '6.3',
    bold: true,
  },
  {
    german:
      'Der Nutzer wird Schneider Electric von Ansprüchen Dritter aufgrund seiner Nutzung von tendergy freistellen, außer soweit diese durch Schneider Electric zu vertreten sind.',
    targetLanguage:
      "L'Utilisateur doit indemniser Schneider Electric contre les réclamations de tiers découlant de l'utilisation de Tendergy, sauf dans la mesure où Schneider Electric est responsable de ces réclamations.",
  },
  {
    german: '7. Verfügbarkeit und Gewährleistung',
    targetLanguage: '7. Disponibilité et garantie',
    subSection: true,
  },
  {
    german: '7.1',
    targetLanguage: '7.1',
    bold: true,
  },
  {
    german:
      'Es besteht kein Anspruch auf Verfügbarkeit, Qualitäts- oder Leistungsmerkmale oder technische Unterstützung für tendergy. Schneider Electric kann das Online-Portal tendergy jederzeit nach freiem Ermessen umgestalten, einschränken oder einstellen. Bestehende Vereinbarungen des Nutzers mit einem Anbieter sowie deren Abwicklung bleiben von solchen Änderungen unberührt.',
    targetLanguage:
      "tendergy ne peut prétendre à aucune disponibilité, qualité ou caractéristiques de performance ou support technique. Schneider Electric peut modifier, restreindre ou interrompre le portail en ligne tendergy à tout moment et à sa seule discrétion. Les accords d'utilisateur existants avec un fournisseur ainsi que leur exécution ne seront pas affectés par de telles modifications.",
  },
  {
    german: '7.2',
    targetLanguage: '7.2',
    bold: true,
  },
  {
    german:
      'Unbeschadet Ziffer 8, übernimmt Schneider Electric keine Gewährleistung für die Richtigkeit oder Vollständigkeit der Daten, die von Dritten (etwa anderen Anbietern und/oder Pool-Betreibern) bereitgestellt werden.',
    targetLanguage:
      'Sans préjudice de la clause 8, Schneider Electric ne garantit pas l’exactitude ou l’exhaustivité des données fournies par des tiers (tels que d’autres fournisseurs et/ou opérateurs de pool).',
  },
  {
    german: '7.3',
    targetLanguage: '7.3',
    bold: true,
  },
  {
    german:
      'Unbeschadet Ziffer 8, übernimmt Schneider Electric keine Gewährleistung für die von den Anbietern und/oder Pool-Betreibern angebotenen Leistungen, es sei denn, Schneider Electric ist selbst Anbieter der jeweiligen Leistung. Ansprechpartner des Nutzers für Fragen und Ansprüche im Zusammenhang mit einem Leistungsvertrag und seiner Durchführung ist jeweils der Anbieter und/oder Pool-Betreiber mit dem der Leistungsvertrag zustande gekommen ist.',
    targetLanguage:
      "Sans préjudice de l'article 8, Schneider Electric ne garantit pas les services proposés par les fournisseurs et/ou les gestionnaires de pools, sauf si Schneider Electric est lui-même le fournisseur du service concerné. L'interlocuteur de l'utilisateur pour les questions et réclamations relatives à un contrat de service et à son exécution est dans chaque cas le fournisseur et/ou le gestionnaire de pool avec lequel le contrat de service a été conclu.",
  },
  {
    german: '8. Haftungsbeschränkung',
    targetLanguage: '8. Limitation de responsabilité',
    subSection: true,
  },
  {
    german: '8.1',
    targetLanguage: '8.1',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet für Schäden, die durch Schneider Electric oder deren Erfüllungs- und Verrichtungsgehilfen durch Vorsatz oder grobe Fahrlässigkeit verursacht wurden.',
    targetLanguage:
      'Schneider Electric est responsable des dommages causés par Schneider Electric ou ses agents ou assistants par intention ou négligence grave.',
  },
  {
    german: '8.2',
    targetLanguage: '8.2',
    bold: true,
  },
  {
    german:
      'Für einfache Fahrlässigkeit haftet Schneider Electric nur bei Verletzung einer wesentlichen Vertragspflicht und nur für vorhersehbare und typische Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.',
    targetLanguage:
      "Schneider Electric ne peut être tenu responsable en cas de négligence simple qu'en cas de violation d'une obligation contractuelle essentielle et uniquement pour des dommages prévisibles et typiques. Les obligations contractuelles essentielles sont celles dont le respect est une condition préalable à la bonne exécution du contrat et sur le respect desquelles l'utilisateur peut régulièrement compter.",
  },
  {
    german: '8.3',
    targetLanguage: '8.3',
    bold: true,
  },
  {
    german:
      'Haftungsbeschränkungen gelten nicht im Rahmen abgegebener Garantien, bei Verletzungen des Lebens, des Körpers und der Gesundheit sowie für Ansprüche aus dem Produkthaftungsgesetz.',
    targetLanguage:
      "Les limitations de responsabilité ne s'appliquent pas dans le cadre des garanties accordées, en cas d'atteinte à la vie, de dommages corporels ou d'atteinte à la santé, ou pour les réclamations découlant de la loi sur la responsabilité du fait des produits.",
  },
  {
    german: '9. Widerrufsbelehrung für Verbraucher',
    targetLanguage: "9. Politique d'annulation du consommateur",
    subSection: true,
  },
  {
    german: 'Widerrufsrecht',
    targetLanguage: 'Droit de rétractation',
  },
  {
    german:
      'Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.',
    targetLanguage:
      'Vous avez le droit de révoquer ce contrat dans un délai de quatorze jours sans donner de motif.',
  },
  {
    german:
      'Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telefon: +49 30 23 88 84 - 240, Telefax: +49 30 23 88 84 – 120, info@Schneider Electric.com) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.',
    targetLanguage:
      "Le délai de rétractation est de quatorze jours à compter du jour de la conclusion du contrat. Pour exercer votre droit de rétractation, vous devez nous notifier (Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, téléphone : +49 30 23 88 84 - 240, télécopie : +49 30 23 88 84 - 120, info@Schneider Electric.com) votre décision de rétractation du présent contrat au moyen d'une déclaration dénuée d'ambiguïté (par ex. lettre envoyée par la poste, télécopie ou courrier électronique). Vous pouvez utiliser à cet effet le modèle de formulaire de rétractation ci-joint. L'utilisation du modèle n'est toutefois pas obligatoire.",
  },
  {
    german:
      'Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.',
    targetLanguage:
      'L’envoi de la notification de l’exercice du droit de rétractation avant la fin du délai de rétractation suffit pour respecter le délai de rétractation.',
  },
  {
    german: 'Folgen des Widerrufs',
    targetLanguage: "Conséquences de l'annulation",
  },
  {
    german:
      'Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.',
    targetLanguage:
      'En cas de rétractation de votre part du présent contrat, nous sommes tenus de vous rembourser tous les paiements reçus de vous, y compris les frais de livraison (à l’exception des frais supplémentaires découlant du fait que vous avez choisi un mode de livraison autre que la livraison standard la moins chère que nous proposons), sans délai et au plus tard dans les quatorze jours à compter du jour où nous avons reçu la notification de la rétractation du présent contrat. Pour ce remboursement, nous utiliserons le même moyen de paiement que celui que vous avez utilisé pour la transaction initiale, sauf accord exprès contraire avec vous ; en aucun cas, des frais ne vous seront facturés du fait de ce remboursement.',
  },
  {
    german: '- Ende der Widerrufsbelehrung -',
    targetLanguage: "- Fin de la politique d'annulation -",
  },
  {
    german:
      'Das Widerrufsrecht erlischt, wenn wir mit der Ausführung des Vertrags begonnen haben, nachdem Sie ausdrücklich zugestimmt haben, dass mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist begonnen werden soll und ihre Kenntnis davon bestätigt haben, dass Sie dadurch das Widerrufsrecht verlieren.',
    targetLanguage:
      "Le droit de rétractation s'éteint si nous avons commencé l'exécution du contrat après que vous avez expressément convenu que l'exécution du contrat devait commencer avant l'expiration du délai de rétractation et que vous avez confirmé que vous perdez ainsi votre droit de rétractation.",
  },
  {
    german: '10. Kündigung',
    targetLanguage: '10. Résiliation',
    subSection: true,
  },
  {
    german:
      'Nutzer können ihre Registrierung bei tendergy jederzeit durch Sperrung ihres Nutzerkontos beenden. Schneider Electric kann eine Registrierung einseitig mit einer Frist von einer Woche beenden. Zuvor entstandene Ansprüche bleiben hiervon unberührt. Das Recht zur außerordentlichen Kündigung bleibt unberührt.',
    targetLanguage:
      "Les utilisateurs peuvent mettre fin à leur inscription auprès de tendergy à tout moment en suspendant leur compte utilisateur. Schneider Electric peut mettre fin unilatéralement à toute inscription moyennant un préavis d'une semaine. Les droits nés antérieurement ne sont pas affectés par cette mesure. Le droit de résiliation extraordinaire reste inchangé.",
  },
  {
    german: '11. Datenschutz',
    targetLanguage: '11. Protection des données',
    subSection: true,
  },
  {
    german: `Schneider Electric erhebt und verwendet personenbezogene Daten wie in den Datenschutzbestimmungen von Tendergy unter <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a> beschrieben.`,
    targetLanguage: `Schneider Electric collecte et utilise les informations personnelles comme décrit dans la politique de confidentialité de tendergy à l'adresse <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a>.`,
  },
  {
    german: '12. Änderung dieser Nutzungsbedingungen',
    targetLanguage: "12. Modifications des présentes conditions d'utilisation",
    subSection: true,
  },
  {
    german:
      'Schneider Electric behält sich vor, diese Nutzungsbedingungen jederzeit und ohne Angabe von Gründen zu ändern. Schneider Electric wird gegenüber Verbrauchern nur solche Änderungen vornehmen, auf die der Nutzer sich in Abwägung der beiderseitigen Interessen vernünftigerweise einlassen muss. Dies betrifft z.B. Fälle der Äquivalenzstörung, aber auch Regelungslücken und Änderungen der Rechtslage. Die geänderten Bedingungen werden den Nutzern per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesendet. Widerspricht ein Nutzer der Geltung der neuen Nutzungsbedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail, gelten die geänderten Nutzungsbedingungen als angenommen. Schneider Electric wird die Nutzer in der E-Mail, die die geänderten Bedingungen enthält, auf die Bedeutung dieser Zweiwochenfrist gesondert hinweisen.',
    targetLanguage:
      "Schneider Electric se réserve le droit de modifier les présentes Conditions d'utilisation à tout moment et sans indication de motifs. Schneider Electric n'apportera aux Consommateurs que les modifications auxquelles l'Utilisateur doit raisonnablement consentir en tenant compte des intérêts des deux parties. Cela concerne par exemple les cas de rupture d'équivalence, mais aussi les lacunes réglementaires et les changements de la situation juridique. Les Conditions d'utilisation modifiées seront envoyées aux Utilisateurs par courrier électronique au plus tard deux semaines avant leur entrée en vigueur. Si un utilisateur ne s'oppose pas à la validité des nouvelles Conditions d'utilisation dans les deux semaines suivant la réception du courrier électronique, les Conditions d'utilisation modifiées seront réputées avoir été acceptées. Schneider Electric informera séparément les Utilisateurs de l'importance de ce délai de deux semaines dans le courrier électronique contenant les Conditions d'utilisation modifiées.",
  },
  {
    german: '13. Sonstige Bestimmungen',
    targetLanguage: '13. Autres dispositions',
    subSection: true,
  },
  {
    german: '13.1',
    targetLanguage: '13.1',
    bold: true,
  },
  {
    german:
      'Die Plattform der EU zur außergerichtlichen Onlinestreitbeilegung ist abrufbar unter <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>. Schneider Electric ist nicht bereit und nicht verpflichtet an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
    targetLanguage:
      "La plateforme de règlement extrajudiciaire des litiges en ligne de l'UE est disponible à l'adresse <a href=\"http://ec.europa.eu/consumers/odr/\">http://ec.europa.eu/consumers/odr/</a>. Schneider Electric n'est ni disposé ni obligé de participer à une procédure de règlement des litiges devant un conseil d'arbitrage de consommation.",
  },
  {
    german: '13.2',
    targetLanguage: '13.2',
    bold: true,
  },
  {
    german:
      'Es gilt das Recht der Bundesrepublik Deutschland. Wenn Sie die Bestellung als Verbraucher abgegeben haben und zum Zeitpunkt Ihrer Bestellung Ihren gewöhnlichen Aufenthalt in einem anderen Land haben, bleibt die Anwendung zwingender Rechtsvorschriften dieses Landes von der in Satz 1 getroffenen Rechtswahl unberührt. Ausschließlicher Gerichtsstand ist Berlin, soweit der Nutzer Kaufmann im Sinne des Handelsgesetzbuches ist oder bei Klageerhebung keinen festen Wohnsitz in Deutschland hat. Gesetzlich zwingende Gerichtsstände bleiben unberührt.',
    targetLanguage:
      "Le droit applicable est celui de la République fédérale d'Allemagne. Si vous avez passé commande en tant que consommateur et que votre résidence habituelle au moment de votre commande se trouve dans un autre pays, l'application des dispositions légales impératives de ce pays n'est pas affectée par le choix du droit effectué dans la phrase 1. Le lieu de juridiction exclusif est Berlin, dans la mesure où l'utilisateur est un commerçant au sens du Code de commerce allemand ou n'a pas de domicile fixe en Allemagne au moment de l'introduction de l'action.",
  },
  {
    german: '13.3',
    targetLanguage: '13.3',
    bold: true,
  },
  {
    german:
      'Sollten Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden, berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.',
    targetLanguage:
      'Si l’une des dispositions des présentes Conditions d’utilisation est ou devient invalide, cette invalidité n’affectera pas la validité des dispositions restantes.',
  },
  {
    german: 'Muster-Widerrufsformular',
    targetLanguage: "Modèle de formulaire d'annulation",
  },
  {
    german: `(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
    <br /><br />
    An Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telefon: +49 30 23 88 84 240, Telefax: +49 30 23 88 84 120, E-Mail: info[at]Schneider Electric.com)
    <br /><br />
    Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*) – Bestellt am (*)/erhalten am (*).
    <ul>
      <li>Name des/der Verbraucher(s)</li>
      <li>Anschrift des/der Verbraucher(s)</li>
      <li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
      <li>Datum</li>
    </ul>`,
    targetLanguage: `(Si vous souhaitez annuler le contrat, veuillez remplir et renvoyer ce formulaire.)
    <br /><br />
    À Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Téléphone : +49 30 23 88 84 240, Téléfax : +49 30 23 88 84 120, E-mail : info[at]Schneider Electric.com)
    <br/><br />
    Je/nous (*) révoque/révoquons par la présente le contrat conclu par moi/nous (*) pour l'achat du bien suivant (*)/la fourniture du service suivant (*) - Commandé le (*)/reçu le (*).
    <ul>
      <li>Nom du consommateur</li>
      <li>Adresse du consommateur</li>
      <li>Signature du consommateur (uniquement pour les communications papier)</li>
      <li>Date</li>
    </ul>`,
  },
  {
    german: '---------------------------------',
    targetLanguage: '---------------------------------',
  },
  {
    german: '(*) Unzutreffendes streichen',
    targetLanguage: '(*) Supprimer la mention inutile',
  },
];

const TermsConditionsForCustomerFr: React.FC = () => (
  <BiLingualTable columns={columns} dataSource={privacyData} />
);

export default TermsConditionsForCustomerFr;
