import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'Kunden-Nutzungsbedingungen für tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: 'Tendergy Customer Terms of Use',
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german:
      'Die Schneider Electric GmbH („Schneider Electric“) betreibt eine Vermittlungs-Plattform für die Installation (und ggfs. Kauf) einer sog. Wallbox („tendergy“). Auf der Plattform haben Nutzer die Möglichkeit deren Installation durch einen Installateur zu buchen. Die Angebote werden von verschiedenen Anbietern („Anbieter“) online gestellt, mit denen auch der jeweilige Vertrag über die Leistung zustande kommt („Leistungsvertrag“). Zu den Leistungen der Anbieter können über Tendergy Informationen abgerufen und Bestellungen veranlasst werden.',
    targetLanguage:
      'Schneider Electric GmbH ("Schneider Electric") operates a brokering platform for the installation (and, if applicable, purchase) of a so-called wallbox ("tendergy"). The Platform gives users the opportunity to book their installations through an installer. Tendergy can be used to obtain information about Providers\' services and to place orders.',
  },
  {
    german:
      'Tendergy richtet sich gleichermaßen an Verbraucher und Unternehmer. Für die Zwecke dieser Nutzungsbedingungen gilt Folgendes:',
    targetLanguage:
      'Tendergy is directed equally at consumers and businesspersons. The following shall apply for the purposes of these Terms of Use:',
  },
  {
    german:
      '(a) Ein „Verbraucher“ ist jede natürliche Person, die den Vertrag zu Zwecken abschließt, die überwiegend keiner gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 Bürgerliches Gesetzbuch – „BGB“).',
    targetLanguage:
      '(a) A "Consumer" is any natural person who enters into the contract for purposes that are predominantly not attributable to a commercial or self-employed professional activity (Section 13 of the German Civil Code).',
    indented: true,
  },
  {
    german:
      '(b) Ein „Unternehmer“ ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss des Vertrages in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt (§ 14 Abs. 1 BGB).',
    targetLanguage:
      '(b) A "Businessperson" is a natural or legal person or a partnership with legal capacity who, at the time of conclusion of the contract, acts in the exercise of their commercial or self-employed professional activity (Section 14 (1) of the German Civil Code).',
    indented: true,
  },
  {
    german:
      '„Nutzer“ bezeichnet eine natürliche Person, es sei denn, sie ist ausdrücklich als juristische Person bei tendergy registriert. Eine Nutzung von Handlungen und Unterlassungen unter der Registrierung einer juristischen Person werden der natürlichen Person zugerechnet, außer im Rahmen ihrer Vollmacht für die juristische Person.',
    targetLanguage:
      '"User" shall refer to a natural person, unless they are expressly registered as a legal entity with tendergy. A use of acts and omissions under the registration of a legal person shall be attributed to the natural person, except within the scope of their power of attorney on behalf of the legal person.',
  },
  {
    german: '1. Gegenstand',
    targetLanguage: '1. Subject Matter',
    subSection: true,
  },
  {
    german:
      'Diese Nutzungsbedingungen gelten für die Nutzung von tendergy. Das Vertragsverhältnis zwischen dem Nutzer und tendergy umfasst nur die ordnungsgemäße Vermittlung der Nutzer an den Anbieter. Die Vermittlung zwischen Nutzern und Anbietern findet in verschiedenen Gruppen und/oder Projekte statt – sog. Pools („Pools“). tendergy tritt nicht selbst als Installateur in Bezug auf den Leistungsvertrag mit dem Nutzer auf. Schneider Electric bietet selbst keine Installationsleistungen an und wird nicht Vertragspartner der Verträge, die ausschließlich zwischen den Nutzern und Anbietern geschlossen werden. Für die Vermittlung der Nutzer an den Anbieter gelten deshalb diese Nutzungsbedingungen von tendergy. Für den jeweiligen Leistungsvertrag zwischen Nutzer und Anbieter gelten die jeweiligen Bedingungen der Anbieter.',
    targetLanguage:
      'These Terms of Use shall apply to the use of tendergy. The contractual relationship between the User and tendergy shall only include the proper referral of Users to the Provider. Brokering between Users and Providers shall take place in different groups and/or projects - so called Pools ("Pools"). tendergy will not itself act as installer with regard to the service contract with the User. Schneider Electric itself does not offer any installation services and will not become a contractual partner to the contracts, which shall be concluded exclusively between the Users and Providers. Therefore, tendergy\'s Terms of Use shall apply to the referral of Users to the Provider. The respective Provider Terms and Conditions shall apply to the respective service contract between the User and the Provider.',
  },
  {
    german: '2. Registrierung',
    targetLanguage: '2. Registration',
    subSection: true,
  },
  {
    german:
      'Die Nutzung von tendergy und das Veranlassen von Buchungen, erfordern eine Registrierung. Bei der Registrierung sendet der Nutzer ein elektronisches Registrierungsformular ab und willigt in die Nutzungsbedingungen ein. Anschließend wird die Registrierung durch Schneider Electric bestätigt. Natürliche Personen müssen das 18. Lebensjahr vollendet haben.',
    targetLanguage:
      'Registration is required for the use of tendergy and the arrangement of bookings. The User will submit an electronic registration form upon registration and will agree to the Terms and Conditions of Use. Schneider Electric will then confirm registration. Natural persons must have reached the age of 18.',
  },
  {
    german: '3. Leistungen von Schneider Electric/Vertragsschluss',
    targetLanguage: '3. Schneider Electric Services/Conclusion of Contract',
    subSection: true,
  },
  {
    german: '3.1',
    targetLanguage: '3.1',
    bold: true,
  },
  {
    german:
      'Für die Vermittlung der Installationsleistungen auf tendergy gibt es einen öffentlichen Pool, dem alle Nutzer und Anbieter betreten können und in dem eine freie Vermittlung stattfindet.',
    targetLanguage:
      'A Public Pool is available for the brokering of installation services on tendergy, which all Users and Providers can enter and in which free brokering takes place.',
  },
  {
    german: '3.2',
    targetLanguage: '3.2',
    bold: true,
  },
  {
    german:
      'Darüber hinaus können sich Anbieter zu sog. Pool-Betreibern freischalten lassen. Pool-Betreiber können zusätzlich zum öffentlichen Pool auch private Pools zur Vermittlung („Privater Vermittlungs-Pool“) von Installationsleistungen durch andere Anbieter oder zum Abschluss der Installationsleistung mit sich selber („Privater Betreiber-Pool“) erstellen. Nutzer können diesen privaten Pools nur auf Einladung der entsprechenden Pool-Betreiber beitreten.',
    targetLanguage:
      'Providers can also be authorised to become so-called Pool Operators. In addition to the Public Pool, Pool Operators may also create private pools for the brokering ("Private Brokerage Pool") of installation services by other Providers or for the conclusion of installation service contracts with themselves ("Private Operator Pool"). Users may only join these private pools by invitation of the respective Pool Operators.',
  },
  {
    german: '3.3',
    targetLanguage: '3.3',
    bold: true,
  },
  {
    german:
      'Nachdem der Nutzer auf tendergy in einem öffentlichen Pool seine Leistungswünsche geäußert hat und diese die grundsätzlichen Anforderungen an die Vermittlung eines Leistungsvertrags erfüllen, übermittelt Schneider Electric diese Informationen den Anbietern („Leistungsinformationen“). In einem Privaten Vermittlungs-Pool werden die Leistungsinformationen nur an die Anbieter in diesem Pool übermittelt. In einem Privaten Betreiber-Pool erhält neben den Anbietern in diesem Pool auch der Pool-Betreiber die entsprechenden Leistungsinformationen. Auf Grundlage dieser Informationen können Anbieter bzw. Pool-Betreiber ein Vertragsangebot zur Weiterleitung an den Nutzer abgeben. Dieses Angebot kann durch Klicken auf die Schaltfläche „kostenpflichtig bestellen“ angenommen werden. Im Anschluss erhält der Nutzer eine Bestätigung über den Vertragsschluss vom entsprechenden Anbieter bzw. Pool-Betreiber dessen Angebot vom Nutzer angenommen wurde.',
    targetLanguage:
      'Upon User submission of service requests in a Public Pool on tendergy, which meet the basic requirements for the brokering of a service contract, Schneider Electric shall transmit such information to the Providers ("Service Information"). In a Private Brokerage Pool, Service Information shall only be transmitted to the providers in that Pool. In a Private Operator Pool, the Pool Operator in addition to the providers in the Pool shall also receive the relevant Service Information. Providers or Pool Operators may submit a contract offer for referral to the User on the basis of this information. This offer may be accepted by clicking on the "chargeable order" button. The User will then receive confirmation of the conclusion of the contract from the corresponding Provider or Pool Operator whose offer has been accepted by the User.',
  },
  {
    german: '3.4',
    targetLanguage: '3.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric stellt dem Nutzer die Geschäftsbedingungen der Anbieter für deren Verhältnis zum Nutzer und für deren Leistungen zur Verfügung, soweit diese der Schneider Electric vorliegen.',
    targetLanguage:
      "To the extent available to Schneider Electric, Schneider Electric shall provide the User with the Providers' Terms and Conditions for their relationship with the User and for their services.",
  },
  {
    german: '3.5',
    targetLanguage: '3.5',
    bold: true,
  },
  {
    german:
      'Die Kosten für den technischen Zugang zu tendergy (z.B. Internetzugang) trägt der Nutzer selbst.',
    targetLanguage:
      'The User shall bear the costs for technical access to tendergy (e.g. internet access).',
  },
  {
    german: '4. Bezahlen bei Tendergy',
    targetLanguage: '4. Payment at Tendergy',
    subSection: true,
  },
  {
    german:
      'Die Zahlungsabwicklung erfolgt allein über die Anbieter. Schneider Electric ist in Zahlungsabwicklungen nicht involviert.',
    targetLanguage:
      'Payment processing shall be carried out solely by the Providers. Schneider Electric is not involved in payment processing.',
  },
  {
    german: '5. Die Preisgestaltung von Tendergy',
    targetLanguage: '5. Tendergy Pricing',
    subSection: true,
  },
  {
    german: '5.1',
    targetLanguage: '5.1',
    bold: true,
  },
  {
    german:
      'Alle Preise auf Tendergy werden pro Person und einschließlich Umsatzsteuer und aller anderen Steuern angezeigt.',
    targetLanguage:
      'All prices on tendergy are shown per person and include VAT and all other taxes.',
  },
  {
    german: '5.2',
    targetLanguage: '5.2',
    bold: true,
  },
  {
    german:
      'Diese von den Anbietern und/oder Pool-Betreibern festgelegten Preise unterliegen gegebenenfalls besonderen Bestimmungen, zum Beispiel in Bezug auf Stornierungen und die Erstattung von geleisteten Zahlungen. Bitte überprüfen Sie vor der Buchung daher genau, ob der jeweilige Leistungsvertrag gesonderten Bestimmungen unterliegt.',
    targetLanguage:
      'These prices set by the Providers and/or Pool Operators may be subject to special provisions, relating for example to cancellations and refunds of payments made. Please therefore check whether the respective service contract is subject to separate provisions before booking.',
  },
  {
    german: '6. Pflichten und Obliegenheiten des Nutzers',
    targetLanguage: '6. User Duties and Obligations',
    subSection: true,
  },
  {
    german: '6.1',
    targetLanguage: '6.1',
    bold: true,
  },
  {
    german:
      'Der Nutzer wird Registrierungsdaten (Nutzerkennung und Passwort) geheim halten und Dritten keinen Zugang zu tendergy mit seinen Registrierungsdaten ermöglichen. Eine Nutzung von tendergy unter der Registrierung des Nutzers wird diesem zugerechnet.',
    targetLanguage:
      "The User shall keep registration data (user ID and password) secret and shall not allow third parties to access tendergy with such registration data. Any use of tendergy under the User's registration shall be attributed to the User.",
  },
  {
    german: '6.2',
    targetLanguage: '6.2',
    bold: true,
  },
  {
    german:
      'Der Nutzer kann nach Erhalt von Leistungsinformationen etwaige Bestellungen an tendergy zur Weiterleitung an den Anbieter und/oder Pool-Betreiber senden.',
    targetLanguage:
      'Upon receipt of Service Information, the User may send any orders to tendergy for referral to the Provider and/or Pool Operator.',
  },
  {
    german: '6.3',
    targetLanguage: '6.3',
    bold: true,
  },
  {
    german:
      'Der Nutzer wird Schneider Electric von Ansprüchen Dritter aufgrund seiner Nutzung von tendergy freistellen, außer soweit diese durch Schneider Electric zu vertreten sind.',
    targetLanguage:
      'The User shall indemnify Schneider Electric against claims by third parties arising from the use of tendergy, except to the extent that Schneider Electric is responsible for such claims.',
  },
  {
    german: '7. Verfügbarkeit und Gewährleistung',
    targetLanguage: '7. Availability and warranty',
    subSection: true,
  },
  {
    german: '7.1',
    targetLanguage: '7.1',
    bold: true,
  },
  {
    german:
      'Es besteht kein Anspruch auf Verfügbarkeit, Qualitäts- oder Leistungsmerkmale oder technische Unterstützung für tendergy. Schneider Electric kann das Online-Portal tendergy jederzeit nach freiem Ermessen umgestalten, einschränken oder einstellen. Bestehende Vereinbarungen des Nutzers mit einem Anbieter sowie deren Abwicklung bleiben von solchen Änderungen unberührt.',
    targetLanguage:
      'tendergy shall have no claim to availability, quality or performance characteristics or technical support. Schneider Electric may modify, restrict or discontinue the tendergy Online Portal at any time at its sole discretion. Existing User agreements with a Provider as well as their execution shall remain unaffected by such changes.',
  },
  {
    german: '7.2',
    targetLanguage: '7.2',
    bold: true,
  },
  {
    german:
      'Unbeschadet Ziffer 8, übernimmt Schneider Electric keine Gewährleistung für die Richtigkeit oder Vollständigkeit der Daten, die von Dritten (etwa anderen Anbietern und/oder Pool-Betreibern) bereitgestellt werden.',
    targetLanguage:
      'Without prejudice to clause 8, Schneider Electric does not warrant the accuracy or completeness of the data provided by third parties (such as other Providers and/or Pool Operators).',
  },
  {
    german: '7.3',
    targetLanguage: '7.3',
    bold: true,
  },
  {
    german:
      'Unbeschadet Ziffer 8, übernimmt Schneider Electric keine Gewährleistung für die von den Anbietern und/oder Pool-Betreibern angebotenen Leistungen, es sei denn, Schneider Electric ist selbst Anbieter der jeweiligen Leistung. Ansprechpartner des Nutzers für Fragen und Ansprüche im Zusammenhang mit einem Leistungsvertrag und seiner Durchführung ist jeweils der Anbieter und/oder Pool-Betreiber mit dem der Leistungsvertrag zustande gekommen ist.',
    targetLanguage:
      "Without prejudice to clause 8, Schneider Electric does not warrant the services offered by the Providers and/or Pool Operators unless Schneider Electric is itself the provider of the relevant service. The User's contact for questions and claims in relation to a service contract and its performance in each case shall be the Provider and/or Pool Operator with whom the service contract has come into being.",
  },
  {
    german: '8. Haftungsbeschränkung',
    targetLanguage: '8. Liability Limitation',
    subSection: true,
  },
  {
    german: '8.1',
    targetLanguage: '8.1',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet für Schäden, die durch Schneider Electric oder deren Erfüllungs- und Verrichtungsgehilfen durch Vorsatz oder grobe Fahrlässigkeit verursacht wurden.',
    targetLanguage:
      'Schneider Electric shall be liable for damage caused by Schneider Electric or its vicarious agents or assistants through intent or gross negligence.',
  },
  {
    german: '8.2',
    targetLanguage: '8.2',
    bold: true,
  },
  {
    german:
      'Für einfache Fahrlässigkeit haftet Schneider Electric nur bei Verletzung einer wesentlichen Vertragspflicht und nur für vorhersehbare und typische Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.',
    targetLanguage:
      'Schneider Electric shall only be liable for simple negligence in the event of a breach of a material contractual obligation and only for foreseeable and typical damage. Material contractual obligations are those whose fulfilment is a prerequisite for the proper performance of the contract and on whose compliance the user may regularly rely.',
  },
  {
    german: '8.3',
    targetLanguage: '8.3',
    bold: true,
  },
  {
    german:
      'Haftungsbeschränkungen gelten nicht im Rahmen abgegebener Garantien, bei Verletzungen des Lebens, des Körpers und der Gesundheit sowie für Ansprüche aus dem Produkthaftungsgesetz.',
    targetLanguage:
      'Liability limitations shall not apply within the scope of guarantees given, in the event of injury to life, bodily injury or injury to health, or for claims arising from the Product Liability Act.',
  },
  {
    german: '9. Widerrufsbelehrung für Verbraucher',
    targetLanguage: '9. Consumer Cancellation Policy',
    subSection: true,
  },
  {
    german: 'Widerrufsrecht',
    targetLanguage: 'Right of withdrawal',
  },
  {
    german:
      'Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.',
    targetLanguage:
      'You have the right to cancel this contract within fourteen days without stating any reasons.',
  },
  {
    german:
      'Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telefon: +49 30 23 88 84 - 240, Telefax: +49 30 23 88 84 – 120, info@Schneider Electric.com) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.',
    targetLanguage:
      'The cancellation period is fourteen days from the day of the conclusion of the contract. To exercise your right of withdrawal, you must inform us (Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telephone: +49 30 23 88 84 - 240, Telefax: +49 30 23 88 84 – 120, info@Schneider Electric.com) of your decision to withdraw from this contract by means of a clear declaration (e.g. a letter sent by post, fax or email). You can use the attached template cancellation form for this purpose. Use of the template, however, is not mandatory.',
  },
  {
    german:
      'Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.',
    targetLanguage:
      'Sending the notification of the exercise of the right of withdrawal before the end of the cancellation period is sufficient to comply with the cancellation period.',
  },
  {
    german: 'Folgen des Widerrufs',
    targetLanguage: 'Cancellation consequences',
  },
  {
    german:
      'Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.',
    targetLanguage:
      'If you withdraw from this contract, we are obliged to refund all payments we have received from you, including delivery costs (with the exception of additional costs resulting from the fact that you have chosen a type of delivery differing from the cheapest standard delivery offered by us), without delay and at the latest within fourteen days from the day on which we received the notification of withdrawal from this contract. For this repayment, we shall use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you; in no case shall you be charged any fees because of this repayment.',
  },
  {
    german: '- Ende der Widerrufsbelehrung -',
    targetLanguage: '- End of the Cancellation Policy -',
  },
  {
    german:
      'Das Widerrufsrecht erlischt, wenn wir mit der Ausführung des Vertrags begonnen haben, nachdem Sie ausdrücklich zugestimmt haben, dass mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist begonnen werden soll und ihre Kenntnis davon bestätigt haben, dass Sie dadurch das Widerrufsrecht verlieren.',
    targetLanguage:
      'The right of withdrawal shall lapse if we have commenced performance of the contract after you have expressly agreed that performance of the contract should commence before the expiry of the withdrawal period and have confirmed your acknowledgement that you thereby forfeit the right of withdrawal.',
  },
  {
    german: '10. Kündigung',
    targetLanguage: '10. Termination',
    subSection: true,
  },
  {
    german:
      'Nutzer können ihre Registrierung bei tendergy jederzeit durch Sperrung ihres Nutzerkontos beenden. Schneider Electric kann eine Registrierung einseitig mit einer Frist von einer Woche beenden. Zuvor entstandene Ansprüche bleiben hiervon unberührt. Das Recht zur außerordentlichen Kündigung bleibt unberührt.',
    targetLanguage:
      "Users may terminate their registration with tendergy at any time by suspending their user account. Schneider Electric may terminate any registrations unilaterally with one week's notice. Previously arising claims shall remain unaffected by this. The right to extraordinary termination shall remain unaffected.",
  },
  {
    german: '11. Datenschutz',
    targetLanguage: '11. Data Protection',
    subSection: true,
  },
  {
    german: `Schneider Electric erhebt und verwendet personenbezogene Daten wie in den Datenschutzbestimmungen von Tendergy unter <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a> beschrieben.`,
    targetLanguage: `Schneider Electric collects and uses personal information as described in tendergy's Privacy Policy at <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a>.`,
  },
  {
    german: '12. Änderung dieser Nutzungsbedingungen',
    targetLanguage: '12. Amendments to these Terms of Use',
    subSection: true,
  },
  {
    german:
      'Schneider Electric behält sich vor, diese Nutzungsbedingungen jederzeit und ohne Angabe von Gründen zu ändern. Schneider Electric wird gegenüber Verbrauchern nur solche Änderungen vornehmen, auf die der Nutzer sich in Abwägung der beiderseitigen Interessen vernünftigerweise einlassen muss. Dies betrifft z.B. Fälle der Äquivalenzstörung, aber auch Regelungslücken und Änderungen der Rechtslage. Die geänderten Bedingungen werden den Nutzern per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesendet. Widerspricht ein Nutzer der Geltung der neuen Nutzungsbedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail, gelten die geänderten Nutzungsbedingungen als angenommen. Schneider Electric wird die Nutzer in der E-Mail, die die geänderten Bedingungen enthält, auf die Bedeutung dieser Zweiwochenfrist gesondert hinweisen.',
    targetLanguage:
      'Schneider Electric reserves the right to change these Terms of Use at any time and without stating reasons. Schneider Electric will only make such changes for Consumers to which the User must reasonably agree when considering the interests of both parties. This concerns, for example, cases of equivalence disruption, but also regulatory gaps and changes in the legal situation. The amended Terms and Conditions shall be sent to Users by email no later than two weeks before their entry into force. Should a user not object to the validity of the new Terms of Use within two weeks of receipt of the email, the amended Terms of Use shall be deemed to have been accepted. Schneider Electric will separately advise Users of the significance of this two-week period in the email containing the amended Terms and Conditions.',
  },
  {
    german: '13. Sonstige Bestimmungen',
    targetLanguage: '13. Other Provisions',
    subSection: true,
  },
  {
    german: '13.1',
    targetLanguage: '13.1',
    bold: true,
  },
  {
    german:
      'Die Plattform der EU zur außergerichtlichen Onlinestreitbeilegung ist abrufbar unter <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>. Schneider Electric ist nicht bereit und nicht verpflichtet an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
    targetLanguage:
      'The EU\'s online extrajudicial dispute resolution platform is available at <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>. Schneider Electric is neither willing nor obliged to participate in dispute resolution proceedings before a consumer arbitration board.',
  },
  {
    german: '13.2',
    targetLanguage: '13.2',
    bold: true,
  },
  {
    german:
      'Es gilt das Recht der Bundesrepublik Deutschland. Wenn Sie die Bestellung als Verbraucher abgegeben haben und zum Zeitpunkt Ihrer Bestellung Ihren gewöhnlichen Aufenthalt in einem anderen Land haben, bleibt die Anwendung zwingender Rechtsvorschriften dieses Landes von der in Satz 1 getroffenen Rechtswahl unberührt. Ausschließlicher Gerichtsstand ist Berlin, soweit der Nutzer Kaufmann im Sinne des Handelsgesetzbuches ist oder bei Klageerhebung keinen festen Wohnsitz in Deutschland hat. Gesetzlich zwingende Gerichtsstände bleiben unberührt.',
    targetLanguage:
      'The law of the Federal Republic of Germany shall apply. If you have placed the order as a Consumer and your habitual residence at the time of your order is in another country, the application of mandatory legal provisions of that country shall remain unaffected by the selection of law made in sentence 1. The exclusive place of jurisdiction is Berlin, insofar as the User is a merchant within the meaning of the German Commercial Code or has no fixed abode in Germany at the time the action is brought.',
  },
  {
    german: '13.3',
    targetLanguage: '13.3',
    bold: true,
  },
  {
    german:
      'Sollten Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden, berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.',
    targetLanguage:
      'Should any provisions of these Terms of Use be or become invalid, such invalidity shall not affect the validity of the remaining provisions.',
  },
  {
    german: 'Muster-Widerrufsformular',
    targetLanguage: 'Template Cancellation Form',
  },
  {
    german: `(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
    <br /><br />
    An Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telefon: +49 30 23 88 84 240, Telefax: +49 30 23 88 84 120, E-Mail: info[at]Schneider Electric.com)
    <br /><br />
    Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*) – Bestellt am (*)/erhalten am (*).
    <ul>
      <li>Name des/der Verbraucher(s)</li>
      <li>Anschrift des/der Verbraucher(s)</li>
      <li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
      <li>Datum</li>
    </ul>`,
    targetLanguage: `(If you wish to cancel the contract, please complete and return this form.)
    <br /><br />
    To Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telephone: +49 30 23 88 84 240, Telefax: +49 30 23 88 84 120, Email: info[at]Schneider Electric.com)
    <br/><br />
    I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the following goods (*)/the provision of the following service (*) -
    Ordered on (*)/received on (*).
    <ul>
      <li>Consumer name</li>
      <li>Consumer address</li>
      <li>Consumer signature (solely for paper communication)</li>
      <li>Date</li>
    </ul>`,
  },
  {
    german: '---------------------------------',
    targetLanguage: '---------------------------------',
  },
  {
    german: '(*) Unzutreffendes streichen',
    targetLanguage: '(*) Delete as applicable',
  },
];

const TermsConditionsForCustomerDeEn: React.FC = () => (
  <BiLingualTable columns={columns} dataSource={privacyData} />
);

export default TermsConditionsForCustomerDeEn;
