import React from 'react';

import { SupportedLanguages } from 'framework/constants';
import DataPrivacyDeEn from './DataPrivacy.de_en';
import DataPrivacyFr from './DataPrivacy.fr';
import DataPrivacyIt from './DataPrivacy.it';

type DataPrivacyProps = {
  language: string;
};

const DataPrivacyComponents: Record<SupportedLanguages, React.FC> = {
  de: DataPrivacyDeEn,
  en: DataPrivacyDeEn,
  fr: DataPrivacyFr,
  it: DataPrivacyIt,
};

const DataPrivacy = (props: DataPrivacyProps) => {
  const Content = DataPrivacyComponents[props.language as SupportedLanguages];
  return <Content />;
};

export default DataPrivacy;
