import React from 'react';

import { SupportedLanguages } from 'framework/constants';
import TermsConditionsForCustomerDeEn from './TermsConditionsForCustomer.de_en';
import TermsConditionsForCustomerFr from './TermsConditionsForCustomer.fr';
import TermsConditionsForCustomerIt from './TermsConditionsForCustomer.it';

type TermsConditionsForCustomerProps = {
  language: string;
};

const TermsConditionsForCustomerComponents: Record<SupportedLanguages, React.FC> = {
  de: TermsConditionsForCustomerDeEn,
  en: TermsConditionsForCustomerDeEn,
  fr: TermsConditionsForCustomerFr,
  it: TermsConditionsForCustomerIt,
};

const TermsConditionsForCustomer = (props: TermsConditionsForCustomerProps) => {
  const Content = TermsConditionsForCustomerComponents[props.language as SupportedLanguages];
  return <Content />;
};

export default TermsConditionsForCustomer;
