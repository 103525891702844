import React from 'react';

import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';
import styles from './TermsConditionsForInstaller.module.sass';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'Anbieterbedingungen für tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: 'Termini e condizioni del fornitore per tendergy',
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german: `Die Schneider Electric GmbH, (nachfolgend „Schneider Electric“ oder „wir“ oder „uns“) betreibt die Internet-Plattform <a href="${document.location.origin}/">${document.location.origin}/</a> sowie alle damit verbundenen Internet-Domänen und -Auftritte (nachfolgend die „Plattform“), auf der Anbieter (nachfolgend der oder die „Anbieter“ oder „Sie“) Endkunden (nachfolgend die „Kunden“) die Installation (und ggfs. Verkauf) einer sog. Wallbox („Service“) anbieten können.`,
    targetLanguage: `Schneider Electric GmbH (di seguito denominata "Schneider Electric" o "noi") gestisce la piattaforma Internet <a href="${document.location.origin}/">${document.location.origin}/</a>, nonché tutti i domini Internet e siti Web associati (di seguito denominati "Piattaforma"), tramite i quali i provider (di seguito denominati "Provider" o "voi") possono offrire ai clienti finali (di seguito denominati "Clienti") l'installazione (e, se applicabile, la vendita) di una cosiddetta wallbox ("Servizio").`,
  },
  {
    german:
      'Anbieter und Poolbetreiber stellen im Zuge dieser Bedingungen immer Unternehmen im Sinne von § 14 BGB dar. Kunden können entweder Verbraucher im Sinne von § 13 BGB oder Unternehmen im Sinne von § 14 BGB darstellen. Insofern gilt folgendes:',
    targetLanguage:
      'Ai fini delle presenti Condizioni Generali, il Fornitore e il Pool Operator costituiscono sempre società ai sensi della Sezione 14 del Codice Civile Tedesco. I Clienti possono essere consumatori ai sensi della Sezione 13 del Codice Civile Tedesco o aziende ai sensi della Sezione 14 del Codice Civile Tedesco. A tale riguardo, si applica quanto segue:',
  },
  {
    german:
      'Ein „Verbraucher“ ist jede natürliche Person, die den Vertrag zu Zwecken abschließt, die überwiegend keiner gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 Bürgerliches Gesetzbuch – „BGB“).',
    targetLanguage:
      'Per "consumatore" si intende qualsiasi persona fisica che stipula il contratto per scopi che non sono prevalentemente attribuibili ad un\'attività commerciale o professionale autonoma (art. 13 del Codice civile tedesco).',
  },
  {
    german:
      'Ein „Unternehmer“ ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss des Vertrages in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt (§ 14 Abs. 1 BGB).',
    targetLanguage:
      'Per "imprenditore" si intende una persona fisica o giuridica oppure una società di persone con capacità giuridica che, al momento della conclusione del contratto, agisce nell\'esercizio della propria attività commerciale o professionale autonoma (articolo 14 (1) del Codice civile tedesco).',
  },
  {
    german:
      'Kunde ist somit grundsätzlich eine natürliche Person, es sei denn, sie ist ausdrücklich als juristische Person bei tendergy registriert.',
    targetLanguage:
      'In linea di principio, il Cliente è quindi una persona fisica, a meno che non sia espressamente registrato come persona giuridica su tendergy.',
  },
  {
    german:
      'Schneider Electric stellt die Plattform nur zur Verfügung, um es den Anbietern zu ermöglichen, den Kunden oder Pool-Betreibern ihren Service direkt anzubieten bzw. Verträge abzuschließen. Schneider Electric tritt als vom Anbieter autorisierter und beauftragter gewerblicher Vermittler auf und wird zu keiner Zeit Vertragspartner bezüglich des Services, den der Anbieter den Kunden oder Pool-Betreibern anbietet.',
    targetLanguage:
      'Schneider Electric fornirà la Piattaforma solo allo scopo di consentire ai Provider di offrire il loro servizio o di stipulare contratti con Clienti o Pool Operator direttamente. Schneider Electric agirà come un broker commerciale autorizzato e nominato dal Provider e non diventerà in nessun momento parte di alcun contratto in merito al servizio offerto dal Provider ai Clienti o Pool Operator.',
  },
  {
    german: '1. Anwendungsbereich dieser Anbieterbedingungen',
    targetLanguage: '1. Ambito di applicazione delle presenti Condizioni Generali',
    subSection: true,
  },
  {
    german: `Diese Anbieterbedingungen (nachfolgend die „Bedingungen“ oder der „Vertrag“) gelten für alle Anbieter, die den Kunden ihren Service über die Plattform anbieten, und regeln lediglich das Verhältnis zwischen dem Anbieter und Schneider Electric. Das Vertragsverhältnis zwischen dem Anbieter und tendergy umfasst nur die ordnungsgemäße Vermittlung der Kunden und Pool-Betreiber an den Anbieter. Die Vermittlung zwischen Kunden bzw. Pool-Betreibern und Anbietern findet in verschiedenen Gruppen und/oder Projekten statt – sog. Pools („Pools“). Das Verhältnis zwischen den Kunden und dem Anbieter ist nicht Gegenstand der Bedingungen, sondern allein Gegenstand der einzelnen Verträge, die zwischen diesen Parteien abgeschlossen werden. Das Verhältnis zwischen Schneider Electric und den Kunden ist unterdessen Gegenstand der Nutzungsbedingungen von Schneider Electric unter <a href="${document.location.origin}/legal/terms-and-conditions?at=2">${document.location.origin}/legal/terms-and-conditions?at=2</a>`,
    targetLanguage: `Questi Termini e Condizioni del Fornitore (di seguito denominati "Termini" o "Contratto") sono applicabili a tutti i Fornitori che offrono il loro servizio ai Clienti tramite la Piattaforma e regoleranno esclusivamente il rapporto tra il Fornitore e Schneider Electric. Il rapporto contrattuale tra il Fornitore e tendergy includerà esclusivamente il corretto rinvio di Clienti e Pool Operator al Fornitore. L'intermediazione tra Clienti o Pool Operator e fornitori avverrà tramite diversi gruppi e/o progetti, i cosiddetti pool ("Pool"). Il rapporto tra i Clienti e il Fornitore non sarà oggetto dei Termini, ma sarà esclusivamente oggetto dei singoli contratti conclusi tra queste parti. Il rapporto tra Schneider Electric e i suoi clienti, inoltre, sarà regolato dai Termini di utilizzo di Schneider Electric all'indirizzo <a href="${document.location.origin}/legal/terms-and-conditions?at=2">${document.location.origin}/legal/terms-and-conditions?at=2</a>`,
  },
  {
    german: '2. Registrierung des Anbieters',
    targetLanguage: '2. Registrazione del fornitore',
    subSection: true,
  },
  {
    german: '2.1',
    targetLanguage: '2.1',
    bold: true,
  },
  {
    german:
      'Nach Registrierung auf der Plattform ist der Anbieter berechtigt, seinen Service über die Plattform und insbesondere dem von Schneider Electric betriebenen öffentlichen Pool anzubieten. Im Zuge der Registrierung auf der Plattform nimmt der Anbieter die Bedingungen an.',
    targetLanguage:
      'Il Fornitore avrà il diritto di offrire il proprio Servizio tramite la Piattaforma, e in particolare il Pool pubblico gestito da Schneider Electric, previa registrazione alla Piattaforma. Il Fornitore accetterà i Termini e le Condizioni durante la registrazione alla Piattaforma.',
  },
  {
    german: '2.2',
    targetLanguage: '2.2',
    bold: true,
  },
  {
    german:
      'Schneider Electric ist nicht dafür verantwortlich, die Identität, die Echtheit oder Berechtigungsnachweise der Person zu überprüfen, die diesen Vertrag abschließt. Schneider Electric ist jedoch jederzeit berechtigt, sich derartige Nachweise auf Kosten des Anbieters vorlegen zu lassen.',
    targetLanguage:
      "Schneider Electric non è responsabile della verifica dell'identità, dell'autenticità o delle credenziali della persona che stipula il presente Contratto. Tuttavia, Schneider Electric avrà il diritto in qualsiasi momento di ottenere tale prova a spese del Fornitore.",
  },
  {
    german: '2.3',
    targetLanguage: '2.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist verpflichtet, seinen Service als Unternehmer gemäß den für ihn einschlägigen Steuervorschriften des anwendbaren Umsatzsteuergesetzes und den Verfahren und Anforderungen der Steuerbehörden anzubieten und zu erbringen. Der Anbieter ist verpflichtet, im Rahmen der Führung seiner Geschäfte bzw. seiner selbständigen beruflichen Tätigkeit die maßgeblichen Verträge mit den Kunden abzuschließen.',
    targetLanguage:
      'Il Fornitore è tenuto a offrire e fornire il proprio Servizio in qualità di Imprenditore in conformità con le normative fiscali della legge IVA applicabile e le procedure e i requisiti delle autorità fiscali ad essa applicabili. Il Fornitore è tenuto a concludere i contratti pertinenti con i Clienti durante lo svolgimento della propria attività commerciale o professionale autonoma.',
  },
  {
    german: '2.4',
    targetLanguage: '2.4',
    bold: true,
  },
  {
    german:
      'Der Anbieter kann sich von Schneider Electric zum sog.„Pool-Betreiber“ unter den Bedingungen eines separat abzuschließenden Lizenzvertrags freischalten lassen. Pool-Betreiber können zusätzlich zum von Schneider Electric betriebenen öffentlichen Pool auch private Pools zur Vermittlung („Privater Vermittlungs-Pool“) von Installationsleistungen durch anderer Anbieter oder zum Abschluss der Installationsleistung mit sich selber („Privater Betreiber-Pool“) erstellen. Anbieter und Kunden können diesen privaten Pools nur auf Einladung der entsprechenden Pool-Betreiber beitreten. Bei Beitritt zu diesen privaten Pools unterwerfen sich die Anbieter den besonderen Bestimmungen des jeweiligen Pools und insbesondere dem entsprechenden Buchungsverfahren wie in Ziffer 5 dargestellt.',
    targetLanguage:
      'Il Fornitore può ricevere l\'autorizzazione di Schneider Electric a diventare un cosiddetto "Operatore di Pool" ai sensi dei termini e delle condizioni di un contratto di licenza da stipulare separatamente. Gli Operatori di Pool possono, oltre al pool pubblico gestito da Schneider Electric, anche creare pool privati ​​per l\'intermediazione ("Private Brokerage Pool") di servizi di installazione da parte di altri fornitori o per la conclusione di contratti di servizi di installazione con se stessi ("Private Operator Pool"). I Fornitori e i Clienti possono unirsi a questi Pool privati ​​solo su invito dei rispettivi operatori di Pool. Quando aderiscono a questi Pool privati, i Fornitori saranno soggetti ai Termini e alle Condizioni specifici del Pool pertinente e in particolare alla relativa procedura di prenotazione come stabilito nella Clausola 5.',
  },
  {
    german: '3. Der von Schneider Electric angebotene Service',
    targetLanguage: '3. Servizio fornito da Schneider Electric',
    subSection: true,
  },
  {
    german:
      'Die Plattform ermöglicht es den Anbietern, den Kunden ihren Service anzubieten und Verträge mit diesen abzuschließen. Dabei beauftragt der Anbieter Schneider Electric als gewerblichen Vermittler und bevollmächtigt uns, die Anbahnung und den Vertragsschluss in Bezug auf den Service direkt zwischen dem Anbieter bzw. dem Pool-Betreiber und den Kunden über die Plattform abzuwickeln.',
    targetLanguage:
      "La Piattaforma consente ai Provider di offrire il loro servizio ai Clienti e di concludere contratti con loro. Nel fare ciò, il Provider nominerà Schneider Electric come agente di mediazione commerciale e ci autorizzerà a gestire direttamente l'avvio e la conclusione del contratto in relazione al Servizio tra il provider o il Pool Operator e i Clienti tramite la Piattaforma.",
  },
  {
    german: '4. Pflichten des Anbieters',
    targetLanguage: '4. Obblighi del fornitore',
    subSection: true,
  },
  {
    german: '4.1',
    targetLanguage: '4.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass alle Informationen im Zusammenhang mit seinem Angebot jederzeit richtig, wahr und nicht irreführend sind. Der Anbieter ist verpflichtet, die hochgeladenen Informationen zeitnah zu aktualisieren, so dass die Richtigkeit der bereitgestellten Informationen sichergestellt ist. Der Anbieter kann für alle Forderungen der Kunden haftbar gemacht werden, die auf Unrichtigkeiten der hochgeladenen, auf der Plattform angezeigten Informationen (wie z. B. Informationen zu dem Service und Preisen) beruhen.',
    targetLanguage:
      "Il Fornitore assicurerà e garantirà che tutte le informazioni relative alla propria Offerta siano sempre accurate, veritiere e non fuorvianti. Il Fornitore è tenuto ad aggiornare le informazioni caricate in modo tempestivo al fine di garantire l'accuratezza delle informazioni fornite. Il Fornitore può essere ritenuto responsabile per eventuali reclami da parte dei Clienti basati su inesattezze nelle informazioni caricate visualizzate sulla Piattaforma (come le informazioni sul Servizio e sui prezzi).",
  },
  {
    german: '4.2',
    targetLanguage: '4.2',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass der Service ausschließlich im Einklang mit allen anwendbaren Gesetzen erbracht wird und dass die Erbringung des Services nicht gegen Rechte Dritter verstößt. Insbesondere garantiert der Anbieter, dass er (a) sämtliche Verbraucherschutz- und sonstige Schutz-, Informations- und Beratungspflichten und -vorschriften einhält und (b) über alle erforderlichen verwaltungsrechtlichen und sonstigen Genehmigungen und Lizenzen sowie über Versicherungen und ähnliches verfügt, und dass er seinen im Rahmen der Erfüllung des Services eingesetzten Auftragnehmern Weisungen erteilt, die diesen Bedingungen entsprechen. Der Anbieter ist verpflichtet, auf Nachfrage entsprechende Nachweise und Unterlagen, wie z. B. Kopien von Genehmigungen und Lizenzen, vorzulegen. Für den Fall, dass der vom Anbieter erbrachte Service die genannten Vorgaben nicht einhält oder wenn ein entsprechender Verdacht besteht, ist der Anbieter verpflichtet, unverzüglich auf eigene Kosten mit den Verwaltungsbehörden oder Wettbewerbs-/Verbraucherschutzverbänden zusammenzuarbeiten.',
    targetLanguage:
      "Il Fornitore assicura e garantisce che il Servizio sarà fornito esclusivamente in conformità con tutte le leggi applicabili e che la fornitura del Servizio non violerà alcun diritto di terze parti. In particolare, il Fornitore garantisce che (a) rispetterà tutti gli obblighi e le normative in materia di protezione dei consumatori, altre tutele, informazioni e consultazione e (b) sarà in possesso di tutti i necessari permessi e licenze amministrativi e di altro tipo, nonché polizze assicurative e equivalenti, e che emetterà istruzioni ai propri appaltatori impegnati nell'esecuzione del Servizio che siano conformi a queste condizioni. Il Fornitore sarà tenuto a presentare prove e documentazione appropriate, come copie di permessi e licenze, su richiesta. Nel caso in cui il servizio fornito dal Fornitore non sia conforme alle specifiche di cui sopra, o se vi è un sospetto in tal senso, il Fornitore è tenuto a collaborare immediatamente con le autorità amministrative o le associazioni di tutela della concorrenza/dei consumatori a proprie spese.",
  },
  {
    german: '4.3',
    targetLanguage: '4.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass er den Kunden alle für die Ausführung des Services erforderlichen Informationen (wie z. B. den Treffpunkt und die Uhrzeit) rechtzeitig im Voraus mitteilt.',
    targetLanguage:
      "Il Fornitore assicura e garantisce che fornirà ai Clienti tutte le informazioni necessarie per l'esecuzione del Servizio (come luogo e ora dell'incontro) in tempo utile prima dell'esecuzione del Servizio.",
  },
  {
    german: '4.4',
    targetLanguage: '4.4',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist nur berechtigt, seinen eigenen Service über die Plattform anzubieten. Der Anbieter ist nicht berechtigt, einen Service als Vermittler anzubieten. Sofern der Anbieter dennoch als Vermittler oder Makler auftritt, wird er wie eine Vertragspartei behandelt und kann für etwaige Schäden haftbar gemacht werden. Der Anbieter muss in der Lage sein, den angebotenen Service auszuführen. Er ist nicht berechtigt, einen vorgetäuschten Service auf der Plattform einzustellen.',
    targetLanguage:
      'Il Fornitore ha il diritto di offrire il proprio servizio solo tramite la Piattaforma. Il Fornitore non è autorizzato a offrire un servizio come agente di mediazione. Nella misura in cui il Fornitore agisce comunque come mediatore, il Fornitore sarà trattato come parte contraente e potrà essere ritenuto responsabile per eventuali danni. Il Fornitore deve essere in grado di eseguire il servizio offerto. Il Fornitore non ha il diritto di pubblicare servizi fittizi sulla Piattaforma.',
  },
  {
    german: '4.5',
    targetLanguage: '4.5',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist verpflichtet, eine umfassende allgemeine Haftpflichtversicherung für alle über die Plattform angebotenen Services abzuschließen. Der Anbieter muss zwingend eine Versicherungssumme vereinbaren, die für die festgelegten Buchungsvolumina und -risiken angemessen ist. Darüber hinaus muss die allgemeine Haftpflichtversicherung potenzielle Regressansprüche von Schneider Electric abdecken.',
    targetLanguage:
      "Il Fornitore è tenuto a stipulare un'assicurazione di responsabilità civile generale completa per tutti i servizi offerti tramite la Piattaforma. Il Fornitore è tenuto a concordare una somma assicurativa adeguata ai volumi di prenotazione e ai rischi specificati. L'assicurazione di responsabilità civile generale deve inoltre coprire potenziali richieste di rivalsa da parte di Schneider Electric.",
  },
  {
    german: '4.6',
    targetLanguage: '4.6',
    bold: true,
  },
  {
    german:
      'Die vom Anbieter auf seinem Account für seine Angebote und Services angegebenen Preise sind einschließlich der USt. bzw. MwSt. und sonstiger Preisbestandteile. Es ist nicht gestattet, von den Kunden zu verlangen, dass sie auf die Ausführung des Service weitere Beträge, Steuern, Gebühren oder Provisionen zahlen oder sonstige Kosten erstatten.',
    targetLanguage:
      "I prezzi indicati dal Fornitore sul proprio account per le proprie offerte e servizi saranno comprensivi di IVA e di altre componenti di prezzo. Non è consentito richiedere ai clienti di pagare ulteriori importi, tasse, commissioni o tasse sull'esecuzione del Servizio o di rimborsare altri costi.",
  },
  {
    german:
      'Der Anbieter ist verpflichtet, den Kunden eine ordnungsgemäße Rechnung zu übermitteln, die sämtliche anwendbaren steuerlichen Voraussetzungen erfüllt.',
    targetLanguage:
      'Il Fornitore è tenuto a inviare ai Clienti una fattura corretta e conforme a tutti i requisiti fiscali applicabili.',
  },
  {
    german: '5. Buchungsverfahren',
    targetLanguage: '5. Procedura di prenotazione',
    subSection: true,
  },
  {
    german: '5.1',
    targetLanguage: '5.1',
    bold: true,
  },
  {
    german:
      'Im öffentlichen Pool gilt: Wenn der Kunde an einem bestimmten Service interessiert ist, erstellt der Kunde eine Anfrage im Pool, und die Anbieter im Pool werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    targetLanguage:
      'Nel Pool Pubblico, se il Cliente è interessato a un Servizio particolare, dovrà creare una richiesta nel Pool e i Fornitori nel Pool saranno debitamente informati della richiesta in questione.',
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, können die Anbieter im Pool dem Kunden ein verbindliches Angebot machen. Dieses Angebot unterliegt ggf. der abschließenden Kontrolle durch Schneider Electric. Der Anbieter ist an dieses Angebot 60 Werktage gebunden. Wenn der Kunde das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Kunden kommt ein verbindlicher Vertrag mit dem Anbieter des verbindlichen Angebots über den Service zustande.',
    targetLanguage:
      "Dopo aver fornito tutte le informazioni necessarie, i Provider nel Pool possono presentare un'offerta vincolante al Cliente. Tale offerta può essere soggetta a ispezione finale da parte di Schneider Electric. Qualora il Cliente accetti l'offerta, Schneider Electric ne darà comunicazione al Provider. Dopo l'accettazione da parte del Cliente, verrà concluso un contratto vincolante con il Provider dell'offerta vincolante per il servizio.",
  },
  {
    german: '5.2',
    targetLanguage: '5.2',
    bold: true,
  },
  {
    german:
      'In Privaten Vermittlungs-Pools gilt: Wenn der Kunde in diesem Pool an einem bestimmten Service interessiert ist, erstellt er eine entsprechende Anfrage, und die Anbieter im Pool sowie der Pool-Betreiber werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    targetLanguage:
      "Nei pool di intermediazione privata, qualora il cliente di quel pool sia interessato a un servizio specifico, il cliente deve creare una richiesta in tal senso e i fornitori del pool e l'operatore del pool saranno debitamente informati della richiesta in questione.",
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, können die Anbieter dem Kunden ein verbindliches Angebot machen. Dieses Angebot unterliegt ggf. der abschließenden Kontrolle durch den Pool-Betreiber. Der Anbieter ist an dieses Angebot 60 Werktage gebunden. Wenn der Kunde das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Kunden kommt ein verbindlicher Vertrag mit dem Anbieter des verbindlichen Angebots über den Service zustande.',
    targetLanguage:
      "I provider possono fare un'offerta vincolante al cliente dopo aver fornito tutte le informazioni richieste. Questa offerta è soggetta a revisione finale da parte del Pool Operator, se applicabile. Il provider sarà vincolato a questa offerta per 60 giorni lavorativi. Se il cliente accetta l'offerta, Schneider Electric ne darà comunicazione al provider. Dopo l'accettazione da parte del cliente, verrà concluso un contratto vincolante con il provider dell'offerta vincolante per il servizio.",
  },
  {
    german: '5.3',
    targetLanguage: '5.3',
    bold: true,
  },
  {
    german:
      'In Privaten Betreiber-Pools gilt: Wenn der Kunde in diesem Pool an einem bestimmten Service interessiert ist, erstellt er eine Anfrage, und die Anbieter in diesem Pool sowie der Pool-Betreiber werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    targetLanguage:
      "Nei Pool di operatori privati, qualora il Cliente di quel Pool sia interessato a un Servizio particolare, il Cliente deve creare una richiesta in tal senso e i Fornitori nel Pool e l'Operatore del Pool saranno debitamente informati della richiesta in questione.",
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, kann der Anbieter dem Pool-Betreiber ein verbindliches Angebot zur Ausführung der Anfrage des Kunden als Unterauftragnehmer des Pool-Betreibers machen. Der Anbieter ist an dieses Angebot fünfzehn (15) Werktage gebunden. Wenn der Pool-Betreiber das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Pool-Betreibers kommt ein verbindlicher Vertrag zwischen dem Anbieter und dem Pool-Betreiber hinsichtlich des verbindlichen Angebots über die Erbringung des Service beim Kunden als Unterauftrag im Namen und Auftrag des Pool-Betreibers zustande.',
    targetLanguage:
      "Dopo aver fornito tutte le informazioni richieste, il Fornitore può fare un'offerta vincolante al Pool Operator per eseguire la richiesta del Cliente come subappaltatore del Pool Operator. Il Fornitore sarà vincolato da questa offerta per quindici (15) giorni lavorativi. Qualora il Pool Operator accetti l'offerta, Schneider Electric ne darà comunicazione al Fornitore. Un contratto vincolante tra il Fornitore e il Pool Operator in merito all'offerta vincolante per la fornitura del Servizio presso i locali del Cliente, sarà concluso come subappalto in nome e per conto del Pool Operator previa accettazione da parte del Pool Operator.",
  },
  {
    german: '5.4',
    targetLanguage: '5.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet nicht für die Genauigkeit und/oder die Vollständigkeit der kundenbezogenen Daten und Anfragen.',
    targetLanguage:
      "Schneider Electric non è responsabile dell'accuratezza e/o della completezza dei dati e delle richieste relativi al Cliente.",
  },
  {
    german: '6. Buchungsangebote des Anbieters',
    targetLanguage: '6. Offerte di prenotazione del fornitore',
    subSection: true,
  },
  {
    german: '6.1',
    targetLanguage: '6.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter gibt die Angaben zum Service in den Anbieter-Account ein. Er ist dafür verantwortlich sicherzustellen, dass die gemachten Angaben stets auf dem aktuellen Stand sind. Überarbeitete, stornierte oder nicht mehr gültige Services sind unverzüglich zu löschen.',
    targetLanguage:
      "Il Fornitore inserirà i dettagli del Servizio nell'Account del Fornitore. È responsabilità del Fornitore garantire che le informazioni fornite siano sempre aggiornate. I servizi modificati, annullati o non più validi devono essere eliminati immediatamente.",
  },
  {
    german: '6.2',
    targetLanguage: '6.2',
    bold: true,
  },
  {
    german:
      'Aus der Servicebeschreibung muss klar ersichtlich sein, dass der Anbieter den Service in seinem eigenen Namen und in seiner eigenen Verantwortung anbietet. Der Service muss unter dem allgemein verwendeten Markennamen angeboten werden. Ist dieser Markenname ein anderer als der in allgemeinen (Marken-) Registern eingetragene, muss der Anbieter auch diesen eingetragenen Markennamen nennen. Es darf weder mittelbar noch unmittelbar der Eindruck entstehen, dass Schneider Electric Anbieter des Services ist oder das Schneider Electric die Qualität des angebotenen Services geprüft hat oder ihre Qualität anderweitig hervorhebt.',
    targetLanguage:
      'La Descrizione del Servizio deve indicare chiaramente che il Fornitore fornisce il Servizio per proprio conto e sotto la propria responsabilità. Il Servizio deve essere offerto con il marchio di uso comune. Se questo marchio è diverso da quello registrato nei registri generali (marchi), il Fornitore deve menzionare anche questo marchio registrato. Non deve esserci alcuna impressione diretta o indiretta che Schneider Electric sia il fornitore del servizio o che Schneider Electric abbia verificato la qualità del servizio offerto o ne promuova in altro modo la qualità.',
  },
  {
    german: '7. Verantwortung für den Inhalt',
    targetLanguage: '7. Responsabilità per i contenuti',
    subSection: true,
  },
  {
    german: '7.1',
    targetLanguage: '7.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist für den Inhalt verantwortlich, der über seinen Account übermittelt wird, wie z. B. Beschreibungen des Services. Schneider Electric behandelt diesen Inhalt des Anbieters wie externen Inhalt.',
    targetLanguage:
      'Il Fornitore è responsabile del contenuto trasmesso tramite il suo account, come le descrizioni del Servizio. Schneider Electric tratterà questo Contenuto del Fornitore come Contenuto Esterno.',
  },
  {
    german: '7.2',
    targetLanguage: '7.2',
    bold: true,
  },
  {
    german:
      'Wird Schneider Electric über Inhalt informiert oder entdeckt Inhalt, der gegen diese Bedingungen oder sonstige rechtliche Bestimmungen verstößt, ist Schneider Electric berechtigt, diesen Inhalt ganz oder teilweise zu löschen. Falls der missbräuchliche Inhalt von einem Anbieter stammt, ist Schneider Electric berechtigt, den Zugang zu seinem Account zu sperren oder seinen Account zu löschen. Schneider Electric wird stets die jeweiligen beiderseitigen Interessen an dem betreffenden Fall berücksichtigen, bevor das Unternehmen einen Anbieter-Account ganz oder vorübergehend sperrt.',
    targetLanguage:
      "Qualora Schneider Electric venisse a conoscenza o scoprisse Contenuti che violano i presenti Termini o altre disposizioni legali, Schneider Electric avrà il diritto di eliminare tali Contenuti in tutto o in parte. Schneider Electric ha il diritto di bloccare l'accesso all'account o di eliminare l'account nel caso in cui il contenuto abusivo provenga da un Fornitore. Schneider Electric considererà sempre i rispettivi interessi reciproci nel caso in questione prima di sospendere completamente o temporaneamente un Account Fornitore.",
  },
  {
    german: '7.3',
    targetLanguage: '7.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist nur berechtigt, Inhalte und Daten hochzuladen, zu denen er alle notwendigen Rechte besitzt. Diese Informationen dürfen nicht gegen die Datenschutzrechte von Kunden, Dritten, etc. verstoßen und müssen den geltenden Wettbewerbsgesetzen und sonstigen rechtlichen Anforderungen entsprechen.',
    targetLanguage:
      'Il Fornitore ha il diritto di caricare solo contenuti e dati sui quali detiene tutti i diritti necessari. Tali informazioni non devono violare i diritti di protezione dei dati dei clienti, di terze parti, ecc. e devono rispettare le leggi sulla concorrenza applicabili e altri requisiti legali.',
  },
  {
    german: '8. Kundenbeschwerden',
    targetLanguage: '8. Reclami dei clienti',
    subSection: true,
  },
  {
    german: '8.1',
    targetLanguage: '8.1',
    bold: true,
  },
  {
    german:
      'Um Beschwerden oder Forderungen bezüglich (des durch den Anbieter angebotenen, erbrachten oder zur Verfügung gestellten Services) des Anbieters oder spezielle vom Kunden vorgebrachte Anfragen hat sich der Anbieter zu kümmern, da Schneider Electric nicht für solche Anfragen von Kunden verantwortlich ist und für Forderungen nicht einsteht. Schneider Electric ist berechtigt, jederzeit und nach eigenem Ermessen (a) einem Kunden Kunden-(Support-)Service anzubieten, (b) als Vermittler zwischen dem Anbieter und den Kunden aufzutreten oder (c) die Kunden anderweitig bei ihrer Kommunikation mit dem oder der Geltendmachung von Ansprüchen gegenüber dem Anbieter zu unterstützen.',
    targetLanguage:
      'I reclami o le rivendicazioni riguardanti (il servizio offerto, eseguito o fornito da) il Fornitore o richieste specifiche presentate dal Cliente saranno gestiti dal Fornitore, in quanto Schneider Electric non è responsabile di tali richieste del Cliente e non sarà ritenuta responsabile per eventuali reclami. Schneider Electric può, in qualsiasi momento e a sua esclusiva discrezione, (a) fornire servizi di assistenza clienti a un Cliente, (b) agire come agente di mediazione tra il Fornitore e i Clienti, o (c) altrimenti assistere i Clienti nelle loro comunicazioni con o nei reclami contro il Fornitore.',
  },
  {
    german: '9. Zahlung und Abrechnung',
    targetLanguage: '9. Pagamento e fatturazione',
    subSection: true,
  },
  {
    german:
      'Für die Zahlung des Kunden und die diesbezügliche Abrechnung ist allein der Anbieter verantwortlich.',
    targetLanguage:
      "A tale riguardo, il Fornitore sarà l'unico responsabile del pagamento e della fatturazione da parte del Cliente.",
  },
  {
    german: '10. Steuern',
    targetLanguage: '10. Tasse',
    subSection: true,
  },
  {
    german:
      'Der Anbieter nimmt zustimmend zur Kenntnis, dass er allein für die Genauigkeit der Angaben zum Steuersatz, für die Ermittlung der anwendbaren Steuern und etwaige Änderungen der Steuersätze verantwortlich ist, die auf der Plattform eingegeben werden. Der Anbieter ist dafür verantwortlich, den zuständigen Steuerbehörden seine Abrechnungen im Hinblick auf jegliche anwendbaren Steuern zu allen Beträgen einzureichen, die als Gegenleistung für den Service bei ihm eingehen. Sofern Schneider Electric es verlangt, ist der Anbieter verpflichtet, Schneider Electric in den Fällen, in denen nach anwendbarem Recht Steuern anfallen, unverzüglich gültige Steuerrechnungen im Hinblick auf alle im Rahmen dieser Bedingungen abgeschlossenen Transaktionen vorzulegen.',
    targetLanguage:
      "Il Fornitore riconosce e accetta di essere l'unico responsabile dell'accuratezza delle informazioni sulle aliquote fiscali, della determinazione delle imposte applicabili e di qualsiasi modifica alle aliquote fiscali immesse sulla Piattaforma. Il Fornitore è responsabile della presentazione alle autorità fiscali competenti dei propri conti in relazione a qualsiasi imposta applicabile su tutti gli importi ricevuti in cambio del Servizio. Ove richiesto da Schneider Electric, il Fornitore dovrà fornire tempestivamente a Schneider Electric fatture fiscali valide in relazione a tutte le transazioni effettuate ai sensi dei presenti Termini e Condizioni nei casi in cui le imposte siano pagabili ai sensi della legge applicabile.",
  },
  {
    german: '11. Datenschutz',
    targetLanguage: '11. Protezione dei dati',
    subSection: true,
  },
  {
    german:
      'Der Anbieter versichert, dass er angemessene Sicherheitsverfahren und -kontrollen installiert hat und aufrechterhalten wird, bzw. dass er diese einrichten und aufrechterhalten wird, um die unbeabsichtigte Offenlegung von und den unbefugten Zugriff auf oder die widerrechtliche Verwendung von personenbezogenen Daten oder Informationen von Kunden zu verhindern.',
    targetLanguage:
      "Il Fornitore deve garantire di aver installato e di mantenere, oppure di installare e mantenere, procedure e controlli di sicurezza adeguati per impedire la divulgazione involontaria, l'accesso non autorizzato o l'uso illecito dei dati o delle informazioni personali del Cliente.",
  },
  {
    german:
      'Weder der Anbieter noch seine verbundenen Unternehmen beteiligen sich mittelbar oder unmittelbar an verlangt oder unverlangt zugesandten Marketing- oder Promotion-Maßnahmen für Kunden oder ähnlichen Mitteilungen an Kunden, die über die Plattform gebucht haben, ohne dass hierzu die Zustimmung des betreffenden Kunden vorliegt. Der Anbieter ist verpflichtet, sämtliche kundenbezogenen Informationen (wie z. B. Kreditkarten-, Debitkarten- oder Bankkontodaten) gemäß den auf den Anbieter anwendbaren Gesetzen zu verarbeiten, zu speichern, zu übermitteln und darauf zuzugreifen. Der Anbieter ist auf Anforderung von Schneider Electric verpflichtet nachzuweisen, dass er technische und organisatorische Sicherheitsmaßnahmen, die die Verarbeitung personenbezogener Daten gemäß dem vorliegenden Abschnitt regeln, installiert hat und aufrechterhält.',
    targetLanguage:
      "Né il Fornitore né alcuna delle sue affiliate parteciperà direttamente o indirettamente ad alcuna attività di marketing o promozionale sollecitata o non sollecitata per i Clienti o comunicazioni simili ai Clienti che hanno prenotato tramite la Piattaforma senza il consenso del Cliente in questione. Il Fornitore è tenuto a elaborare, archiviare, trasmettere e accedere a tutte le informazioni relative al Cliente (come i dati della carta di credito, della carta di debito o del conto bancario) in conformità con le leggi applicabili al Fornitore. Su richiesta di Schneider Electric, il Fornitore sarà tenuto a dimostrare di aver installato e mantenuto misure di sicurezza tecniche e organizzative che regolano l'elaborazione dei Dati personali in conformità con la presente Sezione.",
  },
  {
    german: `Schneider Electric erhebt und verwendet personenbezogene Daten wie in den Datenschutzbestimmungen von tendergy unter <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a> beschrieben.`,
    targetLanguage: `Schneider Electric raccoglie e utilizza i dati personali come descritto nell'informativa sulla privacy di tendergy all'indirizzo <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a>.`,
  },
  {
    german: '12. Zusicherungen und Garantien',
    targetLanguage: '12. Assicurazioni e garanzie',
    subSection: true,
  },
  {
    german:
      'Zusätzlich zu etwaigen sonstigen Zusicherungen und Garantien des Anbieters in diesen Bedingungen sichert der Anbieter hiermit zu und garantiert, dass (a) er sämtliche Lizenzen, Genehmigungen und Vollmachten besitzt, die er benötigt, um seinen Service zur Buchung über die Plattform anzubieten und seine Verpflichtungen im Rahmen dieser Bedingungen einzuhalten, (b) er Inhaber sämtlicher Rechte an geistigem Eigentum ist, die über den Anbieter-Account hochgeladen wurden, bzw. dass der Inhaber dieser Rechte seine Einwilligung erteilt hat, dies auf der Plattform hochzuladen und die Rechte über die Plattform zu lizenzieren.',
    targetLanguage:
      "Oltre a qualsiasi altra assicurazione e garanzia fornita dal Fornitore nei presenti Termini, il Fornitore assicura e garantisce con la presente che (a) detiene tutte le licenze, i permessi e le autorizzazioni necessarie per fornire il proprio Servizio di Prenotazione tramite la Piattaforma e per rispettare i propri obblighi ai sensi dei presenti Termini, (b) è il proprietario di tutti i Diritti di Proprietà Intellettuale caricati tramite l'Account del Fornitore, o che il proprietario di tali diritti ha dato il consenso a caricarli sulla Piattaforma e a concedere in licenza i diritti tramite la Piattaforma.",
  },
  {
    german: '13. Beschränkung der Haftung von Schneider Electric',
    targetLanguage: '13. Limitazione di responsabilità Schneider Electric',
    subSection: true,
  },
  {
    german: '13.1',
    targetLanguage: '13.1',
    bold: true,
  },
  {
    german:
      'Schneider Electric macht keine Zusicherungen und übernimmt keine Garantien im Hinblick auf die Plattform, wie z. B. für eine vorübergehende oder dauerhafte Unterbrechung des Betriebs der Plattform.',
    targetLanguage:
      "Schneider Electric non fornisce alcuna garanzia e non si assume alcuna assicurazione in relazione alla Piattaforma, ad esempio per l'interruzione temporanea o permanente del funzionamento della Piattaforma.",
  },
  {
    german: '13.2',
    targetLanguage: '13.2',
    bold: true,
  },
  {
    german:
      'Schneider Electric trägt kein Risiko hinsichtlich der Anzahl, der Häufigkeit oder der Art von Servicebuchungen über die Plattform.',
    targetLanguage:
      'Schneider Electric non si assume alcun rischio in merito al numero, alla frequenza o alla natura delle Prenotazioni dei Servizi effettuate tramite la Piattaforma.',
  },
  {
    german: '13.3',
    targetLanguage: '13.3',
    bold: true,
  },
  {
    german:
      'Die Haftung von Schneider Electric im Rahmen der Bedingungen ist wie folgt eingeschränkt: Die Schneider Electric haftet für Schäden, die durch die Schneider Electric oder deren Erfüllungs- und Verrichtungsgehilfen durch Vorsatz oder grobe Fahrlässigkeit verursacht wurden.',
    targetLanguage:
      'La responsabilità di Schneider Electric ai sensi dei Termini e Condizioni è limitata come segue: Schneider Electric sarà responsabile per i danni causati da Schneider Electric o dai suoi ausiliari o dipendenti per dolo o grave negligenza.',
  },
  {
    german:
      'Für einfache Fahrlässigkeit haftet die Schneider Electric nur bei Verletzung einer wesentlichen Vertragspflicht und nur für vorhersehbare und typische Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.',
    targetLanguage:
      "Schneider Electric sarà responsabile solo per semplice negligenza in caso di violazione di un obbligo contrattuale sostanziale e solo per danni prevedibili e tipici. Gli obblighi contrattuali sostanziali sono quelli il cui adempimento è un prerequisito per la corretta esecuzione del contratto e sulla cui conformità l'utente può regolarmente fare affidamento.",
  },
  {
    german:
      'Haftungsbeschränkungen gelten nicht im Rahmen abgegebener Garantien, bei Verletzungen des Lebens, des Körpers und der Gesundheit sowie für Ansprüche aus dem Produkthaftungsgesetz.',
    targetLanguage:
      "Le limitazioni di responsabilità non si applicano nell'ambito delle garanzie fornite, in caso di lesioni alla vita, lesioni corporali o lesioni alla salute, nonché per richieste derivanti dalla legge sulla responsabilità del produttore.",
  },
  {
    german: '13.4',
    targetLanguage: '13.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet für einen Datenverlust nur bis zur Höhe der typischen Wiederherstellungskosten, die entstehen würden, wenn ordnungsgemäß und regelmäßig Datensicherungsmaßnahmen ergriffen worden wären.',
    targetLanguage:
      "Schneider Electric sarà responsabile per la perdita di dati solo fino all'importo dei costi di ripristino tipici che sarebbero stati sostenuti se fossero state adottate misure di backup dei dati adeguate e regolari.",
  },
  {
    german: '14. Laufzeit und Kündigung/Beendigung',
    targetLanguage: '14. Durata del contratto e risoluzione',
    subSection: true,
  },
  {
    german: '14.1',
    targetLanguage: '14.1',
    bold: true,
  },
  {
    german: 'Der Vertrag wird auf unbestimmte Zeit geschlossen.',
    targetLanguage: 'Il contratto è concluso a tempo indeterminato.',
  },
  {
    german: '14.2',
    targetLanguage: '14.2',
    bold: true,
  },
  {
    german:
      'Der Anbieter und Schneider Electric sind berechtigt, den Vertrag jederzeit und ohne Angabe von Gründen zu kündigen. Die verbleibenden bestätigten Buchungen müssen wie geplant ausgeführt werden.',
    targetLanguage:
      'Il Fornitore e Schneider Electric avranno il diritto di recedere dal Contratto in qualsiasi momento e senza indicarne i motivi. Le restanti prenotazioni confermate dovranno essere eseguite come pianificato.',
  },
  {
    german: '14.3',
    targetLanguage: '14.3',
    bold: true,
  },
  {
    german:
      'Wenn konkrete Hinweise darauf vorliegen, dass ein Anbieter schuldhaft gegen rechtliche Bestimmungen, Rechte Dritter oder diese Bedingungen verstößt, oder wenn wir ansonsten ein legitimes Interesse haben, insbesondere im Hinblick auf den Schutz der Kunden und anderer Anbieter vor betrügerischen Aktivitäten, sind wir berechtigt, unter angemessener Berücksichtigung der jeweiligen Interessen des Anbieters in dem betreffenden Fall eine oder mehrere der folgenden Maßnahmen zu ergreifen:',
    targetLanguage:
      'Se vi sono prove concrete che un Fornitore viola colpevolmente le disposizioni di legge, i diritti di terzi o i presenti Termini e Condizioni, o se abbiamo un legittimo interesse, in particolare per quanto riguarda la protezione dei Clienti e degli altri Fornitori da attività fraudolente, avremo il diritto di adottare una o più delle seguenti misure, tenendo debitamente conto dei rispettivi interessi del Fornitore nel caso in questione:',
  },
  {
    german: `
    <ul>
      <li>Den Anbieter warnen;</li>
      <li>Die Angebote des Anbieters oder sonstigen Inhalt löschen;</li>
      <li>Die Nutzung der Website durch den Anbieter einschränken;</li>
      <li>Den Anbieter vorübergehend oder dauerhaft von der Plattform ausschließen (sperren);</li>
      <li>Bestehende Buchungen auf den Namen des Anbieters ungeachtet der geltenden Stornierungsrichtlinien zu stornieren und die Angebote der Kunden zum Abschluss eines Vertrags im Namen des Anbieters ablehnen.,</li>
      </ul>
      `,
    targetLanguage: `
      <ul>
        <li>Avvertire il Fornitore;</li>
        <li>Eliminare le inserzioni o altri contenuti del Fornitore;</li>
        <li>Limitare l'utilizzo del Sito Web da parte del Fornitore;</li>
        <li>Escludere temporaneamente o permanentemente (bloccare) il Fornitore dalla Piattaforma;</li>
        <li>Annullare le prenotazioni esistenti a nome del Fornitore, nonostante la politica di cancellazione applicabile, e rifiutare le offerte dei Clienti di stipulare un contratto a nome del Fornitore.</li>
      </ul>
      `,
    indented: true,
  },
  {
    german: '15. Änderung',
    targetLanguage: '15. Emendamenti',
    subSection: true,
  },
  {
    german:
      'Schneider Electric behält sich das Recht vor, die Bestimmungen dieses Vertrags jederzeit zu ändern und neue oder zusätzliche Bestimmungen einzuführen. Schneider Electric wird den Anbieter über etwaige Änderungen an diesem Vertrag per E-Mail informieren. Die geänderten Bedingungen werden dem Anbieter per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesendet. Widerspricht der Anbieter der Geltung der neuen Bedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail, gelten die geänderten Bedingungen als angenommen. Schneider Electric wird den Anbieter in der E-Mail, die die geänderten Bedingungen enthält, auf die Bedeutung dieser Zweiwochenfrist gesondert hinweisen.',
    targetLanguage:
      "Schneider Electric si riserva il diritto di modificare le disposizioni del presente Contratto in qualsiasi momento e di introdurre nuove o ulteriori disposizioni. Schneider Electric notificherà al Fornitore eventuali modifiche al presente Contratto tramite e-mail. I Termini e Condizioni modificati dovranno essere inviati al Fornitore tramite e-mail non oltre due settimane prima della loro entrata in vigore. Qualora il Fornitore non si opponga alla validità dei nuovi Termini e Condizioni entro due settimane dalla ricezione dell'e-mail, i Termini e Condizioni modificati saranno considerati accettati. Schneider Electric informerà separatamente gli Utenti del significato di questo periodo di due settimane nell'e-mail contenente i Termini e Condizioni modificati.",
  },
  {
    german: '16. Schlussbestimmung',
    targetLanguage: '16. Disposizione finale',
    subSection: true,
  },
  {
    german: '16.1',
    targetLanguage: '16.1',
    bold: true,
  },
  {
    german:
      'Nebenabreden wurden nicht getroffen. Alle Änderungen und Ergänzungen zu diesem Vertrag müssen schriftlich erfolgen, um rechtswirksam zu sein.',
    targetLanguage:
      'Non sono stati stipulati accordi accessori. Tutte le modifiche e le aggiunte al presente Contratto devono essere effettuate per iscritto per essere legalmente efficaci.',
  },
  {
    german: '16.2',
    targetLanguage: '16.2',
    bold: true,
  },
  {
    german:
      'Falls einzelne Bestimmungen dieses Vertrags ungültig oder nichtig sind oder werden, bleibt die Gültigkeit der übrigen Vertragsteile hiervon unberührt.',
    targetLanguage:
      'La validità delle restanti parti del contratto non sarà pregiudicata nel caso in cui singole disposizioni del presente contratto siano o diventino invalide o nulle.',
  },
  {
    german: '16.3',
    targetLanguage: '16.3',
    bold: true,
  },
  {
    german:
      'Dieser Vertrag und alle Ansprüche aus oder im Zusammenhang mit diesem Vertrag unterliegen unter Ausschluss der Regeln des internationalen Privatrechts deutschem Recht und sind diesem Recht gemäß auszulegen. Ausschließlicher Gerichtsstand ist Berlin, soweit der Anbieter Kaufmann im Sinne des Handelsgesetzbuches ist oder bei Klageerhebung keinen festen Wohnsitz in Deutschland hat. Gesetzlich zwingende Gerichtsstände bleiben unberührt.',
    targetLanguage:
      "Il presente Contratto e tutti i reclami derivanti da o in relazione al presente Contratto saranno disciplinati e interpretati in conformità con la legge tedesca, escluse le norme del diritto internazionale privato. Il foro competente esclusivo è Berlino, nella misura in cui il Fornitore è un commerciante ai sensi del Codice commerciale tedesco o non ha una residenza fissa in Germania al momento in cui viene intentata l'azione. I fori competenti obbligatori statutari rimangono inalterati.",
  },
];

const TermsConditionsForInstallerIt: React.FC = () => {
  return (
    <div className={styles.container}>
      <BiLingualTable columns={columns} dataSource={privacyData} />
    </div>
  );
};

export default TermsConditionsForInstallerIt;
