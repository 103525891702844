import { Form, FormInstance } from 'antd';
import { IProduct } from 'store/product/product.types';
import { IOfferDocumentBundle, IOfferDocumentForm } from './OfferDocumentGenerator';

interface IOfferDocumentCosts {
  net: number;
  vat: number;
  gross: number;
}
export interface IOfferDocumentTotalCosts {
  hardware: IOfferDocumentCosts;
  installation: IOfferDocumentCosts;
  total: IOfferDocumentCosts;
}
const useCalculateCosts = (
  form: FormInstance<IOfferDocumentForm>,
  vat: number,
): IOfferDocumentTotalCosts => {
  const productsWatcher: IProduct[] = Form.useWatch('products', form);
  const bundle: IOfferDocumentBundle = Form.useWatch('bundle', form);

  const productsTotalPrice = productsWatcher?.reduce(
    (acc: number, currentProduct) => acc + +(currentProduct.netPrice || 0),
    0,
  );
  const productsVat = productsTotalPrice * (vat / 100);
  const productsGross = productsTotalPrice + productsVat;
  const bundlesTotalPrice = bundle?.components?.reduce(
    (componentsTotal, currentBundle) =>
      componentsTotal +
      (currentBundle?.items?.reduce(
        (itemsTotal, currentItem) =>
          itemsTotal + +(currentItem?.price || 0) * +(currentItem?.quantity || 0),
        0,
      ) || 0),
    0,
  );
  const bundlesVat = bundlesTotalPrice * (vat / 100);
  const bundlesGross = bundlesTotalPrice + bundlesVat;
  const totalCostNet = productsTotalPrice + bundlesTotalPrice;
  const totalCostVat = productsVat + bundlesVat;
  const totalCostGross = productsGross + bundlesGross;
  return {
    hardware: {
      net: productsTotalPrice,
      vat: productsVat,
      gross: productsGross,
    },
    installation: {
      net: bundlesTotalPrice,
      vat: bundlesVat,
      gross: bundlesGross,
    },
    total: {
      net: totalCostNet,
      vat: totalCostVat,
      gross: totalCostGross,
    },
  };
};
export default useCalculateCosts;
