import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Switch,
  Typography,
  Upload,
} from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import FormRadioControl from 'components/form/components/FormRadioControl';
import { ValidationFactory } from 'framework/validations';
import { countriesListSelector } from 'store/country/country.selectors';
import { countriesGet } from 'store/country/country.actions';
import {
  installationWizardCustomImageUrlsSelector,
  poolFilesSelector,
  poolsSelector,
} from 'store/intermediate/intermediate.selectors';
import {
  getInstallationWizardCustomImageUrls,
  poolFileUpload,
  poolFilesGet,
  poolUpdate,
  poolsGet,
} from 'store/intermediate/intermediate.actions';
import { IPool } from 'store/intermediate/intermediate.types';
import { IDocument, MediaCategoryType } from 'store/client-request/client-request.types';
import {
  InstallationSelectionModeOptionsType,
  PoolSettingsFieldsType,
  RequestValidationOptionsType,
} from 'types/pool-settings.types';
import {
  HomeCheckOfferApprovalTooltip,
  InstallationOfferApprovalTooltip,
  InstallationOfferInvoiceTooltip,
  InstallationSelectionModeTooltip,
  RequestSettingTooltip,
  RequestValidityTooltip,
} from './tooltips';
import styles from './GeneralSettings.module.sass';
import SurveyToggle from '../components/SurveyToggle';
import CustomPoolSettings from '../CustomPoolSettings';

export interface IGeneralSettingsProps {
  form?: FormInstance<any>;
  isCreating?: boolean;
  pool?: IPool;
  isSurveyDisabled?: boolean;
  isSurvey?: boolean;
  isParent?: boolean;
}

enum ParentPoolModificationState {
  AddParent,
  RemoveParent,
  None,
}

enum SetParentPoolAction {
  OK,
  Cancel,
}

const locizeKey = 'intermediate:myProjects:poolSettings';

const GeneralSettings: React.FC<IGeneralSettingsProps> = ({
  form,
  isCreating,
  pool,
  isSurveyDisabled,
  isSurvey,
  isParent,
}) => {
  const [loading, setLoading] = useState(false);
  const [wizardCustomImagesUploadState, setWizardCustomImagesUploadState] = useState({
    isUploadingWallboxOnly: false,
    isUploadingWalboxAndInstallationLoading: false,
  });
  const [isParentPool, setParentPool] = useState(isParent);
  const poolFiles = useSelector(poolFilesSelector);
  const installationWizardCustomImageUrls = useSelector(installationWizardCustomImageUrlsSelector);
  const [selectedParent, setSelectedParent] = useState<string | null>(pool?.parentId ?? null);
  const [parentPoolModificationState, setParentPoolModificationState] =
    useState<ParentPoolModificationState>(ParentPoolModificationState.None);
  const previousParent = useRef<string | null>(pool?.parentId ?? null);
  const { t } = useTranslation();
  const countries = useSelector(countriesListSelector);
  const pools = useSelector(poolsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countriesGet());
    dispatch(poolsGet({ isParent: true }));

    return () => {
      dispatch(poolUpdate());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const installationWizardWallboxOnlyCustomImageFileName = poolFiles.find(
      (file: IDocument) => file.category === MediaCategoryType.RequestWallboxOnly,
    )?.name;
    const installationWizardWallboxAndInstallationCustomImageFileName = poolFiles.find(
      (file: IDocument) => file.category === MediaCategoryType.RequestWallboxAndInstallation,
    )?.name;
    dispatch(
      getInstallationWizardCustomImageUrls({
        wallboxOnlyFileName: installationWizardWallboxOnlyCustomImageFileName,
        wallboxAndInstallationFileName: installationWizardWallboxAndInstallationCustomImageFileName,
      }),
    );
  }, [poolFiles, dispatch]);

  const handleSearch = debounce((searchValue: string) => {
    setLoading(true);
    dispatch(poolsGet({ isParent: true, query: searchValue }, () => setLoading(false)));
  }, 500);

  const countriesOptions = useMemo(
    () =>
      countries?.map((country) => ({
        value: country.iso31661Alpha3,
        label: t(`common:country:${country.iso31661Alpha3}`),
      })),
    [countries, t],
  );

  const poolsOptions: Array<{ label: string; value: string }> = useMemo(
    () =>
      pools
        .filter((item: IPool) => item.id !== pool?.id)
        .map((item: IPool) => ({ value: item.id, label: item.name })),
    [pools, pool?.id],
  );

  const getApprovalOptions = (key: string) =>
    ['replaceByIntermediate', 'payByIntermediate'].map((option) => ({
      value: option,
      label: t(`${locizeKey}:${key}:${option}`),
    }));

  const handleSetParentPool = (action: SetParentPoolAction) => {
    switch (parentPoolModificationState) {
      case ParentPoolModificationState.AddParent:
        switch (action) {
          case SetParentPoolAction.OK:
            previousParent.current = selectedParent;
            // Do nothing
            break;
          case SetParentPoolAction.Cancel:
            form?.setFieldValue('parentId', previousParent?.current);
            setSelectedParent(previousParent?.current ?? null);
            break;
        }
        break;
      case ParentPoolModificationState.RemoveParent:
        switch (action) {
          case SetParentPoolAction.OK:
            form?.setFieldValue('parentId', null);
            setSelectedParent(null);
            previousParent.current = null;
            break;
          case SetParentPoolAction.Cancel:
            // Do nothing
            break;
        }
        break;
    }
    setParentPoolModificationState(ParentPoolModificationState.None);
  };

  const parentSetConfirmationModal: {
    title: string;
    content: string;
    okBtnContent: string;
    cancelBtnContent: string;
  } = useMemo(() => {
    switch (parentPoolModificationState) {
      case ParentPoolModificationState.AddParent:
        return {
          title: t(`${locizeKey}:addParentPool`),
          content: t(`${locizeKey}:addParentPoolContent`),
          okBtnContent: t(`${locizeKey}:addParentPoolOkBtn`),
          cancelBtnContent: t('common:buttons:cancel'),
        };
      case ParentPoolModificationState.RemoveParent:
        return {
          title: t(`${locizeKey}:removeParentPool`),
          content: t(`${locizeKey}:removeParentPoolContent`),
          okBtnContent: t(`${locizeKey}:removeParentPoolOkBtn`),
          cancelBtnContent: t('common:buttons:cancel'),
        };
      default:
        return { title: '', content: '', okBtnContent: '', cancelBtnContent: '' };
    }
  }, [parentPoolModificationState, t]);

  const doesPoolHaveChildren = useMemo(
    () =>
      isParentPool &&
      pools.find((item: IPool) => item.id === pool?.id)?.childClientRequestPool?.length > 0,
    [isParentPool, pool?.id, pools],
  );

  const renderParentPoolSwitch = () => (
    <Form.Item
      name={PoolSettingsFieldsType.IsParent}
      label={t(`intermediate:myProjects:createPool:${PoolSettingsFieldsType.IsParent}`)}
      valuePropName="checked"
    >
      <Switch
        onChange={(parentPoolStatus) => setParentPool(parentPoolStatus)}
        disabled={doesPoolHaveChildren}
      />
    </Form.Item>
  );

  const installationWizardCustomImages = [
    {
      title: t('intermediate:myProjects:editProjectPools:wallboxOnlyImage'),
      imgSrc: installationWizardCustomImageUrls.wallboxOnly,
      btnLabel: t('intermediate:myProjects:editProjectPools:uploadImage'),
      loading: wizardCustomImagesUploadState.isUploadingWallboxOnly,
      handleUpload: ({ file, onSuccess, onError }: UploadRequestOption) => {
        setWizardCustomImagesUploadState((prev) => ({ ...prev, isUploadingWallboxOnly: true }));
        dispatch(
          poolFileUpload(
            file as File,
            pool!.id,
            MediaCategoryType.RequestWallboxOnly,
            null,
            onSuccess,
            onError,
            () => dispatch(poolFilesGet(pool!.id, MediaCategoryType.RequestWallboxOnly)),
          ),
        );
        setWizardCustomImagesUploadState((prev) => ({ ...prev, isUploadingWallboxOnly: false }));
      },
    },
    {
      title: t('intermediate:myProjects:editProjectPools:wallboxAndInstallationImage'),
      imgSrc: installationWizardCustomImageUrls.wallboxAndInstallation,
      btnLabel: t('intermediate:myProjects:editProjectPools:uploadImage'),
      loading: wizardCustomImagesUploadState.isUploadingWalboxAndInstallationLoading,
      handleUpload: ({ file, onSuccess, onError }: UploadRequestOption) => {
        setWizardCustomImagesUploadState((prev) => ({
          ...prev,
          isUploadingWalboxAndInstallationLoading: true,
        }));
        dispatch(
          poolFileUpload(
            file as File,
            pool!.id,
            MediaCategoryType.RequestWallboxAndInstallation,
            null,
            onSuccess,
            onError,
            () => dispatch(poolFilesGet(pool!.id, MediaCategoryType.RequestWallboxAndInstallation)),
          ),
        );
        setWizardCustomImagesUploadState((prev) => ({
          ...prev,
          isUploadingWalboxAndInstallationLoading: false,
        }));
      },
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col span={18}>
        {!isCreating && (
          <>
            <Form.Item name={PoolSettingsFieldsType.Name} label={t(`${locizeKey}:placeholder`)}>
              <Input />
            </Form.Item>
            <Typography.Title level={5}>
              {t('intermediate:myProjects:createPool:poolType')}
            </Typography.Title>
            <Row align="bottom">
              <Col span={5}>
                <Form.Item
                  name={PoolSettingsFieldsType.PoolType}
                  label={t('intermediate:myProjects:createPool:survey')}
                >
                  <SurveyToggle disabled={isSurveyDisabled} />
                </Form.Item>
              </Col>
              <Col span={5}>
                {doesPoolHaveChildren ? (
                  <Popover content={t(`${locizeKey}:cantChangeParent`)}>
                    {renderParentPoolSwitch()}
                  </Popover>
                ) : (
                  renderParentPoolSwitch()
                )}
              </Col>
              {!isParentPool && (
                <Col span={14}>
                  <Row gutter={8} align="top">
                    <Col span={18}>
                      <Modal
                        open={parentPoolModificationState !== ParentPoolModificationState.None}
                        title={parentSetConfirmationModal.title}
                        onOk={() => handleSetParentPool(SetParentPoolAction.OK)}
                        onCancel={() => handleSetParentPool(SetParentPoolAction.Cancel)}
                        footer={[
                          <Button
                            key="submit"
                            type="primary"
                            onClick={() => handleSetParentPool(SetParentPoolAction.OK)}
                          >
                            {parentSetConfirmationModal.okBtnContent}
                          </Button>,
                          <Button
                            key="back"
                            onClick={() => handleSetParentPool(SetParentPoolAction.Cancel)}
                          >
                            {parentSetConfirmationModal.cancelBtnContent}
                          </Button>,
                        ]}
                      >
                        {parentSetConfirmationModal.content}
                      </Modal>
                      <Form.Item
                        name={PoolSettingsFieldsType.ParentId}
                        rules={isParentPool ? [] : [ValidationFactory.REQUIRED]}
                      >
                        <Select
                          onChange={(value) => {
                            setSelectedParent(value);
                            setParentPoolModificationState(ParentPoolModificationState.AddParent);
                          }}
                          showSearch
                          onSearch={handleSearch}
                          loading={loading}
                          filterOption={false}
                          options={poolsOptions}
                          placeholder={t('intermediate:myProjects:createPool:parentPlaceholder')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Button
                        danger
                        type="primary"
                        size="small"
                        disabled={selectedParent === null}
                        onClick={() =>
                          setParentPoolModificationState(ParentPoolModificationState.RemoveParent)
                        }
                      >
                        {t('common:buttons:remove')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </>
        )}
        {!isSurvey && (
          <>
            <Form.Item
              name={PoolSettingsFieldsType.RequireRequestConfirmation}
              label={t(`${locizeKey}:${PoolSettingsFieldsType.RequireRequestConfirmation}:title`)}
              labelAlign="left"
              tooltip={<RequestSettingTooltip t={t} />}
            >
              <FormRadioControl
                options={[
                  {
                    value: true,
                    label: `${locizeKey}:requireRequestConfirmation:yes`,
                  },
                  {
                    value: false,
                    label: `${locizeKey}:requireRequestConfirmation:no`,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={PoolSettingsFieldsType.RequestValidationSelectionMode}
              label={t(`${locizeKey}:requireRequestConfirmation:requestSubtitle`)}
              tooltip={<RequestValidityTooltip t={t} />}
              labelAlign="left"
            >
              <FormRadioControl
                options={[
                  {
                    value: RequestValidationOptionsType.Default,
                    label: t(`${locizeKey}:requireRequestConfirmation:unlimited`),
                  },
                  {
                    value: RequestValidationOptionsType.Manual,
                    label: t(`${locizeKey}:requireRequestConfirmation:manual`),
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              dependencies={[PoolSettingsFieldsType.RequestValidationSelectionMode]}
              noStyle
            >
              {({ getFieldValue }) => {
                const isManualSelectionMode =
                  getFieldValue(PoolSettingsFieldsType.RequestValidationSelectionMode) ===
                  RequestValidationOptionsType.Manual;

                return (
                  <Form.Item
                    name={PoolSettingsFieldsType.RequestValidity}
                    label={t(`${locizeKey}:requireRequestConfirmation:days`)}
                    labelAlign="left"
                    rules={isManualSelectionMode ? [ValidationFactory.REQUIRED] : []}
                    hidden={!isManualSelectionMode}
                  >
                    <InputNumber />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Typography.Title level={5}>
              {t(`${locizeKey}:homeCheckOfferApproval:title`)}
            </Typography.Title>
            <Form.Item
              name={PoolSettingsFieldsType.HomeCheckOfferApproval}
              label={t(
                `${locizeKey}:${PoolSettingsFieldsType.HomeCheckOfferApproval}:homeCheckSubtitle`,
              )}
              labelAlign="left"
              tooltip={<HomeCheckOfferApprovalTooltip t={t} />}
            >
              <FormRadioControl
                options={getApprovalOptions(PoolSettingsFieldsType.HomeCheckOfferApproval)}
              />
            </Form.Item>
            <Form.Item
              name={PoolSettingsFieldsType.InstallationOfferApproval}
              label={t(`${locizeKey}:${PoolSettingsFieldsType.InstallationOfferApproval}:title`)}
              labelAlign="left"
              tooltip={<InstallationOfferApprovalTooltip t={t} />}
            >
              <FormRadioControl
                options={getApprovalOptions(PoolSettingsFieldsType.InstallationOfferApproval)}
              />
            </Form.Item>
            <Form.Item
              name={PoolSettingsFieldsType.OfferMinimumValidity}
              label={t(`${locizeKey}:offerRequestConfirmation:offerValidity`)}
              labelAlign="left"
              wrapperCol={{ span: 18 }}
              rules={[ValidationFactory.REQUIRED, ValidationFactory.MIN_DAYS('14')]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name={PoolSettingsFieldsType.InstallationInvoiceApproval}
              label={t(`${locizeKey}:${PoolSettingsFieldsType.InstallationInvoiceApproval}:title`)}
              tooltip={<InstallationOfferInvoiceTooltip t={t} />}
            >
              <FormRadioControl
                options={getApprovalOptions(PoolSettingsFieldsType.InstallationInvoiceApproval)}
              />
            </Form.Item>
          </>
        )}
      </Col>
      <Col span={6}>{pool?.id && <CustomPoolSettings pool={pool} />}</Col>
      <Col span={24}>
        <Form.Item
          label={t(`${locizeKey}:installationSelectionMode:title`)}
          tooltip={<InstallationSelectionModeTooltip t={t} />}
          labelAlign="left"
          name={PoolSettingsFieldsType.InstallationSelectionMode}
          rules={[ValidationFactory.REQUIRED]}
        >
          <FormRadioControl
            options={[
              {
                value: InstallationSelectionModeOptionsType.Unrestricted,
                label: t(`${locizeKey}:installationSelectionMode:unrestricted`),
              },
              {
                value: InstallationSelectionModeOptionsType.Range,
                label: t(`${locizeKey}:installationSelectionMode:range:range1`),
              },
            ]}
          />
        </Form.Item>
        <Form.Item dependencies={[PoolSettingsFieldsType.InstallationSelectionMode]} noStyle>
          {({ getFieldValue }) => {
            const isModeRange =
              getFieldValue(PoolSettingsFieldsType.InstallationSelectionMode) ===
              InstallationSelectionModeOptionsType.Range;

            const fields = [
              PoolSettingsFieldsType.AppointmentFrom,
              PoolSettingsFieldsType.AppointmentUntil,
            ];
            return fields.map((field) => (
              <Form.Item
                rules={isModeRange ? [ValidationFactory.REQUIRED] : []}
                key={field}
                name={field}
                hidden={!isModeRange}
                label={t(`${locizeKey}:${field}:title`)}
                labelAlign="left"
              >
                <InputNumber />
              </Form.Item>
            ));
          }}
        </Form.Item>
        <Typography.Title level={5}>{t(`${locizeKey}:customerJourney:title`)}</Typography.Title>
        <Form.Item
          name={PoolSettingsFieldsType.WizardWallboxJourney}
          valuePropName="checked"
          noStyle
        >
          <Checkbox>{t(`${locizeKey}:customerJourney:options:wallbox`)}</Checkbox>
        </Form.Item>
        <Form.Item name={PoolSettingsFieldsType.WizardInstallationJourney} valuePropName="checked">
          <Checkbox>{t(`${locizeKey}:customerJourney:options:installation`)}</Checkbox>
        </Form.Item>
      </Col>
      {!isCreating && (
        <Col span={24}>
          <Flex justify="space-around" align="center" gap="large">
            {installationWizardCustomImages.map((customImage) => (
              <div className={styles.installationWizardImageContainer} key={customImage.title}>
                <Typography.Paragraph className={styles.smallTitle}>
                  {customImage.title}
                </Typography.Paragraph>

                <div className={styles.imageContainer}>
                  {customImage.imgSrc ? (
                    <img
                      className={styles.avatar}
                      src={customImage.imgSrc}
                      alt={customImage.title}
                    />
                  ) : (
                    <Skeleton.Image className={styles.avatar} />
                  )}
                </div>

                <ImgCrop
                  zoomSlider={false}
                  quality={1}
                  modalWidth={600}
                  aspectSlider={false}
                  aspect={2.05}
                >
                  <Upload
                    maxCount={1}
                    showUploadList={false}
                    customRequest={customImage.handleUpload}
                    accept=".png, .jpg, .jpeg"
                    className={styles.installationWizardUpload}
                  >
                    <Button
                      size="small"
                      type="primary"
                      loading={customImage.loading}
                      icon={<UploadOutlined />}
                    >
                      {customImage.btnLabel}
                    </Button>
                  </Upload>
                </ImgCrop>
              </div>
            ))}
          </Flex>
        </Col>
      )}
      <Col span={24}>
        <Form.Item name={PoolSettingsFieldsType.Country} rules={[ValidationFactory.REQUIRED]}>
          <Select options={countriesOptions} virtual={false} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default GeneralSettings;
