import config from 'config';
import HttpService from './http.service';

class UserAPI extends HttpService {
  static USERS_API = 'users';

  getUser = () => {
    return this.get(`${UserAPI.USERS_API}/me`);
  };

  updateUserProfile = (fields: any) => {
    return this.put(`${UserAPI.USERS_API}/me`, { ...fields, context: 'tendergy' });
  };

  deleteAccount = () => {
    return this.delete(`${UserAPI.USERS_API}/me`);
  };

  getUserEmailStatus = () => this.get(`${UserAPI.USERS_API}/me/email_status`);

  verifyPasswordResetToken = (token: string) => {
    return this.get(`${UserAPI.USERS_API}/pool-organization/${token}`);
  };
}

export default new UserAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
