import React, { useEffect, useCallback, useState, useMemo } from 'react';

import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row, Typography } from 'antd';

import Button, { ButtonColor } from 'components/ui/Button/Button';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import BackButton from 'components/ui/BackButton/BackButton';
import DropDown from 'components/ui/DropDown/DropDown';
import {
  currentClientRequestCurrencySymbolSelector,
  currentClientRequestSelector,
  currentClientRequestVatRateSelector,
} from 'store/client-request/client-request.selectors';
import { getOfferFiles, offerCreate, offerGet, offerTransition } from 'store/offer/offer.actions';
import { clientRequestGet } from 'store/client-request/client-request.actions';
import { MediaCategoryType } from 'store/client-request/client-request.types';
import { getVendors } from 'store/product/product.actions';
import { currentOfferSelector } from 'store/offer/offer.selectors';
import { ICountry } from 'store/intermediate/intermediate.types';
import { IOffer, OfferEventType } from 'store/offer/offer.types';
import { IOption } from 'store/common.types';

import { percentFilter, removeThousandSeparator } from 'utils/currencyUtils';
import { checkIsHomeCheck } from 'utils/offerHelpers';

import InstallationDocumentationPage from './InstallationDocumentationPage';
import HomeCheckDocumentationPage from './HomeCheckDocumentationPage';
import IntermediateDetailsWidget from '../TenderParticipatePage/IntermediateDetailsWidget';
import styles from '../TenderParticipatePage/TenderParticipate.module.sass';

interface IRouteParams {
  id?: string;
}

export enum DocumentType {
  INVOICE = 'invoice',
  HOMECHECK_INVOICE = 'homecheckInvoice',
  DOCUMENTATION = 'documentation',
  HOMECHECK_DOCUMENTATION = 'homecheckDocumentation',
  UTILITY = 'utilityForm',
  HOMECHECK_PHOTOS = 'homecheckPhotos',
}

const TenderDocumentationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: offerId } = useParams() as IRouteParams;

  const [documentationComment, setDocumentationComment] = useState('');
  const [documentationPrice, setDocumentationPrice] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState({
    invoice: false,
    documentation: false,
    utilityForm: false,
    homecheckInvoice: false,
    homecheckDocumentation: false,
    homecheckPhotos: false,
  });

  const offer = useSelector(currentOfferSelector);

  const isHomeCheck = checkIsHomeCheck(offer?.state!);

  const clientRequest = useSelector(currentClientRequestSelector);
  const vatRate = useSelector(currentClientRequestVatRateSelector)?.toString();
  const currencySymbol = useSelector(currentClientRequestCurrencySymbolSelector);
  const clientRequestId = offer?.clientRequestId;
  const poolId = clientRequest?.pool?.id;

  const countriesOptions = (clientRequest?.pool?.countries || []).reduce(
    (accumulator: IOption[], currentValue: ICountry) => {
      const existed = accumulator.find(
        (option: IOption) => option.value === currentValue.currencySymbol,
      );
      if (!existed) {
        return [
          ...accumulator,
          {
            value: currentValue.currencySymbol,
            label: `${currentValue.currencySymbol} ${currentValue.currency}`,
          },
        ];
      } else {
        return accumulator;
      }
    },
    [],
  );

  useEffect(() => {
    if (offerId) {
      dispatch(getOfferFiles(offerId));
    }
  }, [dispatch, offerId]);

  useEffect(() => {
    if (!offer && offerId) {
      dispatch(offerGet({ id: offerId }));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (poolId) {
      dispatch(getVendors(poolId));
    }
  }, [dispatch, poolId]);

  useEffect(() => {
    if (clientRequestId) {
      dispatch(clientRequestGet(clientRequestId, false));
    }
  }, [clientRequestId, dispatch]);

  const onBackButtonClick = useCallback(() => {
    navigate(`/installer/order/${offerId}/details`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  const onContinueClick = (isProceed?: boolean) => {
    if (!vatRate) return;
    const clearInvoiceAmount = removeThousandSeparator(documentationPrice);
    const payload: any = {
      id: offerId,
      currencySymbol: currencySymbol!,
      initialCurrencySymbol: currencySymbol!,
      vatRate: +vatRate.replace('%', ''),
      initialVatRate: +vatRate.replace('%', ''),
    };
    const amountKey = isHomeCheck ? 'initialInvoiceHomeCheck' : 'initialInvoiceAmount';
    payload[amountKey] = clearInvoiceAmount;

    const event = isHomeCheck
      ? OfferEventType.HOME_CHECK_COMPLETED
      : OfferEventType.INSTALLER_WORK_COMPLETED;
    if (isHomeCheck) {
      dispatch(
        offerTransition(event, payload, () =>
          dispatch(
            offerCreate(
              { clientRequestId },
              (installationOffer: IOffer) =>
                navigate(`/installer/order/${installationOffer.id}/make-offer`),
              () => navigate(`/installer/order/${offerId}/details`),
            ),
          ),
        ),
      );
    } else {
      dispatch(
        offerTransition(
          event,
          {
            ...payload,
            installedProductModel: {
              id: null,
            },
          },
          () => navigate(`/installer/order/${offerId}/details`),
        ),
      );
    }
  };

  const handleDocumentUploaded = (status: boolean, type: DocumentType) =>
    setUploadedFiles((prev) => ({ ...prev, [type]: status }));

  const sectionAnnotationProps = {
    value: documentationComment,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) =>
      setDocumentationComment(e.target.value),
    className: styles.textArea,
  };

  const inputPriceProps = {
    className: styles.inputItem,
    label: t('installerFlow:tenderParticipate:invoices:invoiceAmount'),
    value: documentationPrice,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDocumentationPrice(e.target.value),
  };

  const inputVatProps = {
    isAllowed: percentFilter,
    suffix: '%',
    className: styles.inputItem,
    label: '% VAT',
    value: vatRate,
    disabled: true,
  };

  const allowedDocuments = clientRequest?.pool?.documentsAllowed || [];

  const isInstallationFinalDocumentationRequired = allowedDocuments.includes(
    MediaCategoryType.InstallationFinalDocumentation,
  );
  const isOfferNetworkRegistrationRequired = allowedDocuments.includes(
    MediaCategoryType.OfferNetworkRegistration,
  );

  const isSubmitButtonDisabled = useMemo(() => {
    const edit = !documentationPrice || !vatRate || !currencySymbol;
    if (isHomeCheck) {
      return (
        edit ||
        !uploadedFiles[DocumentType.HOMECHECK_DOCUMENTATION] ||
        !uploadedFiles[DocumentType.HOMECHECK_INVOICE]
      );
    }
    return (
      edit ||
      !uploadedFiles[DocumentType.INVOICE] ||
      (isInstallationFinalDocumentationRequired && !uploadedFiles[DocumentType.DOCUMENTATION]) ||
      (isOfferNetworkRegistrationRequired && !uploadedFiles[DocumentType.UTILITY])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencySymbol, documentationPrice, isHomeCheck, uploadedFiles, vatRate]);

  const installationDocumentationProps = {
    offerId: offerId,
    handleDocumentUploaded,
    allowedDocuments,
  };
  const homeCheckDocumentationProps = {
    handleDocumentUploaded,
    offerId: offerId,
  };

  enum HomeCheckButton {
    continueWithInstallation = 'continueWithInstallation',
    dontMakeInstallation = 'dontMakeInstallation',
  }

  const homecheckDocumentationButtons = [
    {
      key: HomeCheckButton.continueWithInstallation,
      buttonColor: ButtonColor.GREEN,
    },
    { key: HomeCheckButton.dontMakeInstallation, buttonColor: ButtonColor.GREY },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton className={styles.back} onClick={onBackButtonClick}>
          {t('common:buttons:back')}
        </BackButton>
        <Typography.Title level={3} className={styles.title}>
          {t('installerFlow:orderDetails:documentation:topTitle')}
        </Typography.Title>
      </div>
      <IntermediateDetailsWidget clientRequest={clientRequest} />
      <Rectangle title={t('installerFlow:orderDetails:documentation:pageTitle')}>
        <Typography className={styles.text}>
          {t(
            `installerFlow:orderDetails:${
              isHomeCheck ? 'homecheck' : 'installation'
            }:documentation:subTitle`,
          )}
        </Typography>
        {isHomeCheck ? (
          <HomeCheckDocumentationPage {...homeCheckDocumentationProps} />
        ) : (
          <InstallationDocumentationPage {...installationDocumentationProps} />
        )}
        <Row gutter={16} align="middle" className={styles.priceFormRow}>
          <Col span={5}>
            <Typography className={clsx(styles.titleSmall, styles.inputItem)}>
              {t('installerFlow:tenderParticipate:invoices:invoiceAmount')}:
            </Typography>
          </Col>

          <Col span={19}>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <NumericInput {...inputPriceProps} />
              </Col>
              <Col span={4}>
                <NumericInput {...inputVatProps} />
              </Col>
              <Col span={13} />
              <Col span={7}>
                <DropDown
                  value={currencySymbol}
                  placeholder={'Currency'}
                  options={countriesOptions}
                  virtual={false}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Typography className={clsx(styles.titleSmall, styles.margin)}>
          {t('installerFlow:tenderParticipate:sectionAnnotation:subtitle')}
        </Typography>
        <Input.TextArea {...sectionAnnotationProps} />
      </Rectangle>
      <div className={styles.actionButton}>
        {isHomeCheck ? (
          <div>
            {homecheckDocumentationButtons.map((item) => (
              <Button
                key={item.key}
                type={isSubmitButtonDisabled ? 'default' : 'primary'}
                disabled={isSubmitButtonDisabled}
                color={item.buttonColor}
                style={{ marginRight: '10px' }}
                onClick={() =>
                  onContinueClick(item.key === HomeCheckButton.continueWithInstallation)
                }
              >
                {t(`installerFlow:orderDetails:documentation:button:${item.key}`)}
              </Button>
            ))}
          </div>
        ) : (
          <Button
            type={isSubmitButtonDisabled ? 'default' : 'primary'}
            disabled={isSubmitButtonDisabled}
            color={ButtonColor.GREEN}
            onClick={() => onContinueClick()}
          >
            {t('installerFlow:orderDetails:documentation:buttonSubmitBinding')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TenderDocumentationPage;
