import React from 'react';
import { Card, Col, DatePicker, Flex, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { GENDER_OPTIONS } from 'framework/constants';
const OfferDocumentSimpleForm = () => {
  const { t } = useTranslation();
  const locizeKey = 'intermediate:pdfGen';
  return (
    <Flex vertical={true} gap={4}>
      <Card title={t(`${locizeKey}:offer:offerData`)}>
        <Row gutter={4}>
          <Col span={6}>
            <Form.Item
              name={['offer', 'id']}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
              label={t(`${locizeKey}:offer:offerId`)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={['offer', 'date']}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
              label={t(`${locizeKey}:offer:offerDate`)}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['request', 'agent']}
              label={t(`${locizeKey}:offer:agent`)}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title={t(`${locizeKey}:request:title`)}>
        <Row gutter={4}>
          <Col span={8}>
            <Form.Item
              name={['request', 'id']}
              label={t(`${locizeKey}:request:id`)}
              rules={[{ required: true, message: t('common:errors:fieldRequired ') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name={['request', 'poolName']}
              label={t(`${locizeKey}:request:poolName`)}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name={['owner', 'gender']}
              label={t(`${locizeKey}:request:gender`)}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
            >
              <Select
                options={GENDER_OPTIONS.map((option) => ({
                  value: option.value,
                  label: t(option.label),
                }))}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={['owner', 'firstName']}
              label={t(`${locizeKey}:request:firstName`)}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={['owner', 'lastName']}
              label={t(`${locizeKey}:request:lastName`)}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={17}>
            <Form.Item
              name={['owner', 'street']}
              label={t(`${locizeKey}:request:street`)}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item name={['owner', 'no']} required={true} label={t(`${locizeKey}:request:no`)}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={['owner', 'address2']} label={t(`${locizeKey}:request:address`)}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name={['owner', 'zipCode']}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
              label={t(`${locizeKey}:request:zipCode`)}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name={['owner', 'city']}
              rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
              label={t(`${locizeKey}:request:city`)}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Flex>
  );
};
export default OfferDocumentSimpleForm;
