import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { getPoolLegalDocuments } from 'store/intermediate/intermediate.actions';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import {
  CustomDocumentTextType,
  CustomDocumentsCategoryType,
  ICustomDocument,
} from 'store/intermediate/intermediate.types';
import styles from './Footer.module.sass';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inviteTokenData = useSelector(isRegistrationInviteTokenSelector);
  const legalDocuments: ICustomDocument[] = useSelector(legalDocumentsSelector);
  const fetchLegalDocuments = useCallback(() => {
    if (inviteTokenData?.clientRequestPool?.id) {
      dispatch(getPoolLegalDocuments(inviteTokenData.clientRequestPool.id, true));
    }
  }, [inviteTokenData, dispatch]);
  useEffect(() => {
    if (!legalDocuments.length) {
      fetchLegalDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteTokenData]);
  useLanguageChangeCallback(fetchLegalDocuments);

  const privacyPolicy = legalDocuments.find(
    (item) => item?.category === CustomDocumentsCategoryType.PoolPrivacyPolicy,
  );
  const isPrivacyLink = privacyPolicy?.textType === CustomDocumentTextType.LINK;

  const imprint = legalDocuments.find(
    (item) => item?.category === CustomDocumentsCategoryType.PoolImprint,
  );
  const isImprintLink = imprint?.textType === CustomDocumentTextType.LINK;

  const termsAndConditions = legalDocuments.find(
    (item) => item?.category === CustomDocumentsCategoryType.PoolTermsConditions,
  );

  const isTermsLink = termsAndConditions?.textType === CustomDocumentTextType.LINK;

  return (
    <div className={styles.footer}>
      <Link
        to={{
          pathname: isImprintLink ? imprint?.text : '/legal/legal',
        }}
        className={styles.link}
        target={isImprintLink ? '_blank' : '_self'}
      >
        {t('common:texts:legal')}
      </Link>
      <Link
        to={{
          pathname: isPrivacyLink ? privacyPolicy?.text : '/legal/privacy-policy',
        }}
        className={styles.link}
        target={isPrivacyLink ? '_blank' : '_self'}
      >
        {t('common:texts:privacyPolicy')}
      </Link>
      <Link
        to={{
          pathname: isTermsLink ? termsAndConditions?.text : '/legal/terms-and-conditions',
        }}
        className={styles.link}
        target={isTermsLink ? '_blank' : '_self'}
      >
        {t('common:texts:termsAndConditions')}
      </Link>
    </div>
  );
};

export default Footer;
