import React, { createContext } from 'react';

export const CLIENT_REQUEST_OVERVIEW_TAB = 'CLIENT_REQUEST_OVERVIEW_TAB';
export const POOL_OVERVIEW_TAB = 'POOL_OVERVIEW_TAB';

export default createContext<{
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}>({
  activeTab: CLIENT_REQUEST_OVERVIEW_TAB,
  setActiveTab: () => {},
});
