import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Tag } from 'antd';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import Button from 'components/ui/Button/Button';
import Loader from 'components/ui/Loader/Loader';
import { poolTagsSelector } from 'store/intermediate/intermediate.selectors';
import { poolTagsGet } from 'store/intermediate/intermediate.actions';
import { RequestState } from 'store/common.types';
import { IPoolTag } from 'store/intermediate/intermediate.types';
import { clientRequestGet } from 'store/client-request/client-request.actions';
import styles from './ClientRequestTagsWidget.module.scss';
import ManagePoolTags from './ManagePoolTags';

const WIDGET_TITLE_LOCIZE_KEY = `intermediate:tenderDetails:clientRequestTags`;

type ClientRequestTagsWidgetProps = {
  assignedTagsToClientRequest?: Array<IPoolTag>;
  clientRequestId: string;
  poolId: string;
};

const ClientRequestTagsWidget = (props: ClientRequestTagsWidgetProps) => {
  const [shouldShowManageTagsModal, setShowManageTagsModal] = useState(false);
  const poolTagsRaw = useSelector(poolTagsSelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(poolTagsGet({ poolId: props.poolId }));
  }, [dispatch, props.poolId]);

  const poolTagsWithAssignedStatus = useMemo(
    () =>
      poolTagsRaw.tags.map((poolTag) => {
        if (props.assignedTagsToClientRequest && props.assignedTagsToClientRequest?.length < 1) {
          return {
            ...poolTag,
            isAssignedToClientRequest: false,
            isAssignmentLoading: false,
            isLoading: false,
          };
        }
        const isAssignedToClientRequest = props.assignedTagsToClientRequest?.find(
          (item) => item.id === poolTag.id,
        );
        return {
          ...poolTag,
          isAssignedToClientRequest: !!isAssignedToClientRequest,
          isAssignmentLoading: false,
          isLoading: false,
        };
      }),
    [props.assignedTagsToClientRequest, poolTagsRaw.tags],
  );

  return (
    <Rectangle
      className={styles.container}
      title={t(`${WIDGET_TITLE_LOCIZE_KEY}:title`)}
      headingExtra={
        <Button type="primary" size="small" onClick={() => setShowManageTagsModal((prev) => !prev)}>
          {t(`${WIDGET_TITLE_LOCIZE_KEY}:manageTags`)}
        </Button>
      }
    >
      <Flex gap="4px 0" wrap="wrap">
        {props.assignedTagsToClientRequest?.length === 0 && t(`${WIDGET_TITLE_LOCIZE_KEY}:noTags`)}
        {props.assignedTagsToClientRequest?.map((item) => (
          <Tag color={item.colorCode} key={item.id}>
            {item.title}
          </Tag>
        ))}
      </Flex>
      {poolTagsRaw.state === RequestState.Loading ? (
        <Loader />
      ) : (
        shouldShowManageTagsModal && (
          <ManagePoolTags
            clientRequestId={props.clientRequestId}
            handleClose={() => {
              setShowManageTagsModal((prev) => !prev);
              dispatch(poolTagsGet({ poolId: props.poolId }));
              dispatch(clientRequestGet(props.clientRequestId, true));
            }}
            isOpen={shouldShowManageTagsModal}
            poolId={props.poolId}
            poolTagsRaw={poolTagsWithAssignedStatus}
            title={t(`${WIDGET_TITLE_LOCIZE_KEY}:manageTags`)}
          />
        )
      )}
    </Rectangle>
  );
};

export default ClientRequestTagsWidget;
