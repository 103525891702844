type GroupByCallback<T> = (item: T) => any;

export const groupBy = <T>(array: T[], callback: GroupByCallback<T>): Record<string, T[]> => {
  return array.reduce(
    (result, currentItem) => {
      const groupKey = String(callback(currentItem));

      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentItem);

      return result;
    },
    {} as Record<string, T[]>,
  );
};
export const flatten = <T>(array: T[][]): T[] => {
  return array.reduce((flat, current) => flat.concat(current), []);
};
