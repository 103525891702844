import React from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import { acceptOrder } from 'store/orders/orders.actions';
import { OrderConfirmationType } from 'store/orders/orders.types';
import styles from './Accepted.module.sass';

export const OrderAccepted: React.FC = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleAccept = (type: OrderConfirmationType) => {
    if (id) {
      dispatch(
        acceptOrder(id, undefined, type, (offerId: string) =>
          navigate(`/installer/order/${offerId}/details`),
        ),
      );
    }
  };

  const buttons = [
    {
      key: OrderConfirmationType.ConfirmedRemote,
      color: ButtonColor.GREEN,
      title: t('installerFlow:order:accept:remote:title'),
      description: t('installerFlow:order:accept:remote:description'),
    },
    {
      key: OrderConfirmationType.Confirmed,
      color: ButtonColor.BLUE,
      title: t('installerFlow:order:accept:onsite:title'),
      description: t('installerFlow:order:accept:onsite:description'),
    },
  ];
  return (
    <div className={styles.container}>
      <Typography.Title level={4}>{t('common:order:accept:header')}</Typography.Title>

      <Typography.Title level={5}>
        {t('common:order:accept:body1')}
        <br />
        {t('common:order:accept:body2')}
      </Typography.Title>
      <div className={styles.buttons}>
        {buttons.map((button) => (
          <div key={button.key}>
            <Button color={button.color} type="primary" onClick={() => handleAccept(button.key)}>
              {button.title}
            </Button>
            <Typography.Paragraph className={styles.description}>
              {button.description}
            </Typography.Paragraph>
          </div>
        ))}
      </div>
    </div>
  );
};
