import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import Form from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import BoxContentSection from 'components/layout/BoxContentSection/BoxContentSection';
import TermsOfUseModal from 'components/static/TermsOfUseModal/TermsOfUseModal';
import DataProtectionModal from 'components/static/DataProtectionModal/DataProtectionModal';
import TermsConditionsForInstaller from 'components/static/TermsConditionsForInstaller/TermsConditionsForInstaller';
import Loader from 'components/ui/Loader/Loader';
import Box from 'components/layout/Box/Box';
import { PATH } from 'framework/path';
import { registerInstaller } from 'store/register/register.actions';
import { isAuthorizedSelector, isUserFetchingSelector } from 'store/auth/auth.selectors';
import {
  isRegistrationFetchingSelector,
  isRegistrationInviteTokenSelector,
  isTokenValidationLoadingSelector,
} from 'store/register/register.selectors';
import { activeSubStepSelector } from 'store/app/app.selectors';
import { changeStep } from 'store/app/app.actions';
import { InviteTokenType } from 'store/intermediate/intermediate.types';
import { countriesGet } from 'store/country/country.actions';
import { ValidateInviteType } from 'store/register/register.types';
import { validateInviteToken } from 'store/register/register.actions';

import styles from './InstallerRegistrationPage.module.sass';
import LandingStep from './LandingStep';
import PasswordStep from './PasswordStep';
import AddressStep from './AddressStep';
import ContactStep from './ContactStep';
import CompanyStep from './CompanyStep';

const LAST_SUB_STEP: number = 4;
const InstallerRegistrationPage: React.FC = () => {
  const [registrationFields, setRegistrationFields] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsOfUseVisible, setTermsOfUseVisible] = useState(false);
  const [dataProtectionVisible, setDataProtectionVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { token } = useParams();

  const [form] = Form.useForm();

  const isUserFetching = useSelector(isUserFetchingSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const isLoading = useSelector(isRegistrationFetchingSelector);
  const isTokenValidationLoading = useSelector(isTokenValidationLoadingSelector);
  const activeSubStep = useSelector(activeSubStepSelector);
  const inviteTokenData = useSelector(isRegistrationInviteTokenSelector);
  const isInviteTokenData = inviteTokenData?.type !== InviteTokenType.User ? inviteTokenData : null;
  const isPublicInviteManager = inviteTokenData?.type === ValidateInviteType.PublicInstallerManager;
  const activeSubStepNotFirst = activeSubStep > 1;
  const companyAddress = isPublicInviteManager
    ? undefined
    : _get(isInviteTokenData, 'organization.address', undefined);
  const poolCountries = isPublicInviteManager
    ? undefined
    : _get(isInviteTokenData, 'clientRequestPool.countries', undefined);

  const SECTIONS_TITLES = [
    t('installerFlow:registration:sectionTitle1'),
    t('installerFlow:registration:sectionTitle2'),
    t('installerFlow:registration:sectionTitle3'),
    t('installerFlow:registration:sectionTitle4'),
  ];

  useEffect(() => {
    if (!isUserFetching && isAuthorized) {
      navigate(PATH.HOME);
    }

    dispatch(changeStep(1, isInviteTokenData && isInviteTokenData.clientRequestPool ? 0 : 1));
    if (isInviteTokenData?.inviteeData) {
      form.setFieldsValue({
        ...isInviteTokenData?.inviteeData,
      });
    }
  }, [isAuthorized]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !poolCountries && dispatch(countriesGet());
    if (token) {
      dispatch(validateInviteToken(token));
    }
  }, []); // eslint-disable-line

  const onFormSubmit = async () => {
    dispatch(registerInstaller(registrationFields, token!));
  };

  const onNextButtonClick = async () => {
    const values = await form.validateFields();
    const updatesRegistrationFields = _merge({}, registrationFields, values);
    setRegistrationFields(updatesRegistrationFields);

    if (activeSubStep === LAST_SUB_STEP) {
      form.submit();
    } else {
      dispatch(changeStep(1, activeSubStep + 1));
    }
    // dispatch(registerInstaller(data));
  };

  const openTermsOfUse = () => {
    setTermsOfUseVisible(true);
  };

  const openDataProtection = () => {
    setDataProtectionVisible(true);
  };

  const renderContent = () => {
    switch (activeSubStep) {
      case 0:
        return <LandingStep inviteTokenData={isInviteTokenData} />;

      case 1:
        return (
          <BoxContentSection title={SECTIONS_TITLES[0]} containerClassName={styles.section}>
            <CompanyStep token={isPublicInviteManager ? '' : token} />
          </BoxContentSection>
        );

      case 2:
        return (
          <BoxContentSection title={SECTIONS_TITLES[1]} containerClassName={styles.section}>
            <ContactStep />
          </BoxContentSection>
        );

      case 3:
        return (
          <BoxContentSection title={SECTIONS_TITLES[2]} containerClassName={styles.section}>
            <AddressStep
              companyAddress={companyAddress}
              form={form}
              filteredCountries={poolCountries}
            />
          </BoxContentSection>
        );

      case 4:
        return (
          <BoxContentSection title={SECTIONS_TITLES[3]} containerClassName={styles.section}>
            <PasswordStep {...passwordStepProps} />
            <TermsOfUseModal
              open={termsOfUseVisible}
              onOk={() => setTermsOfUseVisible(false)}
              onCancel={() => setTermsOfUseVisible(false)}
              destroyOnClose={true}
              termsComponent={<TermsConditionsForInstaller language={i18n.language} />}
              width={800}
            />
            <DataProtectionModal
              open={dataProtectionVisible}
              onOk={() => setDataProtectionVisible(false)}
              onCancel={() => setDataProtectionVisible(false)}
              destroyOnClose={true}
              width={800}
            />
          </BoxContentSection>
        );

      default:
        return <div>Something went wrong!</div>;
    }
  };

  const prevStep = () => {
    if (activeSubStepNotFirst) {
      dispatch(changeStep(1, activeSubStep - 1));
    }
  };

  const boxProps = {
    title: t('installerFlow:registration:title'),
    boxContentClassName: styles.content,
    showNextButton: !(activeSubStep === LAST_SUB_STEP && !termsChecked) && !!activeSubStep,
    showBackButton: activeSubStepNotFirst,
    nextButtonLoading: isLoading || isTokenValidationLoading,
    onNextClick: onNextButtonClick,
    onBackClick: prevStep,
  };

  const formProps = {
    className: styles.form,
    form: form,
    name: 'request-personal-details-form',
    onFinish: onFormSubmit,
  };

  const passwordStepProps = {
    termsChecked: termsChecked,
    onTermsChange: (value: boolean) => setTermsChecked(value),
    onOpenProtectionModal: openDataProtection,
    onOpenTermsModal: openTermsOfUse,
  };

  return (
    <Box>
      <BoxContent {...boxProps}>
        {!isTokenValidationLoading ? <Form {...formProps}>{renderContent()}</Form> : <Loader />}
      </BoxContent>
    </Box>
  );
};

export default InstallerRegistrationPage;
