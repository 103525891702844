import React, { useContext, useEffect } from 'react';
import { Tabs, TabsProps } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IntermediateActiveTabContext, {
  CLIENT_REQUEST_OVERVIEW_TAB,
  POOL_OVERVIEW_TAB,
} from 'context/intermediateActiveTab.context';
import ClientRequestsOverview from './ManagementDashboard/ClientRequestsOverview';
import ProjectPools from './ProjectPools/ProjectPools';

const IntermediateTabs = () => {
  const { activeTab, setActiveTab } = useContext(IntermediateActiveTabContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    // This is to identify if pool overview tab should be shown at first mount
    if (searchParams.has('poolName')) {
      setActiveTab(POOL_OVERVIEW_TAB);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: TabsProps['items'] = [
    {
      key: CLIENT_REQUEST_OVERVIEW_TAB,
      label: t('intermediate:myProjects:tabs:clientRequestOverview'),
      children: <ClientRequestsOverview />,
      destroyInactiveTabPane: true,
    },
    {
      key: POOL_OVERVIEW_TAB,
      label: t('intermediate:myProjects:tabs:poolOverview'),
      children: <ProjectPools />,
      destroyInactiveTabPane: true,
    },
  ];

  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    setSearchParams({});
  };

  return (
    <Tabs
      defaultActiveKey={activeTab}
      items={items}
      onChange={(activeKey) => handleTabChange(activeKey)}
      key={activeTab}
    />
  );
};

export default IntermediateTabs;
