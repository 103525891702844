import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './TermsConditionsDataProtectionTranslationAlert.module.scss';

const TermsConditionsDataProtectionTranslationAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert
      description={t('common:legalAlert')}
      type="info"
      showIcon
      className={styles.legalAlert}
    />
  );
};

export default TermsConditionsDataProtectionTranslationAlert;
