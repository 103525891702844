import React, { useMemo } from 'react';
import { Col, Descriptions, Flex, Form, FormInstance, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import TextField from 'components/ui/TextField/TextField';
import NumericInput from 'components/ui/NumberInput/NumericInput';
import Button from 'components/ui/Button/Button';
import { ValidationFactory } from 'framework/validations';
import { IPool } from 'store/intermediate/intermediate.types';
import { IBundleComponent } from 'store/bundle/bundle.types';
import { formatPrice, removeThousandSeparator } from 'utils/currencyUtils';
import styles from './Bundle.module.sass';
import { componentColumns } from './constants';

interface IBundleFormProps {
  pool: IPool;
  form?: FormInstance;
}
interface IFormBundle {
  component?: IBundleComponent[];
  name?: string;
}
const convertFieldToNumber = (fieldValue?: string): number =>
  fieldValue ? removeThousandSeparator(fieldValue) : 0;
export const FormBody: React.FC<IBundleFormProps> = ({ pool, form }) => {
  const { t } = useTranslation();

  const onComponentRemove = (field: any, remove: (index: number) => void) => {
    const formComponents = form?.getFieldValue('components');
    const filtered = Array.isArray(formComponents)
      ? formComponents.filter((_, i) => i !== field.name)
      : [];
    remove(field.name);
    form?.setFieldsValue({ filtered });
  };
  const bundlesWatcher: IFormBundle[] = Form.useWatch('components', form);
  const bundlesComponentsTotalPrice = useMemo(() => {
    return bundlesWatcher?.map(
      (_bundle) =>
        _bundle?.component?.map(
          (_component) =>
            convertFieldToNumber(_component?.quantity) *
            convertFieldToNumber(_component?.price?.toString()),
        ),
    );
  }, [bundlesWatcher]);
  const componentsTotalPrice = useMemo(() => {
    return bundlesComponentsTotalPrice?.reduce(
      (total, currentBundle) =>
        total +
        (currentBundle?.reduce(
          (componentsTotal, currentComponent) => componentsTotal + currentComponent,
          0,
        ) || 0),
      0,
    );
  }, [bundlesComponentsTotalPrice]);
  const vat = useMemo(
    () => +(componentsTotalPrice * (pool.country.vatRate / 100)).toFixed(2),
    [pool, componentsTotalPrice],
  );
  const totalPriceGross = useMemo(() => componentsTotalPrice + vat, [componentsTotalPrice, vat]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <Form.Item name="name" rules={[ValidationFactory.REQUIRED]}>
            <TextField label={t('intermediate:myProjects:bundle:name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Form.Item
          colon={false}
          name="active"
          label={t('intermediate:myProjects:bundle:table:active')}
          valuePropName="checked"
        >
          <Switch defaultChecked />
        </Form.Item>
        <Col offset={1} />
      </Row>
      <Form.List name="components">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, bundleIndex) => (
              <React.Fragment key={`${field.key} - ${field.name}`}>
                <Row gutter={[16, 16]}>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      rules={[ValidationFactory.REQUIRED]}
                    >
                      <TextField
                        className={styles.dashedBorder}
                        label={t('intermediate:myProjects:bundle.componentCategoryName')}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <DeleteOutlined
                      onClick={() => onComponentRemove(field, remove)}
                      className={styles.remove}
                    />
                  </Col>
                </Row>

                <Form.List name={[field.name, 'component']}>
                  {(component, { add, remove }) => (
                    <Flex gap={8} vertical>
                      {component.length > 0 && (
                        <Row gutter={[16, 16]}>
                          {componentColumns.map((col, index) => (
                            <Col key={index} span={col.span} style={{ textAlign: 'center' }}>
                              <h5>{t(`intermediate:myProjects:bundle.${col.heading}`)}</h5>
                            </Col>
                          ))}
                          <Col span={4} style={{ textAlign: 'center' }}>
                            <h5>{t(`intermediate:myProjects:bundle.totalPriceNet`)}</h5>
                          </Col>
                        </Row>
                      )}
                      {component.map((component, componentIndex) => (
                        <Row gutter={[16, 16]} align={'middle'} key={component.key}>
                          {componentColumns.map((val, id) => (
                            <Col span={val.span} key={id}>
                              <Form.Item
                                {...component}
                                name={[component.name, val.name]}
                                rules={val.rules}
                                noStyle
                              >
                                {val.inputType === 'number' ? (
                                  <NumericInput min={0} decimalScale={val.decimalScale} />
                                ) : (
                                  <TextField />
                                )}
                              </Form.Item>
                            </Col>
                          ))}
                          <Col span={4}>
                            {bundlesComponentsTotalPrice &&
                              formatPrice(
                                bundlesComponentsTotalPrice[bundleIndex]?.[componentIndex],
                              )}
                          </Col>
                          <Col span={1}>
                            <DeleteOutlined
                              onClick={() => remove(component.name)}
                              className={styles.remove}
                              style={{ padding: '11px' }}
                            />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()}>
                          <PlusOutlined /> {t(`intermediate:myProjects:bundle.newComponent`)}
                        </Button>
                      </Form.Item>
                    </Flex>
                  )}
                </Form.List>
              </React.Fragment>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()}>
                <PlusOutlined /> {t(`intermediate:myProjects:bundle.newComponentCategory`)}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Descriptions layout={'horizontal'}>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.bundleCurrency`)}>
          {pool.country.currency}
        </Descriptions.Item>
        <Descriptions.Item label={t('intermediate:myProjects:bundle:vat')}>
          {pool.country.vatRate}
        </Descriptions.Item>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.totalBundlePriceNet`)}>
          {formatPrice(componentsTotalPrice)}
        </Descriptions.Item>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.vat`)}>
          {formatPrice(vat)}
        </Descriptions.Item>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.totalBundlePriceGross`)}>
          {formatPrice(totalPriceGross)}
        </Descriptions.Item>
      </Descriptions>

      <Col offset={1} />
    </>
  );
};
