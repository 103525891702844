import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import TermsOfUseModal from 'components/static/TermsOfUseModal/TermsOfUseModal';
import DataProtectionModal from 'components/static/DataProtectionModal/DataProtectionModal';
import CustomInstallationDateModal from 'components/modals/CustomInstallationDateModal';
import TermsConditionsForCustomer from 'components/static/TermsConditionsForCustomer/TermsConditionsForCustomer';
import {
  MODAL_DATA_PROTECTION_KEY,
  MODAL_SELECT_DATE_KEY,
  MODAL_TERMS_KEY,
} from 'framework/constants';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import {
  CustomDocumentsCategoryType,
  CustomDocumentTextType,
  ICustomDocument,
} from 'store/intermediate/intermediate.types';
import styles from './OfferSubmitStep.module.sass';
import { CheckboxesType } from './OfferSubmitStep';

interface Props {
  values: CheckboxesType;
  modal: string;
  installerData: any;
  onHideModal(): void;
  onNextClick(date: Date): void;
  onLinkClick(modalName: string): void;
  onChange(e: CheckboxChangeEvent): void;
}

const TermsPolicy: React.FC<Props> = (props) => {
  const { values, modal, installerData, onHideModal, onNextClick, onChange, onLinkClick } = props;
  const { t, i18n } = useTranslation();

  const legalDocuments: ICustomDocument[] = useSelector(legalDocumentsSelector);

  const cancelationPolicy = legalDocuments.find(
    (item) =>
      item.category === CustomDocumentsCategoryType.PoolCancelationPolicy &&
      item.textType === CustomDocumentTextType.LINK,
  );

  const terms = legalDocuments.find(
    (item) =>
      item.category === CustomDocumentsCategoryType.PoolTermsConditions &&
      item.textType === CustomDocumentTextType.LINK,
  );

  const renderModal = () => {
    const commonProps = {
      width: 1200,
      destroyOnClose: true,
      onOk: onHideModal,
      onCancel: onHideModal,
    };

    switch (modal) {
      case MODAL_TERMS_KEY:
        return (
          <TermsOfUseModal
            {...commonProps}
            termsComponent={<TermsConditionsForCustomer language={i18n.language} />}
            open={modal === MODAL_TERMS_KEY}
            customer
          />
        );

      case MODAL_DATA_PROTECTION_KEY:
        return (
          <DataProtectionModal
            termsComponent={<TermsConditionsForCustomer language={i18n.language} />}
            {...commonProps}
            type="cancelationPolicy"
            open={modal === MODAL_DATA_PROTECTION_KEY}
            customer
          />
        );

      case MODAL_SELECT_DATE_KEY:
        return (
          <CustomInstallationDateModal
            open={modal === MODAL_SELECT_DATE_KEY}
            installerUser={installerData}
            onCancel={onHideModal}
            onSubmit={(customDate) => {
              onHideModal();
              onNextClick(customDate);
            }}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.checkboxList}>
      <Checkbox name="terms" checked={values['terms']} onChange={onChange}>
        {`${t('customerFlow:registration:termsText1')} `}
        {terms ? (
          <a href={terms.text} target="_blank" rel="noopener noreferrer">
            {t('common:texts:termsAndConditions')}
          </a>
        ) : (
          <Link to="#" onClick={() => onLinkClick(MODAL_TERMS_KEY)}>
            {t('common:texts:termsAndConditions')}
          </Link>
        )}
      </Checkbox>

      <Checkbox
        name="protection"
        className={styles.checkbox}
        checked={values['protection']}
        onChange={onChange}
      >
        {`${t('customerFlow:registration:termsText1')} `}
        {cancelationPolicy ? (
          <a href={cancelationPolicy.text} target="_blank" rel="noopener noreferrer">
            {t('intermediate:myProjects:legalInformation:cancelationPolicy')}
          </a>
        ) : (
          <Link to="#" onClick={() => onLinkClick(MODAL_DATA_PROTECTION_KEY)}>
            {t('intermediate:myProjects:legalInformation:cancelationPolicy')}
          </Link>
        )}
      </Checkbox>

      {modal ? renderModal() : null}
    </div>
  );
};

export default TermsPolicy;
