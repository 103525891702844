import React from 'react';
import styles from './Imprint.module.sass';

const ImprintIt: React.FC = () => {
  return (
    <div className={styles.container}>
      <p>Informazioni ai sensi del § 5 TMG:</p>
      <p>
        Schneider Electric GmbH <br />
        Via Gothaer 29 <br />
        40880 Ratingen
      </p>
      <p>E-Mail: info@inno2fleet.com</p>
      <p>
        Amministratore delegato: Christophe de Maistre <br />
        Vice Presidente del Consiglio di Sorveglianza: Thierry Tricot <br />
        Sede dell&apos;azienda: Ratingen <br />
      </p>
      <p>
        Tribunale distrettuale di Düsseldorf | HRB 47852 <br />
        Partita IVA DE225673854 <br />
      </p>
    </div>
  );
};

export default ImprintIt;
