import React from 'react';
import { Col } from 'antd';

import { useTranslation } from 'react-i18next';
import Button, { ButtonColor } from 'components/ui/Button/Button';

import styles from '../../OrderDetailsPage.module.sass';

interface IScheduleOffButtons {
  handleDirect: () => void;
  handleOff: () => void;
  state?: string;
}

export const ScheduleOffButtons: React.FC<IScheduleOffButtons> = ({
  handleDirect,
  handleOff,
  state,
}) => {
  const { t } = useTranslation();
  const buttons = [
    { label: 'appointmentSuggest', color: ButtonColor.GREEN, onClick: handleDirect, lg: 10 },
    /*
     * TEN-1907: Remove off-tendergy appointments
     * {
     *   label: 'appointmentOff',
     *   color: ButtonColor.BLUE,
     *   onClick: handleOff,
     *   lg: 12,
     * },
     */
  ];
  return (
    <>
      {buttons.map((button) => (
        <Col key={button.label} md={24} lg={button.lg}>
          <Button
            color={button.color}
            type="primary"
            size="large"
            onClick={button.onClick}
            className={styles.customButton}
            block
          >
            {t(
              `installerFlow:orderDetails:confirmButton:offerAcceptedHomeCheckAppointmentNA:${button.label}:title`,
            )}
          </Button>
          <div className={styles.buttonDescription}>
            {t(
              `installerFlow:orderDetails:confirmButton:offerAcceptedHomeCheckAppointmentNA:${button.label}:description`,
            )}
          </div>
        </Col>
      ))}
    </>
  );
};
