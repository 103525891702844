import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Col, Row, Spin, Typography, message } from 'antd';

import BackButton from 'components/ui/BackButton/BackButton';
import Button from 'components/ui/Button/Button';
import Tabs from 'components/ui/Tabs/Tabs';
import ConfirmationModal from 'components/modals/ConfirmationModal/ConfirmationModal';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import QuestionnaireSkippedIcon from 'components/ui/QuestionnaireSkippedIcon/QuestionnaireSkippedIcon';
import { PATH } from 'framework/path';
import useLanguageChangeCallback from 'hooks/useLaguageChangeCallback';
import LocationWidget from 'pages/installer/TenderDetailsPage/LocationWidget';
import InstallationPeriodWidget from 'pages/installer/TenderDetailsPage/InstallationPeriodWidget';
import PriceWidget from 'pages/installer/TenderDetailsPage/PriceWidget';
import DocumentsWidget from 'pages/installer/TenderDetailsPage/DocumentsWidget';
import SpecificationWidget from 'pages/installer/TenderDetailsPage/SpecificationWidget/SpecificationWidget';
import Chat from 'pages/installer/TenderDetailsPage/Chat/Chat';

import styles from 'pages/installer/TenderDetailsPage/TenderDetailsPage.module.sass';
import ProductComponentWidget from 'pages/installer/TenderDetailsPage/ProductComponentWidget/ProductComponentWidget';
import ClientRequestTagsWidget from 'pages/installer/TenderDetailsPage/ClientRequestTags/ClientRequestTagsWidget';
import { orderInstallerDirectly } from 'store/intermediate/intermediate.actions';
import {
  clientRequestGet,
  clientRequestDistanceGetAction,
  getClientRequestFiles,
  updateTransitionState,
  assignBundleToRequest,
} from 'store/client-request/client-request.actions';

import {
  currentClientRequestSelector,
  clientRequestStateSelector,
  isClientRequestDistance,
} from 'store/client-request/client-request.selectors';
import {
  ClientRequestState,
  ClientRequestTransitionTypes,
} from 'store/client-request/client-request.types';
import { orderInstallerDirectlyStateSelector } from 'store/intermediate/intermediate.selectors';
import { RequestState } from 'store/common.types';
import { Orders } from './tabs/OrdersTab/Orders';
import OffersTab from './tabs/OffersTab/OffersTab';
import InstallerOrganizationsModal from '../InstallerOrganizationsModal/InstallerOrganizationsModal';
import ChangePoolWidget from '../../installer/TenderDetailsPage/ChangePool/ChangePoolWidget';

const ClientRequestPage = () => {
  const { id } = useParams();

  const [selectedTab, setSelectedTab] = useState('request');
  const [showRejectModal, setShowRejectModal] = useState(false);

  const [showOrderInstallerModal, setShowOrderInstallerModal] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const orderInstallerDirectlyState = useSelector(orderInstallerDirectlyStateSelector);

  const clientRequestState = useSelector(clientRequestStateSelector);
  const clientRequest = useSelector(currentClientRequestSelector);
  const clientRequestDistance = useSelector(isClientRequestDistance);
  const isQuestionnaireSkipped = clientRequest?.questionnaireSkipped;

  const [selectedBundle, setSelectedBundle] = useState<string>();
  const fetchClientRequest = useCallback(() => {
    dispatch(clientRequestGet(id!, true));
  }, [dispatch, id]);
  useEffect(() => {
    fetchClientRequest();
  }, [fetchClientRequest]);
  useLanguageChangeCallback(fetchClientRequest);
  useEffect(() => {
    if (clientRequest && clientRequest.address) {
      setSelectedBundle(clientRequest.bundleId);
      dispatch(clientRequestDistanceGetAction(id!));
    }
  }, [dispatch, id, clientRequest]);

  useEffect(() => {
    dispatch(getClientRequestFiles(id!));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orderInstallerDirectlyState === RequestState.Success) {
      dispatch(clientRequestGet(id!, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInstallerDirectlyState]);

  const tabsProps = {
    selected: selectedTab,
    list: [
      {
        title: t('intermediate:myProjects:clientRequest'),
        value: 'request',
        action: () => setSelectedTab('request'),
      },
      {
        title: t('intermediate:myProjects:orders'),
        value: 'orders',
        number: clientRequest?.orders?.length,
        action: () => setSelectedTab('orders'),
      },
      {
        title: t('intermediate:myProjects:offers'),
        value: 'offers',
        number: clientRequest?.offers?.length,
        action: () => setSelectedTab('offers'),
      },
    ],
  };

  const onBundleSelect = (bundleId: string) => {
    dispatch(assignBundleToRequest(id!, bundleId));
    setSelectedBundle(bundleId);
  };

  const handleRejectCR = (rejectReason: string) => {
    dispatch(
      updateTransitionState(id!, ClientRequestTransitionTypes.coordinatorRejected, rejectReason),
    );
    navigate(PATH.HOME);
  };

  const handleOrderInstaller = (organizationId: string) => {
    dispatch(orderInstallerDirectly(id!, organizationId));
    setShowOrderInstallerModal(false);
  };

  const onApproveClick = () => {
    dispatch(updateTransitionState(id!, ClientRequestTransitionTypes.coordinatorAccepted));
    message.info(t('intermediate:myProjects:clientRequestApproved'));
  };

  const goBack = () => {
    navigate(-1);
  };

  const rejectModalProps = {
    onSubmit: handleRejectCR,
    onCancel: () => setShowRejectModal(false),
    visible: showRejectModal,
    title: t('intermediate:myProjects:rejectRequest'),
  };

  const showOrderInstallerModalProps = {
    poolId: clientRequest?.pool?.id,
    requestId: id!,
    onSubmit: handleOrderInstaller,
    onCancel: () => setShowOrderInstallerModal(false),
    visible: showOrderInstallerModal,
  };

  const isOrderButton =
    !!clientRequest?.bundleId && clientRequest?.state === ClientRequestState.ApprovalPending;

  const onOrderRejectSuccess = () => {
    dispatch(clientRequestGet(id!, true));
    setSelectedTab('request');
  };
  const canChangeThePool =
    clientRequest?.state === ClientRequestState.ApprovalPending &&
    clientRequest.offers?.length === 0;
  const renderContent = () => {
    if (!clientRequest) {
      return <div>Loading...</div>;
    }

    return (
      <div className={styles.container}>
        {clientRequestState === RequestState.Loading && <Spin fullscreen size="large" />}
        <>
          <div className={styles.header}>
            <BackButton className={styles.back} onClick={goBack}>
              {t('common:buttons:back')}
            </BackButton>
            <Row justify="space-between" align="middle" gutter={[0, 32]}>
              <div className={styles.headerWrapper}>
                <Typography.Title level={3} className={styles.title}>
                  {t('intermediate:myProjects:clientRequest')} {clientRequest.displayId}
                </Typography.Title>
                <p className={styles.text}>
                  {t('installerFlow:tenderDetails:published')}{' '}
                  <DateFormat date={clientRequest?.createdAt as Date} />
                </p>
              </div>
              <Button
                type="default"
                size="small"
                onClick={() => setShowOrderInstallerModal(true)}
                className={styles.orderInstaller}
                disabled={!isOrderButton}
              >
                {t('intermediate:myProjects:orderInstaller')}
              </Button>
              {clientRequest.state === ClientRequestState.ApprovalPending &&
                !clientRequest.archived && (
                  <div className={styles.buttonsAction}>
                    <Button
                      type="primary"
                      danger={true}
                      size="small"
                      onClick={() => setShowRejectModal(true)}
                    >
                      {t('intermediate:myProjects:rejectRequest')}
                    </Button>
                    <Button type="primary" size="small" onClick={onApproveClick}>
                      {t('intermediate:myProjects:approveCR')}
                    </Button>
                  </div>
                )}
              {clientRequest.state !== ClientRequestState.ApprovalPending &&
                clientRequest.state !== ClientRequestState.Cancelled && <Tabs {...tabsProps} />}
            </Row>
          </div>
          {selectedTab === 'offers' && <OffersTab clientRequest={clientRequest} />}
          {selectedTab === 'orders' && (
            <Orders clientRequest={clientRequest} onRejectSuccess={onOrderRejectSuccess} />
          )}
          {selectedTab === 'request' && (
            <Row gutter={[16, 16]} key="tender-section-contact">
              <Col span={8} key="tender-section-location">
                <LocationWidget
                  clientRequest={clientRequest}
                  distance={clientRequestDistance}
                  fullInfo
                />
              </Col>
              <Col span={8} key="tender-section-period">
                <InstallationPeriodWidget
                  clientRequest={clientRequest}
                  isOperatorSide
                  shouldShowWeekdays={false}
                />
              </Col>
              <Col span={8} key="tender-section-price-range">
                <PriceWidget clientRequest={clientRequest} />
              </Col>
              <Col span={16} key="tender-section-specification">
                <div className={styles.heightSeparator}>
                  {clientRequest.products && clientRequest?.products?.length > 0 && (
                    <ProductComponentWidget
                      onEditButtonClicked={() => navigate('edit-products')}
                      products={clientRequest.products}
                      poolOperator
                    />
                  )}
                  {isQuestionnaireSkipped ? (
                    <QuestionnaireSkippedIcon />
                  ) : (
                    <SpecificationWidget
                      clientRequest={clientRequest}
                      selectedBundle={selectedBundle}
                      onBundleSelect={onBundleSelect}
                    />
                  )}
                </div>
              </Col>
              <Col span={8} key="tender-section-documents">
                <div className={styles.heightSeparator}>
                  {canChangeThePool && <ChangePoolWidget />}
                  <ClientRequestTagsWidget
                    assignedTagsToClientRequest={clientRequest?.poolTags}
                    clientRequestId={id!}
                    poolId={clientRequest.pool?.id!}
                  />
                  <DocumentsWidget clientRequest={clientRequest} />
                  <Chat clientRequestId={id!} intermediate={true} />
                </div>
              </Col>
            </Row>
          )}
        </>
        {/* )} */}

        <ConfirmationModal {...rejectModalProps} />
        <InstallerOrganizationsModal {...showOrderInstallerModalProps} />
      </div>
    );
  };
  return renderContent();
};

export default ClientRequestPage;
