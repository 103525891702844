import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Collapse, Flex, Form, message, Row } from 'antd';
import Editor from '@monaco-editor/react';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import NormalizeAntd from 'components/containers/NormalizeAntd';
import { SupportedLanguages } from 'framework/constants';
import { useDebounce } from 'hooks/useDebounceCallback';
import ClientRequestAPI from 'services/client-request.service';
import pdfMaker from 'services/pdf-maker';
import OfferAPI from 'services/offer.service';
import { IClientRequest } from 'store/client-request/client-request.types';
import { userDataSelector } from 'store/auth/auth.selectors';
import { IProduct } from 'store/product/product.types';
import { IOffer, OfferEventType, OfferState } from 'store/offer/offer.types';
import { offerFileUpload, offerTransition } from 'store/offer/offer.actions';
import PDFGenerator from 'utils/pdfGenerator';
import {
  checkIsCustomerPriceEditable,
  checkIsHomeCheck,
  generateOfferFileName,
  getUploadOfferUploadCategory,
} from 'utils/offerHelpers';
import OfferDocumentSimpleForm from './SimpleForm';
import OfferDocumentProductForm from './ProductsForm';
import OfferDocumentPdfPreview from './OfferDocumentPdfPreview';
import OfferDocumentBundlesForm from './BundlesForm';
import useCalculateCosts from './useCalculateCosts';
import CostTable from './CostTable';
import ActionButtons from './ActionButtons';
const { jsonToPDF, downloadPDF } = PDFGenerator();

export interface IOfferDocumentBundleComponent {
  category?: string;
  items: {
    description?: string;
    quantity?: string;
    unit?: string;
    price?: string;
  }[];
}
export interface IOfferDocumentBundle {
  id?: string;
  name?: string;
  components: IOfferDocumentBundleComponent[];
}
export interface IOfferDocumentForm {
  offer: {
    id?: string;
    date?: Date;
  };
  request: {
    id: string;
    poolName?: string;
    agent?: string;
  };
  owner: {
    lastName?: string;
    firstName?: string;
    gender?: number;
    street?: string;
    address2?: string;
    zipCode?: string;
    city?: string;
    no?: string;
  };
  bundle: IOfferDocumentBundle;
  products: IProduct[];
}

const OfferDocumentGenerator = () => {
  const [pdfJSON, setPdfJSON] = useState<string>();
  const [clientRequest, setClientRequest] = useState<IClientRequest>();
  const [offer, setOffer] = useState<IOffer>();
  const { requestId, offerId } = useParams();
  const [form] = useForm<IOfferDocumentForm>();
  const { t } = useTranslation();
  const [isOfferSubmitting, setIsOfferSubmitting] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<Blob>();
  const totalCosts = useCalculateCosts(form, clientRequest?.pool?.country.vatRate || 0);
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);
  const navigate = useNavigate();
  const goBackToRequest = () => {
    navigate(`/my-projects/client-request/${clientRequest?.id}`);
  };
  const loadUserLanguage = useCallback(() => {
    if (clientRequest?.owner?.defaultLanguage.isoCode)
      i18next.loadLanguages(clientRequest?.owner?.defaultLanguage.isoCode);
  }, [clientRequest]);
  useEffect(() => {
    loadUserLanguage();
  }, [loadUserLanguage]);
  const fetchClientRequest = async () => {
    if (requestId) {
      try {
        const clientRequest = await ClientRequestAPI.getByIdWithRelations(requestId, [
          'bundle',
          'createdBy',
          'pool',
          'offers',
          'products',
          'owner',
        ]);
        setClientRequest(clientRequest);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const fetchTemplate = async () => {
    if (!clientRequest) return;
    const template = await pdfMaker.template(
      'offer',
      clientRequest.owner?.defaultLanguage.isoCode || 'en',
    );
    setPdfJSON(template.template);
  };
  useEffect(() => {
    fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRequest]);
  useEffect(() => {
    fetchClientRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);
  const fetchOffer = async () => {
    if (!offerId) return;
    const _offer = await OfferAPI.getById({ id: offerId, relations: ['documents'] });
    setOffer(_offer);
  };
  useEffect(() => {
    fetchOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);
  const formWatcher = Form.useWatch([], form);

  const debouncedPDFURL = useDebounce((json: string, formData: IOfferDocumentForm) => {
    jsonToPDF(
      json,
      formData,
      clientRequest?.pool?.country.vatRate || 0,
      clientRequest?.pool?.country.currency || '',
      totalCosts,
      clientRequest?.owner?.defaultLanguage.isoCode || SupportedLanguages.DE,
    )
      .then((pdfBlob) => setPdfBlob(pdfBlob))
      .catch((e) => console.log(e));
  }, 500);
  const downloadPDFHandler = async () => {
    if (!pdfJSON) return;
    try {
      if (!offer) return;
      const uploadCategory = getUploadOfferUploadCategory(offer);
      if (!uploadCategory) return;
      const fileName = generateOfferFileName(userData.id, 'offer', uploadCategory);
      await form.validateFields();
      await downloadPDF(
        fileName,
        pdfJSON,
        formWatcher,
        clientRequest?.pool?.country.vatRate || 0,
        clientRequest?.pool?.country.currency || '',
        totalCosts,
        clientRequest?.owner?.defaultLanguage.isoCode || SupportedLanguages.DE,
      );
    } catch (error) {
      message.error(t('common:error:pdfGenInputs'));
    }
  };
  const onJSONChanged = useCallback(() => {
    if (pdfJSON) debouncedPDFURL(pdfJSON, formWatcher);
  }, [debouncedPDFURL, pdfJSON, formWatcher]);
  useEffect(() => {
    onJSONChanged();
  }, [onJSONChanged]);
  const cancelHandler = () => {
    goBackToRequest();
  };
  const offerTransitionHandler = () => {
    if (!offer || !offer.state || !clientRequest) return;
    const isHomeCheck = checkIsHomeCheck(offer.state);

    const clearPrice = totalCosts.total.net;
    const isCustomerPriceEditable = checkIsCustomerPriceEditable(offer, clientRequest);
    let costFields: any = isHomeCheck
      ? {
          costEstimateHomeCheck: isCustomerPriceEditable
            ? clearPrice
            : offer.initialCostEstimateHomeCheck,
        }
      : {
          costEstimate: isCustomerPriceEditable ? clearPrice : offer.initialCostEstimateHomeCheck,
        };

    if (
      offer.state === OfferState.InvoiceSubmittedHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck ||
      offer.state === OfferState.InvoiceSubmittedInstallation
    ) {
      costFields = isHomeCheck
        ? {
            invoiceHomeCheck: isCustomerPriceEditable ? clearPrice : offer.initialInvoiceHomeCheck,
          }
        : {
            invoiceAmount: isCustomerPriceEditable ? clearPrice : offer.initialInvoiceAmount,
          };
    }
    dispatch(
      offerTransition(
        offer.state === OfferState.InvoiceSubmittedHomeCheck ||
          offer.state === OfferState.InvoiceSubmittedRemoteHomeCheck ||
          offer.state === OfferState.InvoiceSubmittedInstallation
          ? OfferEventType.INTERMEDIATE_ACCEPTED
          : OfferEventType.COORDINATOR_ACCEPTED,
        {
          id: offer.id,
          vatRate: totalCosts.total.vat,
          offerAnnotation: '',
          ...costFields,
        },
        (data: any) => {
          setOffer((prevOffer: any) => ({
            ...prevOffer,
            ...data,
            documents: prevOffer.documents,
            installerUser: prevOffer.installerUser,
          }));
          message.info(t('intermediate:myProjects:offerPublished'));
          setIsOfferSubmitting(false);
          goBackToRequest();
        },
        () => {
          setIsOfferSubmitting(false);
        },
      ),
    );
  };
  const sendOfferHandler = async () => {
    if (!offer || !offerId || !pdfBlob) return;
    try {
      await form.validateFields();
      setIsOfferSubmitting(true);
      const uploadCategory = getUploadOfferUploadCategory(offer);
      if (!uploadCategory) return;
      const file = new File([pdfBlob], 'offer.pdf', {
        type: 'application/pdf',
      });
      dispatch(
        offerFileUpload(
          offerId,
          uploadCategory,
          file,
          () => {},
          offerTransitionHandler,
          () => {
            setIsOfferSubmitting(false);
          },
        ),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const address = clientRequest?.owner?.billingAddress || clientRequest?.owner?.address;
  return (
    <NormalizeAntd>
      <Card loading={!clientRequest || !offer}>
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{
            offer: {
              date: dayjs(offer?.createdAt),
            },
            request: {
              id: clientRequest?.displayId,
              poolName: clientRequest?.pool?.name,
              agent: `${userData.firstName} ${userData.lastName}`,
            },
            owner: {
              lastName: clientRequest?.owner?.lastName,
              firstName: clientRequest?.owner?.firstName,
              gender: clientRequest?.owner?.gender,
              street: address?.street,
              address2: address?.address2,
              zipCode: address?.postalCode,
              city: address?.city,
              no: address?.houseNumber,
            },
            bundle: {
              id: clientRequest?.bundleId,
            },
            products: clientRequest?.products,
          }}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Flex vertical={true} gap={4}>
                <OfferDocumentSimpleForm />
                <OfferDocumentProductForm form={form} clientRequest={clientRequest} />
                <OfferDocumentBundlesForm form={form} clientRequest={clientRequest} />
                <Collapse ghost={true}>
                  <Collapse.Panel key={'Editor'} header={t('intermediate:pdfGen:editor')}>
                    <Editor
                      height="70vh"
                      language="json"
                      theme="vs-dark"
                      value={pdfJSON}
                      onChange={(value) => setPdfJSON(value)}
                      options={{
                        fontSize: '16px',
                        formatOnType: true,
                        autoClosingBrackets: true,
                        minimap: { scale: 10 },
                      }}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Flex>
            </Col>
            <Col span={12}>
              <Flex vertical={true} gap={4}>
                <OfferDocumentPdfPreview pdfBlob={pdfBlob} />
                <CostTable
                  currency={clientRequest?.pool?.country.currency || ''}
                  totalCosts={totalCosts}
                />
                <ActionButtons
                  sendOfferClickHandler={sendOfferHandler}
                  cancelButtonClickHandler={cancelHandler}
                  downloadPDFClickHandler={downloadPDFHandler}
                  isOfferSubmitting={isOfferSubmitting}
                />
              </Flex>
            </Col>
          </Row>
        </Form>
      </Card>
    </NormalizeAntd>
  );
};

export default OfferDocumentGenerator;
