import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import DataPrivacy from 'components/static/DataPrivacy/DataPrivacy';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import Box from 'components/layout/Box/Box';
import useFetchCustomerLegalDocuments from 'hooks/useFetchCustomerLegalDocuments';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import { CustomDocumentsCategoryType } from 'store/intermediate/intermediate.types';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { getLegalDocumentData } from 'utils/commonUtils';
import styles from './PrivacyPolicyPage.module.sass';

const PrivacyPolicyPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const token = useSelector(isRegistrationInviteTokenSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const legalDocuments = useSelector(legalDocumentsSelector);
  useFetchCustomerLegalDocuments();
  const getCustomerPrivacy = () => {
    const value = getLegalDocumentData(
      legalDocuments,
      CustomDocumentsCategoryType.PoolPrivacyPolicy,
    );
    if (isCustomer || token) {
      return value ? (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <DataPrivacy language={i18n.language} />
      );
    }
    return <DataPrivacy language={i18n.language} />;
  };

  return (
    <Box>
      <BoxContent
        title={t('common:texts:privacyPolicy')}
        showBackButton={false}
        secondaryTitle={
          <Alert
            description={t('common:legalAlert')}
            type="info"
            showIcon
            className={styles.legalAlert}
          />
        }
      >
        <div className={styles.container}>{getCustomerPrivacy()}</div>
      </BoxContent>
    </Box>
  );
};

export default PrivacyPolicyPage;
