import React, { useEffect, useState } from 'react';
import Typography from 'antd/lib/typography';
import { Col, Row, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import Button from 'components/ui/Button/Button';
import BackButton from 'components/ui/BackButton/BackButton';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import DateTimePicker, { IDateTime } from 'components/ui/DateTimePicker/DateTimePicker';
import Loader from 'components/ui/Loader/Loader';
import SuccessModal from 'components/modals/SuccessModal/SuccessModal';
import Notification from 'components/ui/Notification/Notification';
import { getAppointmentDisabledDays } from 'framework/dateUtils';
import { currentClientRequestSelector } from 'store/client-request/client-request.selectors';
import { clientRequestGet } from 'store/client-request/client-request.actions';
import { RequestState } from 'store/common.types';
import {
  AppointmentPurpose,
  OfferEventType,
  OfferingUserType,
  OfferState,
} from 'store/offer/offer.types';
import {
  currentOfferSelector,
  offerGetStateSelector,
  offerTransitionStateSelector,
} from 'store/offer/offer.selectors';
import {
  offerGet,
  offerTransition,
  offerTransitionReset,
  updateOffer,
} from 'store/offer/offer.actions';
import { timeSlotsGenerator } from 'utils/dateUtils';
import styles from './TenderAppointmentPage.module.sass';

interface IProps {
  installationAppointment?: boolean;
}

const TenderAppointmentPage: React.FC<IProps> = ({ installationAppointment }) => {
  //@ts-ignore
  const [date, setDate] = useState('' as Date);
  const [successModal, setSuccessModal] = useState(false);
  const [transitionFail, setFail] = useState(false);
  const [selectedValues, setValues] = useState([] as IDateTime[]);

  const { id: offerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const offerState = useSelector(offerGetStateSelector);
  const transitionState = useSelector(offerTransitionStateSelector);
  const offer = useSelector(currentOfferSelector);
  const clientRequest = useSelector(currentClientRequestSelector);

  const timeSlots = timeSlotsGenerator('7:00', '21:30', 30);

  const changeHomeCheckAppointmentsState = [
    OfferState.TenderSubmittedHomeCheck,
    OfferState.TenderSubmittedRemoteHomeCheck,
    OfferState.TenderAcceptedHomeCheck,
    OfferState.TenderAcceptedRemoteHomeCheck,
    OfferState.OfferAcceptedHomeCheckAppointmentNA,
    OfferState.OfferAcceptedRemoteHomeCheckAppointmentNA,
  ].includes(offer?.state!);

  const changeAppointmentsState =
    changeHomeCheckAppointmentsState || offer?.state === OfferState.OfferSubmitted;

  useEffect(() => {
    if (offer?.appointments && changeAppointmentsState) {
      setValues(
        offer.appointments.map((item) => ({
          day: new Date(item.timestamp),
          time: moment(item.timestamp).format('HH:mm'),
        })),
      );
    }
  }, [offer, changeAppointmentsState]);

  useEffect(() => {
    if (!offer) {
      dispatch(offerGet({ id: offerId!, relations: ['appointments'] }));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!clientRequest && offer?.clientRequestId) {
      dispatch(clientRequestGet(offer.clientRequestId, false));
    }
  }, [offer?.clientRequestId]); // eslint-disable-line

  useEffect(() => {
    if (!transitionState) {
      return;
    }
    if (transitionState === RequestState.Error) {
      setFail(true);
      setSuccessModal(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (transitionState === RequestState.Success) {
      setSuccessModal(true);
    }
  }, [transitionState]);

  useEffect(() => {
    return function cleanup() {
      dispatch(offerTransitionReset());
    };
  }, [dispatch]);

  const onOkayClick = () => {
    setSuccessModal(false);
    navigate('/installer/dashboard');
  };

  const acceptOffer = () => {
    const isHasAppointments = Boolean(offer?.appointments?.length);
    if (offer?.id && !isHasAppointments && offer.state !== OfferState.OfferAccepted) {
      dispatch(
        offerTransition(OfferEventType.INSTALLER_HOME_CHECK_APPOINTMENT_CREATED, {
          id: offerId,
          appointments: selectedValues.map((a) => ({
            purpose: AppointmentPurpose.HomeCheck,
            timestamp: a.day,
            proposedBy: OfferingUserType.INSTALLER,
          })),
        }),
      );

      return;
    }

    if (offer?.id && changeAppointmentsState) {
      dispatch(
        updateOffer(
          offer.id,
          {
            appointments: selectedValues.map((a) => ({
              purpose:
                offer?.state === OfferState.OfferSubmitted
                  ? AppointmentPurpose.Installation
                  : AppointmentPurpose.HomeCheck,
              timestamp: a.day,
              proposedBy: OfferingUserType.INSTALLER,
            })),
          },
          () => setSuccessModal(true),
        ),
      );
    } else {
      dispatch(
        offerTransition(OfferEventType.INSTALLER_APPOINTMENT_CREATED, {
          id: offerId,
          appointments: selectedValues.map((a) => ({
            purpose: AppointmentPurpose.Installation,
            timestamp: a.day,
            proposedBy: OfferingUserType.INSTALLER,
          })),
        }),
      );
    }
  };

  const handleTimeClick = (day: Date, time: string) => {
    let [hours, minutes] = time.split(':');
    let tempDate = new Date(date);
    tempDate.setHours(+hours, +minutes);
    // @ts-ignore
    setDate(tempDate);
  };

  const datePickerProps = {
    selectedValues,
    maxValues: 10,
    timeAvailable: timeSlots,
    disabled: clientRequest && offer ? getAppointmentDisabledDays(clientRequest, offer) : {},
    onTimeClick: (day: Date, time: string) => handleTimeClick(day, time),
    onDayClick: (day: Date) => setDate(day),
    setValues: (newValue: any) => setValues(newValue),
  };

  const renderButtons = () => {
    const BUTTONS = [
      {
        disabled: !selectedValues.length,
        color: 'green',
        title: changeAppointmentsState
          ? t('installerFlow:tenderParticipate:changeAppointments')
          : t('installerFlow:tenderParticipate:sendOffer'),
        description: t('installerFlow:tenderParticipate:sendOfferSubtitle'),
        onClick: () => acceptOffer(),
      },
    ];

    return (
      <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
        {BUTTONS.map((button: any, index: number) => (
          <Col className={styles.buttonsCol} span={8} key={`action-button-${index}`}>
            <Button
              type="primary"
              block={true}
              color={button.color}
              danger={button.danger}
              onClick={button.onClick}
              disabled={button.disabled}
            >
              {button.title}
            </Button>

            <small className={styles.small}>{button.description}</small>
          </Col>
        ))}
      </Row>
    );
  };

  const renderSelected = () => {
    if (selectedValues.length === 0) {
      return <div />;
    }
    return (
      <div>
        <Typography.Title level={3}>
          {t('installerFlow:tenderParticipate:selectedAppointments')}
        </Typography.Title>
        {selectedValues.map((appointment: IDateTime, index: number) => (
          <Tag key={index} color="success">
            {moment(appointment.day).format('LLLL')}
          </Tag>
        ))}
      </div>
    );
  };

  const modalProps = {
    onOkayClick,
    open: successModal,
    modalText: t('installerFlow:tenderParticipate:successModal'),
  };

  const renderContent = () => {
    if (!offer) {
      return <div>{t('common:texts:loading')}</div>;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          {transitionFail && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Notification
                  className={styles.notification}
                  size="medium"
                  title="Error!"
                  type="error"
                  content="An error occurred! Please try again."
                />
              </Col>
            </Row>
          )}
          <BackButton className={styles.back} onClick={() => navigate('/installer/dashboard')}>
            {t('common:buttons:back')}
          </BackButton>

          <div>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Rectangle
                  title={
                    changeHomeCheckAppointmentsState
                      ? t('installerFlow:tenderDetails:offerSubmitPage:title')
                      : t(
                          'installerFlow:tenderParticipate:withReservation:possibleInstallationDates',
                        )
                  }
                >
                  <div>
                    <Typography.Paragraph className={styles.paragraph}>
                      {changeHomeCheckAppointmentsState
                        ? t('installerFlow:tenderDetails:offerSubmitPage:textOne')
                        : t(
                            'installerFlow:tenderParticipate:withReservation:installationDatesText1',
                          )}
                    </Typography.Paragraph>

                    <Typography.Paragraph className={styles.paragraphLast}>
                      {changeHomeCheckAppointmentsState
                        ? t('installerFlow:tenderDetails:offerSubmitPage:textTwo')
                        : t(
                            'installerFlow:tenderParticipate:withReservation:installationDatesText2',
                          )}
                    </Typography.Paragraph>

                    <Typography.Title level={4} className={styles.dateTimeTitle}>
                      {t('installerFlow:tenderParticipate:withReservation:dateAndTime')}
                    </Typography.Title>

                    <div className={styles.datePickerWrapper}>
                      <DateTimePicker multiple={false} {...datePickerProps} />
                    </div>
                  </div>
                </Rectangle>
              </Col>
            </Row>
          </div>
        </div>

        {renderSelected()}
        {renderButtons()}
        <SuccessModal {...modalProps} />
      </div>
    );
  };

  return offerState === RequestState.Success ? renderContent() : <Loader />;
};

export default TenderAppointmentPage;
