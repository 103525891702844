import React from 'react';
import { ThemeConfig } from 'antd/lib';
import { ConfigProvider } from 'antd';

export const theme: ThemeConfig = {
  components: {
    Button: {
      controlHeight: 32,
      controlHeightSM: 24,
    },
    Select: {
      controlHeight: 32,
    },
  },
};
interface INormalizeAntdProps {
  children: React.ReactNode[] | React.ReactNode;
}

/**
 * This component removes customizations from AntD components (button and select)
 * because their height has been overridden, and using them in a form would disrupt the layout.
 */
const NormalizeAntd = (props: INormalizeAntdProps) => {
  return <ConfigProvider theme={theme}>{props.children}</ConfigProvider>;
};
export default NormalizeAntd;
