/* eslint-disable no-unused-vars */
import { RequestState, IFindWithRelations, ISearchDto } from 'store/common.types';
import {
  IClientRequest,
  IDocument,
  IFile,
  MediaCategoryType,
} from 'store/client-request/client-request.types';
import { IUserObject } from '../auth/auth.types';

export enum OfferApproval {
  ReplaceByIntermediate = 'replaceByIntermediate',
  PayByIntermediate = 'payByIntermediate',
  CheckByIntermediate = 'checkByIntermediate',
  DirectlySendToCustomer = 'directlySendToCustomer',
}

//TODO Move to common
export enum OfferingUserType {
  END_USER = 'end_user',
  INSTALLER = 'installer',
}

export enum AppointmentPurpose {
  HomeCheck = 'home_check',
  Installation = 'installation',
}

//TODO Move to common
export interface IAppointment {
  purpose: AppointmentPurpose;
  timestamp: Date;
  proposedBy: OfferingUserType;
  id?: string;
}

export enum OfferStatusType {
  OFFERS_IN_PROGRESS = 'offersInProgress',
  OFFERS_COMPLETED = 'offersCompleted',
  WHOLE_COMPANY = 'wholeCompany',
  OFFERS_EXPIRED = 'expired',
}

export interface IOffer {
  id?: string;
  displayId?: string;
  state?: OfferState;
  annotation?: string;
  homeCheckAnnotation?: string;
  // change this field after pool owner annotations will be added
  poolOwnerAnnotation?: string;
  doneAsStated?: boolean;
  feedbackReason?: string;
  feedbackAnnotation?: string;
  preliminary?: boolean;
  contactType?: ContactType;
  clientRequest?: IClientRequest;
  clientRequestId?: string;
  installerUserId?: string;
  installerUser?: IUserObject;
  appointments?: IAppointment[] | null;
  finalInstallationTimestamp?: Date;
  finalHomeCheckTimestamp?: Date;
  createdAt?: Date;
  preliminaryType?: string;
  costEstimateHomeCheck?: number | null;
  costEstimate?: number | null;
  costEstimateAdditional?: number;
  initialCostEstimate?: number;
  initialCostEstimateHomeCheck?: number;
  additionalConditions?: string[];
  additionalConditionsDescription?: string;
  rejectReason?: string;
  documents?: {
    id: string;
    document: IDocument;
  }[];
  tenderAcceptedTimestamp?: Date;
  tenderAcceptedHomeCheckTimestamp?: Date;
  initialInvoiceHomeCheck?: number;
  invoiceHomeCheck?: number;
  initialInvoiceAmount?: number;
  invoiceAmount?: number;
  actualHomeCheckTimestamp?: Date;
  actualInstallationTimestamp?: Date;
  installedProduct?: {
    id?: string;
  };
  remoteHomeCheck?: boolean;
  meetingId?: string;
  vatRate?: number;
  initialVatRate?: number;
  currencySymbol?: string;
  initialCurrencySymbol?: string;
  installerInvoicePaid?: boolean;
  customerInvoicePaid?: boolean;
  offerValidityDate?: any;
  offerAnnotation?: string;
  homeCheckOfferReplacedBy?: IUserObject;
  offerReplacedBy?: IUserObject;
}

//TODO Move to common
export enum OfferEventType {
  INITIATED = 'INITIATED',
  OFFER_WITHOUT_APPOINTMENTS = 'OFFER_WITHOUT_APPOINTMENTS',
  OFFER_HOMECHECK_WITHOUT_APPOINTMENTS = 'OFFER_HOMECHECK_WITHOUT_APPOINTMENTS',
  INSTALLER_ACCEPTED = 'INSTALLER_ACCEPTED',
  INSTALLER_ACCEPTED_HOME_CHECK = 'INSTALLER_ACCEPTED_HOME_CHECK',
  INSTALLER_CANCELLED = 'INSTALLER_CANCELLED',
  INSTALLER_REJECTED = 'INSTALLER_REJECTED',
  INSTALLER_CONFIRMED = 'INSTALLER_CONFIRMED',
  COORDINATOR_ACCEPTED = 'COORDINATOR_ACCEPTED',
  COORDINATOR_REJECTED = 'COORDINATOR_REJECTED',
  INSTALLER_APPOINTMENT_CREATED = 'INSTALLER_APPOINTMENT_CREATED',
  CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED',
  CUSTOMER_REJECTED = 'CUSTOMER_REJECTED',
  HOME_CHECK_COMPLETED = 'HOME_CHECK_COMPLETED',
  INSTALLER_WORK_COMPLETED = 'INSTALLER_WORK_COMPLETED',
  INSTALLER_WORK_CANCELLED = 'INSTALLER_WORK_CANCELLED',
  INTERMEDIATE_ACCEPTED = 'INTERMEDIATE_ACCEPTED',
  INTERMEDIATE_REJECTED = 'INTERMEDIATE_REJECTED',
  CUSTOMER_SELECTED_DATE = 'CUSTOMER_SELECTED_DATE',
  CUSTOMER_APPOINTMENT_CONFIRMED = 'CUSTOMER_APPOINTMENT_CONFIRMED',
  CUSTOMER_ACCEPTED_WITHOUT_APPOINTMENT = 'CUSTOMER_ACCEPTED_WITHOUT_APPOINTMENT',
  HOME_CHECK_DONE = 'HOME_CHECK_DONE',
  INVOICE_PAID = 'INVOICE_PAID',
  INTERMEDIATE_REQUESTED_HOME_CHECK_INVOICE = 'INTERMEDIATE_REQUESTED_HOME_CHECK_INVOICE',
  INSTALLER_HOME_CHECK_APPOINTMENT_CREATED = 'INSTALLER_HOME_CHECK_APPOINTMENT_CREATED',
  INSTALLER_OFFER_APPOINTMENT_OFF_TENDERGY = 'INSTALLER_OFFER_APPOINTMENT_OFF_TENDERGY',
}

//TODO Move to common
export enum OfferState {
  None = 'none',
  TenderCreated = 'tenderCreated',
  TenderCreatedWithoutAppointments = 'tenderCreatedWithoutAppointments',
  TenderSubmitted = 'tenderSubmitted',
  TenderSubmittedHomeCheck = 'tenderSubmittedHomeCheck',
  TenderSubmittedRemoteHomeCheck = 'tenderSubmittedRemoteHomeCheck',
  TenderAcceptedRemoteHomeCheck = 'tenderAcceptedRemoteHomeCheck',
  TenderCheckingAccept = 'tenderCheckingAccept',
  TenderCheckingAcceptHomeCheck = 'tenderCheckingAcceptedHomeCheck',
  TenderCancelled = 'tenderCancelled',
  TenderAccepted = 'tenderAccepted',
  TenderRejected = 'tenderRejected',
  TenderAcceptedHomeCheck = 'tenderAcceptedHomeCheck',
  OfferAcceptedHomeCheckAppointmentNA = 'offerAcceptedHomeCheckAppointmentNA',
  OfferAcceptedRemoteHomeCheckAppointmentNA = 'offerAcceptedRemoteHomeCheckAppointmentNA',
  OfferAcceptedHomeCheckAppointmentTBD = 'offerAcceptedHomeCheckAppointmentTBD',
  OfferAcceptedRemoteHomeCheckAppointmentTBD = 'offerAcceptedRemoteHomeCheckAppointmentTBD',
  OfferAccepted = 'offerAccepted',
  OfferAcceptedHomeCheck = 'offerAcceptedHomeCheck',
  OfferAcceptedRemoteHomeCheck = 'offerAcceptedRemoteHomeCheck',
  OfferHomeCheckPending = 'offerHomeCheckPending',
  OfferRemoteHomeCheckPending = 'offerRemoteHomeCheckPending',
  OfferSubmitted = 'offerSubmitted',
  OfferRejected = 'offerRejected',
  OfferRejectedHomeCheck = 'offerRejectedHomeCheck',
  InvoiceRejectedInstallation = 'invoiceRejectedInstallation',
  InvoiceRejectedHomeCheck = 'invoiceRejectedHomeCheck',
  InstallationPending = 'installationPending',
  InstallationDone = 'installationDone',
  Review = 'review',
  InvoiceCheckingSubmitHomeCheck = 'invoiceCheckingSubmitHomeCheck',
  InvoiceSubmittedHomeCheck = 'invoiceSubmittedHomeCheck',
  InvoiceSubmittedRemoteHomeCheck = 'invoiceSubmittedRemoteHomeCheck',
  InvoiceAcceptedHomeCheck = 'invoiceAcceptedHomeCheck',
  InvoiceAcceptedRemoteHomeCheck = 'invoiceAcceptedRemoteHomeCheck',
  InvoicePaymentPendingHomeCheck = 'invoicePaymentPendingHomeCheck',
  InvoicePaymentPendingRemoteHomeCheck = 'invoicePaymentPendingRemoteHomeCheck',
  InvoicePaidHomeCheck = 'invoicePaidHomeCheck',
  InvoicePaidRemoteHomeCheck = 'invoicePaidRemoteHomeCheck',
  OfferHomeCheckDone = 'offerHomeCheckDone',
  OfferRemoteHomeCheckDone = 'offerRemoteHomeCheckDone',
  InvoiceCheckingSubmitInstallation = 'invoiceCheckingSubmitInstallation', //Temporary state to check if invoice can be submitted directly without pool operator replace
  InvoiceSubmittedInstallation = 'invoiceSubmittedInstallation',
  InvoiceAcceptedInstallation = 'invoiceAcceptedInstallation',
  HomeCheckInvoiceRequested = 'homeCheckInvoiceRequested',
}

export interface IOfferDocsForInstaller {
  id: string;
  document: IFile;
}

export const rejectedStates = [
  OfferState.TenderCancelled,
  OfferState.TenderRejected,
  OfferState.OfferRejected,
  OfferState.OfferRejectedHomeCheck,
];

export const remoteHomeCheckStates = [
  OfferState.TenderSubmittedRemoteHomeCheck,
  OfferState.TenderAcceptedRemoteHomeCheck,
  OfferState.OfferAcceptedRemoteHomeCheckAppointmentNA,
  OfferState.OfferAcceptedRemoteHomeCheckAppointmentTBD,
  OfferState.OfferAcceptedRemoteHomeCheck,
  OfferState.OfferRemoteHomeCheckPending,
  OfferState.InvoiceSubmittedRemoteHomeCheck,
  OfferState.InvoiceAcceptedRemoteHomeCheck,
  OfferState.InvoicePaymentPendingRemoteHomeCheck,
  OfferState.InvoicePaidRemoteHomeCheck,
  OfferState.OfferRemoteHomeCheckDone,
];

export const homecheckStates = [
  OfferState.TenderSubmittedHomeCheck,
  OfferState.TenderAcceptedHomeCheck,
  OfferState.OfferAcceptedHomeCheckAppointmentNA,
  OfferState.OfferAcceptedHomeCheckAppointmentTBD,
  OfferState.OfferAcceptedHomeCheck,
  OfferState.OfferHomeCheckPending,
  OfferState.InvoiceAcceptedHomeCheck,
  OfferState.InvoiceSubmittedHomeCheck,
  OfferState.InvoiceCheckingSubmitHomeCheck,
  OfferState.HomeCheckInvoiceRequested,
  OfferState.InvoicePaymentPendingHomeCheck,
  OfferState.InvoicePaidHomeCheck,
  OfferState.OfferHomeCheckDone,
  OfferState.TenderCreatedWithoutAppointments,
  OfferState.InvoiceRejectedHomeCheck,
  ...remoteHomeCheckStates,
];

export const installationStates = [
  OfferState.TenderSubmitted,
  OfferState.TenderAccepted,
  OfferState.OfferAccepted,
  OfferState.OfferSubmitted,
  OfferState.InstallationPending,
  OfferState.InstallationDone,
  OfferState.InvoiceAcceptedInstallation,
  OfferState.InvoiceSubmittedInstallation,
  OfferState.InvoiceRejectedInstallation,
];

export const statesIgnoreValidity = [
  OfferState.OfferHomeCheckPending,
  OfferState.OfferRemoteHomeCheckPending,
  OfferState.InstallationPending,
  OfferState.InvoiceSubmittedHomeCheck,
  OfferState.InvoiceSubmittedRemoteHomeCheck,
  OfferState.InvoicePaidHomeCheck,
  OfferState.InvoicePaidRemoteHomeCheck,
  OfferState.InvoiceSubmittedInstallation,
  OfferState.InvoiceAcceptedInstallation,
];

export enum ContactType {
  Email = 'email',
  Phone = 'phone',
  SMS = 'sms',
}

export interface IPaginatedOffer {
  items: IOffer[];
  count: number;
}

export interface IOfferState {
  offerCreateState: RequestState | null;
  createError: string | null;

  offerUpdateState: RequestState | null;
  updateError: string | null;

  offerGetState: RequestState | null;
  getError: string | null;

  offerTransitionState: RequestState | null;
  transitionError: string | null;

  offersList: any[] | null;
  offersListError: string | null;
  offersListState: RequestState | null;

  offer: IOffer | null;

  offerFindState: RequestState | null;
  foundError: null;
  foundOffers: IPaginatedOffer;
  files:
    | {
        id: string;
        document: IFile;
      }[]
    | null;

  filesGetState: RequestState | null;
  fileUploadState: RequestState | null;

  offersInProgressCount: null | number;
  offersInProgressCountState: RequestState | null;
  offersInProgressCountError: string | null;

  downloadCalendarEventError: string | null;
  downloadCalendarEventState: RequestState | null;

  docsForInstaller: [];
  docsForInstallerState: RequestState;
}

export const OFFER_GET = 'OFFER_GET';
export const OFFER_GET_BY_INSTALLER = 'OFFER_GET_BY_INSTALLER';
export const OFFER_GET_SUCCESS = 'OFFER_GET_SUCCESS';
export const OFFER_GET_ERROR = 'OFFER_GET_ERROR';

export const OFFER_CREATE = 'OFFER_CREATE';
export const OFFER_CREATE_SUCCESS = 'OFFER_CREATE_SUCCESS';
export const OFFER_CREATE_ERROR = 'OFFER_CREATE_ERROR';

export const OFFER_UPDATE = 'OFFER_UPDATE';
export const OFFER_UPDATE_SUCCESS = 'OFFER_UPDATE_SUCCESS';
export const OFFER_UPDATE_ERROR = 'OFFER_UPDATE_ERROR';

export const UPLOAD_OFFER_FILE = 'UPLOAD_OFFER_FILE';

export const UPLOAD_OFFER_FILE_SUCCESS = 'UPLOAD_OFFER_FILE_SUCCESS';
export const UPLOAD_OFFER_FILE_ERROR = 'UPLOAD_OFFER_FILE_ERROR';

export const GET_OFFER_FILES = 'GET_OFFER_FILES'; //TODO: Make generic document
export const GET_OFFER_FILES_SUCCESS = 'GET_OFFER_FILES_SUCCESS';
export const GET_OFFER_FILES_ERROR = 'GET_OFFER_FILES_ERROR';

export const OFFER_TRANSITION = 'OFFER_TRANSITION';
export const OFFER_TRANSITION_SUCCESS = 'OFFER_TRANSITION_SUCCESS';
export const OFFER_TRANSITION_ERROR = 'OFFER_TRANSITION_ERROR';
export const OFFER_TRANSITION_RESET = 'OFFER_TRANSITION_RESET';

export const OFFER_REASSIGN_INSTALLER = 'OFFER_REASSIGN_INSTALLER';
export const OFFER_REASSIGN_INSTALLER_ERROR = 'OFFER_REASSIGN_INSTALLER_ERROR';
export const OFFER_REASSIGN_INSTALLER_SUCCESS = 'OFFER_REASSIGN_INSTALLER_SUCCESS';

export const OFFERS_FIND = 'OFFERS_FIND';
export const OFFERS_FIND_ERROR = 'OFFERS_FIND_ERROR';

export const MY_ORDERS_FIND = 'MY_ORDERS_FIND';
export const MY_ORDERS_FIND_SUCCESS = 'MY_ORDERS_FIND_SUCCESS';

export const OFFERS_GET_BY_ORGANIZATION = 'OFFERS_GET_BY_ORGANIZATION';
export const OFFERS_GET_BY_ORGANIZATION_SUCCESS = 'OFFERS_GET_BY_ORGANIZATION_SUCCESS';

export const OFFER_GET_FOR_CLIENT_REQUEST = 'OFFER_GET_FOR_CLIENT_REQUEST';

// Find all offers by client request id to end user which are approved by admin
export const GET_OFFERS_BY_CR = 'GET_OFFERS_BY_CR';
export const GET_OFFERS_BY_CR_SUCCESS = 'GET_OFFERS_BY_CR_SUCCESS';
export const GET_OFFERS_BY_CR_ERROR = 'GET_OFFERS_BY_CR_ERROR';

// TODO: define the better way for getting this counter. A separate request for things like that it's not good
export const OFFERS_IN_PROGRESS_COUNT = 'OFFERS_IN_PROGRESS_COUNT';
export const OFFERS_IN_PROGRESS_COUNT_SUCCESS = 'OFFERS_IN_PROGRESS_COUNT_SUCCESS';
export const OFFERS_IN_PROGRESS_COUNT_ERROR = 'OFFERS_IN_PROGRESS_COUNT_ERROR';

export const DOWNLOAD_CALENDAR_EVENT = 'DOWNLOAD_CALENDAR_EVENT';
export const DOWNLOAD_CALENDAR_EVENT_SUCCESS = 'DOWNLOAD_CALENDAR_EVENT_SUCCESS';
export const DOWNLOAD_CALENDAR_EVENT_ERROR = 'DOWNLOAD_CALENDAR_EVENT_ERROR';

export const DOWNLOAD_ALL_OFFER_DOCUMENTS = 'DOWNLOAD_ALL_OFFER_DOCUMENTS';
export const DOWNLOAD_ALL_OFFER_DOCUMENTS_ERROR = 'DOWNLOAD_ALL_OFFER_DOCUMENTS_ERROR';

export const OFFER_GET_DOCS_FOR_INSTALLER = 'OFFER_GET_DOCS_FOR_INSTALLER';
export const OFFER_GET_DOCS_FOR_INSTALLER_SUCCESS = 'OFFER_GET_DOCS_FOR_INSTALLER_SUCCESS';
export const OFFER_GET_DOCS_FOR_INSTALLER_ERROR = 'OFFER_GET_DOCS_FOR_INSTALLER_ERROR';

//Download Calendar Event
export interface DownloadCalendarEventAction {
  type: typeof DOWNLOAD_CALENDAR_EVENT;
  payload: string;
}

export interface DownloadCalendarEventSuccessAction {
  type: typeof DOWNLOAD_CALENDAR_EVENT_SUCCESS;
}

export interface DownloadCalendarEventErrorAction {
  type: typeof DOWNLOAD_CALENDAR_EVENT_ERROR;
  payload: any;
}

//Count In Progress Offers
export interface OffersInProgressCountAction {
  type: typeof OFFERS_IN_PROGRESS_COUNT;
}

export interface OffersInProgressCountSuccessAction {
  type: typeof OFFERS_IN_PROGRESS_COUNT_SUCCESS;
  payload: any;
}

export interface OffersInProgressCountErrorAction {
  type: typeof OFFERS_IN_PROGRESS_COUNT_ERROR;
  payload: any;
}

//Get Offer
export interface OfferGetAction {
  type: typeof OFFER_GET;
  payload: IFindWithRelations;
}

//Get Offer For Client Request
export interface OfferGetForClientRequestAction {
  type: typeof OFFER_GET_FOR_CLIENT_REQUEST;
  payload: string;
}

//Get Offer For Client Request By Logged In Installer
export interface OfferGetByInstallerAction {
  type: typeof OFFER_GET_BY_INSTALLER;
  payload: string;
}

export interface OfferGetSuccessAction {
  type: typeof OFFER_GET_SUCCESS;
  payload: IOffer;
}

export interface OfferGetErrorAction {
  type: typeof OFFER_GET_ERROR;
  payload: any;
}

//Upload file for existing offer
export interface OfferFileUploadAction {
  type: typeof UPLOAD_OFFER_FILE;
  payload: {
    id: string; //offer id
    document: File;
    mediaCategory: MediaCategoryType;
    onProgress?: Function;
    onSuccess?: Function;
    onError?: Function;
  };
}

export interface OfferFileUploadSuccessAction {
  type: typeof UPLOAD_OFFER_FILE_SUCCESS;
  payload: {
    document: IDocument;
  };
}

export interface OfferFileUploadErrorAction {
  type: typeof UPLOAD_OFFER_FILE_ERROR;
  payload: any;
}

//Create Offer
export interface OfferCreateAction {
  type: typeof OFFER_CREATE;
  payload: {
    offer: IOffer;
    eventType: OfferEventType;
    document: File;
    fileCategory: MediaCategoryType;
    onProgress?: Function;
    onSuccess?: Function;
    onError?: Function;
  };
}

export interface OfferSuccessAction {
  type: typeof OFFER_CREATE_SUCCESS;
  payload: {
    offer: IOffer;
    document: IDocument;
  };
}

export interface OfferErrorAction {
  type: typeof OFFER_CREATE_ERROR;
  payload: any;
}

//Transition Offer
export interface OfferTransitionAction {
  type: typeof OFFER_TRANSITION;
  payload: {
    event: OfferEventType;
    offer: IOffer;
    onSuccess?: Function;
    onError?: Function;
  };
}

export interface OfferTransitionSuccessAction {
  type: typeof OFFER_TRANSITION_SUCCESS;
  payload: IOffer;
}

export interface OfferTransitionErrorAction {
  type: typeof OFFER_TRANSITION_ERROR;
  payload: any;
}

export interface OfferTransitionResetAction {
  type: typeof OFFER_TRANSITION_RESET;
  payload: any;
}

export interface MyOrdersFindAction {
  type: typeof MY_ORDERS_FIND;
  payload: {
    limit?: number;
    offset?: number;
    type: OfferStatusType;
  };
}
export interface MyOrdersFindSuccessAction {
  type: typeof MY_ORDERS_FIND_SUCCESS;
  payload: IOffer[];
}
export interface OffersGetByOrganizationAction {
  type: typeof OFFERS_GET_BY_ORGANIZATION;
  payload: ISearchDto;
}

export interface OffersGetByOrganizationSuccessAction {
  type: typeof OFFERS_GET_BY_ORGANIZATION_SUCCESS;
  payload: IPaginatedOffer;
}

export interface OffersFindErrorAction {
  type: typeof OFFERS_FIND_ERROR;
  payload: any;
}

// Get offer files actions
export interface GetOfferFilesAction {
  type: typeof GET_OFFER_FILES;
  payload: {
    offerId: string;
    category: MediaCategoryType;
  };
}

export interface GetOfferFilesSuccessAction {
  type: typeof GET_OFFER_FILES_SUCCESS;
  payload: any;
}

export interface GetOfferFilesErrorAction {
  type: typeof GET_OFFER_FILES_ERROR;
  payload: any;
}

export interface GetOffersByClientRequestAction {
  type: typeof GET_OFFERS_BY_CR;
  payload: string;
}
export interface GetOffersByClientRequestSuccessAction {
  type: typeof GET_OFFERS_BY_CR_SUCCESS;
  payload: string;
}

export interface GetOffersByClientRequestErrorAction {
  type: typeof GET_OFFERS_BY_CR_ERROR;
  payload: string;
}

export interface UpdateOffersAction {
  type: typeof OFFER_UPDATE;
  payload: {
    id: string;
    offer: IOffer;
    onSuccess?: any;
  };
}

export interface UpdateOffersSuccessAction {
  type: typeof OFFER_UPDATE_SUCCESS;
  payload: IOffer;
}

export interface UpdateOffersErrorAction {
  type: typeof OFFER_UPDATE_ERROR;
  payload: string;
}

export interface DownloadAllDocumentsAction {
  type: typeof DOWNLOAD_ALL_OFFER_DOCUMENTS;
  payload: any;
}

export interface DownloadAllDocumentsErrorAction {
  type: typeof DOWNLOAD_ALL_OFFER_DOCUMENTS_ERROR;
  payload: any;
}

export interface OfferReassignInstallerAction {
  type: typeof OFFER_REASSIGN_INSTALLER;
  payload: {
    offerId: string;
    installerId: string;
  };
}
export interface OfferReassignInstallerSuccessAction {
  type: typeof OFFER_REASSIGN_INSTALLER_SUCCESS;
  payload: IOffer;
}
export interface OfferReassignInstallerErrorAction {
  type: typeof OFFER_REASSIGN_INSTALLER_ERROR;
  payload: {
    error: any;
  };
}

export interface OfferGetDocsForInstallerAction {
  type: typeof OFFER_GET_DOCS_FOR_INSTALLER;
  payload: string;
}
export interface OfferGetDocsForInstallerSuccessAction {
  type: typeof OFFER_GET_DOCS_FOR_INSTALLER_SUCCESS;
  payload: string;
}
export interface OfferGetDocsForInstallerErrorAction {
  type: typeof OFFER_GET_DOCS_FOR_INSTALLER_ERROR;
  payload: string;
}

export type OfferActionTypes =
  | OfferGetAction
  | OfferGetForClientRequestAction
  | OfferGetByInstallerAction
  | OfferGetSuccessAction
  | OfferGetErrorAction
  | OfferCreateAction
  | OfferSuccessAction
  | OfferErrorAction
  | OfferTransitionAction
  | OfferTransitionSuccessAction
  | OfferTransitionErrorAction
  | OfferTransitionResetAction
  | MyOrdersFindAction
  | MyOrdersFindSuccessAction
  | OffersGetByOrganizationAction
  | OffersGetByOrganizationSuccessAction
  | OffersFindErrorAction
  | GetOfferFilesAction
  | GetOfferFilesSuccessAction
  | GetOfferFilesErrorAction
  | GetOffersByClientRequestAction
  | GetOffersByClientRequestSuccessAction
  | GetOffersByClientRequestErrorAction
  | OfferFileUploadAction
  | OfferFileUploadSuccessAction
  | OfferFileUploadErrorAction
  | OffersInProgressCountAction
  | OffersInProgressCountSuccessAction
  | OffersInProgressCountErrorAction
  | UpdateOffersAction
  | UpdateOffersSuccessAction
  | UpdateOffersErrorAction
  | DownloadCalendarEventAction
  | DownloadCalendarEventSuccessAction
  | DownloadCalendarEventErrorAction
  | OfferReassignInstallerAction
  | OfferReassignInstallerSuccessAction
  | OfferReassignInstallerErrorAction

  // Download all documents actions
  | DownloadAllDocumentsAction
  | DownloadAllDocumentsErrorAction
  // Get docs for installer
  | OfferGetDocsForInstallerAction
  | OfferGetDocsForInstallerSuccessAction
  | OfferGetDocsForInstallerErrorAction;

export enum OfferCategory {
  HomeCheck = 'homeCheck',
  Installation = 'installation',
}
