/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from 'react';
import _debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Col, Flex, Row, Tag as AntTag, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Tag from 'components/ui/Tag/Tag';
import DeleteAccountModal from 'components/modals/DeleteAccountModal';

import Filters from 'components/ui/Filters/Filters';
import ClientRequestTag from 'components/ui/ClientRequestTag/ClientRequestTag';
import DateFormat from 'components/ui/DateFormat/DateFormat';
import Table from 'components/ui/Table/Table';
import {
  DEFAULT_FILTERS_VALUE,
  DEFAULT_META_VALUE,
  DEFAULT_PAGINATION_PAGE_SIZE,
} from 'framework/constants';
import PoolTagCircle from 'pages/installer/TenderDetailsPage/ClientRequestTags/PoolTagCircle';
import {
  currentPool as currentPoolSelector,
  currentPoolRequestsSelector,
  currentPoolRequestsStateSelector,
  poolTagsSelector,
} from 'store/intermediate/intermediate.selectors';
import { poolGetRequestsById, poolTagsGet } from 'store/intermediate/intermediate.actions';
import { clientRequestArchive } from 'store/client-request/client-request.actions';
import {
  ClientRequestState,
  IAddress,
  IClientRequest,
} from 'store/client-request/client-request.types';
import { RequestState } from 'store/common.types';

import { IPool } from 'store/intermediate/intermediate.types';
import { IUserObject } from 'store/auth/auth.types';
import { formatPriceRange } from 'utils/currencyUtils';
import styles from './RequstsTab.module.sass';

export interface IRequestsProps {
  poolId: string;
  activeTabParam: string;
}
enum RequestsTabs {
  ACTIVE = 'requests',
  ARCHIVED = 'archivedRequests',
}
const ActiveRequestsTab: React.FC<IRequestsProps> = ({ poolId, activeTabParam }) => {
  const [meta, setMeta] = useState(DEFAULT_META_VALUE);
  const [isArchiveModal, setIsArchiveModal] = useState(false);
  const [clientRequest, setClientRequest] = useState<IClientRequest>();
  const [filters, setFilters] = useState(DEFAULT_FILTERS_VALUE);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: requests, total } = useSelector(currentPoolRequestsSelector);
  const currentPool: IPool = useSelector(currentPoolSelector);
  const poolTagsRaw = useSelector(poolTagsSelector);
  const requestsState = useSelector(currentPoolRequestsStateSelector);

  useEffect(() => {
    if (currentPool?.id) dispatch(poolTagsGet({ poolId: currentPool.id }));
  }, [dispatch, currentPool]);

  useEffect(() => {
    if (activeTabParam === RequestsTabs.ARCHIVED) {
      handleFiltersChange('archived', true);
    } else {
      handleFiltersChange('archived', '');
    }
  }, [activeTabParam]); // eslint-disable-line

  useEffect(() => {
    debouncedRequest({ ...meta, limit: DEFAULT_PAGINATION_PAGE_SIZE, ...filters }, poolId);
  }, [filters, poolId]); // eslint-disable-line

  const handlePageChange = (page: number, pageSize: number = DEFAULT_PAGINATION_PAGE_SIZE) => {
    const newMeta = {
      offset: pageSize !== meta.limit ? 0 : pageSize * (page - 1),
      limit: pageSize,
    };

    setMeta(newMeta);
    if (poolId) {
      dispatch(poolGetRequestsById({ ...newMeta, ...filters }, poolId));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRequest = useCallback(
    _debounce((filters: any, currentPoolId: string) => {
      if (!currentPoolId) return;
      dispatch(poolGetRequestsById(filters, currentPoolId));
    }, 500),
    [poolId],
  );

  const handleFiltersChange = (name: string, value: any) => {
    const newValue = {
      ...filters,
      [name]: value,
    };

    setMeta(DEFAULT_META_VALUE);
    setFilters(newValue);
  };

  const handleClearFilters = () => {
    setFilters(DEFAULT_FILTERS_VALUE);
    setMeta(DEFAULT_META_VALUE);
    dispatch(poolGetRequestsById({ offset: 0, ...DEFAULT_FILTERS_VALUE }, poolId));
  };

  const CRTableColumns = [
    {
      title: 'installerFlow:dashboard:id',
      dataIndex: 'displayId',
      key: 'displayId',
    },
    {
      title: 'installerFlow:dashboard:lastName',
      dataIndex: 'createdBy',
      key: 'lastName',
      render: (createdBy: IUserObject) => createdBy?.lastName,
    },
    {
      title: 'installerFlow:dashboard:zip',
      dataIndex: 'address',
      key: 'postalCode',
      render: (address: IAddress) => address?.postalCode,
    },
    {
      title: 'installerFlow:dashboard:period',
      dataIndex: 'period',
      key: 'period',
      render: (_text: string, row: IClientRequest) =>
        row?.soonestPossible || !row?.startDate ? (
          t('installerFlow:dashboard:soonest')
        ) : (
          <span>
            {t('installerFlow:dashboard:from')}
            <DateFormat date={row.startDate} />
          </span>
        ),
    },
    {
      title: 'installerFlow:dashboard:priceRange',
      dataIndex: 'priceRange',
      key: 'priceRange',
      render: (_text: string, row: IClientRequest) => formatPriceRange(row),
    },
    ...(currentPool.isParent
      ? [
          {
            title: 'installerFlow:dashboard:poolName',
            dataIndex: 'pool',
            key: 'pool',
            render: (pool: IPool) =>
              currentPool.id === pool.id ? (
                pool.name
              ) : (
                <Link to={`/my-projects/${pool.id}/requests`} onClick={(e) => e.stopPropagation()}>
                  {pool.name}
                </Link>
              ),
          },
        ]
      : []),
    {
      title: 'installerFlow:dashboard:statusAndTags',
      dataIndex: 'state',
      key: 'state',
      render: (state: ClientRequestState, row: IClientRequest) => (
        <Flex vertical>
          <div>
            <ClientRequestTag state={state} />
            {(row.archived || activeTabParam === RequestsTabs.ARCHIVED) && <Tag>Archived</Tag>}
          </div>
          <Flex align="center" gap="5px" className={styles.crTagLine}>
            {row.poolTags?.map((poolTag) => (
              <Tooltip
                key={poolTag.id}
                placement="bottom"
                title={<AntTag color={poolTag.colorCode}>{poolTag.title}</AntTag>}
              >
                <span>
                  <PoolTagCircle size="14" radius="7" fillColor={poolTag.colorCode} />
                </span>
              </Tooltip>
            ))}
          </Flex>
        </Flex>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: '5%',
      align: 'right',
      render: archiveAction,
    },
  ];

  function archiveAction(_: string, row: IClientRequest) {
    return (
      <Button
        type="text"
        icon={<DeleteOutlined />}
        disabled={!row.archivable || activeTabParam === RequestsTabs.ARCHIVED}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setIsArchiveModal(true);
          setClientRequest(row);
        }}
        danger
      />
    );
  }

  const archiveRequest = () => {
    if (clientRequest?.id) {
      dispatch(clientRequestArchive(clientRequest.id, !clientRequest.archived));
    }
    setIsArchiveModal(false);
  };

  const checkboxList: string[] = [
    ClientRequestState.Initiated,
    ClientRequestState.Cancelled,
    ClientRequestState.Submitted,
    ClientRequestState.ApprovalPending,
    ClientRequestState.HomeCheck,
    ClientRequestState.Approved,
    ClientRequestState.OfferSelectionHomeCheck,
    ClientRequestState.OfferSelectionInstallation,
    ClientRequestState.InstallationPending,
    ClientRequestState.Done,
  ];

  const isFiltersActive = Boolean(filters.query || filters.state.length);
  const archiveModalProps = {
    visible: isArchiveModal,
    onOk: archiveRequest,
    onCancel: () => setIsArchiveModal(false),
    okText: t('intermediate:myProjects:archive:button:ok'),
    cancelText: t('intermediate:myProjects:archive:button:cancel'),
    modalText: t('intermediate:myProjects:archive:innerText'),
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Filters
            filters={filters}
            setFilters={setFilters}
            inputName="search"
            inputValue={filters.query}
            inputPlaceholder={t('intermediate:myProjects:filters:searchField')}
            onFiltersChange={handleFiltersChange}
            onClearFilters={handleClearFilters}
            isActive={isFiltersActive}
          >
            <Filters.MultiSelect
              name="state"
              label={t('intermediate:myProjects:filters:searchStatusLabel')}
              options={checkboxList.map((state) => ({
                value: state,
                label: t(`common:allStates:${state}`),
              }))}
              placeholder={t('intermediate:myProjects:filters:searchStatus')}
              onFilterChange={() => {
                setMeta(DEFAULT_META_VALUE);
              }}
            />
            {poolTagsRaw?.tags.length > 0 && (
              <Filters.MultiSelect
                name="poolTags"
                label={t('intermediate:tenderDetails:clientRequestTags:poolTags')}
                options={poolTagsRaw?.tags?.map((tag) => ({
                  value: tag.id,
                  label: tag.title,
                }))}
                placeholder={t('intermediate:myProjects:filters:searchTags')}
                onFilterChange={() => {
                  setMeta(DEFAULT_META_VALUE);
                }}
              />
            )}
          </Filters>

          <div className={styles.table}>
            <Table
              columns={CRTableColumns.map((tender: any) => ({
                ...tender,
                title: t(`${tender.title}`),
              }))}
              rowKey="id"
              loading={requestsState === RequestState.Loading || requestsState === null}
              dataSource={requests}
              onRowClick={(record: any) => `/my-projects/client-request/${record.id}`}
              pagination={{
                total,
                showSizeChanger: true,
                position: ['bottomCenter'],
                pageSize: meta.limit,
                current: meta.offset ? meta.offset / meta.limit + 1 : 1,
                onChange: handlePageChange,
              }}
            />
          </div>
        </Col>
      </Row>
      <DeleteAccountModal {...archiveModalProps} />
    </>
  );
};

export default ActiveRequestsTab;
