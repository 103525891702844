import React from 'react';

import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';
import styles from './TermsConditionsForInstaller.module.sass';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'Anbieterbedingungen für tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: 'Conditions générales du fournisseur de tendergy',
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german: `Die Schneider Electric GmbH, (nachfolgend „Schneider Electric“ oder „wir“ oder „uns“) betreibt die Internet-Plattform <a href="${document.location.origin}/">${document.location.origin}/</a> sowie alle damit verbundenen Internet-Domänen und -Auftritte (nachfolgend die „Plattform“), auf der Anbieter (nachfolgend der oder die „Anbieter“ oder „Sie“) Endkunden (nachfolgend die „Kunden“) die Installation (und ggfs. Verkauf) einer sog. Wallbox („Service“) anbieten können.`,
    targetLanguage: `Schneider Electric GmbH, (ci-après dénommée « Schneider Electric » ou « nous ») exploite la plateforme Internet <a href="${document.location.origin}/">${document.location.origin}/</a>, ainsi que tous les domaines Internet et sites Web associés (ci-après dénommés la « Plateforme »), sur lesquels les fournisseurs (ci-après dénommés le « Fournisseur » ou « vous ») peuvent proposer aux clients finaux (ci-après dénommés les « Clients ») l'installation (et, le cas échéant, la vente) d'une wallbox (« Service »).`,
  },
  {
    german:
      'Anbieter und Poolbetreiber stellen im Zuge dieser Bedingungen immer Unternehmen im Sinne von § 14 BGB dar. Kunden können entweder Verbraucher im Sinne von § 13 BGB oder Unternehmen im Sinne von § 14 BGB darstellen. Insofern gilt folgendes:',
    targetLanguage:
      "Aux fins des présentes conditions générales, le fournisseur et l'exploitant du pool constituent toujours des sociétés au sens de l'article 14 du Code civil allemand. Les clients peuvent être soit des consommateurs au sens de l'article 13 du Code civil allemand, soit des entreprises au sens de l'article 14 du Code civil allemand. À cet égard, les dispositions suivantes s'appliquent :",
  },
  {
    german:
      'Ein „Verbraucher“ ist jede natürliche Person, die den Vertrag zu Zwecken abschließt, die überwiegend keiner gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 Bürgerliches Gesetzbuch – „BGB“).',
    targetLanguage:
      'Un « consommateur » est toute personne physique qui conclut un contrat à des fins qui ne sont principalement pas imputables à une activité professionnelle commerciale ou indépendante (article 13 du Code civil allemand).',
  },
  {
    german:
      'Ein „Unternehmer“ ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss des Vertrages in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt (§ 14 Abs. 1 BGB).',
    targetLanguage:
      "Un « entrepreneur » est une personne physique ou morale ou une société de personnes dotée de la capacité juridique qui, au moment de la conclusion du contrat, agit dans l'exercice de son activité professionnelle commerciale ou indépendante (article 14 (1) du Code civil allemand).",
  },
  {
    german:
      'Kunde ist somit grundsätzlich eine natürliche Person, es sei denn, sie ist ausdrücklich als juristische Person bei tendergy registriert.',
    targetLanguage:
      'En principe, un Client est donc une personne physique, à moins qu’elle ne soit expressément enregistrée en tant que personne morale auprès de tendergy.',
  },
  {
    german:
      'Schneider Electric stellt die Plattform nur zur Verfügung, um es den Anbietern zu ermöglichen, den Kunden oder Pool-Betreibern ihren Service direkt anzubieten bzw. Verträge abzuschließen. Schneider Electric tritt als vom Anbieter autorisierter und beauftragter gewerblicher Vermittler auf und wird zu keiner Zeit Vertragspartner bezüglich des Services, den der Anbieter den Kunden oder Pool-Betreibern anbietet.',
    targetLanguage:
      "Schneider Electric ne fournira la Plateforme que dans le but de permettre aux Fournisseurs de proposer leurs services ou de conclure des contrats directement avec les Clients ou les Opérateurs de pool. Schneider Electric agira en tant qu'intermédiaire commercial autorisé et désigné par le Fournisseur et ne deviendra à aucun moment partie à un quelconque contrat relatif au service proposé par le Fournisseur aux Clients ou aux Opérateurs de pool.",
  },
  {
    german: '1. Anwendungsbereich dieser Anbieterbedingungen',
    targetLanguage: "1. Champ d'application des présentes conditions générales",
    subSection: true,
  },
  {
    german: `Diese Anbieterbedingungen (nachfolgend die „Bedingungen“ oder der „Vertrag“) gelten für alle Anbieter, die den Kunden ihren Service über die Plattform anbieten, und regeln lediglich das Verhältnis zwischen dem Anbieter und Schneider Electric. Das Vertragsverhältnis zwischen dem Anbieter und tendergy umfasst nur die ordnungsgemäße Vermittlung der Kunden und Pool-Betreiber an den Anbieter. Die Vermittlung zwischen Kunden bzw. Pool-Betreibern und Anbietern findet in verschiedenen Gruppen und/oder Projekten statt – sog. Pools („Pools“). Das Verhältnis zwischen den Kunden und dem Anbieter ist nicht Gegenstand der Bedingungen, sondern allein Gegenstand der einzelnen Verträge, die zwischen diesen Parteien abgeschlossen werden. Das Verhältnis zwischen Schneider Electric und den Kunden ist unterdessen Gegenstand der Nutzungsbedingungen von Schneider Electric unter <a href="${document.location.origin}/legal/terms-and-conditions?at=2">${document.location.origin}/legal/terms-and-conditions?at=2</a>`,
    targetLanguage: `Les présentes Conditions Générales de Vente (ci-après les « Conditions » ou le « Contrat ») s'appliquent à tous les Fournisseurs proposant leurs services aux Clients via la Plateforme et régissent uniquement la relation entre le Fournisseur et Schneider Electric. La relation contractuelle entre le Fournisseur et tendergy comprend uniquement l'orientation appropriée des Clients et des Opérateurs de Pool vers le Fournisseur. La négociation entre les Clients ou les Opérateurs de Pool et les Fournisseurs se fait via différents groupes et/ou projets - appelés pools (« Pools »). La relation entre les Clients et le Fournisseur ne fait pas l'objet des Conditions mais uniquement des contrats individuels conclus entre ces parties. La relation entre Schneider Electric et ses clients est en outre régie par les Conditions d'utilisation de Schneider Electric disponibles à l'adresse <a href="${document.location.origin}/legal/terms-and-conditions?at=2">${document.location.origin}/legal/terms-and-conditions?at=2</a>`,
  },
  {
    german: '2. Registrierung des Anbieters',
    targetLanguage: '2. Enregistrement du fournisseur',
    subSection: true,
  },
  {
    german: '2.1',
    targetLanguage: '2.1',
    bold: true,
  },
  {
    german:
      'Nach Registrierung auf der Plattform ist der Anbieter berechtigt, seinen Service über die Plattform und insbesondere dem von Schneider Electric betriebenen öffentlichen Pool anzubieten. Im Zuge der Registrierung auf der Plattform nimmt der Anbieter die Bedingungen an.',
    targetLanguage:
      'Le Fournisseur est autorisé à proposer son Service via la Plateforme, et notamment le Pool public exploité par Schneider Electric, dès son inscription sur la Plateforme. Le Fournisseur accepte les Conditions Générales lors de son inscription sur la Plateforme.',
  },
  {
    german: '2.2',
    targetLanguage: '2.2',
    bold: true,
  },
  {
    german:
      'Schneider Electric ist nicht dafür verantwortlich, die Identität, die Echtheit oder Berechtigungsnachweise der Person zu überprüfen, die diesen Vertrag abschließt. Schneider Electric ist jedoch jederzeit berechtigt, sich derartige Nachweise auf Kosten des Anbieters vorlegen zu lassen.',
    targetLanguage:
      "Schneider Electric n'est pas tenu de vérifier l'identité, l'authenticité ou les références de la personne qui conclut le présent Contrat. Cependant, Schneider Electric est en droit d'obtenir à tout moment ces preuves aux frais du Fournisseur.",
  },
  {
    german: '2.3',
    targetLanguage: '2.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist verpflichtet, seinen Service als Unternehmer gemäß den für ihn einschlägigen Steuervorschriften des anwendbaren Umsatzsteuergesetzes und den Verfahren und Anforderungen der Steuerbehörden anzubieten und zu erbringen. Der Anbieter ist verpflichtet, im Rahmen der Führung seiner Geschäfte bzw. seiner selbständigen beruflichen Tätigkeit die maßgeblichen Verträge mit den Kunden abzuschließen.',
    targetLanguage:
      "Le prestataire est tenu de proposer et de fournir ses services en tant qu'entrepreneur conformément aux dispositions fiscales de la législation sur la TVA en vigueur et aux procédures et exigences des autorités fiscales qui lui sont applicables. Le prestataire est tenu de conclure les contrats pertinents avec les clients dans le cadre de leur activité professionnelle commerciale ou indépendante.",
  },
  {
    german: '2.4',
    targetLanguage: '2.4',
    bold: true,
  },
  {
    german:
      'Der Anbieter kann sich von Schneider Electric zum sog.„Pool-Betreiber“ unter den Bedingungen eines separat abzuschließenden Lizenzvertrags freischalten lassen. Pool-Betreiber können zusätzlich zum von Schneider Electric betriebenen öffentlichen Pool auch private Pools zur Vermittlung („Privater Vermittlungs-Pool“) von Installationsleistungen durch anderer Anbieter oder zum Abschluss der Installationsleistung mit sich selber („Privater Betreiber-Pool“) erstellen. Anbieter und Kunden können diesen privaten Pools nur auf Einladung der entsprechenden Pool-Betreiber beitreten. Bei Beitritt zu diesen privaten Pools unterwerfen sich die Anbieter den besonderen Bestimmungen des jeweiligen Pools und insbesondere dem entsprechenden Buchungsverfahren wie in Ziffer 5 dargestellt.',
    targetLanguage:
      "Le fournisseur peut recevoir de Schneider Electric l'autorisation de devenir un « opérateur de pool » selon les termes et conditions d'un contrat de licence à conclure séparément. Les opérateurs de pool peuvent, en plus du pool public exploité par Schneider Electric, également créer des pools privés pour le courtage (« pool de courtage privé ») de services d'installation par d'autres fournisseurs ou pour la conclusion de contrats de services d'installation avec eux-mêmes (« pool d'opérateur privé »). Les fournisseurs et les clients ne peuvent rejoindre ces pools privés que sur invitation des opérateurs de pool respectifs. Lorsqu'ils rejoignent ces pools privés, les fournisseurs sont soumis aux conditions générales spécifiques du pool concerné et en particulier à la procédure de réservation pertinente telle que définie à l'article 5.",
  },
  {
    german: '3. Der von Schneider Electric angebotene Service',
    targetLanguage: '3. Service fourni par Schneider Electric',
    subSection: true,
  },
  {
    german:
      'Die Plattform ermöglicht es den Anbietern, den Kunden ihren Service anzubieten und Verträge mit diesen abzuschließen. Dabei beauftragt der Anbieter Schneider Electric als gewerblichen Vermittler und bevollmächtigt uns, die Anbahnung und den Vertragsschluss in Bezug auf den Service direkt zwischen dem Anbieter bzw. dem Pool-Betreiber und den Kunden über die Plattform abzuwickeln.',
    targetLanguage:
      "La Plateforme permet aux Fournisseurs de proposer leurs services aux Clients et de conclure des contrats avec eux. Ce faisant, le Fournisseur désigne Schneider Electric comme intermédiaire commercial et nous autorise à gérer directement l'initiation et la conclusion du contrat relatif au Service entre le Fournisseur ou l'Opérateur du Pool et les Clients via la Plateforme.",
  },
  {
    german: '4. Pflichten des Anbieters',
    targetLanguage: '4. Obligations du fournisseur',
    subSection: true,
  },
  {
    german: '4.1',
    targetLanguage: '4.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass alle Informationen im Zusammenhang mit seinem Angebot jederzeit richtig, wahr und nicht irreführend sind. Der Anbieter ist verpflichtet, die hochgeladenen Informationen zeitnah zu aktualisieren, so dass die Richtigkeit der bereitgestellten Informationen sichergestellt ist. Der Anbieter kann für alle Forderungen der Kunden haftbar gemacht werden, die auf Unrichtigkeiten der hochgeladenen, auf der Plattform angezeigten Informationen (wie z. B. Informationen zu dem Service und Preisen) beruhen.',
    targetLanguage:
      "Le Fournisseur garantit que toutes les informations relatives à son Offre sont à tout moment exactes, véridiques et non trompeuses. Le Fournisseur est tenu de mettre à jour les informations téléchargées en temps utile afin de garantir l'exactitude des informations fournies. Le Fournisseur peut être tenu responsable de toute réclamation des Clients fondée sur des inexactitudes dans les informations téléchargées affichées sur la Plateforme (telles que les informations sur le Service et les prix).",
  },
  {
    german: '4.2',
    targetLanguage: '4.2',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass der Service ausschließlich im Einklang mit allen anwendbaren Gesetzen erbracht wird und dass die Erbringung des Services nicht gegen Rechte Dritter verstößt. Insbesondere garantiert der Anbieter, dass er (a) sämtliche Verbraucherschutz- und sonstige Schutz-, Informations- und Beratungspflichten und -vorschriften einhält und (b) über alle erforderlichen verwaltungsrechtlichen und sonstigen Genehmigungen und Lizenzen sowie über Versicherungen und ähnliches verfügt, und dass er seinen im Rahmen der Erfüllung des Services eingesetzten Auftragnehmern Weisungen erteilt, die diesen Bedingungen entsprechen. Der Anbieter ist verpflichtet, auf Nachfrage entsprechende Nachweise und Unterlagen, wie z. B. Kopien von Genehmigungen und Lizenzen, vorzulegen. Für den Fall, dass der vom Anbieter erbrachte Service die genannten Vorgaben nicht einhält oder wenn ein entsprechender Verdacht besteht, ist der Anbieter verpflichtet, unverzüglich auf eigene Kosten mit den Verwaltungsbehörden oder Wettbewerbs-/Verbraucherschutzverbänden zusammenzuarbeiten.',
    targetLanguage:
      "Le prestataire garantit que le service sera fourni conformément à toutes les lois applicables et que la fourniture du service ne portera atteinte à aucun droit de tiers. En particulier, le prestataire garantit qu'il (a) respectera toutes les obligations et réglementations en matière de protection des consommateurs, de protection, d'information et de consultation et (b) disposera de tous les permis et licences administratifs et autres nécessaires, ainsi que des polices d'assurance et équivalentes, et qu'il donnera des instructions à ses sous-traitants engagés dans l'exécution du service qui soient conformes à ces conditions. Le prestataire est tenu de soumettre sur demande les preuves et documents appropriés, tels que les copies des permis et licences. Dans le cas où le service fourni par le prestataire ne serait pas conforme aux spécifications susmentionnées, ou en cas de suspicion à cet effet, le prestataire est tenu de coopérer immédiatement avec les autorités administratives ou les associations de protection de la concurrence/des consommateurs à ses propres frais.",
  },
  {
    german: '4.3',
    targetLanguage: '4.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter sichert zu und garantiert, dass er den Kunden alle für die Ausführung des Services erforderlichen Informationen (wie z. B. den Treffpunkt und die Uhrzeit) rechtzeitig im Voraus mitteilt.',
    targetLanguage:
      "Le Fournisseur assure et garantit qu'il fournira aux Clients toutes les informations nécessaires à l'exécution du Service (telles que le lieu et l'heure de rendez-vous) en temps utile avant l'exécution du Service.",
  },
  {
    german: '4.4',
    targetLanguage: '4.4',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist nur berechtigt, seinen eigenen Service über die Plattform anzubieten. Der Anbieter ist nicht berechtigt, einen Service als Vermittler anzubieten. Sofern der Anbieter dennoch als Vermittler oder Makler auftritt, wird er wie eine Vertragspartei behandelt und kann für etwaige Schäden haftbar gemacht werden. Der Anbieter muss in der Lage sein, den angebotenen Service auszuführen. Er ist nicht berechtigt, einen vorgetäuschten Service auf der Plattform einzustellen.',
    targetLanguage:
      "Le prestataire est uniquement autorisé à proposer ses propres services via la plateforme. Le prestataire n'est pas autorisé à proposer un service en tant qu'intermédiaire. Dans la mesure où le prestataire agit néanmoins en tant qu'intermédiaire, il est traité comme une partie contractante et peut être tenu responsable de tout dommage. Le prestataire doit être en mesure d'exécuter le service proposé. Le prestataire n'est pas autorisé à publier des services factices sur la plateforme.",
  },
  {
    german: '4.5',
    targetLanguage: '4.5',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist verpflichtet, eine umfassende allgemeine Haftpflichtversicherung für alle über die Plattform angebotenen Services abzuschließen. Der Anbieter muss zwingend eine Versicherungssumme vereinbaren, die für die festgelegten Buchungsvolumina und -risiken angemessen ist. Darüber hinaus muss die allgemeine Haftpflichtversicherung potenzielle Regressansprüche von Schneider Electric abdecken.',
    targetLanguage:
      "Le prestataire est tenu de souscrire une assurance responsabilité civile générale complète pour tous les services proposés via la plateforme. Le prestataire est tenu de convenir d'une somme d'assurance adaptée aux volumes de réservation et aux risques spécifiés. L'assurance responsabilité civile générale doit également couvrir les éventuels recours de Schneider Electric.",
  },
  {
    german: '4.6',
    targetLanguage: '4.6',
    bold: true,
  },
  {
    german:
      'Die vom Anbieter auf seinem Account für seine Angebote und Services angegebenen Preise sind einschließlich der USt. bzw. MwSt. und sonstiger Preisbestandteile. Es ist nicht gestattet, von den Kunden zu verlangen, dass sie auf die Ausführung des Service weitere Beträge, Steuern, Gebühren oder Provisionen zahlen oder sonstige Kosten erstatten.',
    targetLanguage:
      "Les prix indiqués par le fournisseur sur son compte pour ses offres et services incluent la TVA et les autres éléments de prix. Il est interdit d'exiger des clients le paiement de montants supplémentaires, de taxes, de frais ou de commissions sur la prestation du service ou le remboursement de tout autre coût.",
  },
  {
    german:
      'Der Anbieter ist verpflichtet, den Kunden eine ordnungsgemäße Rechnung zu übermitteln, die sämtliche anwendbaren steuerlichen Voraussetzungen erfüllt.',
    targetLanguage:
      'Le Fournisseur est tenu d’envoyer aux Clients une facture appropriée, conforme à toutes les exigences fiscales applicables.',
  },
  {
    german: '5. Buchungsverfahren',
    targetLanguage: '5. Procédure de réservation',
    subSection: true,
  },
  {
    german: '5.1',
    targetLanguage: '5.1',
    bold: true,
  },
  {
    german:
      'Im öffentlichen Pool gilt: Wenn der Kunde an einem bestimmten Service interessiert ist, erstellt der Kunde eine Anfrage im Pool, und die Anbieter im Pool werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    targetLanguage:
      'Dans le Pool Public, si le Client est intéressé par un Service particulier, le Client devra créer une demande dans le Pool et les Fournisseurs du Pool seront dûment informés de la demande concernée.',
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, können die Anbieter im Pool dem Kunden ein verbindliches Angebot machen. Dieses Angebot unterliegt ggf. der abschließenden Kontrolle durch Schneider Electric. Der Anbieter ist an dieses Angebot 60 Werktage gebunden. Wenn der Kunde das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Kunden kommt ein verbindlicher Vertrag mit dem Anbieter des verbindlichen Angebots über den Service zustande.',
    targetLanguage:
      "Après avoir fourni toutes les informations nécessaires, les fournisseurs du pool peuvent soumettre une offre ferme au client. Cette offre peut être soumise à un contrôle final par Schneider Electric. Si le client accepte l'offre, Schneider Electric en informera le fournisseur. Après acceptation par le client, un contrat ferme sera conclu avec le fournisseur concernant l'offre ferme pour le service.",
  },
  {
    german: '5.2',
    targetLanguage: '5.2',
    bold: true,
  },
  {
    german:
      'In Privaten Vermittlungs-Pools gilt: Wenn der Kunde in diesem Pool an einem bestimmten Service interessiert ist, erstellt er eine entsprechende Anfrage, und die Anbieter im Pool sowie der Pool-Betreiber werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    targetLanguage:
      "Dans les pools de courtage privés, si le client de ce pool est intéressé par un service particulier, le client doit créer une demande à cet effet et les fournisseurs du pool et l'opérateur du pool seront dûment informés de la demande en question.",
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, können die Anbieter dem Kunden ein verbindliches Angebot machen. Dieses Angebot unterliegt ggf. der abschließenden Kontrolle durch den Pool-Betreiber. Der Anbieter ist an dieses Angebot 60 Werktage gebunden. Wenn der Kunde das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Kunden kommt ein verbindlicher Vertrag mit dem Anbieter des verbindlichen Angebots über den Service zustande.',
    targetLanguage:
      "Les fournisseurs peuvent faire une offre ferme au client en fournissant toutes les informations requises. Cette offre est soumise à un examen final par l'opérateur du pool, le cas échéant. Le fournisseur sera lié par cette offre pendant 60 jours ouvrables. Si le client accepte l'offre, Schneider Electric en informera le fournisseur en conséquence. Une fois l'acceptation du client effectuée, un contrat contraignant avec le fournisseur de l'offre ferme pour le service sera conclu.",
  },
  {
    german: '5.3',
    targetLanguage: '5.3',
    bold: true,
  },
  {
    german:
      'In Privaten Betreiber-Pools gilt: Wenn der Kunde in diesem Pool an einem bestimmten Service interessiert ist, erstellt er eine Anfrage, und die Anbieter in diesem Pool sowie der Pool-Betreiber werden ordnungsgemäß über die betreffende Anfrage in Kenntnis gesetzt.',
    targetLanguage:
      "Dans les Pools d'Opérateurs Privés, si le Client de ce Pool est intéressé par un Service particulier, le Client doit créer une demande à cet effet et les Fournisseurs du Pool et l'Opérateur du Pool seront dûment informés de la demande en question.",
  },
  {
    german:
      'Nach Angabe aller erforderlichen Angaben, kann der Anbieter dem Pool-Betreiber ein verbindliches Angebot zur Ausführung der Anfrage des Kunden als Unterauftragnehmer des Pool-Betreibers machen. Der Anbieter ist an dieses Angebot fünfzehn (15) Werktage gebunden. Wenn der Pool-Betreiber das Angebot annimmt, wird Schneider Electric dies dem Anbieter mitteilen. Mit der Annahme des Pool-Betreibers kommt ein verbindlicher Vertrag zwischen dem Anbieter und dem Pool-Betreiber hinsichtlich des verbindlichen Angebots über die Erbringung des Service beim Kunden als Unterauftrag im Namen und Auftrag des Pool-Betreibers zustande.',
    targetLanguage:
      "Après avoir fourni toutes les informations requises, le Fournisseur peut faire une offre ferme à l'Opérateur de pool pour exécuter la demande du Client en tant que sous-traitant de l'Opérateur de pool. Le Fournisseur sera lié par cette offre pendant quinze (15) jours ouvrables. Si l'Opérateur de pool accepte l'offre, Schneider Electric en informera le Fournisseur. Un contrat contraignant entre le Fournisseur et l'Opérateur de pool concernant l'offre ferme pour la fourniture du Service dans les locaux du Client sera conclu en tant que sous-contrat au nom et pour le compte de l'Opérateur de pool après acceptation par l'Opérateur de pool.",
  },
  {
    german: '5.4',
    targetLanguage: '5.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet nicht für die Genauigkeit und/oder die Vollständigkeit der kundenbezogenen Daten und Anfragen.',
    targetLanguage:
      'Schneider Electric ne sera pas responsable de l’exactitude et/ou de l’exhaustivité des données et demandes relatives au Client.',
  },
  {
    german: '6. Buchungsangebote des Anbieters',
    targetLanguage: '6. Offres de réservation des fournisseurs',
    subSection: true,
  },
  {
    german: '6.1',
    targetLanguage: '6.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter gibt die Angaben zum Service in den Anbieter-Account ein. Er ist dafür verantwortlich sicherzustellen, dass die gemachten Angaben stets auf dem aktuellen Stand sind. Überarbeitete, stornierte oder nicht mehr gültige Services sind unverzüglich zu löschen.',
    targetLanguage:
      'Le fournisseur saisit les informations relatives au service dans le compte du fournisseur. Il est de la responsabilité du fournisseur de veiller à ce que les informations fournies soient toujours à jour. Les services modifiés, annulés ou qui ne sont plus valables doivent être supprimés immédiatement.',
  },
  {
    german: '6.2',
    targetLanguage: '6.2',
    bold: true,
  },
  {
    german:
      'Aus der Servicebeschreibung muss klar ersichtlich sein, dass der Anbieter den Service in seinem eigenen Namen und in seiner eigenen Verantwortung anbietet. Der Service muss unter dem allgemein verwendeten Markennamen angeboten werden. Ist dieser Markenname ein anderer als der in allgemeinen (Marken-) Registern eingetragene, muss der Anbieter auch diesen eingetragenen Markennamen nennen. Es darf weder mittelbar noch unmittelbar der Eindruck entstehen, dass Schneider Electric Anbieter des Services ist oder das Schneider Electric die Qualität des angebotenen Services geprüft hat oder ihre Qualität anderweitig hervorhebt.',
    targetLanguage:
      "La description du service doit clairement indiquer que le prestataire fournit le service en son nom propre et sous sa propre responsabilité. Le service doit être proposé sous la marque commerciale d'usage courant. Si cette marque commerciale diffère de celle enregistrée dans les registres généraux (des marques), le prestataire doit également mentionner cette marque commerciale enregistrée. Il ne doit y avoir aucune impression directe ou indirecte que Schneider Electric est le fournisseur du service ou que Schneider Electric a vérifié la qualité du service proposé ou promeut sa qualité d'une autre manière.",
  },
  {
    german: '7. Verantwortung für den Inhalt',
    targetLanguage: '7. Responsabilité du contenu',
    subSection: true,
  },
  {
    german: '7.1',
    targetLanguage: '7.1',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist für den Inhalt verantwortlich, der über seinen Account übermittelt wird, wie z. B. Beschreibungen des Services. Schneider Electric behandelt diesen Inhalt des Anbieters wie externen Inhalt.',
    targetLanguage:
      'Le fournisseur est responsable du contenu transmis via son compte, comme les descriptions du service. Schneider Electric traitera ce contenu du fournisseur comme un contenu externe.',
  },
  {
    german: '7.2',
    targetLanguage: '7.2',
    bold: true,
  },
  {
    german:
      'Wird Schneider Electric über Inhalt informiert oder entdeckt Inhalt, der gegen diese Bedingungen oder sonstige rechtliche Bestimmungen verstößt, ist Schneider Electric berechtigt, diesen Inhalt ganz oder teilweise zu löschen. Falls der missbräuchliche Inhalt von einem Anbieter stammt, ist Schneider Electric berechtigt, den Zugang zu seinem Account zu sperren oder seinen Account zu löschen. Schneider Electric wird stets die jeweiligen beiderseitigen Interessen an dem betreffenden Fall berücksichtigen, bevor das Unternehmen einen Anbieter-Account ganz oder vorübergehend sperrt.',
    targetLanguage:
      "Si Schneider Electric est informé ou découvre un Contenu qui viole les présentes Conditions ou toute autre disposition légale, Schneider Electric sera en droit de supprimer ce Contenu en tout ou partie. Schneider Electric est en droit de bloquer l'accès au compte ou de supprimer le compte dans le cas où le contenu abusif émane d'un Fournisseur. Schneider Electric prendra toujours en compte les intérêts mutuels respectifs dans le cas concerné avant de suspendre un Compte Fournisseur totalement ou temporairement.",
  },
  {
    german: '7.3',
    targetLanguage: '7.3',
    bold: true,
  },
  {
    german:
      'Der Anbieter ist nur berechtigt, Inhalte und Daten hochzuladen, zu denen er alle notwendigen Rechte besitzt. Diese Informationen dürfen nicht gegen die Datenschutzrechte von Kunden, Dritten, etc. verstoßen und müssen den geltenden Wettbewerbsgesetzen und sonstigen rechtlichen Anforderungen entsprechen.',
    targetLanguage:
      'Le fournisseur est uniquement autorisé à télécharger des contenus et des données sur lesquels il détient tous les droits nécessaires. Ces informations ne doivent pas violer les droits de protection des données des clients, des tiers, etc. et doivent être conformes aux lois sur la concurrence en vigueur et aux autres exigences légales.',
  },
  {
    german: '8. Kundenbeschwerden',
    targetLanguage: '8. Réclamations des clients',
    subSection: true,
  },
  {
    german: '8.1',
    targetLanguage: '8.1',
    bold: true,
  },
  {
    german:
      'Um Beschwerden oder Forderungen bezüglich (des durch den Anbieter angebotenen, erbrachten oder zur Verfügung gestellten Services) des Anbieters oder spezielle vom Kunden vorgebrachte Anfragen hat sich der Anbieter zu kümmern, da Schneider Electric nicht für solche Anfragen von Kunden verantwortlich ist und für Forderungen nicht einsteht. Schneider Electric ist berechtigt, jederzeit und nach eigenem Ermessen (a) einem Kunden Kunden-(Support-)Service anzubieten, (b) als Vermittler zwischen dem Anbieter und den Kunden aufzutreten oder (c) die Kunden anderweitig bei ihrer Kommunikation mit dem oder der Geltendmachung von Ansprüchen gegenüber dem Anbieter zu unterstützen.',
    targetLanguage:
      "Les plaintes ou réclamations concernant (le service offert, exécuté ou fourni par) le Fournisseur ou les demandes spécifiques formulées par le Client seront traitées par le Fournisseur, car Schneider Electric n'est pas responsable de ces demandes du Client et ne sera pas responsable des réclamations. Schneider Electric peut, à tout moment et à sa seule discrétion, (a) fournir des services client (assistance) à un Client, (b) agir en tant qu'agent médiateur entre le Fournisseur et les Clients, ou (c) aider les Clients dans leurs communications avec le Fournisseur ou leurs réclamations contre lui.",
  },
  {
    german: '9. Zahlung und Abrechnung',
    targetLanguage: '9. Paiement et facturation',
    subSection: true,
  },
  {
    german:
      'Für die Zahlung des Kunden und die diesbezügliche Abrechnung ist allein der Anbieter verantwortlich.',
    targetLanguage:
      'Le Fournisseur sera seul responsable du paiement et de la facturation du Client à cet égard.',
  },
  {
    german: '10. Steuern',
    targetLanguage: '10. Impôts',
    subSection: true,
  },
  {
    german:
      'Der Anbieter nimmt zustimmend zur Kenntnis, dass er allein für die Genauigkeit der Angaben zum Steuersatz, für die Ermittlung der anwendbaren Steuern und etwaige Änderungen der Steuersätze verantwortlich ist, die auf der Plattform eingegeben werden. Der Anbieter ist dafür verantwortlich, den zuständigen Steuerbehörden seine Abrechnungen im Hinblick auf jegliche anwendbaren Steuern zu allen Beträgen einzureichen, die als Gegenleistung für den Service bei ihm eingehen. Sofern Schneider Electric es verlangt, ist der Anbieter verpflichtet, Schneider Electric in den Fällen, in denen nach anwendbarem Recht Steuern anfallen, unverzüglich gültige Steuerrechnungen im Hinblick auf alle im Rahmen dieser Bedingungen abgeschlossenen Transaktionen vorzulegen.',
    targetLanguage:
      "Le Fournisseur reconnaît et accepte qu'il est seul responsable de l'exactitude des informations relatives aux taux d'imposition, de la détermination des taxes applicables et de toute modification des taux d'imposition saisie sur la Plateforme. Le Fournisseur est tenu de soumettre aux autorités fiscales compétentes ses comptes relatifs aux taxes applicables sur tous les montants reçus en contrepartie du Service. Lorsque Schneider Electric l'exige, le Fournisseur doit fournir rapidement à Schneider Electric des factures fiscales valides concernant toutes les transactions conclues en vertu des présentes Conditions générales dans les cas où des taxes sont payables en vertu de la loi applicable.",
  },
  {
    german: '11. Datenschutz',
    targetLanguage: '11. Protection des données',
    subSection: true,
  },
  {
    german:
      'Der Anbieter versichert, dass er angemessene Sicherheitsverfahren und -kontrollen installiert hat und aufrechterhalten wird, bzw. dass er diese einrichten und aufrechterhalten wird, um die unbeabsichtigte Offenlegung von und den unbefugten Zugriff auf oder die widerrechtliche Verwendung von personenbezogenen Daten oder Informationen von Kunden zu verhindern.',
    targetLanguage:
      "Le fournisseur doit s'assurer qu'il a installé et maintiendra, ou installera et maintiendra, des procédures et des contrôles de sécurité appropriés pour empêcher la divulgation par inadvertance, l'accès non autorisé ou l'utilisation illégale des données ou informations personnelles du client.",
  },
  {
    german:
      'Weder der Anbieter noch seine verbundenen Unternehmen beteiligen sich mittelbar oder unmittelbar an verlangt oder unverlangt zugesandten Marketing- oder Promotion-Maßnahmen für Kunden oder ähnlichen Mitteilungen an Kunden, die über die Plattform gebucht haben, ohne dass hierzu die Zustimmung des betreffenden Kunden vorliegt. Der Anbieter ist verpflichtet, sämtliche kundenbezogenen Informationen (wie z. B. Kreditkarten-, Debitkarten- oder Bankkontodaten) gemäß den auf den Anbieter anwendbaren Gesetzen zu verarbeiten, zu speichern, zu übermitteln und darauf zuzugreifen. Der Anbieter ist auf Anforderung von Schneider Electric verpflichtet nachzuweisen, dass er technische und organisatorische Sicherheitsmaßnahmen, die die Verarbeitung personenbezogener Daten gemäß dem vorliegenden Abschnitt regeln, installiert hat und aufrechterhält.',
    targetLanguage:
      "Ni le Fournisseur ni aucune de ses sociétés affiliées ne participeront directement ou indirectement à des activités de marketing ou de promotion, sollicitées ou non, pour les Clients ou à des communications similaires destinées aux Clients ayant réservé via la Plateforme sans le consentement du Client concerné. Le Fournisseur est tenu de traiter, stocker, transmettre et accéder à toutes les informations relatives au Client (telles que les données de carte de crédit, de carte de débit ou de compte bancaire) conformément aux lois applicables au Fournisseur. À la demande de Schneider Electric, le Fournisseur sera tenu de démontrer qu'il a installé et maintient des mesures de sécurité techniques et organisationnelles régissant le traitement des Données personnelles conformément à la présente Section.",
  },
  {
    german: `Schneider Electric erhebt und verwendet personenbezogene Daten wie in den Datenschutzbestimmungen von tendergy unter <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a> beschrieben.`,
    targetLanguage: `Schneider Electric collecte et utilise les données personnelles comme décrit dans la politique de confidentialité de tendergy à l'adresse <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a>.`,
  },
  {
    german: '12. Zusicherungen und Garantien',
    targetLanguage: '12. Assurances et garanties',
    subSection: true,
  },
  {
    german:
      'Zusätzlich zu etwaigen sonstigen Zusicherungen und Garantien des Anbieters in diesen Bedingungen sichert der Anbieter hiermit zu und garantiert, dass (a) er sämtliche Lizenzen, Genehmigungen und Vollmachten besitzt, die er benötigt, um seinen Service zur Buchung über die Plattform anzubieten und seine Verpflichtungen im Rahmen dieser Bedingungen einzuhalten, (b) er Inhaber sämtlicher Rechte an geistigem Eigentum ist, die über den Anbieter-Account hochgeladen wurden, bzw. dass der Inhaber dieser Rechte seine Einwilligung erteilt hat, dies auf der Plattform hochzuladen und die Rechte über die Plattform zu lizenzieren.',
    targetLanguage:
      'En plus de toutes les autres assurances et garanties données par le Fournisseur dans les présentes Conditions, le Fournisseur assure et garantit par la présente que (a) il détient toutes les licences, permissions et autorisations requises pour fournir son Service de Réservation via la Plateforme et pour se conformer à ses obligations en vertu des présentes Conditions, (b) il est le propriétaire de tous les Droits de Propriété Intellectuelle téléchargés via le Compte Fournisseur, ou que le propriétaire de ces droits a donné son consentement pour les télécharger sur la Plateforme et pour concéder sous licence les droits via la Plateforme.',
  },
  {
    german: '13. Beschränkung der Haftung von Schneider Electric',
    targetLanguage: '13. Limitation de responsabilité de Schneider Electric',
    subSection: true,
  },
  {
    german: '13.1',
    targetLanguage: '13.1',
    bold: true,
  },
  {
    german:
      'Schneider Electric macht keine Zusicherungen und übernimmt keine Garantien im Hinblick auf die Plattform, wie z. B. für eine vorübergehende oder dauerhafte Unterbrechung des Betriebs der Plattform.',
    targetLanguage:
      "Schneider Electric ne donne aucune assurance et n'assume aucune garantie concernant la Plateforme, notamment en cas d'interruption temporaire ou permanente du fonctionnement de la Plateforme.",
  },
  {
    german: '13.2',
    targetLanguage: '13.2',
    bold: true,
  },
  {
    german:
      'Schneider Electric trägt kein Risiko hinsichtlich der Anzahl, der Häufigkeit oder der Art von Servicebuchungen über die Plattform.',
    targetLanguage:
      'Schneider Electric ne supporte aucun risque quant au nombre, à la fréquence ou à la nature des Réservations de Services effectuées via la Plateforme.',
  },
  {
    german: '13.3',
    targetLanguage: '13.3',
    bold: true,
  },
  {
    german:
      'Die Haftung von Schneider Electric im Rahmen der Bedingungen ist wie folgt eingeschränkt: Die Schneider Electric haftet für Schäden, die durch die Schneider Electric oder deren Erfüllungs- und Verrichtungsgehilfen durch Vorsatz oder grobe Fahrlässigkeit verursacht wurden.',
    targetLanguage:
      'La responsabilité de Schneider Electric en vertu des Conditions générales est limitée comme suit : Schneider Electric sera responsable des dommages causés par Schneider Electric ou ses agents ou employés par intention ou négligence grave.',
  },
  {
    german:
      'Für einfache Fahrlässigkeit haftet die Schneider Electric nur bei Verletzung einer wesentlichen Vertragspflicht und nur für vorhersehbare und typische Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.',
    targetLanguage:
      "Schneider Electric ne peut être tenu responsable en cas de négligence simple qu'en cas de violation d'une obligation contractuelle essentielle et uniquement pour des dommages prévisibles et typiques. Les obligations contractuelles essentielles sont celles dont le respect est une condition préalable à la bonne exécution du contrat et sur le respect desquelles l'utilisateur peut régulièrement compter.",
  },
  {
    german:
      'Haftungsbeschränkungen gelten nicht im Rahmen abgegebener Garantien, bei Verletzungen des Lebens, des Körpers und der Gesundheit sowie für Ansprüche aus dem Produkthaftungsgesetz.',
    targetLanguage:
      "Les limitations de responsabilité ne s'appliquent pas dans le cadre des garanties accordées, en cas d'atteinte à la vie, de dommages corporels ou d'atteinte à la santé, ou pour les réclamations découlant de la loi sur la responsabilité du fait des produits.",
  },
  {
    german: '13.4',
    targetLanguage: '13.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet für einen Datenverlust nur bis zur Höhe der typischen Wiederherstellungskosten, die entstehen würden, wenn ordnungsgemäß und regelmäßig Datensicherungsmaßnahmen ergriffen worden wären.',
    targetLanguage:
      "Schneider Electric ne sera responsable d'une perte de données qu'à hauteur des coûts de récupération typiques qui auraient été encourus si des mesures de sauvegarde des données appropriées et régulières avaient été prises.",
  },
  {
    german: '14. Laufzeit und Kündigung/Beendigung',
    targetLanguage: '14. Durée du contrat et résiliation',
    subSection: true,
  },
  {
    german: '14.1',
    targetLanguage: '14.1',
    bold: true,
  },
  {
    german: 'Der Vertrag wird auf unbestimmte Zeit geschlossen.',
    targetLanguage: 'Le contrat est conclu pour une durée indéterminée.',
  },
  {
    german: '14.2',
    targetLanguage: '14.2',
    bold: true,
  },
  {
    german:
      'Der Anbieter und Schneider Electric sind berechtigt, den Vertrag jederzeit und ohne Angabe von Gründen zu kündigen. Die verbleibenden bestätigten Buchungen müssen wie geplant ausgeführt werden.',
    targetLanguage:
      'Le fournisseur et Schneider Electric sont en droit de résilier le contrat à tout moment et sans indication de motifs. Les autres réservations confirmées doivent être exécutées comme prévu.',
  },
  {
    german: '14.3',
    targetLanguage: '14.3',
    bold: true,
  },
  {
    german:
      'Wenn konkrete Hinweise darauf vorliegen, dass ein Anbieter schuldhaft gegen rechtliche Bestimmungen, Rechte Dritter oder diese Bedingungen verstößt, oder wenn wir ansonsten ein legitimes Interesse haben, insbesondere im Hinblick auf den Schutz der Kunden und anderer Anbieter vor betrügerischen Aktivitäten, sind wir berechtigt, unter angemessener Berücksichtigung der jeweiligen Interessen des Anbieters in dem betreffenden Fall eine oder mehrere der folgenden Maßnahmen zu ergreifen:',
    targetLanguage:
      "S'il existe des preuves concrètes qu'un fournisseur enfreint de manière coupable des dispositions légales, des droits de tiers ou les présentes conditions générales, ou si nous avons par ailleurs un intérêt légitime, notamment en ce qui concerne la protection des clients et des autres fournisseurs contre toute activité frauduleuse, nous sommes en droit de prendre une ou plusieurs des mesures suivantes, en tenant dûment compte des intérêts respectifs du fournisseur dans le cas en question :",
  },
  {
    german: `
    <ul>
      <li>Den Anbieter warnen;</li>
      <li>Die Angebote des Anbieters oder sonstigen Inhalt löschen;</li>
      <li>Die Nutzung der Website durch den Anbieter einschränken;</li>
      <li>Den Anbieter vorübergehend oder dauerhaft von der Plattform ausschließen (sperren);</li>
      <li>Bestehende Buchungen auf den Namen des Anbieters ungeachtet der geltenden Stornierungsrichtlinien zu stornieren und die Angebote der Kunden zum Abschluss eines Vertrags im Namen des Anbieters ablehnen.,</li>
      </ul>
      `,
    targetLanguage: `
      <ul>
        <li>Avertir le fournisseur;</li>
        <li>Supprimer les annonces ou tout autre contenu du fournisseur;</li>
        <li>Restreindre l’utilisation du site Web par le fournisseur;</li>
        <li>Exclure (bloquer) temporairement ou définitivement le Fournisseur de la Plateforme;</li>
        <li>Annuler les réservations existantes au nom du Fournisseur, nonobstant la politique d'annulation applicable et rejeter les offres des Clients de conclure un contrat au nom du Fournisseur.</li>
      </ul>
      `,
    indented: true,
  },
  {
    german: '15. Änderung',
    targetLanguage: '15. Modifications',
    subSection: true,
  },
  {
    german:
      'Schneider Electric behält sich das Recht vor, die Bestimmungen dieses Vertrags jederzeit zu ändern und neue oder zusätzliche Bestimmungen einzuführen. Schneider Electric wird den Anbieter über etwaige Änderungen an diesem Vertrag per E-Mail informieren. Die geänderten Bedingungen werden dem Anbieter per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesendet. Widerspricht der Anbieter der Geltung der neuen Bedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail, gelten die geänderten Bedingungen als angenommen. Schneider Electric wird den Anbieter in der E-Mail, die die geänderten Bedingungen enthält, auf die Bedeutung dieser Zweiwochenfrist gesondert hinweisen.',
    targetLanguage:
      "Schneider Electric se réserve le droit de modifier les dispositions du présent Contrat à tout moment et d'introduire de nouvelles dispositions ou des dispositions supplémentaires. Schneider Electric informera le Fournisseur de toute modification du présent Contrat par courrier électronique. Les Conditions générales modifiées seront envoyées au Fournisseur par courrier électronique au plus tard deux semaines avant leur entrée en vigueur. Si le Fournisseur ne s'oppose pas à la validité des nouvelles Conditions générales dans les deux semaines suivant la réception du courrier électronique, les Conditions générales modifiées seront réputées acceptées. Schneider Electric informera séparément les Utilisateurs de l'importance de ce délai de deux semaines dans le courrier électronique contenant les Conditions générales modifiées.",
  },
  {
    german: '16. Schlussbestimmung',
    targetLanguage: '16. Disposition finale',
    subSection: true,
  },
  {
    german: '16.1',
    targetLanguage: '16.1',
    bold: true,
  },
  {
    german:
      'Nebenabreden wurden nicht getroffen. Alle Änderungen und Ergänzungen zu diesem Vertrag müssen schriftlich erfolgen, um rechtswirksam zu sein.',
    targetLanguage:
      "Aucun accord annexe n'a été conclu. Toutes les modifications et ajouts au présent accord doivent être effectués par écrit pour être juridiquement valables.",
  },
  {
    german: '16.2',
    targetLanguage: '16.2',
    bold: true,
  },
  {
    german:
      'Falls einzelne Bestimmungen dieses Vertrags ungültig oder nichtig sind oder werden, bleibt die Gültigkeit der übrigen Vertragsteile hiervon unberührt.',
    targetLanguage:
      'La validité des autres parties du contrat ne sera pas affectée dans le cas où certaines dispositions du présent contrat seraient ou deviendraient invalides ou nulles.',
  },
  {
    german: '16.3',
    targetLanguage: '16.3',
    bold: true,
  },
  {
    german:
      'Dieser Vertrag und alle Ansprüche aus oder im Zusammenhang mit diesem Vertrag unterliegen unter Ausschluss der Regeln des internationalen Privatrechts deutschem Recht und sind diesem Recht gemäß auszulegen. Ausschließlicher Gerichtsstand ist Berlin, soweit der Anbieter Kaufmann im Sinne des Handelsgesetzbuches ist oder bei Klageerhebung keinen festen Wohnsitz in Deutschland hat. Gesetzlich zwingende Gerichtsstände bleiben unberührt.',
    targetLanguage:
      "Le présent contrat et toutes les réclamations découlant de celui-ci ou en relation avec celui-ci sont régis et interprétés conformément au droit allemand, à l'exclusion des règles du droit international privé. Le lieu de juridiction exclusif est Berlin, dans la mesure où le fournisseur est un commerçant au sens du Code de commerce allemand ou n'a pas de résidence fixe en Allemagne au moment de l'introduction de l'action. Les lieux de juridiction obligatoires légaux restent inchangés.",
  },
];

const TermsConditionsForInstallerFr: React.FC = () => {
  return (
    <div className={styles.container}>
      <BiLingualTable columns={columns} dataSource={privacyData} />
    </div>
  );
};

export default TermsConditionsForInstallerFr;
