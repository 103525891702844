import React from 'react';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './OfferDocumentGenerator.module.sass';

interface IOfferDocumentGeneratorProps {
  pdfBlob?: Blob;
}

const OfferDocumentPdfPreview = ({ pdfBlob }: IOfferDocumentGeneratorProps) => {
  const { t } = useTranslation();
  const pdfUrl = pdfBlob ? URL.createObjectURL(pdfBlob) : null;
  if (!pdfBlob) return null;

  return (
    <Flex>
      {pdfUrl && (
        <iframe
          src={pdfUrl}
          className={styles.iframe}
          title={t('intermediate:pdfGen:preview')}
        ></iframe>
      )}
    </Flex>
  );
};
export default OfferDocumentPdfPreview;
