import React from 'react';

type PoolTagCircleProps = {
  size: string;
  radius: string;
  fillColor: string;
};

const PoolTagCircle = (props: PoolTagCircleProps) => {
  return (
    <svg height={props.size} width={props.size}>
      <circle cx={props.radius} cy={props.radius} r={props.radius} fill={props.fillColor} />
    </svg>
  );
};

export default PoolTagCircle;
