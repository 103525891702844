import React from 'react';
import styles from './Imprint.module.sass';

const ImprintFr: React.FC = () => {
  return (
    <div className={styles.container}>
      <p>Informations selon le § 5 TMG:</p>
      <p>
        Schneider Electric GmbH <br />
        Rue Gothaer 29 <br />
        40880 Ratingen
      </p>
      <p>E-Mail: info@inno2fleet.com</p>
      <p>
        Directeur Général : Christophe de Maistre <br />
        Adjoint Président du Conseil de Surveillance : Thierry Tricot <br />
        Siège social de l&apos;entreprise : Ratingen <br />
      </p>
      <p>
        Tribunal de grande instance de Düsseldorf | HRB 47852 <br />
        Numéro d&apos;identification TVA DE225673854 <br />
      </p>
    </div>
  );
};

export default ImprintFr;
