import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Imprint from 'components/static/Imprint/Imprint';
import BoxContent from 'components/layout/BoxContent/BoxContent';
import Box from 'components/layout/Box/Box';
import useFetchCustomerLegalDocuments from 'hooks/useFetchCustomerLegalDocuments';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import {
  CustomDocumentsCategoryType,
  ICustomDocument,
} from 'store/intermediate/intermediate.types';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { getLegalDocumentData } from 'utils/commonUtils';
import styles from './LegalPage.module.sass';

const LegalPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  useFetchCustomerLegalDocuments();
  const token = useSelector(isRegistrationInviteTokenSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const legalDocuments: ICustomDocument[] = useSelector(legalDocumentsSelector);
  const getImprintPage = () => {
    const value = getLegalDocumentData(legalDocuments, CustomDocumentsCategoryType.PoolImprint);
    if (token || isCustomer) {
      return value ? (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <Imprint language={i18n.language} />
      );
    }

    return <Imprint language={i18n.language} />;
  };

  return (
    <Box>
      <BoxContent title={t('common:texts:imprint')} showBackButton={false}>
        <div className={styles.container}>{getImprintPage()}</div>
      </BoxContent>
    </Box>
  );
};

export default LegalPage;
