import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'Kunden-Nutzungsbedingungen für tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: 'Termini di utilizzo del cliente Tendergy',
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german:
      'Die Schneider Electric GmbH („Schneider Electric“) betreibt eine Vermittlungs-Plattform für die Installation (und ggfs. Kauf) einer sog. Wallbox („tendergy“). Auf der Plattform haben Nutzer die Möglichkeit deren Installation durch einen Installateur zu buchen. Die Angebote werden von verschiedenen Anbietern („Anbieter“) online gestellt, mit denen auch der jeweilige Vertrag über die Leistung zustande kommt („Leistungsvertrag“). Zu den Leistungen der Anbieter können über Tendergy Informationen abgerufen und Bestellungen veranlasst werden.',
    targetLanguage:
      'Schneider Electric GmbH ("Schneider Electric") gestisce una piattaforma di intermediazione per l\'installazione (e, se applicabile, l\'acquisto) di una cosiddetta wallbox ("tendergy"). La piattaforma offre agli utenti la possibilità di prenotare le proprie installazioni tramite un installatore. Tendergy può essere utilizzata per ottenere informazioni sui servizi dei provider e per effettuare ordini.',
  },
  {
    german:
      'Tendergy richtet sich gleichermaßen an Verbraucher und Unternehmer. Für die Zwecke dieser Nutzungsbedingungen gilt Folgendes:',
    targetLanguage:
      "Tendergy è rivolta in egual misura a consumatori e imprenditori. Quanto segue si applica ai fini delle presenti Condizioni d'uso:",
  },
  {
    german:
      '(a) Ein „Verbraucher“ ist jede natürliche Person, die den Vertrag zu Zwecken abschließt, die überwiegend keiner gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 Bürgerliches Gesetzbuch – „BGB“).',
    targetLanguage:
      '(a) Per "consumatore" si intende qualsiasi persona fisica che stipula il contratto per scopi che non sono prevalentemente attribuibili ad un\'attività commerciale o professionale autonoma (articolo 13 del Codice civile tedesco).',
    indented: true,
  },
  {
    german:
      '(b) Ein „Unternehmer“ ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss des Vertrages in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt (§ 14 Abs. 1 BGB).',
    targetLanguage:
      '(b) Per "imprenditore" si intende una persona fisica o giuridica o una società di persone con capacità giuridica che, al momento della conclusione del contratto, agisce nell\'esercizio della propria attività commerciale o professionale autonoma (articolo 14 (1) del Codice civile tedesco).',
    indented: true,
  },
  {
    german:
      '„Nutzer“ bezeichnet eine natürliche Person, es sei denn, sie ist ausdrücklich als juristische Person bei tendergy registriert. Eine Nutzung von Handlungen und Unterlassungen unter der Registrierung einer juristischen Person werden der natürlichen Person zugerechnet, außer im Rahmen ihrer Vollmacht für die juristische Person.',
    targetLanguage:
      '"Utente" si riferisce a una persona fisica, a meno che non sia espressamente registrata come persona giuridica con titolo. Un uso di atti e omissioni ai sensi della registrazione di una persona giuridica è attribuito alla persona fisica, salvo nell\'ambito della sua procura per conto della persona giuridica.',
  },
  {
    german: '1. Gegenstand',
    targetLanguage: '1. Oggetto',
    subSection: true,
  },
  {
    german:
      'Diese Nutzungsbedingungen gelten für die Nutzung von tendergy. Das Vertragsverhältnis zwischen dem Nutzer und tendergy umfasst nur die ordnungsgemäße Vermittlung der Nutzer an den Anbieter. Die Vermittlung zwischen Nutzern und Anbietern findet in verschiedenen Gruppen und/oder Projekte statt – sog. Pools („Pools“). tendergy tritt nicht selbst als Installateur in Bezug auf den Leistungsvertrag mit dem Nutzer auf. Schneider Electric bietet selbst keine Installationsleistungen an und wird nicht Vertragspartner der Verträge, die ausschließlich zwischen den Nutzern und Anbietern geschlossen werden. Für die Vermittlung der Nutzer an den Anbieter gelten deshalb diese Nutzungsbedingungen von tendergy. Für den jeweiligen Leistungsvertrag zwischen Nutzer und Anbieter gelten die jeweiligen Bedingungen der Anbieter.',
    targetLanguage:
      "Le presenti Condizioni d'uso si applicano all'uso di tendergy. Il rapporto contrattuale tra l'Utente e tendergy includerà solo il corretto rinvio degli Utenti al Fornitore. L'intermediazione tra Utenti e Fornitori avverrà in gruppi e/o progetti diversi, i cosiddetti Pool (\"Pool\"). tendergy non agirà da installatore per quanto riguarda il contratto di servizio con l'Utente. Schneider Electric stessa non offre alcun servizio di installazione e non diventerà un partner contrattuale per i contratti, che saranno conclusi esclusivamente tra Utenti e Fornitori. Pertanto, le Condizioni d'uso di tendergy si applicheranno al rinvio degli Utenti al Fornitore. I rispettivi Termini e Condizioni del Fornitore si applicheranno al rispettivo contratto di servizio tra l'Utente e il Fornitore.",
  },
  {
    german: '2. Registrierung',
    targetLanguage: '2. Registrazione',
    subSection: true,
  },
  {
    german:
      'Die Nutzung von tendergy und das Veranlassen von Buchungen, erfordern eine Registrierung. Bei der Registrierung sendet der Nutzer ein elektronisches Registrierungsformular ab und willigt in die Nutzungsbedingungen ein. Anschließend wird die Registrierung durch Schneider Electric bestätigt. Natürliche Personen müssen das 18. Lebensjahr vollendet haben.',
    targetLanguage:
      "Per utilizzare tendergy e organizzare le prenotazioni è richiesta la registrazione. L'Utente invierà un modulo di registrazione elettronico al momento della registrazione e accetterà i Termini e le Condizioni d'Uso. Schneider Electric confermerà quindi la registrazione. Le persone fisiche devono aver compiuto 18 anni.",
  },
  {
    german: '3. Leistungen von Schneider Electric/Vertragsschluss',
    targetLanguage: '3. Servizi Schneider Electric/Conclusione del contratto',
    subSection: true,
  },
  {
    german: '3.1',
    targetLanguage: '3.1',
    bold: true,
  },
  {
    german:
      'Für die Vermittlung der Installationsleistungen auf tendergy gibt es einen öffentlichen Pool, dem alle Nutzer und Anbieter betreten können und in dem eine freie Vermittlung stattfindet.',
    targetLanguage:
      "Per l'intermediazione di servizi di installazione su tendergy è disponibile un Pool Pubblico, al quale possono partecipare tutti gli Utenti e i Fornitori e nel quale avviene l'intermediazione gratuita.",
  },
  {
    german: '3.2',
    targetLanguage: '3.2',
    bold: true,
  },
  {
    german:
      'Darüber hinaus können sich Anbieter zu sog. Pool-Betreibern freischalten lassen. Pool-Betreiber können zusätzlich zum öffentlichen Pool auch private Pools zur Vermittlung („Privater Vermittlungs-Pool“) von Installationsleistungen durch andere Anbieter oder zum Abschluss der Installationsleistung mit sich selber („Privater Betreiber-Pool“) erstellen. Nutzer können diesen privaten Pools nur auf Einladung der entsprechenden Pool-Betreiber beitreten.',
    targetLanguage:
      'I provider possono anche essere autorizzati a diventare i cosiddetti Pool Operator. Oltre al Public Pool, i Pool Operator possono anche creare pool privati ​​per l\'intermediazione ("Private Brokerage Pool") di servizi di installazione da parte di altri provider o per la conclusione di contratti di servizi di installazione con se stessi ("Private Operator Pool"). Gli utenti possono unirsi a questi pool privati ​​solo su invito dei rispettivi Pool Operator.',
  },
  {
    german: '3.3',
    targetLanguage: '3.3',
    bold: true,
  },
  {
    german:
      'Nachdem der Nutzer auf tendergy in einem öffentlichen Pool seine Leistungswünsche geäußert hat und diese die grundsätzlichen Anforderungen an die Vermittlung eines Leistungsvertrags erfüllen, übermittelt Schneider Electric diese Informationen den Anbietern („Leistungsinformationen“). In einem Privaten Vermittlungs-Pool werden die Leistungsinformationen nur an die Anbieter in diesem Pool übermittelt. In einem Privaten Betreiber-Pool erhält neben den Anbietern in diesem Pool auch der Pool-Betreiber die entsprechenden Leistungsinformationen. Auf Grundlage dieser Informationen können Anbieter bzw. Pool-Betreiber ein Vertragsangebot zur Weiterleitung an den Nutzer abgeben. Dieses Angebot kann durch Klicken auf die Schaltfläche „kostenpflichtig bestellen“ angenommen werden. Im Anschluss erhält der Nutzer eine Bestätigung über den Vertragsschluss vom entsprechenden Anbieter bzw. Pool-Betreiber dessen Angebot vom Nutzer angenommen wurde.',
    targetLanguage:
      "Dopo l'invio da parte dell'Utente di richieste di servizio in un Pool pubblico su tendergy, che soddisfano i requisiti di base per l'intermediazione di un contratto di servizio, Schneider Electric trasmetterà tali informazioni ai Provider (\"Informazioni di servizio\"). In un Pool di intermediazione privata, le Informazioni di servizio saranno trasmesse solo ai provider in quel Pool. In un Pool di operatori privati, l'Operatore del Pool, oltre ai provider nel Pool, riceverà anche le Informazioni di servizio pertinenti. I provider o gli Operatori del Pool possono inviare un'offerta contrattuale per il rinvio all'Utente sulla base di queste informazioni. Questa offerta può essere accettata cliccando sul pulsante \"ordine addebitabile\". L'Utente riceverà quindi la conferma della conclusione del contratto dal Provider o dall'Operatore del Pool corrispondente la cui offerta è stata accettata dall'Utente.",
  },
  {
    german: '3.4',
    targetLanguage: '3.4',
    bold: true,
  },
  {
    german:
      'Schneider Electric stellt dem Nutzer die Geschäftsbedingungen der Anbieter für deren Verhältnis zum Nutzer und für deren Leistungen zur Verfügung, soweit diese der Schneider Electric vorliegen.',
    targetLanguage:
      "Nella misura in cui è a disposizione di Schneider Electric, quest'ultima fornirà all'Utente i Termini e le Condizioni dei Fornitori per il rapporto con l'Utente e per i loro servizi.",
  },
  {
    german: '3.5',
    targetLanguage: '3.5',
    bold: true,
  },
  {
    german:
      'Die Kosten für den technischen Zugang zu tendergy (z.B. Internetzugang) trägt der Nutzer selbst.',
    targetLanguage:
      "L'Utente si fa carico dei costi per l'accesso tecnico a tendergy (ad esempio accesso a Internet).",
  },
  {
    german: '4. Bezahlen bei Tendergy',
    targetLanguage: '4. Pagamento a Tendergy',
    subSection: true,
  },
  {
    german:
      'Die Zahlungsabwicklung erfolgt allein über die Anbieter. Schneider Electric ist in Zahlungsabwicklungen nicht involviert.',
    targetLanguage:
      "L'elaborazione dei pagamenti sarà effettuata esclusivamente dai Provider. Schneider Electric non è coinvolta nell'elaborazione dei pagamenti.",
  },
  {
    german: '5. Die Preisgestaltung von Tendergy',
    targetLanguage: '5. Prezzi di Tendergy',
    subSection: true,
  },
  {
    german: '5.1',
    targetLanguage: '5.1',
    bold: true,
  },
  {
    german:
      'Alle Preise auf Tendergy werden pro Person und einschließlich Umsatzsteuer und aller anderen Steuern angezeigt.',
    targetLanguage:
      'Tutti i prezzi su tendergy sono indicati a persona e includono IVA e tutte le altre tasse.',
  },
  {
    german: '5.2',
    targetLanguage: '5.2',
    bold: true,
  },
  {
    german:
      'Diese von den Anbietern und/oder Pool-Betreibern festgelegten Preise unterliegen gegebenenfalls besonderen Bestimmungen, zum Beispiel in Bezug auf Stornierungen und die Erstattung von geleisteten Zahlungen. Bitte überprüfen Sie vor der Buchung daher genau, ob der jeweilige Leistungsvertrag gesonderten Bestimmungen unterliegt.',
    targetLanguage:
      'Questi prezzi stabiliti dai Provider e/o dai Pool Operator possono essere soggetti a disposizioni speciali, relative ad esempio alle cancellazioni e ai rimborsi dei pagamenti effettuati. Si prega pertanto di verificare se il rispettivo contratto di servizio è soggetto a disposizioni separate prima di prenotare.',
  },
  {
    german: '6. Pflichten und Obliegenheiten des Nutzers',
    targetLanguage: "6. Doveri e obblighi dell'utente",
    subSection: true,
  },
  {
    german: '6.1',
    targetLanguage: '6.1',
    bold: true,
  },
  {
    german:
      'Der Nutzer wird Registrierungsdaten (Nutzerkennung und Passwort) geheim halten und Dritten keinen Zugang zu tendergy mit seinen Registrierungsdaten ermöglichen. Eine Nutzung von tendergy unter der Registrierung des Nutzers wird diesem zugerechnet.',
    targetLanguage:
      "L'Utente dovrà mantenere segreti i dati di registrazione (ID utente e password) e non dovrà consentire a terzi di accedere a tendergy con tali dati di registrazione. Qualsiasi utilizzo di tendergy ai sensi della registrazione dell'Utente sarà attribuito all'Utente.",
  },
  {
    german: '6.2',
    targetLanguage: '6.2',
    bold: true,
  },
  {
    german:
      'Der Nutzer kann nach Erhalt von Leistungsinformationen etwaige Bestellungen an tendergy zur Weiterleitung an den Anbieter und/oder Pool-Betreiber senden.',
    targetLanguage:
      "Dopo aver ricevuto le Informazioni sul Servizio, l'Utente può inviare eventuali ordini a tendergy affinché vengano inoltrati al Fornitore e/o al Gestore del Pool.",
  },
  {
    german: '6.3',
    targetLanguage: '6.3',
    bold: true,
  },
  {
    german:
      'Der Nutzer wird Schneider Electric von Ansprüchen Dritter aufgrund seiner Nutzung von tendergy freistellen, außer soweit diese durch Schneider Electric zu vertreten sind.',
    targetLanguage:
      "L'Utente dovrà indennizzare Schneider Electric da eventuali reclami di terzi derivanti dall'uso di tendergy, fatta eccezione per i casi in cui Schneider Electric sia responsabile di tali reclami.",
  },
  {
    german: '7. Verfügbarkeit und Gewährleistung',
    targetLanguage: '7. Disponibilità e garanzia',
    subSection: true,
  },
  {
    german: '7.1',
    targetLanguage: '7.1',
    bold: true,
  },
  {
    german:
      'Es besteht kein Anspruch auf Verfügbarkeit, Qualitäts- oder Leistungsmerkmale oder technische Unterstützung für tendergy. Schneider Electric kann das Online-Portal tendergy jederzeit nach freiem Ermessen umgestalten, einschränken oder einstellen. Bestehende Vereinbarungen des Nutzers mit einem Anbieter sowie deren Abwicklung bleiben von solchen Änderungen unberührt.',
    targetLanguage:
      'tendergy non avrà alcuna pretesa di disponibilità, qualità o caratteristiche di prestazione o supporto tecnico. Schneider Electric può modificare, limitare o interrompere il Portale Online tendergy in qualsiasi momento a sua esclusiva discrezione. Gli accordi Utente esistenti con un Fornitore, nonché la loro esecuzione, non saranno influenzati da tali modifiche.',
  },
  {
    german: '7.2',
    targetLanguage: '7.2',
    bold: true,
  },
  {
    german:
      'Unbeschadet Ziffer 8, übernimmt Schneider Electric keine Gewährleistung für die Richtigkeit oder Vollständigkeit der Daten, die von Dritten (etwa anderen Anbietern und/oder Pool-Betreibern) bereitgestellt werden.',
    targetLanguage:
      "Fatto salvo quanto previsto dalla clausola 8, Schneider Electric non garantisce l'accuratezza o la completezza dei dati forniti da terze parti (come altri Fornitori e/o Pool Operator).",
  },
  {
    german: '7.3',
    targetLanguage: '7.3',
    bold: true,
  },
  {
    german:
      'Unbeschadet Ziffer 8, übernimmt Schneider Electric keine Gewährleistung für die von den Anbietern und/oder Pool-Betreibern angebotenen Leistungen, es sei denn, Schneider Electric ist selbst Anbieter der jeweiligen Leistung. Ansprechpartner des Nutzers für Fragen und Ansprüche im Zusammenhang mit einem Leistungsvertrag und seiner Durchführung ist jeweils der Anbieter und/oder Pool-Betreiber mit dem der Leistungsvertrag zustande gekommen ist.',
    targetLanguage:
      "Fatto salvo quanto previsto dalla clausola 8, Schneider Electric non garantisce i servizi offerti dai Provider e/o dai Pool Operator a meno che Schneider Electric non sia essa stessa il fornitore del servizio in questione. Il contatto dell'Utente per domande e reclami in relazione a un contratto di servizio e alla sua esecuzione in ogni caso sarà il Provider e/o il Pool Operator con cui è stato stipulato il contratto di servizio.",
  },
  {
    german: '8. Haftungsbeschränkung',
    targetLanguage: '8. Limitazione di responsabilità',
    subSection: true,
  },
  {
    german: '8.1',
    targetLanguage: '8.1',
    bold: true,
  },
  {
    german:
      'Schneider Electric haftet für Schäden, die durch Schneider Electric oder deren Erfüllungs- und Verrichtungsgehilfen durch Vorsatz oder grobe Fahrlässigkeit verursacht wurden.',
    targetLanguage:
      'Schneider Electric sarà responsabile per i danni causati da Schneider Electric o dai suoi ausiliari o assistenti per dolo o grave negligenza.',
  },
  {
    german: '8.2',
    targetLanguage: '8.2',
    bold: true,
  },
  {
    german:
      'Für einfache Fahrlässigkeit haftet Schneider Electric nur bei Verletzung einer wesentlichen Vertragspflicht und nur für vorhersehbare und typische Schäden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.',
    targetLanguage:
      "Schneider Electric sarà responsabile solo per semplice negligenza in caso di violazione di un obbligo contrattuale sostanziale e solo per danni prevedibili e tipici. Gli obblighi contrattuali sostanziali sono quelli il cui adempimento è un prerequisito per la corretta esecuzione del contratto e sulla cui conformità l'utente può regolarmente fare affidamento.",
  },
  {
    german: '8.3',
    targetLanguage: '8.3',
    bold: true,
  },
  {
    german:
      'Haftungsbeschränkungen gelten nicht im Rahmen abgegebener Garantien, bei Verletzungen des Lebens, des Körpers und der Gesundheit sowie für Ansprüche aus dem Produkthaftungsgesetz.',
    targetLanguage:
      "Le limitazioni di responsabilità non si applicano nell'ambito delle garanzie fornite, in caso di lesioni alla vita, lesioni corporali o lesioni alla salute, nonché per richieste derivanti dalla legge sulla responsabilità del produttore.",
  },
  {
    german: '9. Widerrufsbelehrung für Verbraucher',
    targetLanguage: '9. Politica di cancellazione del consumatore',
    subSection: true,
  },
  {
    german: 'Widerrufsrecht',
    targetLanguage: 'Diritto di recesso',
  },
  {
    german:
      'Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.',
    targetLanguage:
      'Hai il diritto di recedere dal presente contratto entro quattordici giorni senza indicarne le ragioni.',
  },
  {
    german:
      'Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telefon: +49 30 23 88 84 - 240, Telefax: +49 30 23 88 84 – 120, info@Schneider Electric.com) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.',
    targetLanguage:
      "Il termine di recesso è di quattordici giorni a partire dal giorno della conclusione del contratto. Per esercitare il diritto di recesso, è necessario informarci (Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlino, Telefono: +49 30 23 88 84 - 240, Telefax: +49 30 23 88 84 – 120, info@Schneider Electric.com) della decisione di recedere dal presente contratto mediante una dichiarazione chiara (ad esempio una lettera inviata per posta, fax o e-mail). A tale scopo, è possibile utilizzare il modello di modulo di recesso allegato. L'utilizzo del modello, tuttavia, non è obbligatorio.",
  },
  {
    german:
      'Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.',
    targetLanguage:
      "Per rispettare il termine di recesso è sufficiente l'invio della comunicazione relativa all'esercizio del diritto di recesso prima della scadenza del periodo di recesso.",
  },
  {
    german: 'Folgen des Widerrufs',
    targetLanguage: 'Conseguenze della cancellazione',
  },
  {
    german:
      'Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.',
    targetLanguage:
      'Se recedi dal presente contratto, siamo tenuti a rimborsarti tutti i pagamenti che abbiamo ricevuto da te, comprese le spese di consegna (ad eccezione dei costi aggiuntivi derivanti dal fatto che hai scelto un tipo di consegna diverso dalla consegna standard più economica da noi offerta), senza indugio e al più tardi entro quattordici giorni dal giorno in cui abbiamo ricevuto la notifica di recesso dal presente contratto. Per questo rimborso, utilizzeremo lo stesso mezzo di pagamento che hai utilizzato per la transazione originale, salvo diverso accordo espresso con te; in nessun caso ti saranno addebitate commissioni a causa di questo rimborso.',
  },
  {
    german: '- Ende der Widerrufsbelehrung -',
    targetLanguage: '- Fine della Politica di Cancellazione -',
  },
  {
    german:
      'Das Widerrufsrecht erlischt, wenn wir mit der Ausführung des Vertrags begonnen haben, nachdem Sie ausdrücklich zugestimmt haben, dass mit der Ausführung des Vertrages vor Ablauf der Widerrufsfrist begonnen werden soll und ihre Kenntnis davon bestätigt haben, dass Sie dadurch das Widerrufsrecht verlieren.',
    targetLanguage:
      "Il diritto di recesso decade se abbiamo iniziato l'esecuzione del contratto dopo che Lei ha espressamente concordato che l'esecuzione del contratto dovesse iniziare prima della scadenza del periodo di recesso e ha confermato di aver accettato che in tal modo perde il diritto di recesso.",
  },
  {
    german: '10. Kündigung',
    targetLanguage: '10. Risoluzione',
    subSection: true,
  },
  {
    german:
      'Nutzer können ihre Registrierung bei tendergy jederzeit durch Sperrung ihres Nutzerkontos beenden. Schneider Electric kann eine Registrierung einseitig mit einer Frist von einer Woche beenden. Zuvor entstandene Ansprüche bleiben hiervon unberührt. Das Recht zur außerordentlichen Kündigung bleibt unberührt.',
    targetLanguage:
      'Gli utenti possono terminare la propria registrazione con tendergy in qualsiasi momento sospendendo il proprio account utente. Schneider Electric può terminare qualsiasi registrazione unilateralmente con un preavviso di una settimana. I reclami sorti in precedenza non saranno interessati da ciò. Il diritto alla risoluzione straordinaria non sarà interessato.',
  },
  {
    german: '11. Datenschutz',
    targetLanguage: '11. Protezione dei dati',
    subSection: true,
  },
  {
    german: `Schneider Electric erhebt und verwendet personenbezogene Daten wie in den Datenschutzbestimmungen von Tendergy unter <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a> beschrieben.`,
    targetLanguage: `Schneider Electric raccoglie e utilizza le informazioni personali come descritto nell'Informativa sulla privacy di tendergy all'indirizzo <a href="${document.location.origin}/legal/privacy-policy">${document.location.origin}/legal/privacy-policy</a>.`,
  },
  {
    german: '12. Änderung dieser Nutzungsbedingungen',
    targetLanguage: "12. Modifiche alle presenti Condizioni d'Uso",
    subSection: true,
  },
  {
    german:
      'Schneider Electric behält sich vor, diese Nutzungsbedingungen jederzeit und ohne Angabe von Gründen zu ändern. Schneider Electric wird gegenüber Verbrauchern nur solche Änderungen vornehmen, auf die der Nutzer sich in Abwägung der beiderseitigen Interessen vernünftigerweise einlassen muss. Dies betrifft z.B. Fälle der Äquivalenzstörung, aber auch Regelungslücken und Änderungen der Rechtslage. Die geänderten Bedingungen werden den Nutzern per E-Mail spätestens zwei Wochen vor ihrem Inkrafttreten zugesendet. Widerspricht ein Nutzer der Geltung der neuen Nutzungsbedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail, gelten die geänderten Nutzungsbedingungen als angenommen. Schneider Electric wird die Nutzer in der E-Mail, die die geänderten Bedingungen enthält, auf die Bedeutung dieser Zweiwochenfrist gesondert hinweisen.',
    targetLanguage:
      "Schneider Electric si riserva il diritto di modificare i presenti Termini di utilizzo in qualsiasi momento e senza indicarne i motivi. Schneider Electric apporterà ai Consumatori solo le modifiche che l'Utente deve ragionevolmente accettare considerando gli interessi di entrambe le parti. Ciò riguarda, ad esempio, i casi di interruzione dell'equivalenza, ma anche lacune normative e cambiamenti nella situazione legale. I Termini e condizioni modificati saranno inviati agli Utenti tramite e-mail non oltre due settimane prima della loro entrata in vigore. Qualora un utente non si opponga alla validità dei nuovi Termini di utilizzo entro due settimane dalla ricezione dell'e-mail, i Termini di utilizzo modificati saranno considerati accettati. Schneider Electric informerà separatamente gli Utenti del significato di questo periodo di due settimane nell'e-mail contenente i Termini e condizioni modificati.",
  },
  {
    german: '13. Sonstige Bestimmungen',
    targetLanguage: '13. Altre disposizioni',
    subSection: true,
  },
  {
    german: '13.1',
    targetLanguage: '13.1',
    bold: true,
  },
  {
    german:
      'Die Plattform der EU zur außergerichtlichen Onlinestreitbeilegung ist abrufbar unter <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>. Schneider Electric ist nicht bereit und nicht verpflichtet an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
    targetLanguage:
      'La piattaforma di risoluzione extragiudiziale delle controversie online dell\'UE è disponibile all\'indirizzo <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>. Schneider Electric non è né disposta né obbligata a partecipare a procedimenti di risoluzione delle controversie dinanzi a un collegio arbitrale dei consumatori.',
  },
  {
    german: '13.2',
    targetLanguage: '13.2',
    bold: true,
  },
  {
    german:
      'Es gilt das Recht der Bundesrepublik Deutschland. Wenn Sie die Bestellung als Verbraucher abgegeben haben und zum Zeitpunkt Ihrer Bestellung Ihren gewöhnlichen Aufenthalt in einem anderen Land haben, bleibt die Anwendung zwingender Rechtsvorschriften dieses Landes von der in Satz 1 getroffenen Rechtswahl unberührt. Ausschließlicher Gerichtsstand ist Berlin, soweit der Nutzer Kaufmann im Sinne des Handelsgesetzbuches ist oder bei Klageerhebung keinen festen Wohnsitz in Deutschland hat. Gesetzlich zwingende Gerichtsstände bleiben unberührt.',
    targetLanguage:
      "Si applica la legge della Repubblica Federale Tedesca. Se hai effettuato l'ordine come Consumatore e la tua residenza abituale al momento dell'ordine è in un altro Paese, l'applicazione delle disposizioni legali imperative di tale Paese non sarà influenzata dalla selezione della legge effettuata nella frase 1. Il foro competente esclusivo è Berlino, nella misura in cui l'Utente è un commerciante ai sensi del Codice commerciale tedesco o non ha una residenza fissa in Germania al momento in cui viene intentata l'azione.",
  },
  {
    german: '13.3',
    targetLanguage: '13.3',
    bold: true,
  },
  {
    german:
      'Sollten Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden, berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.',
    targetLanguage:
      "Qualora una qualsiasi disposizione delle presenti Condizioni d'uso dovesse risultare o diventare invalida, tale invalidità non pregiudicherà la validità delle restanti disposizioni.",
  },
  {
    german: 'Muster-Widerrufsformular',
    targetLanguage: 'Modello di modulo di cancellazione',
  },
  {
    german: `(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
    <br /><br />
    An Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telefon: +49 30 23 88 84 240, Telefax: +49 30 23 88 84 120, E-Mail: info[at]Schneider Electric.com)
    <br /><br />
    Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*) – Bestellt am (*)/erhalten am (*).
    <ul>
      <li>Name des/der Verbraucher(s)</li>
      <li>Anschrift des/der Verbraucher(s)</li>
      <li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
      <li>Datum</li>
    </ul>`,
    targetLanguage: `(Se desideri annullare il contratto, ti preghiamo di compilare e restituire il presente modulo.)
    <br /><br />
    A Schneider Electric GmbH, EUREF-Campus Haus 13, Torgauer Straße 12-15, 10829 Berlin, Telefono: +49 30 23 88 84 240, Telefax: +49 30 23 88 84 120, E-mail: info[at]Schneider Electric.com)
    <br/><br />
    Con la presente io/noi (*) revoco/revochiamo il contratto da me/noi (*) concluso per l'acquisto dei seguenti beni (*)/la fornitura del seguente servizio (*) - Ordinato il (*)/ricevuto il (*).
    <ul>
      <li>Nome del consumatore</li>
      <li>Indirizzo del consumatore</li>
      <li>Firma del consumatore (solo per comunicazioni cartacee)</li>
      <li>Data</li>
    </ul>`,
  },
  {
    german: '---------------------------------',
    targetLanguage: '---------------------------------',
  },
  {
    german: '(*) Unzutreffendes streichen',
    targetLanguage: '(*) Cancellare la dicitura non pertinente',
  },
];

const TermsConditionsForCustomerIt: React.FC = () => (
  <BiLingualTable columns={columns} dataSource={privacyData} />
);

export default TermsConditionsForCustomerIt;
