import React, { useMemo, useState } from 'react';
import { Alert, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TermsConditionsForInstaller from 'components/static/TermsConditionsForInstaller/TermsConditionsForInstaller';
import TermsConditionsForCustomer from 'components/static/TermsConditionsForCustomer/TermsConditionsForCustomer';
import useFetchCustomerLegalDocuments from 'hooks/useFetchCustomerLegalDocuments';
import { legalDocumentsSelector } from 'store/intermediate/intermediate.selectors';
import { isRegistrationInviteTokenSelector } from 'store/register/register.selectors';
import { CustomDocumentsCategoryType } from 'store/intermediate/intermediate.types';
import { isCustomerSelector } from 'store/auth/auth.selectors';
import { getLegalDocumentData } from 'utils/commonUtils';

import styles from './TermsPage.module.sass';
import BoxContent from '../../components/layout/BoxContent/BoxContent';
import Box from '../../components/layout/Box/Box';

const { TabPane } = Tabs;

const TermsPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const { search } = useLocation();
  let activeTabQueryStr = new URLSearchParams(search).get('at'); // at: active tab
  if (activeTabQueryStr !== '1' && activeTabQueryStr !== '2') {
    activeTabQueryStr = '1';
  }
  const [activeTab, setActiveTab] = useState(activeTabQueryStr);

  const token = useSelector(isRegistrationInviteTokenSelector);
  const isCustomer = useSelector(isCustomerSelector);
  const legalDocuments = useSelector(legalDocumentsSelector);
  useFetchCustomerLegalDocuments();
  const customTerms = useMemo(() => {
    const value = getLegalDocumentData(
      legalDocuments,
      CustomDocumentsCategoryType.PoolTermsConditions,
    );
    if (isCustomer || token) {
      return value ? (
        <div dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <TermsConditionsForCustomer language={i18n.language} />
      );
    }

    return <TermsConditionsForCustomer language={i18n.language} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalDocuments, i18n.language]);

  return (
    <Box>
      <BoxContent title={t('common:texts:termsAndConditions')} showBackButton={false}>
        <div className={styles.container}>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
            tabBarExtraContent={
              <Alert
                description={t('common:legalAlert')}
                type="info"
                showIcon
                className={styles.legalAlert}
              />
            }
          >
            <TabPane tab={t('common:texts:termsForInstaller')} key="1">
              <TermsConditionsForInstaller language={i18n.language} />
            </TabPane>

            <TabPane tab={t('common:texts:termsForCustomer')} key="2">
              {customTerms}
            </TabPane>
          </Tabs>
        </div>
      </BoxContent>
    </Box>
  );
};

export default TermsPage;
