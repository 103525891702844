import React from 'react';

import { SupportedLanguages } from 'framework/constants';
import TermsConditionsForInstallerDeEn from './TermsConditionsForInstaller.de_en';
import TermsConditionsForInstallerFr from './TermsConditionsForInstaller.fr';
import TermsConditionsForInstallerIt from './TermsConditionsForInstaller.it';

type TermsConditionsForInstallerProps = {
  language: string;
};

const TermsConditionsForInstallerComponents: Record<SupportedLanguages, React.FC> = {
  de: TermsConditionsForInstallerDeEn,
  en: TermsConditionsForInstallerDeEn,
  fr: TermsConditionsForInstallerFr,
  it: TermsConditionsForInstallerIt,
};

const TermsConditionsForInstaller = (props: TermsConditionsForInstallerProps) => {
  const Content = TermsConditionsForInstallerComponents[props.language as SupportedLanguages];
  return <Content />;
};

export default TermsConditionsForInstaller;
