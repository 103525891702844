import React from 'react';
import { Button, Flex, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

interface IDescriptionModalProps {
  onSubmit: (description: string) => void;
  closeModal: () => void;
  initialDescription: string;
  isModalVisible: boolean;
}
export const DescriptionModal = ({
  onSubmit,
  closeModal,
  isModalVisible,
  initialDescription,
}: IDescriptionModalProps) => {
  const submitForm = (values: { description: string }) => {
    onSubmit(values.description);
  };
  const { t } = useTranslation();
  return (
    <Modal
      title={t('intermediate:pdfGen.bundle.description')}
      footer={null}
      onCancel={closeModal}
      open={isModalVisible}
    >
      <Form onFinish={submitForm} initialValues={{ description: initialDescription }}>
        <Flex vertical={true} gap={4}>
          <Form.Item name={'description'}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Flex gap={4} justify={'end'}>
              <Button onClick={closeModal}> {t('common:cancel')}</Button>
              <Button htmlType={'submit'} type={'primary'}>
                {t('common:submit')}
              </Button>
            </Flex>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
};
