import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/currencyUtils';
import { IOfferDocumentTotalCosts } from './useCalculateCosts';
interface ICostTableProps {
  currency: string;
  totalCosts: IOfferDocumentTotalCosts;
}
const CostTable = ({ totalCosts, currency }: ICostTableProps) => {
  const locizeKey = 'intermediate:pdfGen:costs';
  const { t } = useTranslation();
  const data = [
    {
      title: t(`${locizeKey}:hardware`),
      net: `${formatPrice(totalCosts.hardware.net)} ${currency}`,
      vat: `${formatPrice(totalCosts.hardware.vat)} ${currency}`,
      gross: `${formatPrice(totalCosts.hardware.gross)} ${currency}`,
    },
    {
      title: t(`${locizeKey}:installation`),
      net: `${formatPrice(totalCosts.installation.net)} ${currency}`,
      vat: `${formatPrice(totalCosts.installation.vat)} ${currency}`,
      gross: `${formatPrice(totalCosts.installation.gross)} ${currency}`,
    },
    {
      title: t(`${locizeKey}:total`),
      net: `${formatPrice(totalCosts.total.net)} ${currency}`,
      vat: `${formatPrice(totalCosts.total.vat)} ${currency}`,
      gross: `${formatPrice(totalCosts.total.gross)} ${currency}`,
    },
  ];
  return (
    <Table
      dataSource={data}
      columns={[
        {
          title: '',
          dataIndex: 'title',
        },
        {
          title: t(`${locizeKey}:net`),
          dataIndex: 'net',
        },
        {
          title: t(`${locizeKey}:vat`),
          dataIndex: 'vat',
        },
        {
          title: t(`${locizeKey}:gross`),
          dataIndex: 'gross',
        },
      ]}
      pagination={false}
    />
  );
};
export default CostTable;
