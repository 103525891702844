import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Tag } from 'antd';
import moment from 'moment';
import Typography from 'antd/lib/typography';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import BackButton from 'components/ui/BackButton/BackButton';
import Button, { ButtonColor } from 'components/ui/Button/Button';
import DateTimePicker, { IDateTime } from 'components/ui/DateTimePicker/DateTimePicker';
import { OfferingUserType } from 'store/offer/offer.types';
import { timeSlotsGenerator } from 'utils/dateUtils';

import styles from './TenderParticipate.module.sass';

interface IProps {
  onSubmit: Function;
  startDate?: Date;
}

const TenderHomeCheckSelectDatePage: React.FC<IProps> = ({ onSubmit, startDate }) => {
  const [date, setDate] = useState<Date>(new Date());
  const [selectedValues, setValues] = useState([] as IDateTime[]);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleTimeClick = (_day: Date, time: string) => {
    let [hours, minutes] = time.split(':');
    let tempDate = new Date(date);
    tempDate.setHours(+hours, +minutes);
    setDate(tempDate);
  };

  const handleSubmit = () => {
    onSubmit(
      selectedValues.map((a) => ({
        timestamp: a.day,
        proposedBy: OfferingUserType.INSTALLER,
      })),
    );
  };

  const datePickerProps = {
    selectedValues,
    maxValues: 10,
    disabled: {
      before:
        startDate && new Date(startDate).getTime() > new Date().getTime()
          ? new Date(startDate)
          : new Date(),
    },
    timeAvailable: timeSlotsGenerator('7:00', '21:30', 30),
    setValues: (newValue: any) => setValues(newValue),
    onDayClick: (day: Date) => setDate(day),
    onTimeClick: (day: Date, time: string) => handleTimeClick(day, time),
  };

  const isSubmitDisabled = !selectedValues.length;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackButton className={styles.back} onClick={() => navigate('/installer/dashboard')}>
          {t('common:buttons:back')}
        </BackButton>

        <Rectangle title={t('installerFlow:tenderDetails:offerSubmitPage:title')}>
          <Typography.Paragraph className={styles.paragraph}>
            {t('installerFlow:tenderDetails:offerSubmitPage:textOne')}
          </Typography.Paragraph>

          <Typography.Paragraph className={styles.paragraphLast}>
            {t('installerFlow:tenderDetails:offerSubmitPage:textTwo')}
          </Typography.Paragraph>

          <Typography.Title level={4} className={styles.dateTimeTitle}>
            {t('installerFlow:tenderParticipate:withReservation:dateAndTime')}
          </Typography.Title>

          <div className={styles.datePickerWrapper}>
            <DateTimePicker multiple={false} {...datePickerProps} />
          </div>
        </Rectangle>
      </div>

      {selectedValues.length > 0 && (
        <div className={styles.selectedDates}>
          <Typography.Title level={3}>
            {t('installerFlow:tenderParticipate:selectedAppointments')}
          </Typography.Title>
          {selectedValues.map((appointment: IDateTime, index: number) => (
            <Tag key={index} color="success">
              {moment(appointment.day).format('LLLL')}
            </Tag>
          ))}
        </div>
      )}

      <Button
        type={isSubmitDisabled ? 'default' : 'primary'}
        className={styles.dateAcceptButton}
        color={ButtonColor.GREEN}
        disabled={isSubmitDisabled}
        onClick={handleSubmit}
      >
        {t('installerFlow:tenderDetails:offerSubmitPage:acceptButton')}
      </Button>
    </div>
  );
};

export default TenderHomeCheckSelectDatePage;
