import React from 'react';
import IntermediateTabs from './IntermediateTabs';

import styles from './MyProjects.module.scss';

const MyProjects: React.FC = () => {
  return (
    <div className={styles.container}>
      <IntermediateTabs />
    </div>
  );
};

export default MyProjects;
