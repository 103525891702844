import HttpService from './http.service';
import config from '../config';

class PdfMakerAPI extends HttpService {
  static PDF_MAKER_API = 'pdf-maker';
  template = (name: string, lang: string) => {
    return this.get(`${PdfMakerAPI.PDF_MAKER_API}/template/${name}/${lang}`);
  };
}
export default new PdfMakerAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
