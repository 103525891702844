import {
  AllEffect,
  PutEffect,
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _assign from 'lodash/assign';
import { transformFindOrganizations } from 'framework/dataTransformations';
import IntermediateAPI from 'services/intermediate.service';
import DocumentsAPI from 'services/documents.service';
import { IOffer, OfferState } from 'store/offer/offer.types';
import {
  ClientRequestState,
  IClientRequest,
  IDocument,
} from 'store/client-request/client-request.types';
import { IInviteTokenData } from 'store/register/register.types';
import { IGetResponse, IState, RequestState } from 'store/common.types';
import { getErrorText } from 'utils/helpers';
import {
  poolsGetError,
  poolsGetSuccess,
  poolCreateSuccess,
  poolCreateError,
  poolGetByIdSuccess,
  poolGetByIdError,
  poolUpdateSuccess,
  poolUpdateError,
  unapprovedClientRequestsGetSuccess,
  unapprovedClientRequestsGetError,
  unapprovedOffersGetSuccess,
  unapprovedOffersGetError,
  organizationsFindSuccess,
  organizationsFindError,
  statsGetSuccess,
  statstGetError,
  poolFilesGetSuccess,
  poolFilesGetError,
  poolFileUploadSuccess,
  poolFileUploadError,
  customDocumentGetError,
  customDocumentGetSuccess,
  customDocumentCreateSuccess,
  customDocumentCreateError,
  customDocumentUpdateSuccess,
  customDocumentUpdateError,
  organizationInviteSuccess,
  organizationInviteError,
  questionnaireConfigGetSuccess,
  questionnaireConfigGetError,
  questionnaireGetSuccess,
  questionnaireGetError,
  questionnaireConfigCreateSuccess,
  questionnaireConfigCreateError,
  questionnaireConfigUpdateSuccess,
  questionnaireConfigUpdateError,
  questionnaireConfigDeleteSuccess,
  questionnaireConfigDeleteError,
  questionnaireConfigForCustomerGetSuccess,
  questionnaireConfigForCustomerGetError,
  landingPageGetSuccess,
  landingPageGetError,
  inviteTokenUpdateSuccess,
  inviteTokenUpdateError,
  customDocumentImageUploadSuccess,
  customDocumentImageUploadError,
  customDocumentImagesGetSuccess,
  customDocumentImagesGetError,
  customDocumentMediaLinkGetSuccess,
  customDocumentMediaLinkGetError,
  existingOrganizationInviteSuccess,
  existingOrganizationInviteError,
  organizationsInvitesGetSuccess,
  organizationsInvitesGetError,
  organizationsInvitesGet,
  poolGetRequestsByIdSuccess,
  poolGetRequestsByIdError,
  organizationInviteDeleteSuccess,
  organizationInviteDeleteError,
  poolOffersGetSuccess,
  poolOffersGetError,
  poolClientRequestsGetSuccess,
  poolClientRequestsGetError,
  poolInviteTokensGetSuccess,
  poolInviteTokensGetError,
  sendEmailReminderError,
  poolGetOfferAnnotationByIdError,
  poolGetOfferAnnotationByIdSuccess,
  orderInstallerDirectlySuccess,
  orderInstallerDirectlyError,
  orderDownLoadPdfError,
  getCurrenciesByOrganizationSuccess,
  getCurrenciesByOrganizationError,
  getPoolLegalDocumentsError,
  getPoolLegalDocumentsSuccess,
  savePoolLegalDocumentsError,
  savePoolLegalDocumentsSuccess,
  customDocumentDeleteSuccess,
  customDocumentDeleteError,
  servicesFeeDescriptionGetSuccess,
  servicesFeeDescriptionGetError,
  getInstallationWizardCustomImageUrlsSuccess,
  getInstallationWizardCustomImageUrlsError,
  poolTagsGetSuccess,
  poolTagsGetError,
  poolTagCreateSuccess,
  poolTagCreateError,
  poolTagEditSuccess,
  poolTagEditError,
  poolTagDeleteSuccess,
  poolTagDeleteError,
  clientRequestsGetSuccess,
  clientRequestsGetError,
} from './intermediate.actions';
import {
  INVITE_ORGANIZATION,
  OrganizationInviteAction,
  CustomDocumentCreateAction,
  CustomDocumentGetAction,
  CustomDocumentUpdateAction,
  CUSTOM_DOCUMENTS_CREATE,
  CUSTOM_DOCUMENTS_GET,
  CUSTOM_DOCUMENTS_UPDATE,
  OrganizationsFindAction,
  ORGANIZATIONS_FIND,
  PoolCreateAction,
  PoolFilesGetAction,
  PoolFileUploadAction,
  PoolGetByIdAction,
  POOLS_GET,
  PoolUpdateAction,
  POOL_CREATE,
  POOL_FILES_GET,
  POOL_FILE_UPLOAD,
  POOL_GET_BY_ID,
  POOL_UPDATE,
  STATS_GET,
  UNAPPROVED_CLIENT_REQUESTS_GET,
  UNAPPROVED_OFFERS_GET,
  QuestionnaireConfigGetAction,
  QuestionnaireGetAction,
  QuestionnaireConfigCreateAction,
  QuestionnaireConfigUpdateAction,
  QuestionnaireConfigDeleteAction,
  GET_QUESTIONNAIRE_CONFIGS,
  GET_QUESTIONNAIRE,
  CREATE_QUESTIONNAIRE_CONFIGS,
  UPDATE_QUESTIONNAIRE_CONFIGS,
  DELETE_QUESTIONNAIRE_CONFIGS,
  GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER,
  LandingPageGetAction,
  LANDING_PAGE_GET,
  InviteTokenUpdateAction,
  INVITE_TOKEN_UPDATE,
  CustomDocumentImageUploadAction,
  CUSTOM_DOCUMENTS_IMAGE_UPLOAD,
  CustomDocumentImagesGetAction,
  CUSTOM_DOCUMENTS_IMAGES_GET,
  CustomDocumentMediaLinkGetAction,
  CUSTOM_DOCUMENTS_MEDIA_LINK_GET,
  ExistingOrganizationInviteAction,
  OrganizationsInvitesGetAction,
  EXISTING_ORGANIZATION_INVITE,
  ORGANIZATIONS_INVITES_GET,
  POOL_GET_REQUESTS_BY_ID,
  PoolGetRequestsByIdAction,
  OrganizationInviteDeleteAction,
  ORGANIZATIONS_INVITE_DELETE,
  PoolOffersGetAction,
  POOL_OFFERS_GET,
  PoolClientRequestsGetAction,
  POOL_CLIENT_REQUESTS_GET,
  PoolInviteTokensGetAction,
  POOL_INVITE_TOKENS_GET,
  SendEmailReminderAction,
  SEND_EMAIL_REMINDER,
  Organization,
  UnapprovedClientRequestsGetAction,
  POOL_GET_OFFER_ANNOTATION,
  ORDER_INSTALLER_DIRECTLY,
  ORDER_DOWNLOAD_PDF,
  CurrenciesByOrganizationGetAction,
  GET_CURRENCIES_BY_ORGANIZATION,
  ICurrency,
  PoolLegalDocumentsGetAction,
  ICustomDocument,
  GET_LEGAL_DOCUMENTS,
  PoolLegalDocumentsSaveAction,
  SAVE_LEGAL_DOCUMENTS,
  IQuestionnaireConfig,
  IQuestionnaire,
  IPool,
  IStats,
  PoolsGetAction,
  CustomDocumentDeleteAction,
  CUSTOM_DOCUMENTS_DELETE,
  GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS,
  GetInstallationWizardCustomImagesUrlsAction,
  ServicesFeeDescriptionGetAction,
  SERVICE_FEE_DESCRIPTION_GET,
  GetPoolTagsAction,
  GET_POOL_TAGS,
  CreatePoolTagAction,
  IPoolTag,
  EditPoolTagAction,
  CREATE_POOL_TAG,
  EDIT_POOL_TAG,
  DeletePoolTagAction,
  DELETE_POOL_TAG,
  ClientRequestsGetAction,
  CLIENT_REQUESTS_GET,
} from './intermediate.types';

export function* getPoolInviteTokens({ payload }: PoolInviteTokensGetAction) {
  try {
    const inviteTokens: IInviteTokenData[] = yield call(
      IntermediateAPI.getPoolInviteTokens,
      payload,
    );
    yield put(poolInviteTokensGetSuccess(inviteTokens));
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolInviteTokensGetError(message));
  }
}

export function* deleteOrganizationInvite({ payload }: OrganizationInviteDeleteAction) {
  try {
    const result: string = yield call(IntermediateAPI.deleteOrganizationInvite, payload);
    yield put(organizationInviteDeleteSuccess(result));
  } catch (err) {
    const message = getErrorText(err);
    yield put(organizationInviteDeleteError(message));
  }
}

export function* getOrganizationsInvites({ payload }: OrganizationsInvitesGetAction) {
  try {
    const invites: IInviteTokenData[] = yield call(
      IntermediateAPI.getOrganizationsInvites,
      payload,
    );
    yield put(organizationsInvitesGetSuccess(invites));
  } catch (err) {
    const message = getErrorText(err);
    yield put(organizationsInvitesGetError(message));
  }
}

export function* inviteExistingOrganization({
  payload: { poolId, organizationId },
}: ExistingOrganizationInviteAction) {
  try {
    const invite: string = yield call(
      IntermediateAPI.inviteExistingOrganization,
      poolId,
      organizationId,
    );
    yield put(existingOrganizationInviteSuccess(invite));
    yield put(organizationsInvitesGet(poolId));
  } catch (err) {
    const message = getErrorText(err);
    yield put(existingOrganizationInviteError(message));
  }
}

export function* getCustomDocumentMediaLink({ payload }: CustomDocumentMediaLinkGetAction) {
  try {
    const files: string[] = yield call(
      IntermediateAPI.getCustomDocumentMediaLink,
      payload.customDocumentId,
      payload.mediaName,
    );
    yield put(customDocumentMediaLinkGetSuccess(files));
  } catch (err) {
    const message = getErrorText(err);
    yield put(customDocumentMediaLinkGetError(message));
  }
}

export function* getCustomDocumentImages({ payload }: CustomDocumentImagesGetAction) {
  try {
    const files: IDocument[] = yield call(IntermediateAPI.getCustomDocumentImages, payload);
    yield put(customDocumentImagesGetSuccess(files));
  } catch (err) {
    const message = getErrorText(err);
    yield put(customDocumentImagesGetError(message));
  }
}

export function* uploadCustomDocumentImage({ payload }: CustomDocumentImageUploadAction) {
  const { document, category, uploadProgress, uploadSuccess, uploadError } = payload;

  try {
    const uploadedDocument: IDocument = yield call(
      IntermediateAPI.uploadCustomDocumentImage,
      document,
      category,
      uploadProgress,
    );

    yield put(customDocumentImageUploadSuccess(uploadedDocument));

    if (typeof uploadSuccess === 'function') {
      uploadSuccess(uploadedDocument);
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(customDocumentImageUploadError(message));

    if (typeof uploadError === 'function') {
      uploadError(message);
    }
  }
}

export function* updateInviteToken({ payload: { tokenId, token } }: InviteTokenUpdateAction) {
  try {
    yield call(IntermediateAPI.updateInviteToken, tokenId, token);
    yield put(inviteTokenUpdateSuccess());
  } catch (err) {
    const message = getErrorText(err);
    yield put(inviteTokenUpdateError(message));
  }
}

export function* getLandingPage({ payload }: LandingPageGetAction) {
  try {
    const { poolId, category, onSuccess } = payload;
    const landingPage: ICustomDocument = yield call(
      IntermediateAPI.getLandingPage,
      poolId,
      category,
    );
    yield put(landingPageGetSuccess(landingPage));
    onSuccess?.();
  } catch (err) {
    const message = getErrorText(err);
    yield put(landingPageGetError(message));
    payload.onSuccess?.();
  }
}
export function* servicesFeeDescriptionGetSaga({ payload }: ServicesFeeDescriptionGetAction) {
  try {
    const { poolId } = payload;
    const servicesFeeDescription: ICustomDocument = yield call(
      IntermediateAPI.getServiceFeeDescription,
      poolId,
    );
    yield put(servicesFeeDescriptionGetSuccess(servicesFeeDescription));
  } catch (err) {
    const message = getErrorText(err);
    yield put(servicesFeeDescriptionGetError(message));
  }
}
export function* getQuestionnaireConfigForCustomer() {
  try {
    const configs: IQuestionnaireConfig[] = yield call(
      IntermediateAPI.getQuestionnaireConfigForCustomer,
    );
    yield put(questionnaireConfigForCustomerGetSuccess(configs));
  } catch (err) {
    const message = getErrorText(err);
    yield put(questionnaireConfigForCustomerGetError(message));
  }
}

export function* deleteQuestionnaireConfig({ payload }: QuestionnaireConfigDeleteAction) {
  try {
    yield call(IntermediateAPI.deleteQuestionnaireConfig, payload.poolId, payload.configId);
    yield put(questionnaireConfigDeleteSuccess(payload.configId));
  } catch (err) {
    const message = getErrorText(err);
    yield put(questionnaireConfigDeleteError(message));
  }
}

export function* updateQuestionnaireConfig({
  payload: { poolId, configId, config },
}: QuestionnaireConfigUpdateAction) {
  try {
    const updatedConfig: IQuestionnaireConfig = yield call(
      IntermediateAPI.updateQuestionnaireConfig,
      poolId,
      configId,
      config,
    );
    yield put(questionnaireConfigUpdateSuccess(updatedConfig));
  } catch (err) {
    const message = getErrorText(err);
    yield put(questionnaireConfigUpdateError(message));
  }
}

export function* createQuestionnaireConfig({
  payload: { poolId, config },
}: QuestionnaireConfigCreateAction) {
  try {
    const newConfig: IQuestionnaireConfig = yield call(
      IntermediateAPI.createQuestionnaireConfig,
      poolId,
      config,
    );
    yield put(questionnaireConfigCreateSuccess(newConfig));
  } catch (err) {
    const message = getErrorText(err);
    yield put(questionnaireConfigCreateError(message));
  }
}

export function* getQuestionnaireConfig({ payload }: QuestionnaireConfigGetAction) {
  try {
    const configs: IGetResponse<IQuestionnaireConfig> = yield call(
      IntermediateAPI.getQuestionnaireConfig,
      payload,
    );
    yield put(questionnaireConfigGetSuccess(configs.items));
  } catch (err) {
    const message = getErrorText(err);
    yield put(questionnaireConfigGetError(message));
  }
}

export function* getQuestionnaire({ payload }: QuestionnaireGetAction) {
  try {
    const questionnaire: IQuestionnaire[] = yield call(IntermediateAPI.getQuestionnaire, payload);
    yield put(questionnaireGetSuccess(questionnaire));
  } catch (err) {
    const message = getErrorText(err);
    yield put(questionnaireGetError(message));
  }
}

export function* getPools({ payload }: PoolsGetAction) {
  try {
    const params = {
      sortFields: 'name',
      sortDirections: 1,
      limit: 100,
      offset: 0,
      ...payload?.payload,
    };

    const pools: IGetResponse<IPool> = yield call(IntermediateAPI.getPools, params);
    yield put(poolsGetSuccess({ pools: pools.items, totalPools: pools.total }));
    payload?.onSuccess?.();
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolsGetError(message));
  }
}

export function* getStats() {
  try {
    const stats: IStats = yield call(IntermediateAPI.getStats);
    yield put(statsGetSuccess(stats));
  } catch (err) {
    const message = getErrorText(err);
    yield put(statstGetError(message));
  }
}

export function* getPoolFiles({ payload: { poolId, category } }: PoolFilesGetAction) {
  try {
    const files: IDocument[] = yield call(IntermediateAPI.getPoolFiles, poolId, category);
    yield put(poolFilesGetSuccess(files));
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolFilesGetError(message));
  }
}
/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export function* getInstallationWizardCustomImageUrls({
  payload: { wallboxOnlyFileName, wallboxAndInstallationFileName },
}: GetInstallationWizardCustomImagesUrlsAction): Generator<
  | AllEffect<any>
  | PutEffect<{
      type: string;
      payload: any;
    }>,
  void,
  Array<{ link: string }>
> {
  try {
    const [wallboxOnlyResponse, wallboxAndInstallationResponse] = yield all([
      wallboxOnlyFileName ? call(DocumentsAPI.getFileLink, wallboxOnlyFileName) : '',
      wallboxAndInstallationFileName
        ? call(DocumentsAPI.getFileLink, wallboxAndInstallationFileName)
        : '',
    ]);
    yield put(
      getInstallationWizardCustomImageUrlsSuccess({
        wallboxOnlyUrl:
          typeof wallboxOnlyResponse.link === 'string' ? wallboxOnlyResponse.link : '',
        wallboxAndInstallationUrl:
          typeof wallboxAndInstallationResponse.link === 'string'
            ? wallboxAndInstallationResponse.link
            : '',
      }),
    );
  } catch (err: any) {
    yield put(getInstallationWizardCustomImageUrlsError(err));
  }
}

export function* uploadPoolFile({ payload }: PoolFileUploadAction) {
  const { document, poolId, category, uploadProgress, uploadSuccess, uploadError, onSuccess } =
    payload;
  try {
    const uploadedDocument: IDocument = yield call(
      IntermediateAPI.uploadPoolFile,
      document,
      poolId,
      category,
      uploadProgress,
    );

    yield put(poolFileUploadSuccess(uploadedDocument));

    if (typeof uploadSuccess === 'function') {
      uploadSuccess(uploadedDocument);
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolFileUploadError(message));

    if (typeof uploadError === 'function') {
      uploadError(message);
    }
  }
}

export function* getCustomDocument({ payload }: CustomDocumentGetAction) {
  try {
    const customDocument: ICustomDocument[] = yield call(IntermediateAPI.getPoolDocuments, payload);
    yield put(customDocumentGetSuccess(customDocument));
  } catch (err) {
    const message = getErrorText(err);
    yield put(customDocumentGetError(message));
  }
}

export function* createCustomDocument({
  payload: { poolId, document, createSuccess },
}: CustomDocumentCreateAction) {
  try {
    const customDocument: ICustomDocument = yield call(
      IntermediateAPI.createPoolDocuments,
      poolId,
      {
        ...document,
        media: document.media.map((m) => m.id),
      },
    );
    yield put(
      customDocumentCreateSuccess(
        document.media.length
          ? {
              ...customDocument,
              media: [
                {
                  ...customDocument.media[0],
                  media: document.media[0],
                },
              ],
            }
          : customDocument,
      ),
    );

    if (typeof createSuccess === 'function') {
      createSuccess(customDocument);
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(customDocumentCreateError(message));
  }
}

export function* updateCustomDocument({
  payload: { poolId, documentId, document },
}: CustomDocumentUpdateAction) {
  try {
    const updatedDocument: ICustomDocument = yield call(
      IntermediateAPI.updatePoolDocument,
      poolId,
      documentId,
      {
        ...document,
        media: document.media.map((m) => m.id),
      },
    );
    yield put(
      customDocumentUpdateSuccess(
        document.media.length
          ? {
              ...updatedDocument,
              media: [
                {
                  ...updatedDocument.media[0],
                  media: document.media[0],
                },
              ],
            }
          : updatedDocument,
      ),
    );
  } catch (err) {
    const message = getErrorText(err);
    yield put(customDocumentUpdateError(message));
  }
}
export function* deleteCustomDocument({ payload: { documentId } }: CustomDocumentDeleteAction) {
  try {
    yield call(IntermediateAPI.deletePoolDocument, documentId);
    yield put(customDocumentDeleteSuccess(documentId));
  } catch (err) {
    const message = getErrorText(err);
    yield put(customDocumentDeleteError(message));
  }
}

export function* createPool({ payload }: PoolCreateAction) {
  try {
    const { pool, onSuccess } = payload;
    const createdPool: IPool = yield call(IntermediateAPI.createPool, pool);
    yield put(poolCreateSuccess(createdPool));
    onSuccess();
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolCreateError(message));
  }
}

export function* getPoolById({ payload }: PoolGetByIdAction) {
  try {
    const pool: IPool = yield call(IntermediateAPI.getPoolById, payload);
    yield put(poolGetByIdSuccess(pool));
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolGetByIdError(message));
  }
}

export function* getOfferAnnotation({ payload }: PoolGetByIdAction) {
  try {
    const pool: string = yield call(IntermediateAPI.getOfferAnnotation, payload);
    yield put(poolGetOfferAnnotationByIdSuccess(pool));
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolGetOfferAnnotationByIdError(message));
  }
}

export function* getRequestsByPoolId({
  payload: { searchDto, poolId },
}: PoolGetRequestsByIdAction) {
  try {
    const requests: IClientRequest[] = yield call(
      IntermediateAPI.getPoolRequestsByPoolId,
      searchDto,
      poolId,
    );
    yield put(poolGetRequestsByIdSuccess(requests));
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolGetRequestsByIdError(message));
  }
}

export function* updatePool(poolUpdateAction: PoolUpdateAction) {
  const { payload } = poolUpdateAction;
  if (payload?.poolId && payload?.pool) {
    const { poolId, pool } = payload;
    try {
      const state: IState = yield select();
      const currentPool = state.intermediate.currentPool;
      const poolChanges: any = {};

      if (currentPool) {
        Object.entries(pool).forEach(([key, value]) => {
          if (!_isEqual(value, currentPool[key as keyof IPool])) {
            poolChanges[key] = value;
          }
        });

        if (Object.keys(poolChanges).length) {
          const response: IPool = yield call(IntermediateAPI.updatePool, poolId, poolChanges);
          const updated = _assign({}, currentPool, response);
          yield put(poolUpdateSuccess(updated));
        } else {
          yield put(poolUpdateSuccess(currentPool));
        }
      }
    } catch (err) {
      const message = getErrorText(err);
      yield put(poolUpdateError(message));
    }
  }
}

export function* getUnapprovedClientRequests({ payload }: UnapprovedClientRequestsGetAction) {
  try {
    const unapprovedClientRequests: IClientRequest[] = yield call(
      IntermediateAPI.getPoolClientRequests,
      '',
      [ClientRequestState.ApprovalPending],
      payload.offset,
      payload.archived,
    );
    yield put(unapprovedClientRequestsGetSuccess(unapprovedClientRequests));
  } catch (err) {
    const message = getErrorText(err);
    yield put(unapprovedClientRequestsGetError(message));
  }
}

export function* getClientRequests({ payload }: ClientRequestsGetAction) {
  try {
    const clientRequests: IClientRequest[] = yield call(
      IntermediateAPI.getPoolClientRequests,
      payload.query,
      payload.states,
      payload.offset,
      payload.archived,
      payload.excludedStates,
    );
    yield put(clientRequestsGetSuccess(clientRequests));
  } catch (err) {
    const message = getErrorText(err);
    yield put(clientRequestsGetError(message));
  }
}

export function* getUnapprovedOffers({ payload }: PoolOffersGetAction) {
  try {
    const unapprovedOffers: IOffer[] = yield call(IntermediateAPI.getPoolOffers, {
      state: [
        OfferState.TenderSubmitted,
        OfferState.TenderSubmittedHomeCheck,
        OfferState.TenderSubmittedRemoteHomeCheck,
        OfferState.InvoiceSubmittedHomeCheck,
        OfferState.InvoiceSubmittedRemoteHomeCheck,
        OfferState.InvoiceSubmittedInstallation,
      ],
      ...payload,
    });
    yield put(unapprovedOffersGetSuccess(unapprovedOffers));
  } catch (err) {
    const message = getErrorText(err);
    yield put(unapprovedOffersGetError(message));
  }
}

export function* findOrganizations({ payload }: OrganizationsFindAction) {
  try {
    const { searchValue, intermediateOrganizationId, limit } = payload;
    const response: Organization[] = yield call(
      IntermediateAPI.findOrganization,
      searchValue,
      intermediateOrganizationId,
      limit,
    );
    const items = _get(response, 'items', []);
    const organizations = transformFindOrganizations(items);
    yield put(organizationsFindSuccess(organizations));
  } catch (err) {
    const message = getErrorText(err);
    yield put(organizationsFindError(message));
  }
}

export function* inviteOrganization({
  payload: { inviteData, onSuccess },
}: OrganizationInviteAction) {
  try {
    const invite: string = yield call(IntermediateAPI.inviteOrganization, inviteData);
    yield put(organizationInviteSuccess(invite));

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (err) {
    const message = getErrorText(err);
    yield put(organizationInviteError(message));
  }
}

export function* getPoolClientRequests(action: PoolClientRequestsGetAction) {
  try {
    const clientRequests: IGetResponse<IClientRequest> = yield call(
      IntermediateAPI.getPoolClientRequests,
      action.payload,
    );
    if (clientRequests && clientRequests.items) {
      yield put(poolClientRequestsGetSuccess(clientRequests.items));
    } else {
      yield put(poolClientRequestsGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(poolClientRequestsGetError(message));
  }
}

export function* getPoolOffers(action: PoolOffersGetAction) {
  try {
    const offers: IOffer[] = yield call(IntermediateAPI.getPoolOffers, action.payload);
    if (offers) {
      yield put(poolOffersGetSuccess(offers));
    } else {
      yield put(poolOffersGetError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(poolOffersGetError(message));
  }
}

export function* sendEmailReminder(action: SendEmailReminderAction) {
  try {
    const { userId, text, onSuccess } = action.payload;
    yield call(IntermediateAPI.sendEmailReminder, userId, text);
    onSuccess && onSuccess();
  } catch (err) {
    const message = getErrorText(err);
    yield put(sendEmailReminderError(message));
  }
}

export function* postOrderInstallerDirectly({ payload: { id, organization } }: any) {
  try {
    const updatedConfig: IClientRequest = yield call(
      IntermediateAPI.createOrderInstallerDirectly,
      id,
      organization,
    );
    yield put(orderInstallerDirectlySuccess(updatedConfig));
  } catch (err) {
    const message = getErrorText(err);
    yield put(orderInstallerDirectlyError(message));
  }
}
export function* getOrderDownLoadPdf({ payload: { requestId, orgId } }: any) {
  try {
    yield call(IntermediateAPI.orderDownLoadPdf, requestId, orgId);
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(orderDownLoadPdfError(message));
  }
}

export function* getCurrenciesByOrganization(action: CurrenciesByOrganizationGetAction) {
  try {
    const res: ICurrency[] = yield call(
      IntermediateAPI.getCurrenciesByOrganization,
      action.payload,
    );
    if (res) {
      yield put(getCurrenciesByOrganizationSuccess(res));
    } else {
      yield put(getCurrenciesByOrganizationError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(getCurrenciesByOrganizationError(message));
  }
}

export function* getPoolLegalDocuments(action: PoolLegalDocumentsGetAction) {
  try {
    const res: ICustomDocument[] = yield call(
      IntermediateAPI.getLegalDocumets,
      action.payload.poolId,
      action.payload.isPublic,
    );
    if (res) {
      yield put(getPoolLegalDocumentsSuccess(res));
    } else {
      yield put(getPoolLegalDocumentsError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(getPoolLegalDocumentsError(message));
  }
}

export function* savePoolLegalDocuments(action: PoolLegalDocumentsSaveAction) {
  try {
    const { poolId, legalDocuments, onSuccess } = action.payload;
    const res: ICustomDocument[] = yield call(
      IntermediateAPI.saveLegalDocuments,
      poolId,
      legalDocuments,
    );
    if (res) {
      yield put(savePoolLegalDocumentsSuccess(res));
      onSuccess && onSuccess();
    } else {
      yield put(savePoolLegalDocumentsError('Empty Response'));
    }
  } catch (err: any) {
    const { response } = err;
    const { data } = response;
    const message = Array.isArray(data.message) ? data.message[0] : data.message;
    yield put(savePoolLegalDocumentsError(message));
  }
}

export function* getPoolTags(action: GetPoolTagsAction) {
  try {
    const response: Array<{
      id: string;
      createdAt: string;
      updatedAt: string;
      deletedDate: string;
      title: string;
      colorName: string;
      colorCode: string;
    }> = yield call(IntermediateAPI.getPoolTags, action.payload.poolId);
    yield put(
      poolTagsGetSuccess(
        response.map((poolTag) => ({
          id: poolTag.id,
          title: poolTag.title,
          colorCode: poolTag.colorCode,
          colorName: poolTag.colorName,
          state: RequestState.None,
        })),
      ),
    );
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolTagsGetError(message));
  }
}

export function* createPoolTag(action: CreatePoolTagAction) {
  const { colorCode, colorName, onFinish, poolId, title } = action.payload;
  try {
    const response: IPoolTag & { pool: IPool } = yield call(
      IntermediateAPI.createPoolTag,
      poolId,
      colorCode,
      colorName,
      title,
    );
    yield put(poolTagCreateSuccess(response));
    onFinish(true, response.id);
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolTagCreateError({ error: message, colorCode }));
    onFinish(false, '');
  }
}

export function* editPoolTag(action: EditPoolTagAction) {
  const { onFinish, poolTagId, title } = action.payload;
  try {
    const response: IPoolTag = yield call(IntermediateAPI.editPoolTag, poolTagId, title);
    yield put(poolTagEditSuccess(response));
    onFinish(true);
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolTagEditError({ error: message, poolTagId }));
    onFinish(false);
  }
}

export function* deletePoolTag(action: DeletePoolTagAction) {
  const { onFinish, poolTagId } = action.payload;
  try {
    const response: IPoolTag = yield call(IntermediateAPI.deletePoolTag, poolTagId);
    yield put(poolTagDeleteSuccess(response));
    onFinish(true);
  } catch (err) {
    const message = getErrorText(err);
    yield put(poolTagDeleteError({ error: message, poolTagId }));
    onFinish(false);
  }
}

export default function* root() {
  yield all([
    takeLatest(POOLS_GET, getPools),
    takeLatest(STATS_GET, getStats),
    takeLatest(POOL_CREATE, createPool),
    takeLatest(POOL_GET_BY_ID, getPoolById),
    takeLatest(POOL_UPDATE, updatePool),
    takeLatest(POOL_GET_OFFER_ANNOTATION, getOfferAnnotation),
    takeLatest(UNAPPROVED_CLIENT_REQUESTS_GET, getUnapprovedClientRequests),
    takeLatest(CLIENT_REQUESTS_GET, getClientRequests),
    takeLatest(UNAPPROVED_OFFERS_GET, getUnapprovedOffers),
    takeLatest(ORGANIZATIONS_FIND, findOrganizations),
    takeEvery(POOL_FILES_GET, getPoolFiles),
    takeLatest(GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS, getInstallationWizardCustomImageUrls),
    takeLatest(POOL_FILE_UPLOAD, uploadPoolFile),
    takeLatest(CUSTOM_DOCUMENTS_GET, getCustomDocument),
    takeEvery(CUSTOM_DOCUMENTS_CREATE, createCustomDocument),
    takeEvery(CUSTOM_DOCUMENTS_UPDATE, updateCustomDocument),
    takeEvery(CUSTOM_DOCUMENTS_DELETE, deleteCustomDocument),
    takeLatest(INVITE_ORGANIZATION, inviteOrganization),
    takeLatest(GET_QUESTIONNAIRE_CONFIGS, getQuestionnaireConfig),
    takeLatest(GET_QUESTIONNAIRE, getQuestionnaire),
    takeLatest(CREATE_QUESTIONNAIRE_CONFIGS, createQuestionnaireConfig),
    takeEvery(UPDATE_QUESTIONNAIRE_CONFIGS, updateQuestionnaireConfig),
    takeEvery(DELETE_QUESTIONNAIRE_CONFIGS, deleteQuestionnaireConfig),
    takeLatest(GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER, getQuestionnaireConfigForCustomer),
    takeLatest(LANDING_PAGE_GET, getLandingPage),
    takeLatest(SERVICE_FEE_DESCRIPTION_GET, servicesFeeDescriptionGetSaga),
    takeLatest(INVITE_TOKEN_UPDATE, updateInviteToken),
    takeLatest(CUSTOM_DOCUMENTS_IMAGE_UPLOAD, uploadCustomDocumentImage),
    takeLatest(CUSTOM_DOCUMENTS_IMAGES_GET, getCustomDocumentImages),
    takeLatest(CUSTOM_DOCUMENTS_MEDIA_LINK_GET, getCustomDocumentMediaLink),
    takeLatest(EXISTING_ORGANIZATION_INVITE, inviteExistingOrganization),
    takeLatest(ORGANIZATIONS_INVITES_GET, getOrganizationsInvites),
    takeLatest(POOL_GET_REQUESTS_BY_ID, getRequestsByPoolId),
    takeEvery(ORGANIZATIONS_INVITE_DELETE, deleteOrganizationInvite),
    takeLatest(POOL_OFFERS_GET, getPoolOffers),
    takeLatest(POOL_CLIENT_REQUESTS_GET, getPoolClientRequests),
    takeLatest(POOL_INVITE_TOKENS_GET, getPoolInviteTokens),
    takeLatest(SEND_EMAIL_REMINDER, sendEmailReminder),
    takeLatest(ORDER_INSTALLER_DIRECTLY, postOrderInstallerDirectly),
    takeLatest(ORDER_DOWNLOAD_PDF, getOrderDownLoadPdf),
    takeLatest(GET_CURRENCIES_BY_ORGANIZATION, getCurrenciesByOrganization),
    takeLatest(GET_LEGAL_DOCUMENTS, getPoolLegalDocuments),
    takeLatest(SAVE_LEGAL_DOCUMENTS, savePoolLegalDocuments),
    takeLatest(GET_POOL_TAGS, getPoolTags),
    takeLatest(CREATE_POOL_TAG, createPoolTag),
    takeLatest(EDIT_POOL_TAG, editPoolTag),
    takeLatest(DELETE_POOL_TAG, deletePoolTag),
  ]);
}
