import React from 'react';
import clsx from 'clsx';
import { default as ANTSelect, SelectProps } from 'antd/lib/select';
import { DefaultOptionType } from 'antd/es/select';
import ChevronDown from 'assets/icons/chevron-down.svg';
import styles from './DropDown.module.sass';
import Icon from '../Icon/Icon';

const { Option: ANTOption } = ANTSelect;

interface IDropDownProps {
  className?: string;
  dropDownClassName?: string;
  options: DefaultOptionType[];

  loading?: boolean;
  clear?: boolean;
  suffixIcon?: any;
}

class DropDown extends React.PureComponent<IDropDownProps & SelectProps<any>> {
  static defaultProps = {
    className: '',
    options: [],
  };

  renderOptions = () => {
    const { options } = this.props;

    return options.map((item: DefaultOptionType, index: number) => (
      <ANTOption className={styles.option} value={item.value} key={`${item.value}`}>
        {item.label}
      </ANTOption>
    ));
  };

  render() {
    const { className, dropDownClassName, options, loading, clear, suffixIcon, ...rest } =
      this.props;
    const showSuffixIcon = !clear && !loading;

    // Show our suffix icon only in case
    // if no other suffix icons present.
    const icon =
      showSuffixIcon && !suffixIcon ? (
        <Icon className={styles.icon} icon={ChevronDown} />
      ) : (
        suffixIcon
      );

    const ddProps = {
      ...rest,
      loading,
      clear,
      className: clsx(styles.select, className),
      popupClassName: clsx(styles.dropdown, dropDownClassName),
      suffixIcon: icon,
    };

    return <ANTSelect {...ddProps} options={options} />;
  }
}

export default DropDown;
