import React from 'react';
import clsx from 'clsx';
import { Radio, Input as ANTInput, Select } from 'antd';
import { IOption } from 'store/common.types';
import styles from './AnswerSelection.module.sass';
import FloatingLabel, { LabelPositionType } from '../FloatingLabel/FloatingLabel';

export enum AnswerType {
  INPUT = 'input',
  RADIO = 'radio',
  NORMAL = 'normal',
  TEXTAREA = 'textarea',
  SELECT = 'select',
}

export interface IAnswerSelectionState {
  focused: boolean;
  value: string;
}

export interface IAnswerSelectionProps {
  // styling & sizing options
  className?: string;
  fluid?: boolean;
  width?: string;
  height?: string;
  align?: 'center' | 'left' | 'right';

  // type options
  checked?: boolean;
  type?: AnswerType;

  // content options
  title: string;
  message?: string;
  value?: string;
  titlePosition: LabelPositionType;
  options?: IOption[];
  disabled?: boolean;

  // event handlers
  onClick?: () => void;
  onInput?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => void;
  onBlur?(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onKeyPress?(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

class AnswerSelection extends React.PureComponent<IAnswerSelectionProps> {
  static defaultProps = {
    className: '',
    width: '',
    fluid: false,
    align: 'center',

    type: AnswerType.NORMAL,
    checked: false,

    message: '',
    value: '',
    titlePosition: LabelPositionType.CENTER,
    options: [],

    onClick: () => {},
    onInput: (event: React.FormEvent<HTMLInputElement>, value: string) => {},
    onBlur: (event: React.FormEvent<HTMLInputElement>) => {},
    onKeyPress: (event: React.FormEvent<HTMLInputElement>) => {},
  };

  constructor(props: IAnswerSelectionProps) {
    super(props);

    this.state = {
      focused: false,
      value: props.value,
    } as IAnswerSelectionState;
  }

  calculateStyles = () => {
    const { width, height, align, type } = this.props;
    const styles = {} as any;

    if (width) {
      styles.width = width;
    }

    if (height) {
      styles.height = height;
    }

    if (!!align && type === AnswerType.NORMAL) {
      styles.textAlign = align;
    }

    return styles;
  };

  toggleFocus = (focused: boolean = false) => {
    const { focused: focusedState } = this.state as IAnswerSelectionState;

    if (focusedState !== focused) {
      this.setState(() => ({ focused }));
    }
  };

  onValueChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @ts-ignore
    const { value } = event.target;

    this.setState(() => ({ value }));
    this.props.onInput!(event, value);
  };

  renderCheckMark = () => {
    const { type, checked } = this.props;
    const showCheckMark = type !== 'radio' && checked;

    return showCheckMark ? <i className={styles.checkmark} /> : null;
  };

  renderMessage = () => {
    const { type, message } = this.props;
    const showMessage = type !== 'input' && !!message;

    return showMessage ? <div className={styles.message}>{message}</div> : null;
  };

  renderContent = () => {
    switch (this.props.type) {
      case AnswerType.RADIO:
        return this.renderRadioContent();

      case AnswerType.INPUT:
      case AnswerType.TEXTAREA:
        return this.renderDynamicContent(this.props.type);

      case AnswerType.SELECT:
        return this.renderSelectContent();

      default:
        return this.renderStaticContent();
    }
  };

  renderSelectContent = () => {
    const { title, value, options, disabled } = this.props;
    return (
      <Select
        placeholder={title}
        options={options}
        value={value || null}
        style={{ width: '100%' }}
        onChange={(value) => this.onValueChange({ target: { value } } as any)}
        disabled={disabled}
        virtual={false}
      />
    );
  };

  renderStaticContent = () => {
    const { align } = this.props;
    const stylesObj = {} as any;

    if (align) {
      stylesObj.textAlign = align;
    }

    return (
      <div className={styles.staticTitle} style={stylesObj}>
        <span className={styles.staticText}>{this.props.title}</span>
      </div>
    );
  };

  renderDynamicContent = (type: AnswerType) => {
    const inputProps = {
      onChange: this.onValueChange,
      onFocus: () => this.toggleFocus(true),
      onBlur: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.toggleFocus(false);
        this.props.onBlur!(e);
      },
      onKeyPress: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onKeyPress && this.props.onKeyPress(e);
      },
    };

    const InputComponent = type === AnswerType.INPUT ? ANTInput : ANTInput.TextArea;

    return (
      <div className={styles.dynamic}>
        <FloatingLabel
          label={this.props.title}
          labelPosition={this.props.titlePosition}
          value={this.props.value}
        >
          <InputComponent {...inputProps} value={this.props.value} />
        </FloatingLabel>
      </div>
    );
  };

  renderRadioContent = () => {
    const { title, checked } = this.props;
    return (
      <div className={styles.radio}>
        <Radio checked={checked} className={styles.radioBtn} />
        <div className={styles.title}>{title}</div>
      </div>
    );
  };

  render() {
    const { className, type, checked, fluid, width, value, onClick } = this.props;
    const { focused } = this.state as IAnswerSelectionState;

    const conditionalClassNames = {
      [styles.checked]: checked,
      [styles.fluid]: !width && fluid,
      [styles.focused]: focused || !!value,
    };

    const classNames = clsx(styles[type!], styles.container, conditionalClassNames, className);

    return (
      <div className={classNames} style={this.calculateStyles()} onClick={onClick}>
        {this.renderCheckMark()}
        {this.renderContent()}
        {this.renderMessage()}
      </div>
    );
  }
}

export default AnswerSelection;
