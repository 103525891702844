import React from 'react';
import { Button, Card, Col, Form, FormInstance, FormListFieldData, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { ValidationFactory } from 'framework/validations';
import { ComponentRowInputs } from './ComponentRowInputs';

interface IBundleComponentFormProps {
  field: FormListFieldData;
  remove: (fieldIndex: number) => void;
  form: FormInstance;
  formPath: (string | number)[];
}
export const BundleComponentForm = ({
  field,
  remove,
  form,
  formPath,
}: IBundleComponentFormProps) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Row gutter={[16, 16]} align={'middle'}>
        <Col span={10}>
          <Form.Item
            {...field}
            name={[field.name, 'category']}
            rules={[ValidationFactory.REQUIRED]}
            label={t('intermediate:myProjects:bundle.componentCategoryName')}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Button
            onClick={() => remove(field.name)}
            icon={<DeleteOutlined />}
            type={'primary'}
            danger={true}
          />
        </Col>
      </Row>

      <Form.List name={[field.name, 'items']}>
        {(component, { add, remove }) => (
          <ComponentRowInputs
            formPath={[...formPath, field.name, 'items']}
            form={form}
            components={component}
            add={add}
            remove={remove}
          />
        )}
      </Form.List>
    </Card>
  );
};
