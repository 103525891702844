import { SupportedLanguages } from 'framework/constants';
import {
  ClientRequestState,
  IClientRequest,
  IDocument,
  MediaCategoryType,
} from 'store/client-request/client-request.types';
import { ISearchDto, RequestState } from 'store/common.types';
import { IOffer } from 'store/offer/offer.types';
import { IInviteTokenData } from 'store/register/register.types';
import {
  CREATE_QUESTIONNAIRE_CONFIGS,
  CREATE_QUESTIONNAIRE_CONFIGS_ERROR,
  CREATE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  CUSTOM_DOCUMENTS_CREATE,
  CUSTOM_DOCUMENTS_CREATE_ERROR,
  CUSTOM_DOCUMENTS_CREATE_SUCCESS,
  CUSTOM_DOCUMENTS_GET,
  CUSTOM_DOCUMENTS_GET_ERROR,
  CUSTOM_DOCUMENTS_GET_SUCCESS,
  CUSTOM_DOCUMENTS_IMAGE_UPLOAD,
  CUSTOM_DOCUMENTS_IMAGE_UPLOAD_ERROR,
  CUSTOM_DOCUMENTS_IMAGE_UPLOAD_SUCCESS,
  CUSTOM_DOCUMENTS_IMAGES_GET,
  CUSTOM_DOCUMENTS_IMAGES_GET_ERROR,
  CUSTOM_DOCUMENTS_IMAGES_GET_SUCCESS,
  CUSTOM_DOCUMENTS_MEDIA_LINK_GET,
  CUSTOM_DOCUMENTS_MEDIA_LINK_GET_ERROR,
  CUSTOM_DOCUMENTS_MEDIA_LINK_GET_SUCCESS,
  CUSTOM_DOCUMENTS_UPDATE,
  CUSTOM_DOCUMENTS_UPDATE_ERROR,
  CUSTOM_DOCUMENTS_UPDATE_SUCCESS,
  CustomDocumentsCategoryType,
  DELETE_QUESTIONNAIRE_CONFIGS,
  DELETE_QUESTIONNAIRE_CONFIGS_ERROR,
  DELETE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  EXISTING_ORGANIZATION_INVITE,
  EXISTING_ORGANIZATION_INVITE_ERROR,
  EXISTING_ORGANIZATION_INVITE_SUCCESS,
  GET_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_CONFIGS,
  GET_QUESTIONNAIRE_CONFIGS_ERROR,
  GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER,
  GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_ERROR,
  GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_SUCCESS,
  GET_QUESTIONNAIRE_CONFIGS_SUCCESS,
  GET_QUESTIONNAIRE_ERROR,
  GET_QUESTIONNAIRE_SUCCESS,
  ICustomDocument,
  INVITE_ORGANIZATION,
  INVITE_ORGANIZATION_ERROR,
  INVITE_ORGANIZATION_SUCCESS,
  INVITE_TOKEN_UPDATE,
  INVITE_TOKEN_UPDATE_ERROR,
  INVITE_TOKEN_UPDATE_SUCCESS,
  IOrganizationInvite,
  IPool,
  IPoolCreate,
  IPoolOffersParams,
  IQuestionnaireConfig,
  IStats,
  LANDING_PAGE_GET,
  LANDING_PAGE_GET_ERROR,
  LANDING_PAGE_GET_SUCCESS,
  Organization,
  ORGANIZATIONS_FIND,
  ORGANIZATIONS_FIND_ERROR,
  ORGANIZATIONS_FIND_SUCCESS,
  ORGANIZATIONS_INVITE_DELETE,
  ORGANIZATIONS_INVITE_DELETE_ERROR,
  ORGANIZATIONS_INVITE_DELETE_SUCCESS,
  ORGANIZATIONS_INVITES_GET,
  ORGANIZATIONS_INVITES_GET_ERROR,
  ORGANIZATIONS_INVITES_GET_SUCCESS,
  POOL_CLIENT_REQUESTS_GET,
  POOL_CLIENT_REQUESTS_GET_ERROR,
  POOL_CLIENT_REQUESTS_GET_SUCCESS,
  POOL_CREATE,
  POOL_CREATE_ERROR,
  POOL_CREATE_SUCCESS,
  POOL_FILE_UPLOAD,
  POOL_FILE_UPLOAD_ERROR,
  POOL_FILE_UPLOAD_SUCCESS,
  POOL_FILES_GET,
  POOL_FILES_GET_ERROR,
  POOL_FILES_GET_SUCCESS,
  POOL_GET_BY_ID,
  POOL_GET_BY_ID_ERROR,
  POOL_GET_BY_ID_SUCCESS,
  POOL_GET_REQUESTS_BY_ID,
  POOL_GET_REQUESTS_BY_ID_ERROR,
  POOL_GET_REQUESTS_BY_ID_SUCCESS,
  POOL_INVITE_TOKENS_GET,
  POOL_INVITE_TOKENS_GET_ERROR,
  POOL_INVITE_TOKENS_GET_SUCCESS,
  POOL_OFFERS_GET,
  POOL_OFFERS_GET_ERROR,
  POOL_OFFERS_GET_SUCCESS,
  POOL_UPDATE,
  POOL_UPDATE_ERROR,
  POOL_UPDATE_SUCCESS,
  SERVICE_FEE_DESCRIPTION_GET,
  SERVICE_FEE_DESCRIPTION_GET_ERROR,
  SERVICE_FEE_DESCRIPTION_GET_SUCCESS,
  ServicesFeeDescriptionGetAction,
  ServicesFeeDescriptionGetErrorAction,
  ServicesFeeDescriptionGetSuccessAction,
  STATS_GET,
  STATS_GET_ERROR,
  STATS_GET_SUCCESS,
  UNAPPROVED_CLIENT_REQUESTS_GET,
  UNAPPROVED_CLIENT_REQUESTS_GET_ERROR,
  UNAPPROVED_CLIENT_REQUESTS_GET_SUCCESS,
  UNAPPROVED_OFFERS_GET,
  UNAPPROVED_OFFERS_GET_ERROR,
  UNAPPROVED_OFFERS_GET_SUCCESS,
  UPDATE_QUESTIONNAIRE_CONFIGS,
  UPDATE_QUESTIONNAIRE_CONFIGS_ERROR,
  UPDATE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  SEND_EMAIL_REMINDER,
  SEND_EMAIL_REMINDER_ERROR,
  IUnapprovedOffersParams,
  INTERMEDIATE_ARCHIVE_SUCCESS,
  POOL_GET_OFFER_ANNOTATION,
  POOL_GET_OFFER_ANNOTATION_SUCCESS,
  POOL_GET_OFFER_ANNOTATION_ERROR,
  ORDER_INSTALLER_DIRECTLY,
  ORDER_INSTALLER_DIRECTLY_SUCCESS,
  ORDER_INSTALLER_DIRECTLY_ERROR,
  ORDER_DOWNLOAD_PDF,
  ORDER_DOWNLOAD_PDF_SUCCESS,
  ORDER_DOWNLOAD_PDF_ERROR,
  GET_CURRENCIES_BY_ORGANIZATION,
  GET_CURRENCIES_BY_ORGANIZATION_SUCCESS,
  GET_CURRENCIES_BY_ORGANIZATION_ERROR,
  ICurrency,
  GET_LEGAL_DOCUMENTS,
  GET_LEGAL_DOCUMENTS_ERROR,
  GET_LEGAL_DOCUMENTS_SUCCESS,
  SAVE_LEGAL_DOCUMENTS,
  SAVE_LEGAL_DOCUMENTS_SUCCESS,
  SAVE_LEGAL_DOCUMENTS_ERROR,
  CustomDocumentTextType,
  IQuestionnaire,
  ISearchPoolDto,
  CUSTOM_DOCUMENTS_DELETE_SUCCESS,
  CUSTOM_DOCUMENTS_DELETE_ERROR,
  CUSTOM_DOCUMENTS_DELETE,
  GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS,
  GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_SUCCESS,
  GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_ERROR,
  RESET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS,
  RESET_POOL_FILES,
  POOLS_GET,
  POOLS_GET_SUCCESS,
  POOLS_GET_ERROR,
  GET_POOL_TAGS,
  IPoolTag,
  GET_POOL_TAGS_SUCCESS,
  GET_POOL_TAGS_ERROR,
  CREATE_POOL_TAG,
  CREATE_POOL_TAG_SUCCESS,
  CREATE_POOL_TAG_ERROR,
  EDIT_POOL_TAG,
  EDIT_POOL_TAG_SUCCESS,
  EDIT_POOL_TAG_ERROR,
  DELETE_POOL_TAG,
  DELETE_POOL_TAG_SUCCESS,
  DELETE_POOL_TAG_ERROR,
  CLIENT_REQUESTS_GET,
  CLIENT_REQUESTS_GET_SUCCESS,
  CLIENT_REQUESTS_GET_ERROR,
} from './intermediate.types';

//Get Pool Invite Tokens
export const poolInviteTokensGet = (poolId: string) => ({
  type: POOL_INVITE_TOKENS_GET,
  payload: poolId,
});

export const poolInviteTokensGetSuccess = (payload: IInviteTokenData[]) => ({
  type: POOL_INVITE_TOKENS_GET_SUCCESS,
  payload: payload,
});

export const poolInviteTokensGetError = (error: any) => ({
  type: POOL_INVITE_TOKENS_GET_ERROR,
  payload: error,
});

//Delete Organization Invite
export const organizationInviteDelete = (inviteId: string) => ({
  type: ORGANIZATIONS_INVITE_DELETE,
  payload: inviteId,
});

export const organizationInviteDeleteSuccess = (payload: any) => ({
  type: ORGANIZATIONS_INVITE_DELETE_SUCCESS,
  payload: payload,
});

export const organizationInviteDeleteError = (error: any) => ({
  type: ORGANIZATIONS_INVITE_DELETE_ERROR,
  payload: error,
});

//Get Organizations Invites
export const organizationsInvitesGet = (poolId: string) => ({
  type: ORGANIZATIONS_INVITES_GET,
  payload: poolId,
});

export const organizationsInvitesGetSuccess = (payload: any) => ({
  type: ORGANIZATIONS_INVITES_GET_SUCCESS,
  payload: payload,
});

export const organizationsInvitesGetError = (error: any) => ({
  type: ORGANIZATIONS_INVITES_GET_ERROR,
  payload: error,
});

//Invite Existing Organization
export const existingOrganizationInvite = (poolId: string, organizationId: string) => ({
  type: EXISTING_ORGANIZATION_INVITE,
  payload: {
    poolId,
    organizationId,
  },
});

export const existingOrganizationInviteSuccess = (payload: any) => ({
  type: EXISTING_ORGANIZATION_INVITE_SUCCESS,
  payload: payload,
});

export const existingOrganizationInviteError = (error: any) => ({
  type: EXISTING_ORGANIZATION_INVITE_ERROR,
  payload: error,
});

//Get Custom Document Media Link
export const customDocumentMediaLinkGet = (customDocumentId: string, mediaName: string) => ({
  type: CUSTOM_DOCUMENTS_MEDIA_LINK_GET,
  payload: {
    customDocumentId,
    mediaName,
  },
});

export const customDocumentMediaLinkGetSuccess = (payload: any) => ({
  type: CUSTOM_DOCUMENTS_MEDIA_LINK_GET_SUCCESS,
  payload: payload,
});

export const customDocumentMediaLinkGetError = (error: any) => ({
  type: CUSTOM_DOCUMENTS_MEDIA_LINK_GET_ERROR,
  payload: error,
});

//Get Custom Document Images
export const customDocumentImagesGet = (customDocumentId: string) => ({
  type: CUSTOM_DOCUMENTS_IMAGES_GET,
  payload: customDocumentId,
});

export const customDocumentImagesGetSuccess = (payload: IDocument[]) => ({
  type: CUSTOM_DOCUMENTS_IMAGES_GET_SUCCESS,
  payload: payload,
});

export const customDocumentImagesGetError = (error: any) => ({
  type: CUSTOM_DOCUMENTS_IMAGES_GET_ERROR,
  payload: error,
});

//Upload Custom Document Image
export const customDocumentImageUpload = (
  document: File,
  category: MediaCategoryType,
  uploadProgress: any,
  uploadSuccess?: any,
  uploadError?: any,
) => ({
  type: CUSTOM_DOCUMENTS_IMAGE_UPLOAD,
  payload: {
    document,
    category,
    uploadProgress,
    uploadSuccess,
    uploadError,
  },
});

export const clientRequestArchiveSuccess = (payload: any) => ({
  type: INTERMEDIATE_ARCHIVE_SUCCESS,
  payload: payload,
});

export const customDocumentImageUploadSuccess = (payload: IDocument) => ({
  type: CUSTOM_DOCUMENTS_IMAGE_UPLOAD_SUCCESS,
  payload: payload,
});

export const customDocumentImageUploadError = (error: any) => ({
  type: CUSTOM_DOCUMENTS_IMAGE_UPLOAD_ERROR,
  payload: error,
});

//Update Invite Token
export const inviteTokenUpdate = (tokenId: string, token: IInviteTokenData) => ({
  type: INVITE_TOKEN_UPDATE,
  payload: {
    tokenId,
    token,
  },
});

export const inviteTokenUpdateSuccess = () => ({
  type: INVITE_TOKEN_UPDATE_SUCCESS,
});

export const inviteTokenUpdateError = (error: any) => ({
  type: INVITE_TOKEN_UPDATE_ERROR,
  payload: error,
});

//Get Landing Page
export const landingPageGet = (poolId: string, category: string, onSuccess?: Function) => ({
  type: LANDING_PAGE_GET,
  payload: {
    poolId,
    category,
    onSuccess,
  },
});

export const landingPageGetSuccess = (payload: ICustomDocument) => ({
  type: LANDING_PAGE_GET_SUCCESS,
  payload: payload,
});

export const landingPageGetError = (error: any) => ({
  type: LANDING_PAGE_GET_ERROR,
  payload: error,
});
export const servicesFeeDescriptionGet = (poolId: string): ServicesFeeDescriptionGetAction => ({
  type: SERVICE_FEE_DESCRIPTION_GET,
  payload: {
    poolId,
  },
});
export const servicesFeeDescriptionGetSuccess = (
  descriptionDocument: ICustomDocument,
): ServicesFeeDescriptionGetSuccessAction => ({
  type: SERVICE_FEE_DESCRIPTION_GET_SUCCESS,
  payload: descriptionDocument,
});
export const servicesFeeDescriptionGetError = (
  error: string,
): ServicesFeeDescriptionGetErrorAction => ({
  type: SERVICE_FEE_DESCRIPTION_GET_ERROR,
  payload: error,
});

//Get Questionnaire Config For Customer
export const questionnaireConfigForCustomerGet = () => ({
  type: GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER,
});

export const questionnaireConfigForCustomerGetSuccess = (payload: IQuestionnaireConfig[]) => ({
  type: GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_SUCCESS,
  payload: payload,
});

export const questionnaireConfigForCustomerGetError = (error: any) => ({
  type: GET_QUESTIONNAIRE_CONFIGS_FOR_CUSTOMER_ERROR,
  payload: error,
});

//Delete Questionnaire Config
export const questionnaireConfigDelete = (poolId: string, configId: string) => ({
  type: DELETE_QUESTIONNAIRE_CONFIGS,
  payload: {
    poolId,
    configId,
  },
});

export const questionnaireConfigDeleteSuccess = (payload: string) => ({
  type: DELETE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  payload: payload,
});

export const questionnaireConfigDeleteError = (error: any) => ({
  type: DELETE_QUESTIONNAIRE_CONFIGS_ERROR,
  payload: error,
});

//Update Questionnaire Config
export const questionnaireConfigUpdate = (
  poolId: string,
  configId: string,
  config: IQuestionnaireConfig,
) => ({
  type: UPDATE_QUESTIONNAIRE_CONFIGS,
  payload: {
    poolId,
    configId,
    config,
  },
});

export const questionnaireConfigUpdateSuccess = (payload: IQuestionnaireConfig) => ({
  type: UPDATE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  payload: payload,
});

export const questionnaireConfigUpdateError = (error: any) => ({
  type: UPDATE_QUESTIONNAIRE_CONFIGS_ERROR,
  payload: error,
});

//Create Questionnaire Config
export const questionnaireConfigCreate = (poolId: string, config: IQuestionnaireConfig) => ({
  type: CREATE_QUESTIONNAIRE_CONFIGS,
  payload: {
    poolId,
    config,
  },
});

export const questionnaireConfigCreateSuccess = (payload: IQuestionnaireConfig) => ({
  type: CREATE_QUESTIONNAIRE_CONFIGS_SUCCESS,
  payload: payload,
});

export const questionnaireConfigCreateError = (error: any) => ({
  type: CREATE_QUESTIONNAIRE_CONFIGS_ERROR,
  payload: error,
});

//Get Questionnaire Config
export const questionnaireConfigGet = (payload: string) => ({
  type: GET_QUESTIONNAIRE_CONFIGS,
  payload: payload,
});

export const questionnaireConfigGetSuccess = (payload: IQuestionnaireConfig[]) => ({
  type: GET_QUESTIONNAIRE_CONFIGS_SUCCESS,
  payload: payload,
});

export const questionnaireConfigGetError = (error: any) => ({
  type: GET_QUESTIONNAIRE_CONFIGS_ERROR,
  payload: error,
});

export const questionnaireGet = (payload: string) => ({
  type: GET_QUESTIONNAIRE,
  payload: payload,
});

export const questionnaireGetSuccess = (payload: IQuestionnaire[]) => ({
  type: GET_QUESTIONNAIRE_SUCCESS,
  payload: payload,
});

export const questionnaireGetError = (error: any) => ({
  type: GET_QUESTIONNAIRE_ERROR,
  payload: error,
});

//Send Invitation Link to Organization
export const organizationInvite = (inviteData: IOrganizationInvite, onSuccess?: any) => ({
  type: INVITE_ORGANIZATION,
  payload: {
    inviteData,
    onSuccess,
  },
});

export const organizationInviteSuccess = (payload: any) => ({
  type: INVITE_ORGANIZATION_SUCCESS,
  payload: payload,
});

export const organizationInviteError = (error: any) => ({
  type: INVITE_ORGANIZATION_ERROR,
  payload: error,
});

//Update Custom Documents
export const customDocumentUpdate = (
  poolId: string,
  documentId: string,
  document: ICustomDocument,
) => ({
  type: CUSTOM_DOCUMENTS_UPDATE,
  payload: {
    poolId,
    documentId,
    document,
  },
});

export const customDocumentUpdateSuccess = (payload: ICustomDocument) => ({
  type: CUSTOM_DOCUMENTS_UPDATE_SUCCESS,
  payload: payload,
});

export const customDocumentUpdateError = (error: any) => ({
  type: CUSTOM_DOCUMENTS_UPDATE_ERROR,
  payload: error,
});
export const customDocumentDelete = (documentId: string) => ({
  type: CUSTOM_DOCUMENTS_DELETE,
  payload: {
    documentId,
  },
});
export const customDocumentDeleteSuccess = (documentId: string) => ({
  type: CUSTOM_DOCUMENTS_DELETE_SUCCESS,
  payload: { documentId },
});
export const customDocumentDeleteError = (error: any) => ({
  type: CUSTOM_DOCUMENTS_DELETE_ERROR,
  payload: error,
});
//Create Custom Documents
export const customDocumentCreate = (
  poolId: string,
  document: {
    category: CustomDocumentsCategoryType;
    text?: string;
    imageLink?: string;
    media?: any[];
    heading?: string;
    // [TODO: Make it mandatory]
    lang?: SupportedLanguages;
  },
  createSuccess?: any,
) => ({
  type: CUSTOM_DOCUMENTS_CREATE,
  payload: {
    poolId,
    document,
    createSuccess,
  },
});

export const customDocumentCreateSuccess = (payload: ICustomDocument) => ({
  type: CUSTOM_DOCUMENTS_CREATE_SUCCESS,
  payload: payload,
});

export const customDocumentCreateError = (error: any) => ({
  type: CUSTOM_DOCUMENTS_CREATE_ERROR,
  payload: error,
});

//Get Custom Documents
export const customDocumentGet = (payload: string) => ({
  type: CUSTOM_DOCUMENTS_GET,
  payload: payload,
});

export const customDocumentGetSuccess = (payload: ICustomDocument[]) => ({
  type: CUSTOM_DOCUMENTS_GET_SUCCESS,
  payload: payload,
});

export const customDocumentGetError = (error: any) => ({
  type: CUSTOM_DOCUMENTS_GET_ERROR,
  payload: error,
});

//Get Client Request Pools
export const poolsGet = (payload?: ISearchPoolDto, onSuccess?: Function) => ({
  type: POOLS_GET,
  payload: {
    payload,
    onSuccess,
  },
});

export const poolsGetSuccess = (payload: { pools: IPool[]; totalPools: number }) => ({
  type: POOLS_GET_SUCCESS,
  payload: payload,
});

export const poolsGetError = (error: any) => ({
  type: POOLS_GET_ERROR,
  payload: error,
});

// Get Pool Tags
export const poolTagsGet = (payload: { poolId: string }) => ({
  type: GET_POOL_TAGS,
  payload,
});
export const poolTagsGetSuccess = (payload: Array<IPoolTag & { state: RequestState }>) => ({
  type: GET_POOL_TAGS_SUCCESS,
  payload,
});
export const poolTagsGetError = (payload: { error: string }) => ({
  type: GET_POOL_TAGS_ERROR,
  payload,
});

// Create Pool Tags
export const poolTagCreate = (payload: {
  colorCode: string;
  colorName: string;
  onFinish: (isSuccess: boolean, poolTagId: string) => void;
  poolId: string;
  title: string;
}) => ({
  type: CREATE_POOL_TAG,
  payload,
});
export const poolTagCreateSuccess = (payload: IPoolTag & { pool: IPool }) => ({
  type: CREATE_POOL_TAG_SUCCESS,
  payload,
});
export const poolTagCreateError = (payload: { error: string; colorCode: string }) => ({
  type: CREATE_POOL_TAG_ERROR,
  payload,
});

// Edit Pool Tag
export const poolTagEdit = (payload: {
  onFinish: (isSuccess: boolean) => void;
  poolTagId: string;
  title: string;
}) => ({
  type: EDIT_POOL_TAG,
  payload,
});
export const poolTagEditSuccess = (payload: IPoolTag) => ({
  type: EDIT_POOL_TAG_SUCCESS,
  payload,
});
export const poolTagEditError = (payload: { error: string; poolTagId: string }) => ({
  type: EDIT_POOL_TAG_ERROR,
  payload,
});

// Delete Pool Tag
export const poolTagDelete = (payload: {
  onFinish: (isSuccess: boolean) => void;
  poolTagId: string;
}) => ({
  type: DELETE_POOL_TAG,
  payload,
});
export const poolTagDeleteSuccess = (payload: IPoolTag) => ({
  type: DELETE_POOL_TAG_SUCCESS,
  payload,
});
export const poolTagDeleteError = (payload: { error: string; poolTagId: string }) => ({
  type: DELETE_POOL_TAG_ERROR,
  payload,
});

//Get Stats
export const statsGet = () => ({
  type: STATS_GET,
});

export const statsGetSuccess = (payload: IStats) => ({
  type: STATS_GET_SUCCESS,
  payload: payload,
});

export const statstGetError = (error: any) => ({
  type: STATS_GET_ERROR,
  payload: error,
});

//Get Pool Files
export const poolFileUpload = (
  document: File,
  poolId: string,
  category: MediaCategoryType,
  uploadProgress: any,
  uploadSuccess?: any,
  uploadError?: any,
  onSuccess?: () => void,
) => ({
  type: POOL_FILE_UPLOAD,
  payload: {
    document,
    poolId,
    category,
    uploadProgress,
    uploadSuccess,
    uploadError,
    onSuccess,
  },
});

export const poolFileUploadSuccess = (payload: IDocument) => ({
  type: POOL_FILE_UPLOAD_SUCCESS,
  payload: payload,
});

export const poolFileUploadError = (error: any) => ({
  type: POOL_FILE_UPLOAD_ERROR,
  payload: error,
});

//Get Pool Files
export const poolFilesGet = (poolId: string, category: MediaCategoryType) => ({
  type: POOL_FILES_GET,
  payload: {
    poolId,
    category,
  },
});

export const poolFilesGetSuccess = (payload: IDocument[]) => ({
  type: POOL_FILES_GET_SUCCESS,
  payload: payload,
});

export const poolFilesGetError = (error: any) => ({
  type: POOL_FILES_GET_ERROR,
  payload: error,
});

export const resetPoolFiles = () => ({
  type: RESET_POOL_FILES,
});

export const getInstallationWizardCustomImageUrls = (payload: {
  wallboxOnlyFileName: string;
  wallboxAndInstallationFileName: string;
}) => ({
  type: GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS,
  payload,
});

export const getInstallationWizardCustomImageUrlsSuccess = (payload: {
  wallboxOnlyUrl: string;
  wallboxAndInstallationUrl: string;
}) => ({
  type: GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_SUCCESS,
  payload,
});

export const getInstallationWizardCustomImageUrlsError = (payload: { error: string }) => ({
  type: GET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS_ERROR,
  payload,
});

export const resetInstallationWizardCustomImageUrls = () => ({
  type: RESET_INSTALLATION_WIZARD_CUSTOM_IMAGE_URLS,
});

//Create Client Request Pool
export const poolCreate = (pool: IPoolCreate, onSuccess?: () => void) => ({
  type: POOL_CREATE,
  payload: { pool, onSuccess },
});

export const poolCreateSuccess = (payload: IPool) => ({
  type: POOL_CREATE_SUCCESS,
  payload: payload,
});

export const poolCreateError = (error: any) => ({
  type: POOL_CREATE_ERROR,
  payload: error,
});

//Get Client Request Pool By ID
export const poolGetById = (payload: string) => ({
  type: POOL_GET_BY_ID,
  payload: payload,
});

export const poolGetByIdSuccess = (payload: IPool) => ({
  type: POOL_GET_BY_ID_SUCCESS,
  payload: payload,
});

export const poolGetByIdError = (error: any) => ({
  type: POOL_GET_BY_ID_ERROR,
  payload: error,
});

//Get Client Requests available for an intermediate by pool id
export const poolGetRequestsById = (searchDto: ISearchDto, poolId: string) => ({
  type: POOL_GET_REQUESTS_BY_ID,
  payload: {
    searchDto,
    poolId,
  },
});

export const poolGetRequestsByIdSuccess = (payload: IClientRequest[]) => ({
  type: POOL_GET_REQUESTS_BY_ID_SUCCESS,
  payload: payload,
});

export const poolGetRequestsByIdError = (error: any) => ({
  type: POOL_GET_REQUESTS_BY_ID_ERROR,
  payload: error,
});

export const poolUpdate = (poolId?: string, pool?: Partial<IPool>) => ({
  type: POOL_UPDATE,
  payload: {
    poolId,
    pool,
  },
});

export const poolUpdateSuccess = (payload: IPool) => ({
  type: POOL_UPDATE_SUCCESS,
  payload: payload,
});

export const poolUpdateError = (error: any) => ({
  type: POOL_UPDATE_ERROR,
  payload: error,
});

//Get Unapproved Client Request
export const unapprovedClientRequestsGet = (offset: number, archived?: any) => ({
  type: UNAPPROVED_CLIENT_REQUESTS_GET,
  payload: { offset, archived },
});

export const unapprovedClientRequestsGetSuccess = (payload: IClientRequest[]) => ({
  type: UNAPPROVED_CLIENT_REQUESTS_GET_SUCCESS,
  payload: payload,
});

export const unapprovedClientRequestsGetError = (error: any) => ({
  type: UNAPPROVED_CLIENT_REQUESTS_GET_ERROR,
  payload: error,
});

//Get Client Requests (search)
export const clientRequestsGet = (
  offset: number,
  query?: string,
  states?: Array<ClientRequestState>,
  excludedStates?: Array<ClientRequestState>,
  archived?: any,
) => ({
  type: CLIENT_REQUESTS_GET,
  payload: { offset, query, states, archived, excludedStates },
});

export const clientRequestsGetSuccess = (payload: IClientRequest[]) => ({
  type: CLIENT_REQUESTS_GET_SUCCESS,
  payload: payload,
});

export const clientRequestsGetError = (error: any) => ({
  type: CLIENT_REQUESTS_GET_ERROR,
  payload: error,
});

//Get Pool Client Request
export const poolClientRequestsGet = (query: string) => ({
  type: POOL_CLIENT_REQUESTS_GET,
  payload: query,
});

export const poolClientRequestsGetSuccess = (payload: IClientRequest[]) => ({
  type: POOL_CLIENT_REQUESTS_GET_SUCCESS,
  payload: payload,
});

export const poolClientRequestsGetError = (error: any) => ({
  type: POOL_CLIENT_REQUESTS_GET_ERROR,
  payload: error,
});

//Get Unapproved Offers
export const unapprovedOffersGet = (params: IUnapprovedOffersParams) => ({
  type: UNAPPROVED_OFFERS_GET,
  payload: params,
});

export const unapprovedOffersGetSuccess = (payload: IOffer[]) => ({
  type: UNAPPROVED_OFFERS_GET_SUCCESS,
  payload: payload,
});

export const unapprovedOffersGetError = (error: any) => ({
  type: UNAPPROVED_OFFERS_GET_ERROR,
  payload: error,
});

//Get Pool Offers
export const poolOffersGet = (payload: IPoolOffersParams) => ({
  type: POOL_OFFERS_GET,
  payload: payload,
});

export const poolOffersGetSuccess = (payload: IOffer[]) => ({
  type: POOL_OFFERS_GET_SUCCESS,
  payload: payload,
});

export const poolOffersGetError = (error: any) => ({
  type: POOL_OFFERS_GET_ERROR,
  payload: error,
});

//Find Organizations
export const organizationsFind = (
  searchValue: string,
  intermediateOrganizationId?: string,
  limit?: number,
) => ({
  type: ORGANIZATIONS_FIND,
  payload: {
    searchValue,
    limit: 50,
    intermediateOrganizationId,
  },
});

export const organizationsFindSuccess = (payload: Organization[]) => ({
  type: ORGANIZATIONS_FIND_SUCCESS,
  payload: payload,
});

export const organizationsFindError = (error: any) => ({
  type: ORGANIZATIONS_FIND_ERROR,
  payload: error,
});

export const sendEmailReminder = (payload: {
  text: string;
  userId: string;
  onSuccess: () => void;
}) => ({
  type: SEND_EMAIL_REMINDER,
  payload: payload,
});
export const sendEmailReminderError = (error: any) => ({
  type: SEND_EMAIL_REMINDER_ERROR,
  payload: error,
});

//Get Pool Offer Annotation By ID
export const poolGetOfferAnnotationById = (payload: string) => ({
  type: POOL_GET_OFFER_ANNOTATION,
  payload: payload,
});

export const poolGetOfferAnnotationByIdSuccess = (payload: string) => ({
  type: POOL_GET_OFFER_ANNOTATION_SUCCESS,
  payload: payload,
});

export const poolGetOfferAnnotationByIdError = (error: any) => ({
  type: POOL_GET_OFFER_ANNOTATION_ERROR,
  payload: error,
});
//Update Client Request Pool
export const orderInstallerDirectly = (id: string, organization: string) => ({
  type: ORDER_INSTALLER_DIRECTLY,
  payload: { id, organization },
});

//
export const orderInstallerDirectlySuccess = (payload: IClientRequest) => ({
  type: ORDER_INSTALLER_DIRECTLY_SUCCESS,
  payload: payload,
});

export const orderInstallerDirectlyError = (error: any) => ({
  type: ORDER_INSTALLER_DIRECTLY_ERROR,
  payload: error,
});

//download order pdf
export const getOrderDownLoadPdf = (requestId: string, orgId: string) => ({
  type: ORDER_DOWNLOAD_PDF,
  payload: { requestId, orgId },
});

//
export const orderDownLoadPdfSuccess = (payload: any) => ({
  type: ORDER_DOWNLOAD_PDF_SUCCESS,
  payload: payload,
});

export const orderDownLoadPdfError = (error: any) => ({
  type: ORDER_DOWNLOAD_PDF_ERROR,
  payload: error,
});

export const getCurrenciesByOrganization = (organizationId: string) => ({
  type: GET_CURRENCIES_BY_ORGANIZATION,
  payload: organizationId,
});

export const getCurrenciesByOrganizationSuccess = (payload: ICurrency[]) => ({
  type: GET_CURRENCIES_BY_ORGANIZATION_SUCCESS,
  payload: payload,
});

export const getCurrenciesByOrganizationError = (error: any) => ({
  type: GET_CURRENCIES_BY_ORGANIZATION_ERROR,
  payload: error,
});

export const getPoolLegalDocuments = (poolId: string, isPublic: boolean) => ({
  type: GET_LEGAL_DOCUMENTS,
  payload: { poolId, isPublic },
});

export const getPoolLegalDocumentsSuccess = (legalDocuments: ICustomDocument[]) => ({
  type: GET_LEGAL_DOCUMENTS_SUCCESS,
  payload: legalDocuments,
});

export const getPoolLegalDocumentsError = (error: any) => ({
  type: GET_LEGAL_DOCUMENTS_ERROR,
  payload: error,
});

export const savePoolLegalDocuments = (
  poolId: string,
  legalDocuments: {
    category: string;
    text: string;
    textType: CustomDocumentTextType;
  }[],
  onSuccess?: () => void,
) => ({
  type: SAVE_LEGAL_DOCUMENTS,
  payload: {
    poolId,
    legalDocuments,
    onSuccess,
  },
});

export const savePoolLegalDocumentsSuccess = (legalDocuments: ICustomDocument[]) => ({
  type: SAVE_LEGAL_DOCUMENTS_SUCCESS,
  payload: legalDocuments,
});

export const savePoolLegalDocumentsError = (error: any) => ({
  type: SAVE_LEGAL_DOCUMENTS_ERROR,
  payload: error,
});
