import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { IClientRequest } from 'store/client-request/client-request.types';
import { OrderState } from 'store/orders/orders.types';
import Styles from './ActionWidget.module.sass';
const LOCIZE_KEY = 'installerFlow';
const ActionWidget = ({ clientRequest }: { clientRequest: IClientRequest }) => {
  const { t } = useTranslation();
  const unconfirmedOrder = clientRequest.orders?.find(
    (order) => order.state === OrderState.Created,
  );
  return (
    <Rectangle contentClassName={Styles.Container} title={t(`${LOCIZE_KEY}:actionWidget:title`)}>
      {unconfirmedOrder && (
        <Flex vertical={true} gap={8} justify="space-between" flex={1}>
          <Typography.Text>{t(`${LOCIZE_KEY}:actionWidget:text`)}</Typography.Text>
          <Link to={`/order/${unconfirmedOrder.id}/accept`}>
            <Button
              type={'primary'}
              href={`/order/${unconfirmedOrder.id}/accept`}
              style={{ width: '100%' }}
            >
              {t(`${LOCIZE_KEY}:actionWidget:acceptJob`)}
            </Button>
          </Link>
        </Flex>
      )}
    </Rectangle>
  );
};
export default ActionWidget;
