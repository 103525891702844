import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Card,
  Select,
  Form,
  FormInstance,
  Row,
  Col,
  Flex,
  Descriptions,
  Input,
  Button,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { ValidationFactory } from 'framework/validations';
import { currentBundlesSelector } from 'store/bundle/bundle.selectors';
import { bundlesGet } from 'store/bundle/bundle.actions';
import { IClientRequest } from 'store/client-request/client-request.types';
import { IBundle } from 'store/bundle/bundle.types';
import { formatPrice } from 'utils/currencyUtils';
import { groupBy } from 'utils/array';
import { BundleComponentForm } from './BundleComponentForm';
import { IOfferDocumentBundleComponent, IOfferDocumentForm } from '../OfferDocumentGenerator';

interface IOfferDocumentBundlesFormProps {
  clientRequest?: IClientRequest;
  form: FormInstance<IOfferDocumentForm>;
}

const convertFormData = (bundle: IBundle) => {
  return {
    id: bundle.id,
    name: bundle.name,
    components: Object.entries(groupBy(bundle.components, (_component) => _component.category)).map(
      ([category, items]) => ({
        category,
        items,
      }),
    ),
  };
};

const OfferDocumentBundlesForm = ({ clientRequest, form }: IOfferDocumentBundlesFormProps) => {
  const pool = clientRequest?.pool;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadBundles = useCallback(() => {
    if (clientRequest?.pool?.id) {
      dispatch(bundlesGet(clientRequest.pool.id));
    }
  }, [dispatch, clientRequest]);
  useEffect(() => {
    loadBundles();
  }, [loadBundles]);

  const bundles = useSelector(currentBundlesSelector);
  const bundlesSelectOptions = bundles
    .filter((item) => item.active)
    .map((item) => ({ label: item.name, value: item.id }));

  const bundlesWatcher: IOfferDocumentBundleComponent[] = Form.useWatch(
    ['bundle', 'components'],
    form,
  );
  const bundlesComponentsTotalPrice = useMemo(() => {
    return bundlesWatcher?.map(
      (_bundle) =>
        _bundle?.items?.map(
          (_component) => +(_component?.quantity || 0) * +(_component?.price || 0),
        ),
    );
  }, [bundlesWatcher]);
  const componentsTotalPrice = useMemo(() => {
    return bundlesComponentsTotalPrice?.reduce(
      (total, currentBundle) =>
        total +
        (currentBundle?.reduce(
          (componentsTotal, currentComponent) => componentsTotal + currentComponent,
          0,
        ) || 0),
      0,
    );
  }, [bundlesComponentsTotalPrice]);
  const vat = useMemo(
    () => +(componentsTotalPrice * (+(pool?.country.vatRate || 1) / 100)).toFixed(2),
    [pool, componentsTotalPrice],
  );
  const totalPriceGross = useMemo(() => componentsTotalPrice + vat, [componentsTotalPrice, vat]);
  const bundleIdWatcher = Form.useWatch(['bundle', 'id'], form);
  const setBundleData = useCallback(() => {
    const bundle = bundles.find((_bundle) => _bundle.id === bundleIdWatcher);
    if (!bundle) return;
    form.setFieldValue(['bundle'], convertFormData(bundle));
  }, [bundles, bundleIdWatcher, form]);
  useEffect(() => {
    setBundleData();
  }, [setBundleData]);

  return (
    <Card title={t('intermediate:pdfGen:bundle:title')}>
      <Form.Item
        rules={[{ required: true, message: t('common:errors:fieldRequired') }]}
        name={['bundle', 'id']}
        label={t('intermediate:pdfGen:bundle:title')}
      >
        <Select
          options={bundlesSelectOptions}
          placeholder={t('installerFlow:tenderDetails:selectBundle')}
        />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <Form.Item
            name={['bundle', 'name']}
            label={t('intermediate:myProjects:bundle:name')}
            rules={[ValidationFactory.REQUIRED]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.List name={['bundle', 'components']}>
        {(fields, { add, remove }) => (
          <Flex vertical={true} gap={4}>
            {fields.map((field) => (
              <BundleComponentForm
                formPath={['bundle', 'components']}
                form={form}
                field={field}
                remove={remove}
                key={field.key}
              />
            ))}
            <Form.Item>
              <Button icon={<PlusOutlined />} type="dashed" onClick={() => add()}>
                {t(`intermediate:myProjects:bundle.newComponentCategory`)}
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>

      <Descriptions layout={'horizontal'} column={2}>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.bundleCurrency`)}>
          {pool?.country.currency}
        </Descriptions.Item>
        <Descriptions.Item label={t('intermediate:myProjects:bundle:vat')}>
          {pool?.country.vatRate}
        </Descriptions.Item>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.totalBundlePriceNet`)}>
          {formatPrice(componentsTotalPrice)}
        </Descriptions.Item>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.vat`)}>
          {formatPrice(vat)}
        </Descriptions.Item>
        <Descriptions.Item label={t(`intermediate:myProjects:bundle.totalBundlePriceGross`)}>
          {formatPrice(totalPriceGross)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
export default OfferDocumentBundlesForm;
