import React from 'react';
import { Button, Flex, Form, FormInstance, FormListFieldData } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { BundleRowForm } from './BundleRowForm';

interface IComponentRowInputsProps {
  components: FormListFieldData[];
  add: () => void;
  remove: (index: number) => void;
  form: FormInstance;
  formPath: (string | number)[];
}
export const ComponentRowInputs = ({
  add,
  remove,
  components,
  form,
  formPath,
}: IComponentRowInputsProps) => {
  const { t } = useTranslation();

  return (
    <Flex gap={8} vertical>
      {components.map((component) => (
        <BundleRowForm
          formPath={formPath}
          key={component.key}
          component={component}
          remove={remove}
          form={form}
        />
      ))}
      <Form.Item>
        <Button type="dashed" icon={<PlusOutlined />} onClick={() => add()}>
          {t(`intermediate:myProjects:bundle.newComponent`)}
        </Button>
      </Form.Item>
    </Flex>
  );
};
