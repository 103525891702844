import React from 'react';
import styles from './Imprint.module.sass';

const ImprintEn: React.FC = () => {
  return (
    <div className={styles.container}>
      <p>Information in accordance with Section 5 of the Telemedia Act::</p>
      <p>
        Schneider Electric GmbH <br />
        Gothaer Str. 29 <br />
        40880 Ratingen
      </p>
      <p>E-Mail: info@inno2fleet.com</p>
      <p>
        Managing Director: Christophe de Maistre <br />
        Deputy Chairman of the Supervisory Board: Thierry Tricot <br />
        Registered office: Ratingen <br />
      </p>
      <p>
        District Court of Düsseldorf | HRB 47852 <br />
        VAT ID No. DE225673854 <br />
      </p>
    </div>
  );
};

export default ImprintEn;
