import config from 'config';
import { ISearchDto } from 'store/common.types';
import { IProductComponent } from 'store/product-component/product-components.types';
import HttpService from './http.service';

class ProductComponentAPI extends HttpService {
  getProductComponents = (poolId: string, search?: ISearchDto) => {
    const params = {
      sortDirections: search?.sortDirection,
      sortFields: search?.sortField,
      ...search?.filters,
    };
    return this.get(`product-components/pool/${poolId}`, params);
  };
  getProductWizard = (poolId: string) => {
    return this.get(`product-components/pool/${poolId}/wizard`);
  };
  getProductWizardWithHeaders = (poolId: string, headers: any) => {
    return this.get(`product-components/pool/${poolId}/wizard`, null, true, headers);
  };
  createProductComponent = (poolId: string, body: IProductComponent) => {
    return this.post(`product-components/pool/${poolId}`, body);
  };
  updateProductComponent = (productComponentId: string, body: IProductComponent) => {
    return this.put(`product-components/${productComponentId}`, body);
  };
  deleteProductComponent = (productComponentId: string) => {
    return this.delete(`product-components/${productComponentId}`);
  };
  validateActive = (componentId: string) => {
    return this.get(`product-components/${componentId}/is-activable`);
  };
}

export default new ProductComponentAPI({
  apiURL: config.CRAFT_SERVICE_URL,
});
